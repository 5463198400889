import { axiosInstance, axiosUploadInstance } from './configured_axios';
import cookie from 'react-cookies';
import axios from 'axios';
import {
  FETCH_MY_PROFILE,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER,
  REGISTER_ERROR,
  PREVIOUS_PAGE,
  FETCH_INVERSE_REQUEST,
  FETCH_REQUEST,
  RESET_PASSWORD,
  UPDATE_CREDENTIAL_SUCCESS,
  UPDATE_CREDENTIAL_ERROR,
  SET_PROFILE_ON_SYNC_FLAG,
} from './action_types';
import { fetchMyRoomList } from '.';

function setJWT(payload) {
  const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 180);
  cookie.save('jwt', payload.jwt, { expires });
  localStorage.setItem('user', JSON.stringify(payload.user));
}

export function register(session) {
  return dispatch => {
    axiosInstance
      .post(`/api/register`, {
        session,
      })
      .then(response => {
        dispatch({ type: REGISTER, payload: response });
        dispatch(login(session));
      })
      .catch(error => dispatch({ type: REGISTER_ERROR, payload: error }));
  };
}
export function isProfileOnSyncFlag(flag) {
  return {
    type: SET_PROFILE_ON_SYNC_FLAG,
    payload: flag,
  };
}

export function fetchLoggedInUserProfile() {
  return dispatch => {
    dispatch(isProfileOnSyncFlag(true));
    // console.log('fetch Logged In User Profile action');
    axiosInstance
      .get(`/api/logged_in_user`)
      .then(request =>
        dispatch({
          type: FETCH_MY_PROFILE,
          payload: request,
        })
      )
      .then(user => dispatch(fetchMyRoomList(user.payload.data.data)))
      .then(() => dispatch(isProfileOnSyncFlag(false)));
  };
}

export function login(session) {
  return dispatch => {
    axiosInstance
      .post(`/api/email_login`, { session })
      .then(response => {
        setJWT(response.data);
        window.postMessage({ event: 'login' }, '*');
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${
          response.data.jwt
        }`;
        axiosUploadInstance.defaults.headers.common.Authorization = `Bearer ${
          response.data.jwt
        }`;
        dispatch({ type: LOGIN_SUCCESS, payload: response });
        dispatch(fetchLoggedInUserProfile());
      })
      .catch(error => dispatch({ type: LOGIN_ERROR, payload: error }));
  };
}

export function socialLogin(
  email,
  name,
  date_of_birth,
  gender,
  picture_url,
  token,
  provider,
  new_user = false
) {
  return dispatch => {
    axiosInstance
      .post(`/api/users/social_credentials`, {
        social_credential: {
          email,
          name,
          date_of_birth,
          gender,
          picture_url,
          token,
          provider,
          new_user,
        },
      })
      .then(response => {
        setJWT(response.data);
        window.postMessage({ event: 'login' }, '*');
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${
          response.data.jwt
        }`;
        axiosUploadInstance.defaults.headers.common.Authorization = `Bearer ${
          response.data.jwt
        }`;
        dispatch({ type: LOGIN_SUCCESS, payload: response });
        dispatch(fetchLoggedInUserProfile());
      })
      .catch(error => dispatch({ type: LOGIN_ERROR, payload: error }));
  };
}

export function getUserInfo(token) {
  const request = axios.get(
    `https://graph.facebook.com/me?fields=name,email,picture.width(400).height(400)&access_token=${token}`
  );

  return dispatch => {
    request.then(response => {
      if (response.data.email) {
        dispatch(
          socialLogin(
            response.data.email,
            response.data.name,
            '', // response.data.birthday
            '', // response.data.gender
            response.data.picture.data.url,
            token,
            'facebook'
          )
        );
      } else {
        const facebookInfo = {
          name: response.data.name,
          picture_url: response.data.picture.data.url,
          token,
          provider: 'facebook',
        };
        dispatch({
          type: LOGIN_ERROR,
          payload: { response: { data: facebookInfo } },
        });
      }
    });
  };
}

export function getPreviousPage(path) {
  return {
    type: PREVIOUS_PAGE,
    payload: path,
  };
}

export function fetchMyProfileAndRequests(profile) {
  return dispatch => {
    axiosInstance.get(`/api/logged_in_user`).then(response => {
      dispatch({
        type: FETCH_MY_PROFILE,
        payload: response,
      });
      const otherUserId = profile.id;
      const myUserId = response.data.data.id;

      // Fetching inverse request
      axiosInstance
        .get(`/api/requests/${otherUserId}/${myUserId}`)
        .then(inverseResponse =>
          dispatch({
            type: FETCH_INVERSE_REQUEST,
            payload: inverseResponse,
          })
        );

      // Fetching request
      axiosInstance
        .get(`/api/requests/${myUserId}/${otherUserId}`)
        .then(requestResponse => {
          dispatch({
            type: FETCH_REQUEST,
            payload: requestResponse,
          });
        });
    });
  };
}

export function sendResetEmail(email) {
  return dispatch => {
    axiosInstance
      .post(`/api/reset_password`, { email })
      .then(response => {
        dispatch({ type: RESET_PASSWORD, payload: response });
      })
      .catch(error => {
        dispatch({ type: RESET_PASSWORD, payload: error });
      });
  };
}

export function resetPassword(session) {
  const request = axiosInstance.post(`/api/reset_password`, { session });

  return {
    type: RESET_PASSWORD,
    payload: request,
  };
}

export function updateCredential(email, new_email) {
  return dispatch => {
    axiosInstance
      .post(`/api/update_credential`, {
        email,
        new_email,
      })
      .then(response => {
        dispatch({ type: UPDATE_CREDENTIAL_SUCCESS, payload: response });
      })
      .catch(error => {
        dispatch({ type: UPDATE_CREDENTIAL_ERROR, payload: error });
      });
  };
}
