import React from "react";
import PropTypes from "prop-types";

const TRANSITION_SPEED = 200;

/**
 * Sidebar overlay backdrop
 * @param {*} props
 */
class SidebarOverlay extends React.PureComponent {
  mountTimeout = null;
  unmountTimeout = null;

  state = {
    mountComponent: false,
    ignoreVisible: false,
  };

  render() {
    const { onClick, visible } = this.props;
    const { mountComponent, ignoreVisible } = this.state;
    if (!mountComponent) {
      return null;
    }
    let className = "sidebar-overlay";
    if (visible && !ignoreVisible) {
      className += " sidebar-overlay--visible";
    }
    return <div className={className} onClick={onClick} />;
  }

  componentDidUpdate(prevProps) {
    const { visible: prevVisible } = prevProps;
    const { visible } = this.props;
    if (!prevVisible && visible) {
      this.setState({ mountComponent: true, ignoreVisible: true });
      this.setMountTimeout();
    } else if (prevVisible && !visible) {
      this.setState({ mountComponent: true, ignoreVisible: false });
      this.setUnmountTimeout();
    }
  }

  setMountTimeout = () => {
    if (this.unmountTimeout) {
      clearTimeout(this.unmountTimeout);
      this.unmountTimeout = null;
    }
    if (!this.mountTimeout) {
      this.mountTimeout = setTimeout(() => {
        this.mountTimeout = null;
        this.setState({ mountComponent: true, ignoreVisible: false });
      }, 50);
    }
  };

  setUnmountTimeout = () => {
    if (this.mountTimeout) {
      clearTimeout(this.mountTimeout);
      this.mountTimeout = null;
    }
    if (!this.unmountTimeout) {
      this.unmountTimeout = setTimeout(() => {
        this.unmountTimeout = null;
        this.setState({ mountComponent: false, ignoreVisible: false });
      }, TRANSITION_SPEED);
    }
  };

  componentWillUnmount() {
    if (this.mountTimeout) {
      clearTimeout(this.mountTimeout);
      this.mountTimeout = null;
    }
    if (this.unmountTimeout) {
      clearTimeout(this.unmountTimeout);
      this.unmountTimeout = null;
    }
  }
}

SidebarOverlay.propTypes = {
  onClick: PropTypes.func,
  visible: PropTypes.bool,
};

export default SidebarOverlay;
