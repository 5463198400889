/* global localStorage */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Popup, Icon, Menu, Image, Divider, Label } from "semantic-ui-react";
import cookie from "react-cookies";
import { emptyMyProfile } from "../actions";
import { renderFavouritePicture } from "../utils/helpers";

class UserMenu extends Component {
  logout() {
    window.postMessage({ event: "logout" }, "*");
    cookie.remove("jwt");
    localStorage.removeItem("user");
    this.props.history.push("/login");
    window.location.reload();
    this.props.emptyMyProfile();
  }

  render() {
    return (
      <Popup
        basic
        style={{ borderRadius: "15px" }}
        flowing
        hoverable
        size="large"
        position="right center"
        trigger={
          <div>
            <b
              style={{
                marginRight: 10,
              }}
            ></b>
            {this.props.myProfile.data.pictures[0] ? (
              <Image
                src={renderFavouritePicture(this.props.myProfile.data.pictures)}
                avatar
                style={{
                  height: 50,
                  width: 50,
                }}
              />
            ) : (
              <Icon color="black" size="big" name="user circle outline" />
            )}
            {this.props.profileMessageList.length +
              this.props.receivedRequests.length >
              0 && (
              <Label
                size="tiny"
                circular
                style={{
                  backgroundColor: "#ff5050",
                  zIndex: 1,
                  position: "absolute",
                  top: "7px",
                  right: "7px",
                  color: "#fff",
                }}
              >
                {this.props.profileMessageList.length +
                  this.props.receivedRequests.length}
              </Label>
            )}
          </div>
        }
        content={
          <Menu
            text
            vertical
            style={{
              width: 150,
              paddingRight: 10,
              paddingLeft: 10,
              fontFamily: "MYRIAD",
              fontSize: 15,
            }}
          >
            <Menu.Item style={{ textAlign: "center", marginBottom: 20 }}>
              <h3>
                {this.props.myProfile.data.name}
                <br />
              </h3>
            </Menu.Item>
            <Divider fitted />
            <Link to="/profile">
              <Menu.Item>My Profile</Menu.Item>
            </Link>
            <Link to="/requests">
              <Menu.Item>
                {this.props.profileMessageList.length +
                  this.props.receivedRequests.length >
                  0 && (
                  <Label
                    floating
                    circular
                    style={{
                      backgroundColor: "#ff5050",
                      cursor: "pointer",
                      color: "#fff",
                    }}
                  >
                    {this.props.profileMessageList.length +
                      this.props.receivedRequests.length}
                  </Label>
                )}
                <label style={{ cursor: "pointer" }}>Requests & Chats</label>
              </Menu.Item>
            </Link>
            <Link to="/my_rooms">
              <Menu.Item>My Listing</Menu.Item>
            </Link>
            <Menu.Item />
            <Menu.Item onClick={() => this.logout()}>Logout</Menu.Item>
          </Menu>
        }
      />
    );
  }
}

const mapStateToProps = ({ myProfile }) => ({
  myProfile,
});

const actions = {
  emptyMyProfile,
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(UserMenu)
);
