import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  fetchRoomDetail,
  fetchRequest,
  fetchInverseRequest,
  emptyRequest,
  sendRequest,
  fetchLoggedInUserProfile,
  emptyRoom,
  getPreviousPage,
  acceptRequest,
  declineRequest,
  setConnection,
  fetchMyProfileAndRequests,
} from "../../actions";
import { Form, Container, Image } from "semantic-ui-react";

import RoomDetailView from "./room_detail_view";
import cookie from "react-cookies";
import { IMAGES_S3_BUCKET_URL } from "../../actions/root_url";

class RoomDetail extends Component {
  static propTypes = {
    fetchLoggedInUserProfile: PropTypes.func.isRequired,
    myProfile: PropTypes.object.isRequired,
    room: PropTypes.object.isRequired,
    request: PropTypes.object,
    inverseRequest: PropTypes.object,
    fetchRoomDetail: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    fetchMyProfileAndRequests: PropTypes.func.isRequired,
    sendRequest: PropTypes.func.isRequired,
    fetchRequest: PropTypes.func.isRequired,
    emptyRequest: PropTypes.func.isRequired,
    emptyRoom: PropTypes.func.isRequired,
    getPreviousPage: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    setConnection: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    acceptRequest: PropTypes.func.isRequired,
    declineRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const jwt = cookie.load("jwt");
    if (jwt) {
      this.props.fetchLoggedInUserProfile();
    }
    this.state = {
      myProfile: this.props.myProfile,
      room: this.props.room.data,
      request: this.props.request,
      inverseRequest: this.props.inverseRequest,
      windowWidth: window.innerWidth,
      filePicturesList: [],
      jwt,
    };
    this.props.fetchRoomDetail(this.props.match.params.id);
    this.sendRequest = this.sendRequest.bind(this);
    this.linkToPreviousPage = this.linkToPreviousPage.bind(this);
    this.setConnection = this.setConnection.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.room, nextProps.room) && nextProps.room.data.id) {
      this.setState({ room: nextProps.room.data });
      if (this.state.jwt) {
        this.props.fetchMyProfileAndRequests(nextProps.room.data.renter.data);
      }
    }
    if (!_.isEqual(this.props.inverseRequest, nextProps.inverseRequest)) {
      this.setState({ inverseRequest: nextProps.inverseRequest });
    }
    if (!_.isEqual(this.props.request, nextProps.request)) {
      this.setState({ request: nextProps.request });
    }
    if (!_.isEqual(this.props.myProfile, nextProps.myProfile)) {
      this.setState({ myProfile: nextProps.myProfile });
    }
  }

  sendRequest(receiver) {
    const request = {
      receiver,
      status: "Pending",
    };
    this.props.sendRequest(request);
    this.props.fetchRequest(
      this.props.myProfile.data.id,
      this.state.room.user_id
    );
  }

  componentWillUnmount() {
    this.props.emptyRequest();
    this.props.emptyRoom();
  }

  linkToPreviousPage() {
    this.props.getPreviousPage(this.props.location.pathname);
  }

  renderPicturesList(room) {
    let filePicturesList = [];
    room.pictures.map(picture =>
      filePicturesList.push({
        original: `${IMAGES_S3_BUCKET_URL}/${picture.url}`,
      })
    );
    return filePicturesList;
  }

  renderItem(item) {
    return (
      <Image
        centered
        style={{
          width: "100%",
          height: "90%",
          borderRadius: "8px",
          position: "relative",
        }}
        src={item.original}
      />
    );
  }

  setConnection() {
    this.props.setConnection(this.state.room.renter.data.id);
    this.props.history.push("/requests");
  }

  render() {
    const {
      room,
      windowWidth,
      myProfile,
      request,
      inverseRequest,
    } = this.state;

    return (
      <div className="site-container">
        <Container style={{ maxWidth: "1310px" }}>
          <Form>
            <RoomDetailView
              room={room}
              windowWidth={windowWidth}
              myProfile={myProfile.data}
              sendRequest={this.sendRequest}
              request={request}
              inverseRequest={inverseRequest}
              linkToPreviousPage={this.linkToPreviousPage}
              renderItem={this.renderItem}
              renderPicturesList={this.renderPicturesList}
              acceptRequest={this.props.acceptRequest}
              declineRequest={this.props.declineRequest}
              setConnection={this.setConnection}
              goToProfile={this.goToProfile}
            />
          </Form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ room, myProfile, request, inverseRequest }) => ({
  room,
  myProfile,
  request,
  inverseRequest,
});

const actions = {
  fetchRoomDetail,
  sendRequest,
  fetchRequest,
  fetchInverseRequest,
  emptyRequest,
  fetchLoggedInUserProfile,
  emptyRoom,
  getPreviousPage,
  acceptRequest,
  declineRequest,
  setConnection,
  fetchMyProfileAndRequests,
};

export default connect(
  mapStateToProps,
  actions
)(RoomDetail);
