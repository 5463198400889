import React from "react";

import { Segment } from "semantic-ui-react";

const ContactUs = () => {
  return (
    <div className="site-container">
      <Segment
        style={{
          padding: 35,
          marginTop: "2rem",
        }}
      >
        <h2>Contact Us</h2>
        <p>
          Please send all your enquiries to:{" "}
          <a href="mailto:info@roomigo.io">info@roomigo.io</a>
        </p>
      </Segment>
    </div>
  );
};
export default ContactUs;
