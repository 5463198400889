import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Button, Form, Grid, Dimmer, Loader, Message } from "semantic-ui-react";
import {
  createProfileSearch,
  findProfiles,
  fetchProfileDetail,
  sendRequest,
  fetchProfileList,
  setProfileSearchPage,
  emptyProfileList,
} from "../../../actions";

import SearchProfileView from "./search_profile_view";
import SearchProfilePreferencesView from "./search_profile_preferences";
import SearchProfileResult from "./search_profile_result";
import SearchProfileResultMobile from "./search_profile_result_mobile";

class SearchProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingMore: false,
      search: { ...props.profileSearch },
      visible: true,
      profileList: [],
      emptyListMessage: "",
      personality_same: "",
      interests_same: "",
      searchStatus: true,
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    this.loadProfilesInitially();
  }

  componentDidUpdate(prevProps) {
    this.checkQueryParams(prevProps, this.props);
  }

  checkQueryParams = (prevProps, props) => {
    const {
      location: { search: prevSearch },
    } = prevProps;
    const {
      location: { search },
    } = props;

    const targetOpen = "open=true";
    const prevOpen =
      prevSearch && prevSearch.toLowerCase().indexOf(targetOpen) !== -1;
    const open = search && search.toLowerCase().indexOf(targetOpen) !== -1;

    const targetClosed = "open=false";
    const prevClosed =
      prevSearch && prevSearch.toLowerCase().indexOf(targetClosed) !== -1;
    const closed = search && search.toLowerCase().indexOf(targetClosed) !== -1;

    if ((!prevOpen && open) || (prevClosed && !closed)) {
      this.setState({
        searchStatus: true,
      });
      window.scrollTo(0, 0);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.profileSearch &&
      !_.isEqual(this.props.profileSearch, nextProps.profileSearch) &&
      nextProps.profileSearch.id
    ) {
      this.setState({ search: nextProps.profileSearch });
    }
    if (_.isEmpty(nextProps.profileList)) {
      this.setState({ emptyListMessage: "No results found." });
    }
    if (!_.isEqual(this.props.profileList, nextProps.profileList)) {
      this.setState({ loadingMore: false });
      if (this.props.profileSearchPage === 1) {
        this.setState({
          profileList: nextProps.profileList,
          emptyListMessage: "",
        });
      } else {
        if (nextProps.profileList.length === 0) {
          this.props.setProfileSearchPage(this.props.profileSearchPage - 1);
        }
        this.setState({
          profileList: [...this.state.profileList, ...nextProps.profileList],
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.emptyProfileList();
  }

  loadProfilesInitially = () => {
    const { selectedCity: propSelectedCity, profileSearch } = this.props;
    const selectedCity = propSelectedCity || profileSearch.preferred_city;
    const stateClone = {
      ...this.state,
      search: {
        ...this.state.search,
      },
    };
    if (selectedCity) {
      stateClone.search.preferred_city = selectedCity;
    }

    this.createProfileSearch(stateClone);
    this.setState(stateClone);
  };

  handleFieldChange = (field, value) => {
    this.setState({ search: { ...this.state.search, [field]: value } });
  };
  loadMore = () => {
    if (!this.state.loadingMore) {
      if (this.props.profileList.length !== 0) {
        this.setState({ loadingMore: true });
        const newPage = this.props.profileSearchPage + 1;
        this.props.setProfileSearchPage(newPage);
        this.props.findProfiles(this.state.search, newPage);
      }
    }
  };

  createProfileSearch = (state = this.state) => {
    const { search } = state;
    const searchParams = {
      user_id: this.props.myProfile.data.user_id,
      age_min: parseInt(search.age_min, 10),
      age_max: parseInt(search.age_max, 10),
      is_searchable: true,
      preferred_city: search.preferred_city,
      preferred_area: search.preferred_area,
      preferred_personality: search.preferred_personality,
      preferred_gender:
        search.preferred_gender === "" ? "both" : search.preferred_gender,
      preferred_relationship:
        search.preferred_relationship === ""
          ? "both"
          : search.preferred_relationship,
      preferred_occupation:
        search.preferred_occupation === ""
          ? "both"
          : search.preferred_occupation,
      show_me: search.show_me === "" ? "No preference" : search.show_me,
    };
    if (this.props.myProfile.data.id) {
      this.props.createProfileSearch(searchParams);
    } else {
      this.props.findProfiles(searchParams);
    }
    this.props.setProfileSearchPage(1);
  };

  sendRequest = receiver_id => {
    const request = {
      receiver_id,
      status: "Pending",
    };
    this.props.sendRequest(request);
  };

  changePersonality = value => {
    this.setState({ personality_same: value });
  };

  changeInterests = value => {
    this.setState({ interests_same: value });
  };

  handleSearchStatus = () => {
    let searchStatus = !this.state.searchStatus;
    this.setState({ searchStatus });
    if (!searchStatus) {
      this.props.history.push("search_profile?open=false");
    } else {
      window.scrollTo(0, 0);
    }
  };

  submit = e => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    this.handleSearchStatus();
    this.createProfileSearch();
  };

  renderLoader = () => {
    return (
      <Dimmer active inverted>
        <Loader size="large">Searching</Loader>
      </Dimmer>
    );
  };

  renderNoResults = () => {
    return (
      <Message info style={{ marginTop: 10 }}>
        Sorry, but your search yielded no results. Please try again with
        different filters.
      </Message>
    );
  };

  render() {
    const {
      search,
      searchStatus,
      personality_same,
      interests_same,
      windowWidth,
      profileList,
      emptyListMessage,
    } = this.state;
    return (
      <div className="site-container">
        {searchStatus && (
          <Form onSubmit={e => this.submit(e)}>
            <SearchProfileView
              search={search}
              handleFieldChange={this.handleFieldChange}
              handleSearchStatus={this.handleSearchStatus}
              searchStatus={searchStatus}
            />
            <SearchProfilePreferencesView
              cities={this.props.cities}
              data={search}
              personality_same={personality_same}
              interests_same={interests_same}
              handleFieldChange={this.handleFieldChange}
              changePersonality={this.changePersonality}
              changeInterests={this.changeInterests}
            />
            <Grid stackable>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={2} />
                <Grid.Column width={4}>
                  <Button
                    size="big"
                    type="submit"
                    fluid
                    style={{
                      backgroundColor: "#00D5CC",
                      color: "#fff",
                      borderRadius: "10px",
                      marginBottom: 40,
                      marginTop: 35,
                      marginLeft: window.innerWidth > 767 ? 24 : 0,
                    }}
                  >
                    Find Roomigos!
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}

        {windowWidth > 768 ? (
          <SearchProfileResult
            loadingMore={this.state.loadingMore}
            profileList={profileList}
            emptyListMessage={emptyListMessage}
            sendRequest={this.sendRequest}
            handleSearchStatus={this.handleSearchStatus}
            searchStatus={searchStatus}
            renderNoResults={this.renderNoResults}
            loadMore={this.loadMore}
          />
        ) : (
          <SearchProfileResultMobile
            loadingMore={this.state.loadingMore}
            profileList={profileList}
            emptyListMessage={emptyListMessage}
            sendRequest={this.sendRequest}
            handleSearchStatus={this.handleSearchStatus}
            searchStatus={searchStatus}
            renderNoResults={this.renderNoResults}
            loadMore={this.loadMore}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  profile,
  myProfile,
  profileList,
  profileSearch,
  isSearching,
  profileSearchPage,
  cities,
}) => ({
  profile,
  myProfile,
  profileList,
  profileSearch,
  isSearching,
  profileSearchPage,
  selectedCity: cities.selectedCity,
  cities: cities.cities,
});

const actions = {
  createProfileSearch,
  findProfiles,
  fetchProfileDetail,
  sendRequest,
  fetchProfileList,
  setProfileSearchPage,
  emptyProfileList,
};

export default connect(
  mapStateToProps,
  actions
)(SearchProfile);
