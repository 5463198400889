import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Grid, Button, Message } from "semantic-ui-react";

const EmailLoginView = ({ handleFieldChange, login, session, error }) => {
  return (
    <div className="email-login-wrapper">
      <Form onSubmit={event => event.preventDefault()}>
        <Grid container centered>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <h1
                style={{
                  color: "#FF664D",
                  fontFamily: "MYRIAD",
                  marginLeft: "1%",
                }}
              >
                Login
              </h1>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <Input
                id="email"
                onChange={event =>
                  handleFieldChange("email", event.target.value)
                }
                type="text"
                placeholder="Email address"
                value={session.email}
                icon="mail outline"
                iconPosition="left"
                fluid
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <Input
                id="password"
                name="password"
                type="password"
                onChange={event =>
                  handleFieldChange("password", event.target.value)
                }
                placeholder="Password"
                value={session.password}
                icon="key"
                iconPosition="left"
                fluid
              />
            </Grid.Column>
          </Grid.Row>
          {error && (
            <Grid.Row>
              <Grid.Column computer={10} mobile={16}>
                <Message negative content={error.message} />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row centered>
            <Grid.Column computer={5} mobile={16} verticalAlign="middle">
              <Button
                id="button"
                fluid
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#00D5CC",
                  color: "#fff",
                }}
                onClick={login}
              >
                LOGIN
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Link to="/reset_password">Forgot Password ?</Link>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
};

export default EmailLoginView;
