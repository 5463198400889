import {
  FETCH_SENT_REQUEST_LIST,
  FETCH_RECEIVED_REQUEST_LIST,
  SEND_REQUEST,
  ACCEPT_REQUEST,
  DECLINE_REQUEST,
  FETCH_REQUEST,
  FETCH_INVERSE_REQUEST,
  EMPTY_REQUEST,
} from "../actions/action_types";

const requestInitialState = null;
export function requestReducer(state = requestInitialState, action) {
  switch (action.type) {
    case SEND_REQUEST:
    case ACCEPT_REQUEST:
    case DECLINE_REQUEST:
    case FETCH_REQUEST: {
      return action.payload.data.data;
    }
    case EMPTY_REQUEST: {
      return requestInitialState;
    }
    default: {
      return state;
    }
  }
}
export function inverseRequestReducer(state = requestInitialState, action) {
  switch (action.type) {
    case FETCH_INVERSE_REQUEST: {
      return action.payload.data.data;
    }
    case EMPTY_REQUEST: {
      return requestInitialState;
    }
    default: {
      return state;
    }
  }
}

export function sentRequestListReducer(state = [], action) {
  switch (action.type) {
    case FETCH_SENT_REQUEST_LIST: {
      return action.payload.data.data;
    }
    default: {
      return state;
    }
  }
}

export function receivedRequestListReducer(state = [], action) {
  switch (action.type) {
    case FETCH_RECEIVED_REQUEST_LIST: {
      if (action.error) {
        return state;
      }
      return action.payload.data.data;
    }
    default: {
      return state;
    }
  }
}
