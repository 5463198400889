import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid, Input, Label, Image, Message, Form } from 'semantic-ui-react';
import { renderFavouritePicture } from '../../../utils/helpers';
import { fetchProfileRoomList, setRoomToBook } from '../../../actions';
import ChatRequestBanner from '../../elements/ChatRequestBanner/ChatRequestBanner';
import ChooseRoom from '../../elements/ChooseRoom/ChooseRoom';

class MessageList extends Component {
  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    const { friendProfile, fetchProfileRoomList } = this.props;
    fetchProfileRoomList(friendProfile);
  }

  componentDidUpdate(prevProps) {
    const y = this.scrollref.scrollHeight;
    this.scrollref.scrollTop = y;

    const {
      friendProfile,
      fetchProfileRoomList,
      handleFieldChange,
    } = this.props;
    if (prevProps.friendProfile !== friendProfile) {
      fetchProfileRoomList(friendProfile);
      handleFieldChange('');
    }
  }

  handleBookingClick = () => {
    const { roomList, history, setRoomToBook } = this.props;

    if (roomList.length === 1) {
      const [currentRoom] = roomList;
      setRoomToBook(currentRoom);
      history.push(`/booking/request/${currentRoom.city.id}`);
    } else {
      this.setState({ isModalOpen: true });
    }
  };

  render() {
    const { isModalOpen } = this.state;
    const {
      lastTenMessages,
      windowWidth,
      windowHeight,
      profile,
      connectionList,
      handleFieldChange,
      sendMessage,
      getFriendProfile,
      messageText,
      roomList,
      myRoomList,
      friendProfile,
      setRoomToBook,
      history,
    } = this.props;

    const texts = {
      booker: {
        tip: `Send ${friendProfile.name} a Booking Request`,
        action: `Send Booking Request`,
      },
      lister: {
        tip: `Ask ${friendProfile.name} to send me a Booking Request`,
        action: `Ask for Request`,
        msg: `Hi ${
          friendProfile.name
        }, send me a booking request to book the room securely!`,
      },
      meta: {
        inputPlaceholder: `Type a message...`,
        noMessages: `No messages yet!`,
      },
    };

    return (
      <>
        {isModalOpen && (
          <ChooseRoom
            rooms={roomList}
            setRoomToBook={setRoomToBook}
            history={history}
            onCancel={() => this.setState({ isModalOpen: false })}
          />
        )}
        <Grid.Column verticalAlign="top" mobile={16} computer={10}>
          <div
            style={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              height:
                windowWidth > 768 ? 0.65 * windowHeight : 0.5 * windowHeight,
            }}
            ref={msgList => (this.scrollref = msgList)}
          >
            {myRoomList.length > 0 && (
              <ChatRequestBanner
                tip={texts.lister.tip}
                actionText={texts.lister.action}
                onActionClick={() => handleFieldChange(texts.lister.msg)}
              />
            )}
            {roomList.length > 0 && (
              <ChatRequestBanner
                tip={texts.booker.tip}
                actionText={texts.booker.action}
                onActionClick={this.handleBookingClick}
              />
            )}

            {lastTenMessages.length ? (
              lastTenMessages.map(message => (
                <div key={message.id}>
                  {message.sender_id === profile.id ? (
                    <Grid>
                      {windowWidth > 768 && <Grid.Column width={1} />}
                      <Grid.Column
                        verticalAlign="middle"
                        float="right"
                        width={12}
                        textAlign="right"
                      >
                        <Label
                          basic
                          style={{
                            borderRadius: ' 30px 30px 0px 30px',
                            textAlign: 'right',
                            marginRight: windowWidth > 768 ? 0 : '-5%',
                          }}
                        >
                          <span data-hj-suppress="">{message.message}</span>
                        </Label>
                      </Grid.Column>
                      <Grid.Column computer={2} mobile={4} textAlign="right">
                        <Image
                          float="right"
                          style={{
                            width: windowWidth > 768 ? '50px' : '30px',
                            height: windowWidth > 768 ? '50px' : '30px',
                            borderRadius: '50px',
                          }}
                          src={
                            profile.pictures[0]
                              ? renderFavouritePicture(profile.pictures)
                              : 'https://react.semantic-ui.com/assets/images/wireframe/image.png'
                          }
                        />
                      </Grid.Column>
                      {windowWidth > 768 && <Grid.Column width={1} />}
                    </Grid>
                  ) : (
                    <Grid>
                      {windowWidth > 768 && <Grid.Column width={1} />}
                      <Grid.Column computer={2} mobile={4} textAlign="left">
                        <Image
                          float="left"
                          style={{
                            width: windowWidth > 768 ? '50px' : '30px',
                            height: windowWidth > 768 ? '50px' : '30px',
                            borderRadius: '50px',
                            cursor: 'pointer',
                          }}
                          src={getFriendProfile(
                            connectionList,
                            message.sender_id
                          )}
                        />
                      </Grid.Column>
                      <Grid.Column
                        verticalAlign="middle"
                        float="left"
                        width={12}
                      >
                        <Label
                          basic
                          style={{
                            marginLeft: windowWidth > 768 ? 0 : '-15%',
                            borderRadius: ' 30px 30px 30px 0px',
                          }}
                        >
                          <span data-hj-suppress="">{message.message}</span>
                        </Label>
                      </Grid.Column>
                      {windowWidth > 768 && <Grid.Column width={1} />}
                    </Grid>
                  )}
                </div>
              ))
            ) : (
              <Message style={{ margin: 10 }}>{texts.meta.noMessages}</Message>
            )}
          </div>

          <Form>
            <Input
              fluid
              value={messageText}
              placeholder={texts.meta.inputPlaceholder}
              onChange={e => handleFieldChange(e.target.value)}
              action={{
                onClick: () => sendMessage(),
                content: 'Send',
                color: 'blue',
              }}
            />
          </Form>
        </Grid.Column>
      </>
    );
  }
}

const mapStateToProps = state => ({
  roomList: state.roomList,
  myRoomList: state.myRoomList,
});

const actions = {
  fetchProfileRoomList,
  setRoomToBook,
};

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  withRouter
)(MessageList);
