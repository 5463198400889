import React, { Component } from "react";

import { Link } from "react-router-dom";

import { Button, Transition } from "semantic-ui-react";

export class FloatingCTA extends Component {
	constructor(props) {
		super(props);
		this.state = { visible: false };
	}
	componentDidMount() {
		setTimeout(() => this.setState({ visible: true }), 1500);
	}

	render() {
		const { visible } = this.state;
		return (
			<Transition.Group
				className="floating-cta"
				animation="fade up"
				duration={1500}
			>
				{visible && (
					<Link to="/rooms/new">
						<Button
							className="cta-btn"
							labelPosition="left"
							content="LIST A ROOM"
							icon="plus"
							size="large"
						/>
					</Link>
				)}
			</Transition.Group>
		);
	}
}
