import React from "react";
import { Button, Grid, Segment } from "semantic-ui-react";

const Searchable = ({
  profile,
  handleFieldChange,
  setPublicProfile,
  setNotSearchable
}) => {
  return (
    <Grid style={{ padding: 20 }} centered>
      <Grid.Row>
        <font
          color="#707070"
          style={{ fontFamily: "MYRIAD", fontSize: "16px" }}
        >
          <b>Profile Status</b>
        </font>
        <Button.Group fluid style={{ marginTop: "3px" }}>
          <Button
            onClick={() => {
              handleFieldChange("is_searchable", true);
              !profile.is_new && setPublicProfile(true);
            }}
            style={{
              borderRadius: "8px 0px 0px 8px",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#FF664D",
              backgroundColor: profile.is_searchable ? "#FF664D" : "#FFFFFF"
            }}
          >
            <h1
              style={{
                color: profile.is_searchable ? "#FFFFFF" : "grey",
                fontSize: profile.is_searchable ? 15 : 12,
                marginLeft: "-10px",
                fontWeight: profile.is_searchable && "bold"
              }}
            >
              Visible
            </h1>
          </Button>
          <Button
            onClick={() => {
              setNotSearchable();
            }}
            style={{
              borderRadius: "0px 8px 8px 0px",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#FF664D",
              backgroundColor: profile.is_searchable ? "#FFFFFF" : "#FF664D"
            }}
          >
            <h1
              style={{
                color: profile.is_searchable ? "grey" : "#FFFFFF",
                fontSize: profile.is_searchable ? 12 : 15,
                marginLeft: "-10px",
                fontWeight: !profile.is_searchable && "bold"
              }}
            >
              Hidden
            </h1>
          </Button>
        </Button.Group>
      </Grid.Row>
      <Grid.Row>
        <Segment
          compact
          style={{
            color: "#707070",
            fontStyle: "italic",
            borderRadius: "8px"
          }}
        >
          {profile.is_searchable
            ? "Your profile is now live and can be seen by others on RooMigo. You can connect with other RooMigo members. Enjoy!"
            : "Your profile will not be seen anywhere on the RooMigo platform. You will not be able to message or connect with others (besides those you have already connected with)"}
        </Segment>
      </Grid.Row>
    </Grid>
  );
};

export default Searchable;
