/* global window */
import React from "react";
import { Image, Grid, Divider, Message, Visibility } from "semantic-ui-react";
import Loader from "../../parts/Loader";
import ProfileCard from "../profile_card";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const filter_icon = require("../../../assets/images/search.svg");

const SearchProfileResultMobile = ({
  profileList,
  emptyListMessage,
  sendRequest,
  handleSearchStatus,
  searchStatus,
  loadMore,
  loadingMore,
}) => {
  return (
    <div>
      {searchStatus === false && (
        <Grid stackable verticalAlign="middle">
          <Grid.Row
            columns={1}
            textAlign="left"
            style={{ marginTop: 20, marginBottom: 20, marginLeft: "2%" }}
          >
            <Grid.Column>
              <h2>People</h2>
              <Image
                verticalAlign="middle"
                src={filter_icon}
                style={{
                  width: "25px",
                  cursor: "pointer",
                  position: "absolute",
                  top: window.innerWidth > 767 ? 5 : 6,
                  right: window.innerWidth > 767 ? 15 : 25,
                  zIndex: 1,
                }}
                onClick={() => handleSearchStatus()}
              />
              <Divider
                style={{
                  borderWidth: "3px",
                  borderRadius: "15px",
                  borderColor: "#FF664D",
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              {profileList.length === 0 && (
                <Message info style={{ marginTop: 10 }}>
                  Sorry, there are no roomigos with these characteristics.
                  Please try again with different filters.
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      <Visibility
        offset={325}
        continuous={false}
        once={false}
        onBottomVisible={loadMore}
      >
        {profileList.map(profile => (
          <ProfileCard profile={profile} />
        ))}
        <Grid>
          {loadingMore && (
            <Grid.Row centered>
              <Loader />
            </Grid.Row>
          )}
        </Grid>
      </Visibility>
    </div>
  );
};

export default SearchProfileResultMobile;
