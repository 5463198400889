import React from 'react';

import Button from '../Button/Button';
import { ReactComponent as IconMail } from './mail.svg';

const ChatRequestBanner = ({ tip, actionText, onActionClick, url }) => {
  return (
    <div className="chat-request-banner">
      <div className="chat-request-banner__tip">
        <IconMail />
        {tip}
      </div>
      {url ? (
        <Button url={url}>{actionText}</Button>
      ) : (
        <Button onClick={onActionClick}>{actionText}</Button>
      )}
    </div>
  );
};

export default ChatRequestBanner;
