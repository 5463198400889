import { FETCH_AREA_LIST, FETCH_AREA_CODE_LIST } from "../actions/action_types";

export function areaListReducer(state = [], action) {
  switch (action.type) {
    case FETCH_AREA_LIST: {
      return action.payload.data;
    }
    default: {
      return state;
    }
  }
}

export function areaCodeListReducer(state = [], action) {
  switch (action.type) {
    case FETCH_AREA_CODE_LIST: {
      return action.payload.data;
    }
    default: {
      return state;
    }
  }
}
