import React from "react";
import { Divider, Grid, Image } from "semantic-ui-react";

const filter_icon = require("../../../assets/images/search.svg");

const SearchProfileView = ({
  search,
  handleFieldChange,
  handleSearchStatus,
  searchStatus,
}) => {
  return (
    <div>
      <Grid stackable verticalAlign="middle">
        <Grid.Row
          columns={window.innerWidth > 767 ? 2 : 1}
          textAlign="left"
          style={{
            marginTop: "20px",
            marginLeft: window.innerWidth > 767 ? 0 : "2%",
          }}
        >
          <Grid.Column>
            <h2>People</h2>
            {searchStatus === true && (
              <Image
                verticalAlign="middle"
                src={filter_icon}
                style={{
                  width: "25px",
                  cursor: "pointer",
                  position: "absolute",
                  top: window.innerWidth > 767 ? 5 : 6,
                  right: window.innerWidth > 767 ? 15 : 25,
                  zIndex: 1,
                }}
                onClick={() => handleSearchStatus()}
              />
            )}
            <Divider
              style={{
                borderWidth: "3px",
                borderRadius: "15px",
                borderColor: "#FF664D",
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default SearchProfileView;
