import React from "react";
import { Button, Grid, Modal } from "semantic-ui-react";

import _ from "lodash";
import { Link } from "react-router-dom";

const SubmitProfile = ({
  isUpdated,
  submit,
  openModal,
  closeModal,
  isModalOpen,
  title,
  message,
  goToList,
  handleFieldChange,
  setPublicProfile,
  profile,
  reloadModal,
  errorList,
  resetMessage,
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Modal
            dimmer="inverted"
            size="small"
            onOpen={() => openModal()}
            open={isModalOpen}
            style={{
              marginTop: window.innerWidth < 767 ? "15%" : "20%",
              marginLeft: window.innerWidth < 767 ? "2%" : "25%",
            }}
          >
            <Modal.Content
              style={{
                padding: 40,
                justifyContent: "left",
              }}
            >
              <Grid centered>
                <Grid.Row>
                  <p
                    style={{
                      color: "#FF664D",
                      fontFamily: "MYRIAD",
                      fontSize: "25px",
                      marginBottom: 10,
                    }}
                  >
                    {message}
                  </p>
                </Grid.Row>
                {isUpdated &&
                message &&
                message !==
                  "Switching your profile to hidden will also deactivate your room. Do you want to continue ?" &&
                _.isEmpty(errorList) ? (
                  <Grid.Row>
                    <Link to="/search_profile">
                      <Button
                        style={{
                          width: "180px",
                          borderRadius: "5px",
                          backgroundColor: "#00D5CC",
                          color: "#fff",
                          marginLeft: window.innerWidth < 767 ? 30 : 0,
                        }}
                        size="large"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        Find a Roommate
                      </Button>
                    </Link>
                    <Link to="/search_room">
                      <Button
                        style={{
                          width: "140px",
                          borderRadius: "5px",
                          backgroundColor: "#00D5CC",
                          color: "#fff",
                          marginLeft: window.innerWidth < 767 ? 25 : 0,
                          marginTop: window.innerWidth < 767 ? 20 : 0,
                        }}
                        size="large"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        Find a Room
                      </Button>
                    </Link>
                    <Link to="/rooms/new">
                      <Button
                        style={{
                          width: "140px",
                          borderRadius: "5px",
                          backgroundColor: "#00D5CC",
                          color: "#fff",
                          marginLeft: window.innerWidth < 767 ? 20 : 0,
                          marginTop: window.innerWidth < 767 ? 20 : 0,
                          marginBottom: window.innerWidth < 767 ? 20 : 0,
                        }}
                        size="large"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        List a Room
                      </Button>
                    </Link>
                  </Grid.Row>
                ) : (
                  <Grid.Row>
                    {(message ===
                      "Switching your profile to hidden will also deactivate your room. Do you want to continue ?" ||
                      message ===
                        "Your profile is currently hidden. This means you cannot connect with roommates or list a room. Do you wish to make your profile visible?") && (
                      <div>
                        <Button
                          style={{
                            width: "180px",
                            borderRadius: "5px",
                            backgroundColor: "#00D5CC",
                            color: "#fff",
                            margin: 10,
                          }}
                          size="large"
                          onClick={() => {
                            closeModal();
                          }}
                        >
                          NO
                        </Button>
                        <Button
                          style={{
                            width: "180px",
                            borderRadius: "5px",
                            backgroundColor: "#00D5CC",
                            color: "#fff",
                            margin: 10,
                          }}
                          size="large"
                          onClick={() => {
                            message ===
                            "Switching your profile to hidden will also deactivate your room. Do you want to continue ?"
                              ? reloadModal(false)
                              : reloadModal(true);
                          }}
                        >
                          YES
                        </Button>
                      </div>
                    )}
                    {(message ===
                      "Sorry you can't save your profile without a photo" ||
                      "Sorry you can't save your profile without a city" ||
                      !_.isEmpty(errorList)) &&
                      message !==
                        "Your profile is currently hidden. This means you cannot connect with roommates or list a room. Do you wish to make your profile visible?" && (
                        <Button
                          style={{
                            width: "180px",
                            borderRadius: "5px",
                            backgroundColor: "#00D5CC",
                            color: "#fff",
                            margin: 10,
                          }}
                          size="large"
                          onClick={() => {
                            closeModal();
                            resetMessage();
                          }}
                        >
                          Ok, take me back
                        </Button>
                      )}
                  </Grid.Row>
                )}
              </Grid>
            </Modal.Content>
          </Modal>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ zIndex: 11 }}>
        <Grid.Column width={4}>
          <Button
            primary
            style={{
              width: "180px",
              borderRadius: "5px",
              backgroundColor: "#00D5CC",
            }}
            size="large"
            onClick={() => {
              submit();
            }}
          >
            {title}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SubmitProfile;
