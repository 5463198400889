import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({ children, onClick, url }) => {
  if (url) {
    return (
      <Link to={url} className="button__main">
        {children}
      </Link>
    );
  } else {
    return (
      <button className="button__main" onClick={onClick} type="button">
        {children}
      </button>
    );
  }
};

export default Button;
