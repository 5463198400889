import React from "react";
import PropTypes from "prop-types";
export default function Loader({ classNames, text }) {
  return (
    <div className={`loading-container ${classNames ? classNames : ""}`}>
      <div className="loading-spinner">
        <div />
        <div />
        <div />
        <div />
      </div>
      <span className="loading-text">{text ? text : "Loading..."}</span>
    </div>
  );
}
Loader.propTypes = {
  classNames: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
Loader.defaultProps = {
  classNames: "",
  text: "Loading...",
};
