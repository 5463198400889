import React from 'react';
import _ from 'lodash';
import { Tab, Grid, Menu, Label } from 'semantic-ui-react';
import RequestTab from './request_tab';
import ChatTab from './chat_tab';
import ChatLabel from '../../elements/ChatLabel/ChatLabel';

const RequestListView = ({
  sentRequests,
  receivedRequests,
  acceptRequest,
  declineRequest,
  connectionList,
  fetchMessageList,
  messageList,
  profile,
  connectionId,
  handleFieldChange,
  sendMessage,
  messageText,
  handleTabChange,
  activeIndex,
  isChatVisible,
  showChatWindow,
  viewProfileDetail,
  friendProfile,
  profileMessageList,
}) => {
  const panes = [
    {
      menuItem: (
        <Menu.Item key={0}>
          {_.size(profileMessageList) !== 0 && (
            <Label
              floating
              circular
              size="tiny"
              style={{
                backgroundColor: '#ff5050',
                color: '#fff',
              }}
            >
              {_.size(profileMessageList)}
            </Label>
          )}
          <ChatLabel
            label="Chats"
            isActive={activeIndex === 0}
            onClick={() => isChatVisible && showChatWindow()}
          />
        </Menu.Item>
      ),

      render: () => (
        <ChatTab
          connectionList={connectionList}
          fetchMessageList={fetchMessageList}
          messageList={messageList}
          profileMessageList={profileMessageList}
          profile={profile}
          connectionId={connectionId}
          handleFieldChange={handleFieldChange}
          sendMessage={sendMessage}
          messageText={messageText}
          isChatVisible={isChatVisible}
          showChatWindow={showChatWindow}
          viewProfileDetail={viewProfileDetail}
          friendProfile={friendProfile}
        />
      ),
    },
    {
      menuItem: (
        <Menu.Item key={1}>
          <ChatLabel label="Requests Sent" isActive={activeIndex === 1} />
        </Menu.Item>
      ),
      render: () => (
        <RequestTab
          requests={sentRequests}
          viewProfileDetail={viewProfileDetail}
          type="receiver"
          profile={profile}
        />
      ),
    },
    {
      menuItem: (
        <Menu.Item key={2}>
          {_.size(receivedRequests) !== 0 && (
            <Label
              floating
              circular
              size="tiny"
              style={{
                backgroundColor: '#ff5050',
                color: '#fff',
              }}
            >
              {_.size(receivedRequests)}
            </Label>
          )}
          <ChatLabel label="Requests Received" isActive={activeIndex === 2} />
        </Menu.Item>
      ),
      render: () => (
        <RequestTab
          requests={receivedRequests}
          type="sender"
          acceptRequest={acceptRequest}
          declineRequest={declineRequest}
          viewProfileDetail={viewProfileDetail}
          profile={profile}
        />
      ),
    },
  ];
  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column>
          <Tab
            onTabChange={handleTabChange}
            activeIndex={activeIndex}
            menu={{ text: true }}
            panes={panes}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default RequestListView;
