import { axiosInstance } from './configured_axios';
import { FETCH_INTERESTS } from './action_types';

export function fetchInterestList() {
  const request = axiosInstance.get('/api/interests');

  return {
    type: FETCH_INTERESTS,
    payload: request,
  };
}
