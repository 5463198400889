// dropdown options
export const relationshipOptions = [
  { text: "Single", value: "non-couple" },
  { text: "Couple", value: "couple" },
  { text: "Doesn't Matter", value: "both" },
];
export const relationshipOptionsProfileSearch = [
  { text: "Single", value: "single" },
  { text: "Couple", value: "couple" },
  { text: "Doesn't Matter", value: "both" },
];
export const statusOptions = [
  { text: "Student", value: "study" },
  { text: "Professional", value: "work" },
  { text: "Doesn't Matter", value: "both" },
];
export const genderOptions = [
  { text: "Male", value: "male" },
  { text: "Female", value: "female" },
  { text: "Doesn't Matter", value: "both" },
];
export const roommateNumberOptions = [
  { value: 0, text: "0" },
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
  { value: 6, text: "6" },
  { value: 7, text: "7" },
];

export const maleNumberOptions = [
  { value: 0, text: "0 males" },
  { value: 1, text: "1 male" },
  { value: 2, text: "2 males" },
  { value: 3, text: "3 males" },
  { value: 4, text: "4 males" },
  { value: 5, text: "5 males" },
  { value: 6, text: "6 males" },
  { value: 7, text: "7 males" },
];

export const femaleNumberOptions = [
  { value: 0, text: "0 females" },
  { value: 1, text: "1 female" },
  { value: 2, text: "2 females" },
  { value: 3, text: "3 females" },
  { value: 4, text: "4 females" },
  { value: 5, text: "5 females" },
  { value: 6, text: "6 females" },
  { value: 7, text: "7 females" },
];

export const minStayOptions = [
  { value: "0", text: "Doesn't matter" },
  { value: "1", text: "1" },
  { value: "3", text: "3" },
  { value: "6", text: "6" },
  { value: "12", text: "12" },
];

export const noSloganOption = "This house has no slogan";

export const sloganOptions = [
  { value: "Party all the time!!", text: "Party all the time!!" },
  { value: "Study, sleep, repeat", text: "Study, sleep, repeat" },
  {
    value: "We each love our own time and space",
    text: "We each love our own time and space",
  },
  {
    value: "RooMigos from all over the world",
    text: "RooMigos from all over the world",
  },
  {
    value: "Work all night, sleep all day",
    text: "Work all night, sleep all day",
  },
  {
    value: "Roommates who spend time together",
    text: "Roommates who spend time together",
  },
  { value: "Work hard, play hard", text: "Work hard, play hard" },
  {
    value: "Shift workers on different routines",
    text: "Shift workers on different routines",
  },
  { value: "Gym fanatics", text: "Gym fanatics" },
  {
    value: "Clean, organised and uncluttered",
    text: "Clean, organised and uncluttered",
  },
  {
    value: noSloganOption,
    text: "This house has no slogan",
  },
];
export const minPriceOptions = [
  { value: 0, text: "Min" },
  { value: 100, text: "100 €" },
  { value: 200, text: "200 €" },
  { value: 300, text: "300 €" },
  { value: 400, text: "400 €" },
  { value: 500, text: "500 €" },
  { value: 600, text: "600 €" },
  { value: 700, text: "700 €" },
  { value: 800, text: "800 €" },
  { value: 900, text: "900 €" },
  { value: 1000, text: "1000 €" },
  { value: 1200, text: "1200 €" },
  { value: 1500, text: "1500 €" },
];
export const maxPriceOptions = [
  { value: 100, text: "100 €" },
  { value: 200, text: "200 €" },
  { value: 300, text: "300 €" },
  { value: 400, text: "400 €" },
  { value: 500, text: "500 €" },
  { value: 600, text: "600 €" },
  { value: 700, text: "700 €" },
  { value: 800, text: "800 €" },
  { value: 900, text: "900 €" },
  { value: 1000, text: "1000 €" },
  { value: 1200, text: "1200 €" },
  { value: 1500, text: "1500 €" },
  { value: 2000, text: "2000 €" },
  { value: 3000, text: "3000 €" },
  { value: 9999, text: "Max" },
];
export const findRoomBedOptions = [
  { text: "Single", value: "Single" },
  { text: "Double", value: "Double" },
  { text: "Doesn't Matter", value: "No preference" },
];
export const roomSearchEnsuiteOptions = [
  { text: "Yes", value: "Yes" },
  { text: "No", value: "No" },
  { text: "Doesn't Matter", value: "No preference" },
];
export const roomSearchGenderOptions = [
  { text: "Male", value: "male" },
  { text: "Female", value: "female" },
  { text: "Doesn't Matter", value: "No preference" },
];
export const roomSearchRelationshipOptions = [
  { text: "Single", value: "non-couple" },
  { text: "Couple", value: "couple" },
  { text: "Doesn't Matter", value: "No preference" },
];
export const roomSearchStatusOptions = [
  { text: "Student", value: "study" },
  { text: "Professional", value: "work" },
  { text: "Doesn't Matter", value: "No preference" },
];
// button group options
export const teamOptions = [
  { text: "Room Seekers", value: "room_seekers" },
  { text: "Team Ups to Find a Place", value: "team_ups" },
  { text: "People with Rooms", value: "room_owners" },

  { text: "Everyone", value: "No preference" },
];
export const bedOptions = ["Single", "Double"];
export const ensuiteOptions = ["Yes", "No"];
export const billOptions = [true, false];
