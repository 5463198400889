import {
  FETCH_MY_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  FETCH_PROFILE_LIST,
  CREATE_PROFILE_SEARCH,
  FETCH_USER_PROFILE_SEARCH_PARAMS,
  FIND_PROFILES,
  FETCH_PROFILE_DETAIL,
  EMPTY_PROFILE,
  EMPTY_MY_PROFILE,
  PROFILE_SEARCH_PAGE,
  EMPTY_PROFILE_LIST,
  FETCH_HOME_PROFILE_LIST,
  SET_PROFILE_ON_SYNC_FLAG,
} from "../actions/action_types";

const initialState = {
  profileIsOnSync: false,
  data: {
    bio: "",
    date_of_birth: null,
    gender: "",
    joined_in: null,
    id: null,
    name: "",
    occupation: "",
    interests: [],
    pictures: [],
    relationship_status: "",
    team_with_others: "",
    looking_for_a_room: "",
  },
  error: {},
};

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROFILE_DETAIL: {
      return {
        data: action.payload.data.data,
        error: {},
      };
    }
    case EMPTY_PROFILE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export function myProfileReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE_ON_SYNC_FLAG:
      return {
        ...state,
        profileIsOnSync: action.payload,
      };
    case FETCH_MY_PROFILE:
    case UPDATE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        error: {},
      };
    }
    case UPDATE_USER_PROFILE_ERROR: {
      return {
        ...state,
        data: state.data,
        error: action.payload.response.data,
      };
    }
    case EMPTY_MY_PROFILE: {
      return {
        ...state,
        data: state.data,
        error: {},
      };
    }
    default: {
      return state;
    }
  }
}

export function homeProfileListReducer(state = [], action) {
  switch (action.type) {
    case FETCH_HOME_PROFILE_LIST: {
      return action.payload.data.data;
    }
    default: {
      return state;
    }
  }
}

export function profileListReducer(state = [], action) {
  switch (action.type) {
    case FETCH_PROFILE_LIST:
    case FIND_PROFILES: {
      return action.payload.data.data;
    }
    case EMPTY_PROFILE_LIST: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}

const initialSearchState = {
  age_min: 18,
  age_max: 99,
  preferred_city: {
    id: 1,
    name: "Dublin"
  },
  preferred_occupation: "both",
  preferred_relationship: "both",
  preferred_gender: "both",
  show_me: "No preference",
  _is_initial: true,
};

export function profileSearchReducer(state = initialSearchState, action) {
  switch (action.type) {
    case CREATE_PROFILE_SEARCH:
    case FETCH_USER_PROFILE_SEARCH_PARAMS: {
      const { _is_initial, ...initialSearchState } = state;

      return action.payload.data.data || initialSearchState;
    }
    default: {
      return state;
    }
  }
}

export function profileSearchPageReducer(state = 1, action) {
  switch (action.type) {
    case PROFILE_SEARCH_PAGE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
