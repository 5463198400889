import React from "react";

import { Segment, Grid, Image } from "semantic-ui-react";

import FounderInstitute from "../assets/images/FounderInstitute.png";
import NDRC from "../assets/images/NDRCLOGO.png";
import EUProgram from "../assets/images/EUPROGRAM.jpg";
const Privacy = () => {
  return (
    <div className="site-container">
      <Segment
        style={{
          padding: 35,
          marginTop: "2rem",
        }}
      >
        <h2>
          <strong>About RooMigo</strong>
        </h2>
        <p>&nbsp;</p>
        <p style={{ fontWeight: 400 }}>
          RooMigo is an Irish company based in Dublin. Our offices are in the
          Digital Exchange near the Guinness Storehouse. So if you&rsquo;re ever
          in the area be sure to ping us!
        </p>
        <p>
          <p style={{ fontWeight: 400 }}>
            RooMigo is all about making it easy for you to connect with the
            right roommates. We want you to &nbsp;#livewithfriends so you can
            enjoy sharing your home with great people!{" "}
          </p>
        </p>
        <p>
          <p style={{ fontWeight: 400 }}>
            I hope to catch up with you in Dublin, whether it is online or
            offline at one of our events!
          </p>
        </p>
        <p>
          <p style={{ fontWeight: 400 }}>Warm regards,</p>
          <p style={{ fontWeight: 400 }}>
            <br />
          </p>
          <p style={{ fontWeight: 400 }}>Ed.</p>
        </p>
        <p>
          <p style={{ fontWeight: 400 }}>Founder, RooMigo</p>
        </p>
        <p>
          <p style={{ fontWeight: 400 }}>#livewithfriends </p>
        </p>
        <p>&nbsp;</p>
        <h2>
          <strong>
            <em>What exactly is RooMigo?</em>
          </strong>
        </h2>
        <p>
          <p style={{ fontWeight: 400 }}>
            RooMigo is platform which allows roommates to match, connect, and
            chat based on compatibility. Think tinder, but for finding a
            roommate!
          </p>
        </p>
        <p>
          <p style={{ fontWeight: 400 }}>
            Have you ever lived with someone that made your blood boil? Or have
            you shared a space with someone who is so dissimilar to you that the
            breakfast banter consists of a rehearsed &ldquo;morning&rdquo;
            followed by an extremely captivating social media scroll? Perhaps
            you have lived with perfectly nice people that you simply do not
            seem to click with? You are not alone.
          </p>
        </p>
        <p style={{ fontWeight: 400 }}>
          Imagine if you could choose roommates who have the same lifestyle and
          interests as you. You may simply be looking for someone who is quiet
          when they come in late because they know you have work early. Perhaps
          you are looking for someone who could become more than a roommate- a
          friend. We are RooMigo and{" "}
          <span style={{ fontWeight: 900 }}>
            we are here to make that happen.
          </span>
        </p>
        <p>&nbsp;</p>
        <h2>
          <strong>
            <em>How Does It Work?</em>
          </strong>
        </h2>
        <p>
          <p style={{ fontWeight: 400 }}>
            Our platform is designed to make your roommate matching experience
            as enjoyable and easy as possible.
          </p>
        </p>
        <p>
          <p style={{ fontWeight: 400 }}>
            Start by logging into RooMigo and creating your personal profile. Be
            sure to add multiple photos if you wish, describe yourself in the
            Bio box, and tell other RooMigo members about yourself using the
            many different Lifestyle tags available. The more detail you add,
            the better picture you can create to help others get to know you!
          </p>
        </p>
        <p>
          <p style={{ fontWeight: 400 }}>
            After you create your RooMigo profile, you can list a room, browse
            available rooms, or peruse potential roommates. When listing a room
            be sure to include photos of the space and use RooMigo&rsquo;s
            platform to describe the type of house share you are a part of. Is
            your flat neat and clean? Are your housemates workaholics and
            serious or more carefree? &nbsp;Do you and your roommates work out
            together, or do you collectively avoid the gym? Use RooMigo&rsquo;s
            features to tell members what kind of living experience you enjoy in
            order to ensure quality roommate matches and connections.
          </p>
        </p>
        <p>
          <p style={{ fontWeight: 400 }}>
            Now sit back, put your feet up and relax. Allow RooMigo to work in
            your favor as potential roommates view your profile and browse your
            listings. Enjoy scrolling through potential roommates and connect
            and chat with people that you want to live with.
          </p>
        </p>
        <p>
          <p style={{ fontWeight: 400 }}>
            Once you have got to know people over our chat, you can then arrange
            to view their property or perhaps you might come and meet them at
            one of our events.
          </p>
        </p>
        <p>&nbsp;</p>
        <h2>
          <strong>
            <em>Need More Help?</em>
          </strong>
        </h2>
        <p style={{ fontWeight: 400 }}>
          No problem, we are always here. Feel free to contact us with any
          issues or problems that may arise during your experience with RooMigo.
          Our desktop and mobile web app both offer a{" "}
          <span style={{ fontWeight: 900 }}>
            live chat help-line in the bottom right corner of the screen
          </span>
          <span style={{ fontWeight: 400 }}>. So ask away!</span>
        </p>
        <Grid style={{ margin: 40 }}>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Image src={FounderInstitute} />
            </Grid.Column>
            <Grid.Column>
              <Image src={NDRC} />
            </Grid.Column>
            <Grid.Column>
              <Image src={EUProgram} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  );
};
export default Privacy;
