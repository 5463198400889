import React from "react";
import { Icon, Label, Image, Grid } from "semantic-ui-react";
import {
  renderFavouritePicture,
} from "../../utils/helpers";
import { Link } from "react-router-dom";
import personPlaceholder from "../../assets/images/person-placeholder.jpg";

const ProfileCard = ({ profile }) => {
  return (
    <div
      key={profile.id}
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          position: " absolute",
          top: 10,
          left: 10,
          zIndex: 1,
        }}
      >
        {profile.room_count > 0 && (
          <div>
            <Label
              size="huge"
              floated="left"
              as="a"
              style={{
                zIndex: 1,
                position: "absolute",
                backgroundColor: "rgb(255, 102, 77)",
                color: "#fff",
                opacity: 0.8,
                width: 45,
                borderRadius: 7,
              }}
            >
              <Icon
                name="home"
                style={{
                  marginLeft: -6,
                  opacity: 1.5,
                  backgroundColor: "rgb(255, 102, 77)",
                }}
              />
            </Label>
          </div>
        )}
      </div>
      <div
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 1,
        }}
      >
        {profile.team_with_others === true &&
          profile.looking_for_a_room === false && (
            <Label
              size="huge"
              floated="right"
              as="a"
              style={{
                backgroundColor: "#00D5CC",
                color: "#fff",
                opacity: 0.8,
                width: 45,
                borderRadius: 7,
              }}
            >
              <Icon name="users" style={{ marginLeft: -6, opacity: 1.5 }} />
            </Label>
          )}
        {profile.team_with_others === false &&
          profile.looking_for_a_room === true && (
            <Label
              size="huge"
              floated="right"
              as="a"
              style={{
                backgroundColor: "#00D5CC",
                color: "#fff",
                opacity: 0.8,
                width: 45,
                borderRadius: 7,
              }}
            >
              <Icon
                name="binoculars"
                style={{ marginLeft: -3, opacity: 1.5 }}
              />
            </Label>
          )}
        {profile.team_with_others === true &&
          profile.looking_for_a_room === true && (
            <div>
              <Label
                size="huge"
                floated="right"
                as="a"
                style={{
                  backgroundColor: "#00D5CC",
                  color: "#fff",
                  opacity: 0.8,
                  width: 45,
                  borderRadius: 7,
                }}
              >
                <Icon name="users" style={{ marginLeft: -6, opacity: 1.5 }} />
              </Label>
              <Label
                size="huge"
                floated="right"
                as="a"
                style={{
                  right: "46px",
                  backgroundColor: "#00D5CC",
                  color: "#fff",
                  opacity: 0.8,
                  width: 45,
                  borderRadius: 7,
                }}
              >
                <Icon
                  name="binoculars"
                  style={{ marginLeft: -3, opacity: 1.5 }}
                />
              </Label>
            </div>
          )}
      </div>
      <Link to={`/profile/${profile.slug}`}>
        <Image
          className="profilefoto"
          style={{
            position: "relative",
            cursor: "pointer",
            marginBottom: 20,
            marginTop: 40,
            width: "100%",
          }}
          src={
            profile.pictures[0]
              ? renderFavouritePicture(profile.pictures)
              : { personPlaceholder }
          }
        />
      </Link>
      <Grid stackable columns={4}>
        <Grid.Column key={profile.id} style={{ padding: 38 }} width={4}>
          <label style={{ fontWeight: "bold", color: "#707070", fontSize: 20 }}>
            {profile.name &&
              `${profile.name}, ${profile.age}`}
            <br />
          </label>
          <Grid.Row>
            <p
              style={{
                fontSize: 15,
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "3%",
                color: "#FF664D",
              }}
            >
              {profile.interests[0] ? (
                profile.interests.map(interest => `#${interest.name} `)
              ) : (
                <label style={{ color: "grey" }}>No interest</label>
              )}{" "}
            </p>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ProfileCard;
