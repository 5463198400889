import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import {
  fetchHomeProfileList,
  fetchHomeProfileListByCity,
} from "../../actions/index";

import ProfileListView from "./profile_list_view";
import ProfileListViewMobile from "./profile_list_view_mobile";

class ProfileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileList: props.homeProfileList,
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    this.fetchProfiles();
  }
  componentDidUpdate(prevProps) {
    if (this.props.city !== prevProps.city) {
      this.fetchProfiles();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.homeProfileList, nextProps.homeProfileList)) {
      this.setState({ profileList: nextProps.homeProfileList });
    }
  }

  fetchProfiles = () => {
    if (this.props.city) {
      this.props.fetchHomeProfileListByCity(this.props.city);
    } else {
      this.props.fetchHomeProfileList();
    }
  };
  render() {
    if (this.state.windowWidth > 768) {
      return <ProfileListView profileList={this.state.profileList} />;
    }

    return <ProfileListViewMobile profileList={this.state.profileList} />;
  }
}

const mapStateToProps = ({ homeProfileList }) => ({
  homeProfileList,
});

export default withRouter(
  connect(
    mapStateToProps,
    { fetchHomeProfileList, fetchHomeProfileListByCity }
  )(ProfileList)
);
