import React from "react";
import { Link } from "react-router-dom";
import { Icon, Menu, Image, Label } from "semantic-ui-react";
import UserMenu from "./user_menu";

const logo = require("../assets/images/roomigo.svg");

const NavBar = ({
  location,
  children,
  myProfile,
  linkToPreviousPage,
  receivedRequests,
  profileMessageList,
}) => {
  return (
    <Menu secondary style={{ width: "100%" }}>
      <Menu.Item position="left">
        <Link to="/">
          <Image src={logo} size="small" />
        </Link>
      </Menu.Item>
      <Menu.Menu
        style={{
          justifyContent: "center",
          width: "inherit",
        }}
      >
        {/* {myProfile.data.city && myProfile.data.city.name === "Dublin" ? (
          <Menu.Item>
            <a
              href="https://www.eventbrite.com/e/*****"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Label className="navbar-button">
                <Icon name="calendar alternate outline" />
                <b>EVENTS</b>
              </Label>
            </a>
          </Menu.Item>
        ) : (
          ""
        )} */}
        <Menu.Item>
          <Link to="/search_profile">
            <b>
              <font color="gray">FIND A ROOMMATE</font>
            </b>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/search_room">
            <b>
              <font color="gray">FIND A ROOM</font>
            </b>
          </Link>
        </Menu.Item>
        {location.pathname !== "/rooms/new" ? (
          <Menu.Item>
            <Link to="/rooms/new">
              <Label
                style={{
                  backgroundColor: "#00D5CC",
                  color: "#FFFFFF",
                  fontSize: "15px",
                }}
              >
                <Icon name="plus" />
                <b>LIST A ROOM</b>
              </Label>
            </Link>
          </Menu.Item>
        ) : (
          ""
        )}
      </Menu.Menu>
      {myProfile.data && myProfile.data.id ? (
        <Menu.Item position="right">
          <Link to="/requests">
            <Icon bordered circular color="grey" size="big" name="comments" />
          </Link>
          <UserMenu
            receivedRequests={receivedRequests}
            profileMessageList={profileMessageList}
          />
        </Menu.Item>
      ) : (
        <Menu.Item position="right">
          <Link to="/login">
            <b onClick={() => linkToPreviousPage()}>
              <font color="gray">LOGIN</font>
            </b>
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default NavBar;
