import React from "react";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";

const GoogleMapWrapper = withScriptjs(
  withGoogleMap(({ children, ...rest }) => (
    <GoogleMap {...rest}>{children}</GoogleMap>
  ))
);

export default GoogleMapWrapper;
