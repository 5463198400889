/* global window */
import React from 'react';
import { Grid, Item, Label, Image } from 'semantic-ui-react';
import _ from 'lodash';
import MessageList from './message_list';
import WebChat from './web_chat';
import { renderFavouritePicture } from '../../../utils/helpers';

function getFriendProfile(connectionList, friend_id) {
  let picture;

  const fprofile = connectionList.filter(
    connection => connection.friend_profile.data.id === friend_id
  );
  if (fprofile.length) {
    if (fprofile[0].friend_profile.data.pictures.length) {
      picture = renderFavouritePicture(
        fprofile[0].friend_profile.data.pictures
      );
    } else {
      picture =
        'https://react.semantic-ui.com/assets/images/wireframe/image.png';
    }
  }
  return picture;
}

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const ChatTab = ({
  connectionList,
  fetchMessageList,
  messageList,
  profile,
  connectionId,
  handleFieldChange,
  sendMessage,
  messageText,
  isChatVisible,
  showChatWindow,
  viewProfileDetail,
  friendProfile,
  profileMessageList,
}) => {
  const orderedConnectionList = _.orderBy(
    connectionList,
    ({ last_message_date }) => last_message_date || '',
    ['desc']
  );
  const lastTenMessages = _.orderBy(messageList, 'id');

  return (
    <Grid stackable>
      {windowWidth < 768 && isChatVisible && (
        <Grid.Row style={{ marginBottom: 25 }} mobile={4}>
          <Grid.Column width={2}>
            <Image
              bordered
              style={{
                width: '50px',
                height: '50px',
                borderRadius: 50,
                cursor: 'pointer',
                borderColor: '#FF664D',
                position: 'absolute',
                marginLeft: 20,
              }}
              src={
                friendProfile.pictures[0]
                  ? renderFavouritePicture(friendProfile.pictures)
                  : 'https://react.semantic-ui.com/assets/images/wireframe/image.png'
              }
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <Label
              basic
              style={{
                fontSize: 16,
                cursor: 'pointer',
                marginLeft: '25%',
                border: 0,
              }}
              onClick={() => viewProfileDetail(friendProfile.slug)}
            >
              {friendProfile.name},{friendProfile.age}
            </Label>
          </Grid.Column>
          <Grid.Column>
            <Label
              basic
              size="small"
              style={{
                borderColor: '#ff664d',
                borderRadius: 10,
                marginLeft: '27%',
                color: 'gray',
              }}
              onClick={() => viewProfileDetail(friendProfile.slug)}
            >
              View Profile
            </Label>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column computer={6} mobile={16}>
          <div
            style={{
              overflowY: windowWidth > 768 && 'scroll',
              overflowX: windowWidth > 768 && 'hidden',
              height: windowWidth > 768 && 0.7 * windowHeight,
            }}
          >
            <Item.Group divided>
              {orderedConnectionList.slice(0).map(connection => (
                <WebChat
                  key={connection.id}
                  connectionList={orderedConnectionList}
                  fetchMessageList={fetchMessageList}
                  connectionId={connectionId}
                  handleFieldChange={handleFieldChange}
                  sendMessage={sendMessage}
                  messageText={messageText}
                  isChatVisible={isChatVisible}
                  showChatWindow={showChatWindow}
                  viewProfileDetail={viewProfileDetail}
                  connection={connection}
                  profileMessageList={profileMessageList}
                />
              ))}
            </Item.Group>
          </div>
        </Grid.Column>

        {connectionId &&
          (windowWidth > 768 || (windowWidth < 768 && isChatVisible)) && (
            <MessageList
              friendProfile={friendProfile}
              lastTenMessages={lastTenMessages}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              profile={profile}
              connectionList={orderedConnectionList}
              handleFieldChange={handleFieldChange}
              sendMessage={sendMessage}
              getFriendProfile={getFriendProfile}
              messageText={messageText}
            />
          )}
      </Grid.Row>
    </Grid>
  );
};

export default ChatTab;
