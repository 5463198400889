import { axiosInstance } from './configured_axios';
import {
  CREATE_ROOM_SUCCESS,
  CREATE_ROOM_ERROR,
  EDIT_ROOM_SUCCESS,
  EDIT_ROOM_ERROR,
  FETCH_ROOM_LIST,
  FETCH_HOME_ROOM_LIST,
  FETCH_ROOM_DETAIL,
  SET_ROOM_TO_BOOK,
  EMPTY_ROOM,
  EMPTY_ROOM_LIST,
  CREATE_ROOM_SEARCH,
  FIND_ROOMS,
  FETCH_USER_SEARCH_PARAMS,
  SET_USER_SEARCH_PARAMS_ON_SYNC_FLAG,
  DELETE_ROOM,
  IS_SEARCHING,
  ROOM_SEARCH_PAGE,
  SET_ROOM_FIELDS_ERRORS,
  SET_ROOM_ON_SYNC_FLAG,
  SET_CITIES,
  CITY_SELECT,
  SET_CITIES_ON_SYNC_FLAG,
} from './action_types';
// import { cities } from "../assets/cities";
export function isCitiesOnSyncFlag(flag) {
  return {
    type: SET_CITIES_ON_SYNC_FLAG,
    payload: flag,
  };
}
export function citySelect(city) {
  return dispatch => {
    dispatch({
      type: CITY_SELECT,
      payload: city,
    });
  };
}
export function fetchCities() {
  return dispatch => {
    dispatch(isCitiesOnSyncFlag(true));
    axiosInstance
      .get(`/api/cities`)
      .then(response =>
        dispatch({
          type: SET_CITIES,
          payload: response.data,
        })
      )
      .then(() => dispatch(isCitiesOnSyncFlag(false)));
  };
}

export const setRoomFieldsWithError = fields => dispatch => {
  dispatch({
    type: SET_ROOM_FIELDS_ERRORS,
    payload: fields,
  });
  return Promise.resolve();
};

export function fetchHomeRoomList(page = 1, page_size = 4) {
  return dispatch => {
    axiosInstance
      .get('/api/rooms', { params: { page, page_size } })
      .then(request =>
        dispatch({
          type: FETCH_HOME_ROOM_LIST,
          payload: request,
        })
      )
      .catch(err => console.log(err));
  };
}

export function fetchHomeRoomListByCity(
  city,
  page = 1,
  includePrevious = false
) {
  return dispatch => {
    axiosInstance
      .post('/api/find_rooms', {
        search_params: {
          age_min: 18,
          age_max: 99,
          user_id: 545,
          type: 'No preference',
          price_min: 0,
          price_max: 9999,
          preferred_relationship: 'No preference',
          preferred_occupation: 'No preference',
          preferred_gender: 'No preference',
          move_in_date: null,
          id: 879,
          ensuite: 'No preference',
          city: city,
          area_code: [],
          area: [],
        },
        include_previous: includePrevious,
        page,
      })
      .then(response => {
        dispatch({ type: FETCH_HOME_ROOM_LIST, payload: response });
      })
      .catch(err => console.log(err));
  };
}

export function fetchRoomList(page = 1) {
  return dispatch => {
    axiosInstance.get('/api/rooms', { params: { page } }).then(request =>
      dispatch({
        type: FETCH_ROOM_LIST,
        payload: request,
      })
    );
  };
}

export function fetchUserRoomList() {
  const request = axiosInstance.get('/api/user_rooms');

  return {
    type: FETCH_ROOM_LIST,
    payload: request,
  };
}

export function publishRoom(room) {
  return dispatch => {
    axiosInstance
      .post(`/api/rooms`, { room })
      .then(response =>
        dispatch({ type: CREATE_ROOM_SUCCESS, payload: response })
      )
      .catch(error => dispatch({ type: CREATE_ROOM_ERROR, payload: error }));
  };
}

export function editRoom(room) {
  return dispatch => {
    axiosInstance
      .put(`/api/rooms/${room.slug}/`, { room })
      .then(response =>
        dispatch({ type: EDIT_ROOM_SUCCESS, payload: response })
      )
      .catch(error => dispatch({ type: EDIT_ROOM_ERROR, payload: error }));
  };
}

export function deleteRoom(roomId) {
  return dispatch => {
    axiosInstance.delete(`/api/rooms/${roomId}`).then(response => {
      dispatch({ type: DELETE_ROOM, payload: response });
      dispatch(fetchUserRoomList());
    });
  };
}

export function isRoomOnSyncFlag(flag) {
  return {
    type: SET_ROOM_ON_SYNC_FLAG,
    payload: flag,
  };
}

export function fetchRoomDetail(room) {
  return dispatch => {
    dispatch(isRoomOnSyncFlag(true));

    axiosInstance
      .get(`/api/rooms/${room}`)
      .then(request =>
        dispatch({
          type: FETCH_ROOM_DETAIL,
          payload: request,
        })
      )
      .then(() => dispatch(isRoomOnSyncFlag(false)));
  };
}

export function setRoomToBook(room) {
  return {
    type: SET_ROOM_TO_BOOK,
    payload: room,
  };
}

export function emptyRoom() {
  return {
    type: EMPTY_ROOM,
    payload: {},
  };
}

export function emptyRoomList() {
  return {
    type: EMPTY_ROOM_LIST,
    payload: [],
  };
}

export function createRoomSearch(searchParams) {
  const request = axiosInstance.post('/api/room_search', {
    room_search: searchParams,
  });

  return dispatch => {
    request.then(response => {
      dispatch({ type: CREATE_ROOM_SEARCH, payload: response });
      dispatch(findRooms(response.data.data));
    });
  };
}

export function isUserSearchParamsOnSyncFlag(flag) {
  return {
    type: SET_USER_SEARCH_PARAMS_ON_SYNC_FLAG,
    payload: flag,
  };
}

export function fetchUserSearchParams(userId) {
  return dispatch => {
    dispatch(isUserSearchParamsOnSyncFlag(true));

    axiosInstance
      .get(`/api/users/${userId}/search`)
      .then(request =>
        dispatch({
          type: FETCH_USER_SEARCH_PARAMS,
          payload: request,
        })
      )
      .then(() => dispatch(isUserSearchParamsOnSyncFlag(false)));
  };
}

export function findRooms(searchParams, page = 1, includePrevious = false) {
  // temporarily excluded age filter, so use default values 18 to 99
  // TODO

  // eslint-disable-next-line no-unused-vars
  const { age_max, age_min, ...rest } = searchParams;
  return dispatch => {
    dispatch({ type: IS_SEARCHING, payload: true });
    axiosInstance
      .post('/api/find_rooms', {
        search_params: {
          age_min: 18,
          age_max: 99,
          ...rest,
        },
        include_previous: includePrevious,
        page,
      })
      .then(response => {
        dispatch({ type: FIND_ROOMS, payload: response });
        dispatch({ type: IS_SEARCHING, payload: false });
      })
      .catch(err => console.log(err));
  };
}

export function setRoomSearchPage(pageNum) {
  return {
    type: ROOM_SEARCH_PAGE,
    payload: pageNum,
  };
}
