/* global window */
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Item, Label, Image } from 'semantic-ui-react';
import _ from 'lodash';
import { renderFavouritePicture } from '../../../utils/helpers';

const windowWidth = window.innerWidth;

const WebChat = ({
  connectionList,
  fetchMessageList,
  connectionId,
  handleFieldChange,
  sendMessage,
  messageText,
  isChatVisible,
  showChatWindow,
  viewProfileDetail,
  connection,
  profileMessageList,
}) => {
  if (windowWidth < 768 && isChatVisible) {
    return <div />;
  }
  return (
    <Item style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column style={{ marginTop: 25 }}>
            {_.size(
              _.filter(
                profileMessageList,
                int => int.sender_id === connection.friend_profile.data.id
              )
            ) !== 0 && (
              <Label
                circular
                floating
                style={{
                  backgroundColor: '#ff5050',
                  color: '#fff',
                }}
              >
                {
                  profileMessageList.filter(
                    p => p.sender_id === connection.friend_profile.data.id
                  ).length
                }
              </Label>
            )}
            <Image
              onClick={() => fetchMessageList(connection)}
              bordered
              style={{
                width: windowWidth > 768 ? '60px' : '75px',
                height: windowWidth > 768 ? '60px' : '75px',
                borderRadius: 50,
                cursor: 'pointer',
                borderWidth: connectionId === connection.id ? 3 : 0,
                borderColor: '#FF664D',
                marginBottom: '50%',
              }}
              src={
                connection.friend_profile.data.pictures[0]
                  ? renderFavouritePicture(
                      connection.friend_profile.data.pictures
                    )
                  : 'https://react.semantic-ui.com/assets/images/wireframe/image.png'
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Item.Content style={{ marginLeft: 10 }}>
        <Item.Header onClick={() => fetchMessageList(connection)}>
          <Label
            basic
            style={{
              cursor: 'pointer',
              color: '#707070',
              border: '0px',
              fontWeight:
                _.size(
                  _.filter(
                    profileMessageList,
                    int => int.sender_id === connection.friend_profile.data.id
                  )
                ) !== 0
                  ? 900
                  : 100,
              fontSize: '14px',
            }}
          >
            {connection.friend_profile.data.name},
            {connection.friend_profile.age}
          </Label>
        </Item.Header>
        <Item.Meta
          style={{ cursor: 'pointer', marginLeft: 10, width: '80%' }}
          onClick={() => fetchMessageList(connection)}
        >
          {connection.friend_profile.data.interests &&
            connection.friend_profile.data.interests
              .slice(0, 2)
              .map(interest => (
                <font
                  key={interest.id}
                  style={{ color: '#00D5CC', marginRight: '2%' }}
                >
                  #{interest.name},&ensp;
                </font>
              ))}
        </Item.Meta>
        <Item.Meta style={{ marginLeft: 10 }}>
          <Link to={`/profile/${connection.friend_profile.data.slug}`}>
            <Label
              basic
              size="small"
              style={{
                borderColor: '#ff664d',
                borderRadius: 10,
                color: 'gray',
              }}
            >
              <b>View Profile</b>
            </Label>
          </Link>
        </Item.Meta>
      </Item.Content>
    </Item>
  );
};

export default WebChat;
