import React from "react";

import { Segment } from "semantic-ui-react";
const Privacy = () => {
  return (
    <div className="site-container">
      <Segment
        style={{
          padding: 35,
          marginTop: "2rem",
        }}
      >
        <h2>
          <strong>PRIVACY POLICY</strong>
        </h2>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Welcome to RooMigo&rsquo;s Privacy Policy. Thank you for taking the
            time to read it.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            This Privacy Policy is intended to inform you about how we treat
            personal information that we process about you. If you do not agree
            to any part of this Privacy Policy, then we cannot provide our
            services to you, you should not make any enquiries or interact with
            anyone on our Web App (the website at roomigo.io) and you should not
            continue to access our Web App.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            The Wep App and services are intended{" "}
          </span>
          <strong>solely for persons who are 18 years or older</strong>
          <span style={{ fontWeight: 400 }}>
            , any access to or use of the site or services by anyone under 18
            years of age is expressly prohibited. By accessing, creating a user
            account or using the site or services you represent and warrant that
            you are over 18 years of age.
          </span>
        </p>
        <p>&nbsp;</p>
        <h3>
          <strong>1. WHO WE ARE</strong>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            The company that is responsible for your information under this
            Privacy Policy (the &ldquo;data controller&rdquo;) is:
          </span>
        </p>
        <p>
          <strong>Home View Media Limited</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            The Digital Exchange, Crane Street, Ushers, Dublin 8, Ireland.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            You may contact us at the above address by post, or email us at{" "}
          </span>
          <a href="mailto:info@roomigo.io">
            <span style={{ fontWeight: 400 }}>info@roomigo.io</span>
          </a>
        </p>
        <h3>
          <strong>2. WHERE THIS PRIVACY POLICY APPLIES</strong>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            This Privacy Policy applies to websites, apps, events, communities
            and other services operated by RooMigo. We refer to all of these as
            our &ldquo;services&rdquo; in this Privacy Policy.
          </span>
        </p>
        <h3>
          <strong>3. INFORMATION WE COLLECT / YOU GIVE US</strong>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            We can&rsquo;t help you connect with housemates, landlords or
            tenants without some information about you and your home (if you
            create a room listing post), such as basic profile details, the
            types of people you&rsquo;d like to live with and information
            related to your home such as your address, photos of the home, the
            description of your home and other information such as your
            preferences for the type of people you would like to rent the home
            to. We also collect information generated as you use our services,
            for example access logs, as well as information from third parties,
            like when you access our services through a social media account.
          </span>
        </p>
        <h4>
          <strong>Information you give us:</strong>
        </h4>
        <p>
          <strong>Your Profile Information</strong>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            This includes: When you create an account, you provide with your
            login credentials, as well as some basic details necessary for the
            service to work, such as your gender, date of birth, name or whether
            you are living as a couple or as a single person, whether you or a
            student or professional. You will also fill in your bio.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            You also share with us additional information, such as details on
            your personality, lifestyle, interests and other details about you,
            as well as content such as photos. To add certain content, like
            pictures or videos, you may allow us to access your camera or photo
            album. Some of the information you choose to provide us may be
            considered &ldquo;special&rdquo; or &ldquo;sensitive&rdquo; in
            certain jurisdictions, for example your racial or ethnic origins,
            sexual orientation and religious beliefs. By choosing to provide
            this information, you consent to our processing of that information.
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            The above information will form part of your profile page, and will
            be publically visible to others (note you can turn the visibility of
            your public profile on or off on your profile page).{" "}
          </span>
        </p>

        <p>
          <strong>Your Room Listing Information</strong>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            This includes information related to your home such as the address,
            the photos of the home, a video of the home, a description of the
            home and the people in it, your preferences (for type of tenant
            you&rsquo;d like to rent a room to), information about the number of
            people living and gender of the other people in the house, the type
            of housemate you are looking, house amenities and rules, and other
            information if requested such as links to the profiles of your
            housemates.{" "}
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            The above information will be contained in a link on your public
            profile page, and will be publically visible to others who view it
            via that link, or who find it in the &ldquo;Rooms&rdquo; section of
            the Web App. If you turn your public profile off, or if you
            deactivate or delete your Room Listing, the room Listing will no
            longer be visible to the public.{" "}
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            <br />
          </span>
          <span style={{ fontWeight: 400 }}>
            You will also give us information when:
          </span>
        </p>

        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              When you participate in surveys or feedback via our customer
              support chat or email, you give us your insights into our products
              and services, responses to our questions and testimonials.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              When you choose to participate in our promotions, events or
              contests, we collect the information that you use to register or
              enter.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              If you contact our customer care team, we collect the information
              you give us during the interaction. Sometimes, we monitor or
              record these interactions for training purposes and to ensure a
              high quality of service.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              We also process your chats with other users as well as the content
              you publish, as part of the operation of the services.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              When you create room listings.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Your connection requests sent and received and also requests you
              decline (when using the RooMigo web app.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              You may provide your email address when you attend our events,
              sign up and create an account on RooMigo.io subscribe to our blog
              or newsletter, in our online communities or &nbsp;filling in forms
              where you are asked to provide your email address to receive
              updates / news or marketing material related to our services.{" "}
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              You may choose to import contacts from your address book or enter
              your contacts&rsquo; information manually to invite your contacts
              to become users and join the Web App.{" "}
            </span>
          </li>
        </ul>
        <h4>
          <strong>Information we receive from others</strong>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            In addition to the information you provide us directly, we receive
            information about you from others, including:
          </span>
        </p>
        <p>
          <strong>Other Users</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Other users may provide information about you as they use our
            services. For instance, we may collect information about you from
            other users if they contact us about you.
          </span>
        </p>
        <p>
          <strong>Social Media</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            You may be able to use your social media login (such as Facebook
            Login) to create and log into your RooMigo account. This allows you
            to share some information from your social media account with us.
          </span>
        </p>
        <p>
          <strong>Other Partners</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            We may receive info about you from our partners, for instance where
            RooMigo ads are published on a partner&rsquo;s websites and
            platforms (in which case they may pass along details on a
            campaign&rsquo;s success).
          </span>
        </p>
        <h4>
          <strong>Information collected when you use our services</strong>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            When you use our services, we collect information about which
            features you&rsquo;ve used, how you&rsquo;ve used them and the
            devices you use to access our services. See below for more details:
          </span>
        </p>
        <p>
          <strong>Usage Information</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            We collect information about your activity on our services, for
            instance how you use them (e.g., date and time you logged in,
            features you&rsquo;ve been using, searches, clicks and pages which
            have been shown to you, referring webpage address, advertising that
            you click on) and how you interact with other users (e.g., users you
            connect and interact with, time and date of your exchanges, number
            of messages you send and receive, connection requests sent, received
            and declined). The Web App will collect all information and
            correspondence communicated via our messaging system.{" "}
          </span>
        </p>

        <p>
          <strong>Device information</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            We collect information from and about the device(s) you use to
            access our services, including:
          </span>
        </p>
        <ul>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                hardware and software information such as IP address, device ID
                and type, device-specific and apps settings and characteristics,
                app crashes, advertising IDs (such as Google&rsquo;s AAID and
                Apple's IDFA, both of which are randomly generated numbers that
                you can reset by going into your device&rsquo; settings),
                browser type, version and language, operating system, time
                zones, identifiers associated with cookies or other technologies
                that may uniquely identify your device or browser (e.g.,
                IMEI/UDID and MAC address);
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                information on your wireless and mobile network connection, like
                your service provider and signal strength;
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                information on device sensors such as accelerometers, gyroscopes
                and compasses.
              </span>
            </li>
          </ul>
        </ul>
        <p>
          <strong>Other information with your consent</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            If you give us permission, we can collect your precise geolocation
            (latitude and longitude) through various means, depending on the
            service and device you&rsquo;re using, including GPS, Bluetooth or
            Wi-Fi connections. The collection of your geolocation may occur in
            the background even when you aren&rsquo;t using the services if the
            permission you gave us expressly permits such collection. If you
            decline permission for us to collect your geolocation, we will not
            collect it.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Similarly, if you consent, we may collect your photos and videos
            (for instance, if you want to publish a photo, video or streaming on
            the services).
          </span>
        </p>
        <p>
          <strong>URL Shortener Information</strong>
          <span style={{ fontWeight: 400 }}>
            : We may use URL shortener services to provide you with shorter
            links to our pages (eg: goo.gl links). The Google URL shortener
            service tracks how many times shortened URLs are clicked and when.{" "}
          </span>
        </p>
        <p>
          <strong>Mapping Information: </strong>
          <span style={{ fontWeight: 400 }}>
            For mapping purposes, we may decide to use Google Maps / Eath
            services, including the Google Map API(s). The use of Google
            Maps/Earth is subject to Google Maps privacy statemetn.{" "}
          </span>
        </p>
        <p>
          <strong>Social sharing Information:</strong>
          <span style={{ fontWeight: 400 }}>
            {" "}
            We may decide to use a service such as Addthis.com (or another
            similar type of social sharing service) to allow users share pages
            from the Website on social networks. The third party service also
            sets cookies which track the usage of the service. They collect
            non-personally identifiable information from many of the websites in
            which AddThis is enabled, and use that information to deliver
            targeted advertising and personalized content on those websites as
            well as other websites. You can opt out of this here:{" "}
          </span>
          <a href="http://www.addthis.com/privacy/opt-out">
            <span style={{ fontWeight: 400 }}>
              http://www.addthis.com/privacy/opt-out
            </span>
          </a>
        </p>
        <p>
          <strong>Paid Advertising Information</strong>
          <span style={{ fontWeight: 400 }}>
            : We use paid advertising (such as Google Adwords, Bing Ads,
            Facebook Ads, Google DoubleClick) to promote our website. These
            services will track if you click on a paid link and will mark a
            &ldquo;conversion&rdquo; on specific pages of our Website (eg: when
            you complete a request or a booking). These tracking cookies are
            used to determine what forms of advertisement are more effective to
            promote the Website.{" "}
          </span>
        </p>
        <p>
          <strong>Analytics Information</strong>
          <span style={{ fontWeight: 400 }}>
            : For website analysis Google analytics is used to track in
            aggregate the overall Website performance. For email analysis
            Sendgrid is used to track delivery of emails and interaction with
            call to actions included in those emails. For SMS messages, Nexmo
            and Message Bird are used to track delivery of SMS to your mobile
            phone.
          </span>
        </p>
        <p>
          <strong>Hotjar: </strong>
          <span style={{ fontWeight: 400 }}>
            We use Hotjar in order to better understand our users&rsquo; needs
            and to optimize this service and experience. Hotjar is a technology
            service that helps us better understand our users experience (e.g.
            how much time they spend on which pages, which links they choose to
            click, what users do and don&rsquo;t like, etc.) and this enables us
            to build and maintain our service with user feedback. Hotjar uses
            cookies and other technologies to collect data on our users&rsquo;
            behavior and their devices (in particular device's IP address
            (captured and stored only in anonymized form), device screen size,
            device type (unique device identifiers), browser information,
            geographic location (country only), preferred language used to
            display our website). Hotjar stores this information in a
            pseudonymized user profile. Neither Hotjar nor we will ever use this
            information to identify individual users or to match it with further
            data on an individual user. For further details, please see
            Hotjar&rsquo;s privacy policy by clicking on{" "}
          </span>
          <span style={{ fontWeight: 400 }}>&nbsp;</span>
          <a href="https://www.hotjar.com/legal/policies/privacy">
            <span style={{ fontWeight: 400 }}>this link</span>
          </a>
          <span style={{ fontWeight: 400 }}>.</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            You can opt-out to the creation of a user profile, Hotjar&rsquo;s
            storing of data about your usage of our site and Hotjar&rsquo;s use
            of tracking cookies on other websites by following this{" "}
          </span>
          <a href="https://www.hotjar.com/legal/compliance/opt-out">
            <span style={{ fontWeight: 400 }}>opt-out link</span>
          </a>
        </p>
        <p>
          <strong>
            <br />
          </strong>
          <strong>Use of Intercom Services: </strong>
          <span style={{ fontWeight: 400 }}>
            We use third-party analytics services to help understand your usage
            of our services. In particular, we provide a limited amount of your
            information (such as sign-up date and some personal information like
            your email address) to Intercom, Inc. (&ldquo;Intercom&rdquo;) and
            utilize Intercom to collect data for analytics purposes when you
            visit our website or use our product. As a data processor acting on
            our behalf, Intercom analyzes your use of our website and/or product
            and tracks our relationship by way of cookies and similar
            technologies so that we can improve our service to you. For more
            information on Intercom's use of cookies, please visit{" "}
          </span>
          <a href="https://www.intercom.com/terms-and-policies#cookie-policy">
            <span style={{ fontWeight: 400 }}>
              https://www.intercom.com/terms-and-policies#cookie-policy
            </span>
          </a>
          <span style={{ fontWeight: 400 }}>
            . We may also use Intercom as a medium for communications, either
            through email, or through messages within our product(s). As part of
            our service agreements, Intercom collects publicly available contact
            and social information related to you, such as your email address,
            gender, company, job title, photos, website URLs, social network
            handles and physical addresses, to enhance your user experience. For
            more information on the privacy practices of Intercom, please visit{" "}
          </span>
          <a href="https://protect-eu.mimecast.com/s/zMP6C0glXI29np7TOQGei?domain=intercom.com">
            <span style={{ fontWeight: 400 }}>
              https://www.intercom.com/terms-and-policies#privacy
            </span>
          </a>
          <span style={{ fontWeight: 400 }}>
            . Intercom&rsquo;s services are governed by Intercom&rsquo;s terms
            of use which can be found at{" "}
          </span>
          <a href="https://protect-eu.mimecast.com/s/_s9hCg5BQCljvDoc7KDEG?domain=intercom.com">
            <span style={{ fontWeight: 400 }}>
              https://www.intercom.com/terms-and-policies#terms
            </span>
          </a>
          <span style={{ fontWeight: 400 }}>
            . If you would like to opt out of having this information collected
            by or submitted to Intercom, please contact us.
          </span>
        </p>
        <h3>
          <strong>
            4. COOKIES AND OTHER SIMILAR DATA COLLECTION TECHNOLOGIES
          </strong>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            We use and may allow others to use cookies and similar technologies
            (e.g., web beacons, pixels) to recognize you and/or your device(s).
            You may read our Cookie Policy (at the end of this document) for
            more information on why we use them (such as authenticating you,
            remembering your preferences and settings, analyzing site traffic
            and trends, delivering and measuring the effectiveness of
            advertising campaigns, allowing you to use social features) and how
            you can better control their use, through your browser settings and
            other tools.
          </span>
        </p>
        <h3>
          <strong>5. HOW WE USE INFORMATION</strong>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            The main reason we use your information is to deliver and improve
            our services. Additionally, we use your information to help keep you
            safe and to provide you with advertising that may be of interest to
            you.
          </span>
        </p>
        <h4>
          <strong>
            To administer your account and provide our services to you
          </strong>
        </h4>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Create and manage your account
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Provide you with customer support and respond to your requests
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Complete your transactions</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Communicate with you about our services, including order
              management and billing
            </span>
          </li>
        </ul>
        <h4>
          <strong>To help you connect with other members</strong>
        </h4>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Analyise your profile and that of other users to recommend
              meaningful connections
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Show users&rsquo; profiles to one another
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Show users&rsquo; room listings to other users
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Once you turn your profile to &ldquo;show me on RooMigo&rdquo;
              your profile will be public, however you can only be contacted by
              a member who has signed up and created a public profile on
              RooMigo. This is to ensure transparency and meaningful
              connections. This means if someone sends you a Connection Request
              you can see their profile and learn more about that person before
              you accept or reject that request. &nbsp;You may turn your public
              profile off at any time from your profile and if you this you
              cannot be found on RooMigo. Please note, once you have accepted
              Connection Requests or others have accepted your Connection
              Requests, those people can still send you chats on RooMigo,
              however other members will not be able to do so until you make
              your profile public again.
            </span>
          </li>
        </ul>
        <h4>
          <strong>
            To help you find rooms or to show your room to other people
          </strong>
        </h4>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Analyze your profile and that of other users to recommend room
              listings.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Show room listings to other users or members of the RooMigo
              community. Please note once your profile is public and you have
              posted listings it is possible for members of the public (not on
              RooMigo to see your listing) but they cannot send you connection
              requests until both you and they have activated your RooMigo
              profile.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Show links to your public room listing to members of our Facebook
              Housemate communities.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              At any time you may deactivate room listings, or delete the
              listing. If you delete or deactivate a room listing people will no
              longer be able to find that room listing on RooMigo (save for
              those with whom you are already connected with).
            </span>
          </li>
        </ul>
        <h4>
          <strong>To ensure a consistent experience across your devices</strong>
        </h4>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Link the various devices you use so that you can enjoy a
              consistent experience of our services on all of them. We do this
              by linking devices and browser data, such as when you log into
              your account on different devices or by using partial or full IP
              address, browser version and similar data about your devices to
              help identify and link them.
            </span>
          </li>
        </ul>
        <h4>
          <strong>To provide new RooMigo services to you</strong>
        </h4>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Register you and display your profile on new RooMigo features and
              apps
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Administer your account on these new features and apps
            </span>
          </li>
        </ul>
        <h4>
          <strong>To serve you relevant offers and ads</strong>
        </h4>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Administer sweepstakes, contests, discounts or other offers
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Develop, display and track content and advertising tailored to
              your interests on our services and other sites
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Communicate with you by email, phone, social media or mobile
              device about products or services that we think may interest you
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Send you our newsletter</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Direct Marketing; we, and/or our authorised agents with your
              consent may inform you by telephone (if you provide this number on
              your profile), letter, email or post of special offers, discounts,
              competitions and promotions. Such emails may include links to the
              websites of third parties.{" "}
            </span>
          </li>
        </ul>
        <h4>
          <strong>To improve our services and develop new ones</strong>
        </h4>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Administer focus groups, innovation workshops and surveys
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Conduct research and analysis of users&rsquo; behaviour to improve
              our services and content (for instance, we may decide to change
              the look and feel or even substantially modify a given feature
              based on users&rsquo; behaviour)
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Develop new features and services
            </span>
          </li>
        </ul>
        <h4>
          <strong>
            To prevent, detect and fight fraud or other illegal or unauthorized
            activities
          </strong>
        </h4>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Address on-going or alleged mis-behaviour on and off-platform
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Perform data analysis to better understand and design
              countermeasures against these activities
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Retain data related to fraudulent activities to prevent against
              recurrences
            </span>
          </li>
        </ul>
        <h4>
          <strong>To ensure legal compliance</strong>
        </h4>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Comply with legal requirements
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Assist law enforcement</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Enforce or exercise our rights, for example our Terms
            </span>
          </li>
        </ul>

        <p>
          <strong>
            To process your information as described above, we rely on the
            following legal bases:
          </strong>
        </p>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <strong>Provide our service to you:</strong>
            <span style={{ fontWeight: 400 }}>
              {" "}
              Most of the time, the reason we process your information is to
              perform the contract that you have with us. For instance, as you
              go about using our service to connect with housemates / landlords,
              we use your information to maintain your account and your profile
              and/or room listing(s), to make it viewable to other users and
              recommend other users to you.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <strong>Legitimate interests:</strong>
            <span style={{ fontWeight: 400 }}>
              {" "}
              We may use your information where we have legitimate interests to
              do so. For instance, we analyise users&rsquo; behaviour on our
              services to continuously improve our offerings, we suggest offers
              we think might interest you, and we process information for
              administrative, fraud detection and other legal purposes.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <strong>Consent:</strong>
            <span style={{ fontWeight: 400 }}>
              {" "}
              From time to time, we may ask for your consent to use your
              information for certain specific reasons. You may withdraw your
              consent at any time by contacting us at the address provided at
              the end of this Privacy Policy or emailing us at info@roomigo.io.
            </span>
          </li>
        </ul>
        <h3>
          <strong>6. HOW WE SHARE INFORMATION</strong>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            Since our goal is to help you match with the right housemates, the
            main sharing of users&rsquo; information is, of course, with other
            users. We may for example send other users or members of our
            Facebook communities links to your room listing to help you connect
            with potential housemates, landlords or tenants. We also share some
            users&rsquo; information with service providers and partners who
            assist us in operating the services, and, in some cases, legal
            authorities.
          </span>
        </p>
        <h4>
          <strong>With other users</strong>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            You share information with other users when you voluntarily disclose
            information on the service (including your public profile which can
            be viewed by members of the public). Please be careful with your
            information and make sure that the content you share is stuff that
            you&rsquo;re comfortable being publically viewable since neither you
            nor we can control what others do with your information once you
            share it.
          </span>
        </p>
        <h4>
          <strong>With our service providers and partners</strong>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            We use third parties to help us operate and improve our services.
            These third parties assist us with various tasks, including data
            hosting and maintenance, analytics, advertising, marketing, payment
            processing and security operations.
          </span>
        </p>
        <h4>
          <strong>For corporate transactions</strong>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            We may transfer your information if we are involved, whether in
            whole or in part, in a merger, sale, acquisition, divestiture,
            restructuring, reorganization, dissolution, bankruptcy or other
            change of ownership or control.
          </span>
        </p>
        <h4>
          <strong>When required by law</strong>
        </h4>
        <h4>
          <span style={{ fontWeight: 400 }}>
            We may disclose your information if reasonably necessary: (i) to
            comply with a legal process, such as a court order, subpoena or
            search warrant, government / law enforcement investigation or other
            legal requirements; (ii) to assist in the prevention or detection of
            crime (subject in each case to applicable law); or (iii) to protect
            the safety of any person.
          </span>
        </h4>
        <h4>
          <strong>To enforce legal rights</strong>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            We may also share information: (i) if disclosure would mitigate our
            liability in an actual or threatened lawsuit; (ii) as necessary to
            protect our legal rights and legal rights of our users, business
            partners or other interested parties; (iii) to enforce our
            agreements with you; and (iv) to investigate, prevent, or take other
            action regarding illegal activity, suspected fraud or other
            wrongdoing.
          </span>
        </p>
        <h4>
          <strong>With your consent or at your request</strong>
        </h4>
        <p>
          <span style={{ fontWeight: 400 }}>
            We may ask for your consent to share your information with third
            parties. In any such case, we will make it clear why we want to
            share the information.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            We may use and share non-personal information (meaning information
            that, by itself, does not identify who you are such as device
            information, general demographics, general behavioral data,
            lifestyle choices, geolocation in de-identified form), as well as
            personal information in hashed, non-human readable form, under any
            of the above circumstances. We may also share this information with
            third parties (notably advertisers) to develop and deliver targeted
            advertising on our services and on websites or applications of third
            parties, and to analyze and report on advertising you see. We may
            combine this information with additional non-personal information or
            personal information in hashed, non-human readable form collected
            from other sources. More information on our use of cookies and
            similar technologies can be found in our Cookie Policy (at the end
            of this document).{" "}
          </span>
        </p>
        <h3>
          <strong>7. HOW WE PROTECT YOUR INFORMATION</strong>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            We work hard to protect you from unauthorized access to or
            alteration, disclosure or destruction of your personal information.
            As with all technology companies, although we take steps to secure
            your information, we do not promise, and you should not expect, that
            your personal information will always remain secure.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            We may suspend your use of all or part of the services without
            notice if we suspect or detect any breach of security. If you
            believe that your account or information is no longer secure, please
            notify us immediately info@roomigo.io
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            No method of transmission over the Internet, and no method of
            storing electronic information, can be 100% secure. So, we cannot
            guarantee the security of your transmissions to us and of your
            Personal Information that we storlle.{" "}
          </span>
        </p>
        <h3>
          <strong>8. HOW LONG WE RETAIN YOUR INFORMATION</strong>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            We keep your personal information only as long as we need it for
            legitimate business purposes and as permitted by applicable law.{" "}
          </span>
        </p>
        <h3>
          <strong>9. UPDATING VERIFYING AND DELETING PERSONAL DATA</strong>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            Your information may be accessed and kept up to date via your user
            account. For information you cannot access via your user account,
            you also have a right to request it to be corrected, if inaccurate,
            or erased. Please forward any such requests by email to{" "}
          </span>
          <a href="mailto:info@roomigo.io">
            <span style={{ fontWeight: 400 }}>info@roomigo.io</span>
          </a>
          <span style={{ fontWeight: 400 }}>
            {" "}
            &nbsp;You are responsible for keeping your personal information up
            to date.
          </span>
        </p>
        <h3>
          <strong>10.RIGHT OF ACCESS TO YOU PERSONAL DATA</strong>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            To find out what personal data we hold on you or to have your
            personal data updated, amended or removed from our database, please
            contact{" "}
          </span>
          <a href="mailto:info@roomigo.io">
            <span style={{ fontWeight: 400 }}>info@roomigo.io</span>
          </a>
        </p>

        <h3>
          <strong>
            {" "}
            11. RIGHT TO OPT OF DIRECT MARKETING / MANAGE YOUR CONSTENT
          </strong>
        </h3>

        <p>
          <span style={{ fontWeight: 400 }}>
            You can withdraw your consent to the processing of your personal
            information at any time via your user account settings or by
            requesting to opt out via email to{" "}
          </span>
          <a href="mailto:info@roomigo.io">
            <span style={{ fontWeight: 400 }}>info@roomigo.io</span>
          </a>
        </p>

        <h3>
          <strong>12. CHANGES TO THE PRIVACITY STATEMENT</strong>
        </h3>

        <p>
          <span style={{ fontWeight: 400 }}>
            Any changes to this Privacy Statement will be posted on the Wep App
            so you are always aware of what information we collect, how we use
            it, and under what circumstances, if any, we disclose it. If at any
            time we decide to use personal data in a manner significantly
            different from that stated in this Privacy Statement, or as
            otherwise disclosed to you at the time it was collected, we will
            notify you by email and you will have a choice as to whether or not
            we use your information in the new manner. Should you disagree with
            any changes or modifications to the privacy statement, you may
            cancel your user account. However should you continue to use the
            services following this notification you will be bound by the new
            privacy terms.
          </span>
        </p>

        <h3>
          <strong> 13. GOVERMIMG LAW AND JUSTICE</strong>
        </h3>
        <span style={{ fontWeight: 400 }}>
          <br />
        </span>
        <span style={{ fontWeight: 400 }}>
          This Privacy Statement is governed by the laws of Ireland and is
          subject to the exclusive jurisdiction of the Irish courts. You have
          the right to file a complaint with the Irish Data Protection
          Commissioner in relation to how your data is being processed.
        </span>

        <h3>
          <strong>14. EXCLUSION OF LIABILITY</strong>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            <br />
          </span>
          <span style={{ fontWeight: 400 }}>
            Home Vue Media Limited and its subsidiaries, affiliates, officers,
            agents and employees, from and against any claim, liabilities,
            damages, losses and/or expenses, including, without limitation,
            reasonable legal and accounting fees, arising out of or in any way
            connected with: (i) any claims made by Customers; (ii) the provision
            of accommodation by a RooMigo member; and (iii) the Partner&rsquo;s
            use of the Site and/or the Services, arising out of or in connection
            with the use of any personal information relating to you in
            accordance with this privacy statement and your consent.
          </span>
        </p>
        <h3>
          <strong>15. HOW TO CONTACT US</strong>
        </h3>
        <p>
          <span style={{ fontWeight: 400 }}>
            If you have questions about this Privacy Policy, here&rsquo;s how
            you can reach us at info@roomigo.io
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>By post:</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>Privacy Officer</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>Home Vue Media Limited</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            The Digital Exchange, Crane Street,
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>Ireland</span>
        </p>

        <p>
          <strong>COOKIE POLICY</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Roomigo may use &ldquo;cookies&rdquo; in conjunction with the
            website to obtain information. A cookie is a small information
            package that is transferred to your device for record-keeping
            purposes.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Your browser may allow you to set how it handles cookies, such as
            declining all cookies or prompting you to decide whether to accept
            each cookie.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            There are two main kinds of cookies: session cookies and persistent
            cookies. Session cookies are deleted from your computer when you
            close your browser, whereas persistent cookies remain stored on your
            computer until deleted, or until they reach their expiry date.
          </span>
        </p>
        <p>
          <strong>THIRD PARTY COOKIES</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            We may use Google Analytics to analsye the use of the website, and
            so Google may set cookies on your device. The information generated
            relating to our website is used to create reports about the use of
            the website. Google will store and use this information. Google's
            privacy policy is available at:
          </span>
          <a href="http://www.google.com/privacypolicy.html">
            <span style={{ fontWeight: 400 }}>
              http://www.google.com/privacypolicy.html
            </span>
          </a>
          <span style={{ fontWeight: 400 }}>
            ]. We can change this Cookie Policy at any time. Other third parties
            (such as Hotjar and Intercom) which may set cookies on your device
            are outlined in the Privacy Statement.
          </span>
        </p>
      </Segment>
    </div>
  );
};
export default Privacy;
