import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import {
  fetchHomeRoomList,
  fetchHomeRoomListByCity,
} from "../../actions/index";

import RoomListView from "./room_list_view";
import RoomListViewMobile from "./room_list_view_mobile";

class RoomList extends Component {
  static propTypes = {
    homeRoomList: PropTypes.array.isRequired,
    fetchHomeRoomList: PropTypes.func.isRequired,
    fetchHomeRoomListByCity: PropTypes.func.isRequired,
    city: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      roomList: this.props.homeRoomList,
      windowWidth: window.innerWidth,
    };
  }
  componentDidMount() {
    this.fetchRooms();
  }
  componentDidUpdate(prevProps) {
    // if city changed
    if (this.props.city && prevProps.city) {
      if (this.props.city.id !== prevProps.city.id) {
        this.fetchRooms();
      }
      //if city was null or became null then fetch
    } else if (!!this.props.city !== !!prevProps.city) {
      this.fetchRooms();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.homeRoomList, nextProps.homeRoomList)) {
      this.setState({ roomList: nextProps.homeRoomList });
    }
  }
  fetchRooms = () => {
    if (this.props.city) {
      this.props.fetchHomeRoomListByCity(this.props.city);
    } else {
      this.props.fetchHomeRoomList();
    }
  };
  render() {
    if (this.state.windowWidth > 768) {
      return <RoomListView rooms={this.state.roomList} />;
    }
    return <RoomListViewMobile rooms={this.state.roomList} />;
  }
}

const mapStateToProps = ({ homeRoomList }) => ({
  homeRoomList,
});

const mapDispatchToProps = { fetchHomeRoomList, fetchHomeRoomListByCity };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RoomList)
);
