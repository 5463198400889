import React from "react";
import { Button, Divider } from "semantic-ui-react";
import PropTypes from "prop-types";
import FieldError from "./FieldError";
import {
  relationshipOptions,
  statusOptions,
  genderOptions,
} from "../../../assets/formOptions";

export default function Preferences({
  setListingRoomValue,
  room,
  fieldsWithError,
  age_minRef,
  // eslint-disable-next-line react/prop-types
  preferencesRef,
}) {
  return (
    <div className="roomigo-container">
      <div className="mx-20">
        <h2
          style={{ marginTop: "15px", color: "#707070" }}
          ref={preferencesRef}
        >
          Preferences
        </h2>
        <Divider
          style={{
            borderWidth: "3px",
            borderRadius: "15px",
            borderColor: "#FF664D",
          }}
        />
        <span className="title-16 light preference-description">
          Potential Roommate(s) should be:
        </span>
      </div>

      <div className="desktop-row">
        <div className="flex-1 room-list-flex-property min-w-45">
          <span className="title-16 light">Living As</span>

          <Button.Group widths={relationshipOptions.length}>
            {relationshipOptions.map(relationship => (
              <Button
                key={relationship.value}
                className="roomigo-btn"
                active={room.preferred_relationship === relationship.value}
                value={relationship.value}
                onClick={() =>
                  setListingRoomValue(
                    "preferred_relationship",
                    relationship.value
                  )
                }
              >
                {relationship.text}
              </Button>
            ))}
          </Button.Group>
        </div>
        <div className="flex-1 room-list-flex-property min-w-45">
          <span className="title-16 light">Status</span>
          <Button.Group widths={statusOptions.length}>
            {statusOptions.map(status => (
              <Button
                key={status.value}
                className="roomigo-btn"
                active={room.preferred_occupation === status.value}
                value={status.value}
                onClick={() =>
                  setListingRoomValue("preferred_occupation", status.value)
                }
              >
                {status.text}
              </Button>
            ))}
          </Button.Group>
        </div>
        <div className="flex-1 room-list-flex-property min-w-45">
          <span className="title-16 light">Gender</span>
          <Button.Group widths={genderOptions.length}>
            {genderOptions.map(status => (
              <Button
                key={status.value}
                className="roomigo-btn"
                active={room.preferred_gender === status.value}
                value={status.value}
                onClick={() =>
                  setListingRoomValue("preferred_gender", status.value)
                }
              >
                {status.text}
              </Button>
            ))}
          </Button.Group>
        </div>
        <div className="flex-1 room-list-flex-property min-w-45">
          <span className="title-16 light" ref={age_minRef}>
            Aged
          </span>

          <div className="flex-row p-relative">
            <span className="title-16 light my-auto">From</span>
            <input
              className="room-listing-date-input my-auto"
              type="number"
              value={room.age_min}
              placeholder="Min"
              onChange={event =>
                setListingRoomValue("age_min", parseInt(event.target.value, 10))
              }
            />

            <span className="title-16 light my-auto">To</span>
            <input
              className="room-listing-date-input my-auto"
              value={room.age_max}
              placeholder="Max"
              type="number"
              onChange={event =>
                setListingRoomValue("age_max", parseInt(event.target.value, 10))
              }
            />
          </div>
          {fieldsWithError.age_min && (
            <FieldError message={fieldsWithError.age_min} />
          )}
        </div>
      </div>
    </div>
  );
}

Preferences.propTypes = {
  age_minRef: PropTypes.object.isRequired,
  setListingRoomValue: PropTypes.func.isRequired,
  room: PropTypes.object.isRequired,
  fieldsWithError: PropTypes.object.isRequired,
};
