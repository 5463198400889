/* global localStorage window */
import React, { Component } from "react";
import { Divider, Icon, Image, Menu, Label, Sidebar } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import { getPreviousPage, emptyMyProfile } from "../actions";
import cookie from "react-cookies";
// import Footer from "./footer.js";
import CookieWarning from "./cookie_warning";
// import HeroImage from "../assets/images/Roomigo_Header_Image.jpg";
import { renderFavouritePicture } from "../utils/helpers";
import SidebarOverlay from "./sidebar_overlay";
const logo = require("../assets/images/roomigo.svg");
const windowWidth = window.innerWidth;

class MobileAppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
    this.linkToPreviousPage = this.linkToPreviousPage.bind(this);
  }

  closeSidebar = event => {
    if (event && typeof event.stopPropagation === "function") {
      event.stopPropagation();
    }
    if (this.state.visible) {
      this.setState({ visible: false });
    }
  };

  openSidebar = event => {
    if (event && typeof event.stopPropagation === "function") {
      event.stopPropagation();
    }
    if (!this.state.visible) {
      this.setState({ visible: true });
    }
  };

  logout() {
    window.postMessage({ event: "logout" }, "*");
    cookie.remove("jwt");
    localStorage.removeItem("user");
    this.props.history.push("/login");
    window.location.reload();
    this.props.emptyMyProfile();
    this.setState({ visible: false });
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.location, nextProps.location)) {
      this.setState({ visible: false });
    }
  }

  linkToPreviousPage() {
    this.props.getPreviousPage(this.props.location.pathname);
  }

  render() {
    const { visible } = this.state;
    return (
      <>
        <SidebarOverlay visible={visible} onClick={this.closeSidebar} />
        <Sidebar
          as={Menu}
          animation="overlay"
          visible={visible}
          vertical
          onClick={this.closeSidebar}
          style={{ width: "70%" }}
        >
          <Divider className="grey-text" horizontal>
            My Account
          </Divider>
          {this.props.myProfile.data.id ? (
            <Link to="/profile">
              <Menu.Item className="sidebar-button" link>
                <h4 className="button-text">
                  {this.props.myProfile.data.pictures[0] ? (
                    <Image
                      className="mobile-sidebar-profile-avatar"
                      src={renderFavouritePicture(
                        this.props.myProfile.data.pictures
                      )}
                      avatar
                    />
                  ) : (
                    <Icon
                      className="button-icon"
                      color="grey"
                      size="large"
                      name="user circle outline"
                    />
                  )}
                  Edit Profile
                </h4>
              </Menu.Item>
            </Link>
          ) : (
            <Link to="/login">
              <Menu.Item className="sidebar-button" link>
                <h4 onClick={this.linkToPreviousPage} className="button-text">
                  <Icon
                    className="button-icon"
                    color="grey"
                    size="large"
                    name="user"
                  />
                  Login
                </h4>
              </Menu.Item>
            </Link>
          )}

          {this.props.myProfile.data.id && (
            <Link to="/requests">
              <Menu.Item className="sidebar-button" link>
                <h4 className="button-text">
                  <Icon
                    className="button-icon"
                    color="grey"
                    size="large"
                    name="comments"
                  />
                  Requests & Chats
                  {this.props.profileMessageList.length +
                    this.props.receivedRequests.length >
                    0 && (
                    <Label
                      circular
                      size="tiny"
                      style={{
                        backgroundColor: "#ff5050",
                        color: "#fff",
                        left: "8px",
                        top: "4px",
                        position: "absolute",
                        zIndex: 1,
                      }}
                    >
                      {this.props.profileMessageList.length +
                        this.props.receivedRequests.length}
                    </Label>
                  )}
                </h4>
              </Menu.Item>
            </Link>
          )}

          {this.props.myProfile.data.id && (
            <Link to="/my_rooms">
              <Menu.Item className="sidebar-button" link>
                <h4 className="button-text">
                  <Icon
                    className="button-icon"
                    color="grey"
                    size="large"
                    name="list"
                  />
                  My Listings
                </h4>
              </Menu.Item>
            </Link>
          )}

          <Divider className="grey-text" horizontal>
            Search
          </Divider>

          <Link to="/rooms/new">
            <Menu.Item className="sidebar-button" link>
              <h4 className="button-text">
                <Icon
                  className="button-icon"
                  color="grey"
                  size="large"
                  name="home"
                />
                List a Room
              </h4>
            </Menu.Item>
          </Link>

          <Link to="/search_profile">
            <Menu.Item className="sidebar-button" link>
              <h4 className="button-text">
                <Icon
                  className="button-icon"
                  color="grey"
                  size="large"
                  name="group"
                />
                Find a Roommate
              </h4>
            </Menu.Item>
          </Link>

          <Link to="/search_room">
            <Menu.Item className="sidebar-button" link>
              <h4 className="button-text">
                <Icon
                  className="button-icon"
                  color="grey"
                  size="large"
                  name="search"
                />
                Find a Room
              </h4>
            </Menu.Item>
          </Link>

          <Divider className="grey-text" horizontal>
            More
          </Divider>

          <Menu.Item
            className="sidebar-button"
            link
            onClick={() => {
              var url = "https://roomigo.io/";
              if (navigator.share) {
                navigator.share({
                  title:
                    "Check out RooMigo, a platform for finding like-minded housemates!",
                  text:
                    "RooMigo is a way to find like-minded housemates you share interests with.",
                  url: url,
                });
              } else {
                window
                  .open(
                    "https://www.facebook.com/sharer/sharer.php?u=https://roomigo.io/",
                    "_blank"
                  )
                  .focus();
              }
            }}
          >
            <h4 className="button-text">
              <Icon
                className="button-icon"
                color="grey"
                size="large"
                name="share alternate"
              />
              Invite a Friend
            </h4>
          </Menu.Item>

          {/* {this.props.myProfile.data.city &&
          this.props.myProfile.data.city.name === "Dublin" ? (
            <a
              href="https://www.eventbrite.com/e/*****"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Menu.Item className="sidebar-button" link>
                <h4 className="button-text">
                  <Icon
                    className="button-icon"
                    color="grey"
                    size="large"
                    name="calendar alternate outline"
                  />
                  RooMigo Events
                </h4>
              </Menu.Item>
            </a>
          ) : (
            ""
          )} */}

          {this.props.myProfile.data.id && (
            <Menu.Item
              className="sidebar-button"
              link
              onClick={() => this.logout()}
            >
              <h4 className="button-text">
                <Icon
                  className="button-icon"
                  color="grey"
                  size="large"
                  name="sign out"
                />
                Logout
              </h4>
            </Menu.Item>
          )}
        </Sidebar>
        <Menu
          borderless
          fixed="top"
          width="very wide"
          style={{
            zIndex: 11,
            position: "fixed",
            height: "60px",
            backgroundColor: "#fff",
          }}
        >
          <Menu.Item position="left">
            {_.size(this.props.profileMessageList) +
              _.size(this.props.receivedRequests) ===
            0 ? (
              <Icon
                name="content"
                size={windowWidth < 375 ? "big" : "large"}
                onClick={this.openSidebar}
              />
            ) : (
              <div>
                <Icon
                  name="content"
                  size={windowWidth < 375 ? "big" : "large"}
                  onClick={this.openSidebar}
                />
                <Label
                  size="tiny"
                  circular
                  style={{
                    backgroundColor: "#ff5050",
                    color: "#fff",
                    left: "8px",
                    top: "9px",
                    zIndex: 1,
                    position: "absolute",
                  }}
                  onClick={this.openSidebar}
                >
                  {_.size(this.props.profileMessageList) +
                    _.size(this.props.receivedRequests)}
                </Label>
              </div>
            )}
            <Link to="/">
              <Image
                src={logo}
                size="small"
                style={{ marginLeft: windowWidth < 340 && -15 }}
              />
            </Link>
          </Menu.Item>
          {!this.props.myProfile.data.id &&
            this.props.location.pathname !== "/login" && (
              <Menu.Item>
                <Link to="/login">
                  <Label
                    style={{
                      backgroundColor: "#00D5CC",
                      color: "#FFFFFF",
                      fontSize: "15px",
                    }}
                  >
                    <b>LOGIN</b>
                  </Label>
                </Link>
              </Menu.Item>
            )}
          {this.props.myProfile.data.id &&
            this.props.location.pathname !== "/rooms/new" && (
              <Menu.Item>
                <Link to="/requests">
                  <Label className="mobile-navbar-button">
                    {this.props.myProfile.data.id && <Icon name="comments" />}
                    <b>CHATS</b>
                  </Label>
                </Link>
              </Menu.Item>
            )}
        </Menu>
        <CookieWarning />
      </>
    );
  }
}

const mapStateToProps = ({ previousPage }) => ({ previousPage });

const actions = {
  getPreviousPage,
  emptyMyProfile,
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(MobileAppLayout)
);
