import { axiosUploadInstance, axiosInstance } from "./configured_axios";
import {
  UPLOAD_PICTURE,
  DELETE_ROOM_PICTURE,
  DELETE_PROFILE_PICTURE,
  IS_SEARCHING,
  FAVOURITE_ROOM_PICTURE,
  FAVOURITE_PROFILE_PICTURE,
} from "./action_types";
import { fetchLoggedInUserProfile } from "./session_actions";
import { fetchRoomDetail } from "./room_actions";

export function uploadPicture(file, size) {
  const data = new FormData();
  data.append("file", file);
  data.append("size", size);

  return dispatch => {
    dispatch({ type: IS_SEARCHING, payload: true });
    axiosUploadInstance.post("/api/upload", data).then(response => {
      dispatch({ type: UPLOAD_PICTURE, payload: response });
      dispatch({ type: IS_SEARCHING, payload: false });
    });
  };
}

export function deleteProfilePicture(picture_url) {
  return dispatch => {
    axiosInstance
      .post(`/api/delete_profile_picture`, { picture_url })
      .then(response => {
        dispatch({ type: DELETE_PROFILE_PICTURE, payload: response });
        dispatch(fetchLoggedInUserProfile());
      });
  };
}

export function deleteRoomPicture(picture_url, roomId) {
  return dispatch => {
    axiosInstance
      .post(`/api/delete_room_picture`, { picture_url })
      .then(response => {
        dispatch({ type: DELETE_ROOM_PICTURE, payload: response });
        dispatch(fetchRoomDetail(roomId));
      });
  };
}

export function favouriteRoomPicture(picture_url, room_id) {
  return dispatch => {
    axiosInstance
      .post(`/api/favourite_room_picture`, { picture_url, room_id })
      .then(response => {
        dispatch({ type: FAVOURITE_ROOM_PICTURE, payload: response });
        dispatch(fetchLoggedInUserProfile());
      });
  };
}

export function favouriteProfilePicture(picture_url) {
  return dispatch => {
    axiosInstance
      .put(`/api/favourite_profile_picture`, { picture_url })
      .then(response => {
        dispatch({ type: FAVOURITE_PROFILE_PICTURE, payload: response });
        dispatch(fetchLoggedInUserProfile());
      });
  };
}
