import moment from 'moment';
import _ from 'lodash';
import { IMAGES_S3_BUCKET_URL } from '../actions/root_url';

export function parseProfileError(errors) {
  const errorList = {};
  if (errors) {
    if (errors.name) {
      errorList.msg_error_name = ' Name';
    }
    if (errors.date_of_birth) {
      errorList.msg_error_date_of_birth = ' Date of Birth';
    }
    if (errors.occupation) {
      errorList.msg_error_occupation = ' Status';
    }
    if (errors.gender) {
      errorList.msg_error_gender = ' Gender';
    }
    if (errors.bio) {
      errorList.msg_error_name = ' Bio';
    }
    if (errors.relationship_status) {
      errorList.msg_error_roommate_relationship_status = ' Living as';
    }

    return `Oops, some fields are missing: ${Object.values(errorList)}.`;
  }
}

export function parseError(errors, type) {
  const errorList = {};
  if (errors.area_id) {
    errorList.msg_error_area = ' Area';
  }
  if (errors.area_code_id) {
    errorList.msg_error_area_code = ' Area Code';
  }
  if (errors.bills_included) {
    errorList.msg_error_bills = ' Bills';
  }

  if (errors.description) {
    errorList.msg_error_description = ' Description*';
  }

  if (errors.room_pictures) {
    errorList.msg_error_room_picture = ' Room Picture';
  }

  if (errors.move_in_date) {
    errorList.msg_error_date = ' Date';
  }

  if (errors.price) {
    errorList.msg_error_price = ' Price';
  }

  if (errors.roommate_female_number) {
    errorList.msg_error_roommate_female_number = ' number of roommate Female';
  }

  if (errors.roommate_male_number) {
    errorList.msg_error_roommate_male_numer = ' number of roommate Male';
  }

  if (errors.relationship_status) {
    errorList.msg_error_roommate_relationship_status = ' Living as';
  }
  return `Oops, it looks like we're missing some important info. Please fill in the following; ${Object.values(
    errorList
  )}.`;
}

export function dateToMoment(date) {
  let newDate = date;
  if (date && !moment.isMoment(date)) {
    newDate = moment(date);
  }
  return newDate;
}

export function momentToDate(momentDate) {
  let newDate = '';
  if (momentDate) {
    newDate = moment(momentDate).format('DD/MM/YYYY');
  }
  return newDate;
}

const filterList = (searchTerm, searchField, items) => {
  const lowerSearchTerm = searchTerm.toLowerCase();
  const filteredInterests = _.filter(items, item => {
    return item[searchField].toLowerCase().search(lowerSearchTerm) !== -1;
  });
  return filteredInterests;
};
export default filterList;

export function getJoinedFromDate(joinDate) {
  let joinedDate = '-';
  if (joinDate) {
    joinedDate = moment(joinDate).fromNow();
  }
  return joinedDate;
}

export function renderOccupation(occupation) {
  switch (occupation) {
    case 'work':
      return 'Professional';
    case 'study':
      return 'Student';
    case 'both':
      return 'Student and professional';
    default:
      return '-';
  }
}

export function getChatId(profileId, friendProfileId) {
  let chatId = '';
  if (profileId > friendProfileId) {
    chatId = `chat:${friendProfileId}-${profileId}`;
  } else {
    chatId = `chat:${profileId}-${friendProfileId}`;
  }
  return chatId;
}

export function areaToOptionList(areas, labelField, valueField) {
  const values = areas.map(element => ({
    id: element[valueField],
    text: element[labelField],
    value: element[valueField],
  }));
  return [...values];
}

export function renderPictures(pictures) {
  let filePictures = [];
  pictures.map(picture =>
    filePictures.push({
      original: `${IMAGES_S3_BUCKET_URL}/${picture.url}`,
      favourite: picture.favourite,
    })
  );
  return _.orderBy(filePictures, 'favourite', 'desc');
}

export function renderFavouritePicture(pictures) {
  let picture;
  const favouritePicture = _.find(pictures, { favourite: true });
  if (favouritePicture) {
    picture = favouritePicture;
  } else {
    picture = pictures[0];
  }
  return `${IMAGES_S3_BUCKET_URL}/${picture.url}`;
}

export function formatAreaName(area, areaCode) {
  if (area && areaCode) {
    return `${area.name}, ${areaCode.code}`;
  } else if (area && !areaCode) {
    return area.name;
  } else if (!area && areaCode) {
    return areaCode.code;
  } else {
    return '';
  }
}

export function formatMoney(money) {
  return `€${money}`;
}
