import React from "react";
import { Grid, TextArea, Segment } from "semantic-ui-react";
import "../fonts/extrafonts.css";

const Bio = ({ profile, handleFieldChange }) => {
  return (
    <Grid style={{ marginTop: 0 }}>
      <Segment
        compact
        style={{
          color: "#FF664D",
          fontSize: "1.2em",
          fontStyle: "italic",
          borderRadius: "8px",
        }}
      >
        To use RooMigo you’ll need to create a <b>profile</b>. Please{" "}
        <b>fill in all the fields </b> and press <b>'Save Profile' </b> to start
        connecting with people on RooMigo.
      </Segment>

      <font color="#707070" size="16px" style={{ fontFamily: "MYRIAD" }}>
        <b>
          BIO&nbsp;
          <font color="#FF664D" size="26px" style={{ fontSize: 20 }}>
            *
          </font>
        </b>
      </font>
      <Grid.Row>
        <Grid.Column width={16} textAlign="left">
          <TextArea
            id="bio"
            style={{ minHeight: 150, minWidth: 250, fontSize: 16 }}
            placeholder="Please use this space to tell people about you and what you are looking for."
            value={profile.bio || ""}
            onChange={event => handleFieldChange("bio", event.target.value)}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Bio;
