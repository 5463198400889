import cookie from 'react-cookies';

import { ACCEPT_COOKIE } from '../actions/action_types';

const uid = cookie.load('uid');
const initalState = uid ? { uid } : {};

export default function cookieReducer(state=initalState, action) {
  switch (action.type) {
    case ACCEPT_COOKIE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
