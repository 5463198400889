import React from "react";

import { Segment } from "semantic-ui-react";
const TermsAndConditions = () => {
  return (
    <div className="site-container">
      <Segment
        style={{
          padding: 35,
          marginTop: "2rem",
        }}
      >
        <h2>
          <strong>RooMigo Terms</strong>
        </h2>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo is the trading name of the Irish company Home Vue Media
            Limited. You may contact us at this email address: info@roomigo.io
            or by letter to Home Vue Media Limited, Crane Street, Ushers, Dublin
            8, Ireland.
          </span>
        </p>
        <p>
          <strong>General Conditions of Use</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            These Terms apply to the entire contents of the website at the
            domain
          </span>
          <a href="http://www.romigo.io/">
            {" "}
            <span style={{ fontWeight: 400 }}>www.roomigo.io</span>
          </a>
          <span style={{ fontWeight: 400 }}>
            {" "}
            including the blog and the use of any of our social media
            communities on Facebook or otherwise including any other communities
            we run on other platforms. We collectively refer to the former as
            the &ldquo;Web App&rdquo; or &ldquo;Website&rdquo;.{" "}
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            Please read this document carefully. Using the Website means you
            expressly agree with these Terms, even if you don&rsquo;t register
            on the Website. For example, you can access parts of the Website
            without registering and creating a profile, if you do so you
            expressly agree to these Terms.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            If you in any way use the roomigo.io (website / platform) you will
            be giving your express consent to the Terms of use in this document.
            If you don&rsquo;t agree please do not use the Web App. &nbsp;
          </span>
        </p>
        <p>
          <strong>1 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>
            RooMigo is changing the way people and property connect
          </strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo is a peer to peer platform, therefore Estate Agent or
            Property Management Companies may not advertise rooms on it.
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            This is a platform for people looking for a new roommate, looking to
            team-up with others, looking to rent out spare rooms and for people
            looking to find a place to live.{" "}
          </span>
        </p>

        <p>
          <strong>No short term lettings (under 30 days)</strong>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            If you want to rent out a spare room, the room must be available for
            a period of{" "}
          </span>
          <strong>30 days or more. </strong>
          <span style={{ fontWeight: 400 }}>
            We are not a short-term lettings site, and such listings (e.g., for
            under 30 days) are prohibited.{" "}
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            If you break the law in any respect, you are wholly responsible, and
            we are not in any way responsible for any damage you cause or any
            laws or regulations you have infringed, and you expressly undertake
            to indemnify us against any losses or damages caused.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            You may not post illegal room listings or room listings in breach of
            your private contractual agreements with third parties. We are in no
            way responsible for any infringements of the law or any regulations
            or any contract. &nbsp;
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>If you post </span>
          <strong>unethical room listings</strong>
          <span style={{ fontWeight: 400 }}>
            {" "}
            (albeit technically legal room listings) we reserve the right to
            immediately remove and ban you and your listing. We don&rsquo;t
            attempt to define what an unethical listing might be, but an example
            might be renting out a bed in a room with 3 other beds in the room
            for an extortionate rent. &nbsp;We don&rsquo;t want you on our
            platform, so kindly don&rsquo;t attempt to join!
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>We encourage all members to </span>
          <strong>report suspicious behaviour</strong>
          <span style={{ fontWeight: 400 }}> to </span>
          <a href="mailto:info@roomigo.io">
            <span style={{ fontWeight: 400 }}>info@roomigo.io</span>
          </a>
          <span style={{ fontWeight: 400 }}>
            {" "}
            or via the chat function on the RooMigo Web App (bottom right hand
            corner of the screen).{" "}
          </span>
        </p>
        <p>
          <strong>WARNING</strong>{" "}
          <span style={{ fontWeight: 400 }}>
            if a member contacts you with a &nbsp;link to a third party website
            encouraging you to pay for an alleged room rental{" "}
          </span>
          <strong>DO NOT ENGAGE WITH THIS MEMBER</strong>{" "}
          <span style={{ fontWeight: 400 }}>
            and immediately report that person to our team on our chat. You may
            also email us at info@roomigo.io{" "}
          </span>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            If members contact you with links to any other websites or services
            outside the RooMigo platform please contact us and let us know.{" "}
          </span>
        </p>

        <p>
          <strong>2 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Licence</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Unless otherwise stated, the copyright and other intellectual
            property rights in all material on the Web App (including without
            limitation photographs and graphical images) are owned by RooMigo or
            its licensor. Any rights not expressly granted to you in these terms
            are reserved by the Company.
          </span>
        </p>

        <p>
          <strong>3 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Your Use of the Website</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            You may not attempt to gain unauthorised access to any portion or
            feature of the Web App or to any other systems or networks connected
            to the Website or to any RooMigo server, or to any of the products
            or services offered on or through the Web App, by hacking, password
            &ldquo;mining&rdquo; or any other illegitimate means.
          </span>
        </p>

        <p>
          <span style={{ fontWeight: 400 }}>
            You may not use the Web App or any material contain therein for any
            purpose that is unlawful or prohibited by these Terms, or to solicit
            the performance of any illegal activity or other activity which
            infringes the rights of RooMigo or others.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            You agree not to modify the software underlying the Web App in any
            manner or form or to use modified versions of such software,
            including (without limitation) for the purpose of obtaining
            unauthorised access to the Website.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Where we request registration information from you, you will provide
            us with true, accurate, current, and complete information.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Use of the Web App is subject to existing laws and legal process.
            Nothing contained in these Terms shall limit our right to comply
            with governmental, court, and law-enforcement requests or
            requirements relating to your use of the Website.
          </span>
        </p>
        <p>
          <strong>4 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Data Protection</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo shall comply with all of its obligations as a data
            controller in relation to your personal information in accordance
            with Irish and EU laws on data protection.
          </span>
        </p>

        <p>
          <strong>5 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Privacy Statement</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Use of the Web App and all of the information that we collect from
            you, including personal and non personal information is subject to
            our Privacy Statement.
          </span>
        </p>

        <p>
          <strong>6 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Service access</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            While RooMigo endeavours to ensure that the Wep App is normally
            available 24 hours a day, the Company shall not be liable if for any
            reason the Website is unavailable at any time or for any period.
            RooMigo may withdraw a member&rsquo;s access to the Web App at any
            time without reason.{" "}
          </span>
        </p>
        <p>
          <strong>7 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Visitor material and conduct</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Other than personally identifiable information (which is covered
            under our Privacy Statement) any material you transmit or post to
            the Website shall be considered non-confidential and
            non-proprietary. RooMigo shall have no obligations with respect to
            such material. RooMigo and its designees shall be free to copy,
            disclose, distribute, incorporate and otherwise use such material
            and all data, images, sounds, text and other things embodied therein
            for any and all commercial or non-commercial purposes.
          </span>
        </p>

        <p>
          <strong>
            You are prohibited from posting or transmitting to or from the Web
            App including through the use of the messaging system any material:
          </strong>
        </p>
        <ol>
          <li>
            <span style={{ fontWeight: 400 }}>
              that is threatening, defamatory, obscene, indecent, seditious,
              offensive, pornographic, abusive, liable to incite racial hatred,
              discriminatory, menacing, scandalous, inflammatory, blasphemous,
              in breach of confidence, in breach of privacy or which may cause
              annoyance or inconvenience; or
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}>
              for which you have not obtained all necessary licences and/or
              approvals; or
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}>
              which constitutes or encourages conduct that would be considered a
              criminal offence, give rise to civil liability, or otherwise be
              contrary to the law of or infringe the rights of any third party,
              in any country in the world; or
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}>
              which is technically harmful (including, without limitation,
              computer viruses, logic bombs, Trojan horses, worms, harmful
              components, corrupted data or other malicious software or harmful
              data).
            </span>
          </li>
        </ol>
        <p>
          <strong>You fully agree to the following:</strong>
        </p>
        <ol>
          <li>
            <span style={{ fontWeight: 400 }}> &nbsp;</span>{" "}
            <span style={{ fontWeight: 400 }}>
              Not to use the Web App for illegal or unauthorized purposes;
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}> &nbsp;</span>{" "}
            <span style={{ fontWeight: 400 }}>
              Not to host, store, divulge, publish, distribute or share any
              content that is an interference or infringement of someone
              else&rsquo;s personal data.
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}> &nbsp;&nbsp;</span>{" "}
            <span style={{ fontWeight: 400 }}>
              Not to post on the Web App or profile or that of another Member
              any comment or information that is illegal, racist, xenophobic,
              obscene, pornographic, abusive, defamatory, misleading, fraudulent
              or otherwise contrary to public morality or order;
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}> &nbsp;</span>{" "}
            <span style={{ fontWeight: 400 }}>
              Not to alter or modify, totally or partially, the Web App,
              bypassing, deactivating or manipulating any other functions or
              services thereof;
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}> &nbsp;</span>{" "}
            <span style={{ fontWeight: 400 }}>
              Not to infringe the intellectual property rights or the norms
              regulating the protection of personal data;
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}>
              {" "}
              &nbsp;&nbsp;Not to use the Web App to insult, defame, intimidate,
              violate the image itself or harass other Members;
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}> &nbsp;&nbsp;</span>{" "}
            <span style={{ fontWeight: 400 }}>
              Not to access the email accounts of other Members;
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}> &nbsp;</span>{" "}
            <span style={{ fontWeight: 400 }}>
              Not to introduce computer viruses, defective files, or any other
              software that may cause damage or alterations in the contents or
              systems of RooMigo or third parties;
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}>
              {" "}
              &nbsp;&nbsp;&nbsp;Not to send emails with a repetitive nature to a
              plurality of people, nor send email addresses of third parties
              without their consent;
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}>
              {" "}
              &nbsp;&nbsp;&nbsp;&nbsp;Not to impair or damage the reputation of
              RooMigo;
            </span>
          </li>
          <li>
            <span style={{ fontWeight: 400 }}> &nbsp;&nbsp;</span>{" "}
            <span style={{ fontWeight: 400 }}>
              Not to distribute, make available or otherwise communicate
              publicly, transform or modify any protected content or benefit
              without the prior authorization of the legitimate right holders
            </span>
          </li>
        </ol>
        <p>
          &nbsp;
          <span style={{ fontWeight: 400 }}>
            RooMigo shall fully co-operate with any law enforcement authorities
            or court order requesting or directing the Company to disclose the
            identity or locate anyone posting any material or otherwise misusing
            the Web App.
          </span>
        </p>
        <p>
          <strong>8 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Member Obligations</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Members are fully responsible for the access to and correct use of
            their profile, their listing and of the Web App.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo will not be held responsible for the publishing of any
            content or the messages sent on the Web App by any member who assume
            all the consequences for any content or actions they take.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Those who fail to comply with such obligations will be liable for
            any damages or harm they cause. RooMigo shall not be held liable for
            any consequences, damages or losses that may arise from such access
            or use by third parties and the Member indemnifies RooMigo against
            any losses or damages so caused.
          </span>
        </p>
        <p>
          <strong>Members Represent and Warrant that:</strong>
        </p>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              You are using your real name on the Web App.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              You are using the Web App in your personal capacity and not on
              behalf of a third party such as a competitor of RooMigo, other
              property websites, Estate Agencies or otherwise.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              You will not use it to advertise services that are not permitted
              by RooMigo. Users of the Web App may post Room Rental Ads and
              create listings stating they are looking for rooms, roommates or
              looking to team-up.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              You will not send people messages with links to external websites
              or inappropriate content or otherwise.{" "}
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              You will abide by all the other Terms set out herein.
            </span>
          </li>
        </ol>
        <p>
          <strong>9 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Access for Members</strong>
        </p>
        <p />
        <p>
          <span style={{ fontWeight: 400 }}>
            In order for people to use the Web App you must:
          </span>
        </p>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Accept these RooMigo Terms.</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Accept our </span>
            <strong>Privacy Policy</strong>
            <span style={{ fontWeight: 400 }}>
              {" "}
              which can be found on RooMigo.io
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Accept the </span>
            <strong>Cookies Policy</strong>
            <span style={{ fontWeight: 400 }}>
              , which can be found at the bottom of the Privacy Policy.
            </span>
          </li>
        </ol>
        <p>
          <strong>10 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Identify of Members</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo cannot guarantee the identity of registered Members, and if
            a member creates a fake profile we are in no way responsible for
            this behaviour or veracity of any profile. We do not accept any
            responsibility for any fake profiles or the activities of any of the
            Member&rsquo;s on RooMigo, or the activities or behaviour of any
            Member you meet on RooMigo. You are joining the RooMigo platform at
            your own risk. &nbsp;
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            If a third party gains access to your account, via hacking or
            otherwise, we are in no way responsible for their behaviour or for
            their access to any personal data.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            We encourage you to keep your password safe and secure. If you
            believe your account has been compromised, immediately contact us at
            info@roomigo.io &nbsp;&nbsp;
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            You agree not to allow any third parties to use your account. You
            shall be solely responsible in the event of use of such data by
            third parties.
          </span>
        </p>
        <p>
          <strong>11 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Scams</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo does not accept any responsibility if a Member is scammed by
            another Member using a fake profile or otherwise. &nbsp;Below are
            some tips to avoid scams, but this is not an exhaustive list and we
            bear no responsibility if you are scammed.
          </span>
        </p>
        <p>
          <strong>
            Tips to avoid scams (these are not exhaustive and we accept no
            responsibility for any scams):
          </strong>
        </p>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Any Members who suggest they cannot show you the property because
              they are out of the country and therefore want you to transfer
              them money before viewing the property, is a{" "}
            </span>
            <strong>major red flag. </strong>
            <span style={{ fontWeight: 400 }}>&nbsp;&nbsp;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              If Members try to contact you off the platform via a private email
              address, sometimes, this is a red flag. We suggest you communicate
              with Members only over our messaging system.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>If the </span>
            <strong>price of the property is unrealistic</strong>
            <span style={{ fontWeight: 400 }}>
              . &nbsp;For example, the rent seems very low. As a rule of thumb,
              if it&rsquo;s too good to be true, it probably isn&rsquo;t true.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Attempts to get you to transfer money via Western Union or via
              links to fake rental sites. Any attempts to get you to transfer
              money before you physically see the property is a{" "}
            </span>
            <strong>major red flag.</strong>
          </li>
        </ol>
        <p>
          <strong>
            RooMigo members are encouraged to report any suspicious profiles or
            listings to our team by clicking on the chat icon in the bottom
            right-hand corner of the screen. &nbsp;You may also email us at
            info@roomigo.io
          </strong>
        </p>
        <p>
          <strong>12 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Your Profile</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            In order to complete registration on the Web App, you must provide
            some information such as name, surname, email address, telephone
            number (if asked), lifestyle choices and some other information.
            Once you complete the registration, you will be able to access your
            profile and complete it and/or edit it.
          </span>
        </p>
        <p>
          <strong>13 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Geolocation</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo may collect, use and share accurate location data, including
            the real-time geographical location of the Member&rsquo;s computer
            or mobile device. This location data is collected and used by
            RooMigo to show Members the location of the lease offers.
          </span>
        </p>
        <p>
          <strong>14 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Rental Prices</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Members freely determine rental prices. RooMigo does not, in any
            way, determine the prices of leases, nor does it offer
            recommendations in any respect.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo does not make any recommendations to Members regarding the
            offer of leases, including but not limited to the promotion of
            tourist rentals, and Members are solely responsible for compliance
            with local, regional, state or national laws that are applicable in
            each case.
          </span>
        </p>
        <p>
          <strong>We are not a property agency</strong>{" "}
          <span style={{ fontWeight: 400 }}>
            and we do not provide regulated property agent services. All
            property agents in Ireland must be regulated and licensed by the
            appropriate authority.{" "}
          </span>
          <span style={{ fontWeight: 400 }}>&nbsp;</span>
        </p>
        <p>
          <strong>15 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Report Suspected Breaches of these Terms</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            Any Member may report another Member when he believes that he is in
            breach of these Terms, and all Members may inform RooMigo of any
            abuse or violation of these Terms, through the email
            info@roomigo.io.{" "}
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo reserves the right to withdraw and/or suspend any Member of
            the Wep App for breach of these Terms. RooMigo also reserves the
            right to withdraw and/or suspend any message with illegal or
            offensive content, without the need for prior notice or subsequent
            notification.
          </span>
        </p>
        <p>
          <strong>16 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Liability</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo, any other party (whether or not involved in creating,
            producing, maintaining or delivering the Website), and any of the
            RooMigo&rsquo;s group companies and the officers, directors,
            employees, shareholders or agents of any of them, exclude all
            liability and responsibility for any amount or kind of loss or
            damage that may result to you or a third party (including without
            limitation, any direct, indirect, punitive or consequential loss or
            damages, or any loss of income, profits, goodwill, data, contracts,
            use of money, or loss or damages arising from or connected in any
            way to business interruption, and whether in tort (including without
            limitation negligence), contract or otherwise) in connection with
            the Web App in any way or in connection with the use, inability to
            use or the results of use of the Web App and services, any websites
            linked to the Web App or the material on such websites, including
            but not limited to loss or damage due to viruses that may infect
            your computer equipment, software, data or other property on account
            of your access to, use of, or browsing the Website or your
            downloading of any material from the Website or any websites linked
            to the Website. If your use of material on the Web App results in
            the need for servicing, repair or correction of equipment, software
            or data, you assume all costs thereof.
          </span>
        </p>
        <p>
          <strong>17 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Member Posting Public Content</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            The Member declares to know and accept that when making comments or
            posting content in spaces such as blogs, forums, walls, etc., the
            personal data and information that you enter can be viewed by other
            Members and members of the public, making the Member assume total
            responsibility for any such comments.
          </span>
        </p>
        <p>
          <strong>18 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Content Responsibility</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo has no obligation to control and does not control
            Members&rsquo; use of the Web App and, therefore, does not guarantee
            the Members use the Web App in accordance with the provisions of
            these Terms of Use.
          </span>
        </p>
        <p>
          <strong>19 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Right to withdraw membership</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo reserves the right to limit, in whole or in part, access to
            the Web App for any Members.
          </span>
        </p>
        <p>
          <strong>20 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Update and Modification of the Platform</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo reserves the right to modify, at any time and without
            previous notice, these Terms and the Privacy Policy. Members must
            read these Terms carefully when accessing the Web App. In any case,
            the acceptance of the Terms will be a preliminary and indispensable
            step to access the services and contents available through the
            RooMigo Web App.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            In addition, RooMigo reserves the right to make, at any time and
            without prior notice, updates, modifications or deletions of
            information contained in its platform in the configuration and
            presentation of this and the conditions of access, without assuming
            any responsibility for it. RooMigo does not guarantee the absence of
            interruptions or errors in the access of the Web App or its content,
            nor that this one is always up-to-date, however, RooMigo will carry
            out, as long as there are no causes that make it impossible or
            difficult to execute, and as soon as it notices the errors,
            disconnections or lack of content updates, all those tasks aimed at
            rectifying errors, restoring communication and updating the
            contents.
          </span>
        </p>
        <p>
          <strong>21 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Links</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            If there are any links to third party websites on the Website / Web
            App or the Blog, they are provided solely for your convenience. If
            you use these links you leave the Website. RooMigo has not reviewed
            all of these third party websites and does not control and is not
            responsible for these websites or their content or availability.
            RooMigo therefore does not endorse or make any representations about
            them, or any material found on them, or any results that may be
            obtained from using them. If you decide to access any of the third
            party websites linked to the Website you do so entirely at your own
            risk.
          </span>
        </p>
        <p>
          <strong>22 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Indemnity</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            You agree to hold harmless and indemnify RooMigo, and its
            subsidiaries, affiliates, officers, agents, and employees, from and
            against any claim arising from or in any way related to your use of
            the Website / Web App, including any liability or expense (including
            legal costs and expenses) arising from all claims, losses, damages
            (actual and consequential), suits, judgments, legal costs of every
            kind and nature.
          </span>
        </p>
        <p>
          <strong>23 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Severability</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            If at any time, any one or more of the provisions in these Terms (or
            any clause or any part thereof) is held to be or becomes void or
            otherwise unenforceable for any reason under any applicable law, the
            same shall be deemed to be omitted from these Terms and the validity
            and or enforceability of the remaining provisions shall not in any
            way be affected or impaired thereby.
          </span>
        </p>
        <p>
          <strong>24 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Non Waiver</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            RooMigo&rsquo;s rights and remedies shall not be diminished, waived
            or extinguished by the granting of any indulgence, forbearance or
            extension of time by us nor by any failure of or delay by us in
            asserting any such rights or remedies.
          </span>
        </p>
        <p>
          <strong>25 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Governing Law and Jurisdiction</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            These Terms are governed by and construed in accordance with the
            laws of Ireland and any dispute or claim arising out of or in
            connection with these Terms will be subject to the exclusive
            jurisdiction of the courts of Ireland.
          </span>
        </p>
        <p>
          <strong>26 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
          <strong>Assignment</strong>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>
            These Terms, and any rights and licenses granted hereunder, may not
            be transferred or assigned by you, but may be assigned by the
            Company without restriction.
          </span>
        </p>
      </Segment>
    </div>
  );
};
export default TermsAndConditions;
