import React from "react";
import { Button, Grid, Modal } from "semantic-ui-react";
import { Link } from "react-router-dom";

const SubmitProfile = ({
  submit,
  openModal,
  closeModal,
  isModalOpen,
  title,
  message,
  goToList,
  updateAndList,
  updateAndListRoom,
  isUpdated,
  closeOnDimmerClick,
  searchable,
  goToProfile,
  slug,
  closeIcon,
}) => {
  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column textAlign="left">
          <Modal
            dimmer="inverted"
            size={updateAndList ? "small" : "tiny"}
            onOpen={() => openModal()}
            open={isModalOpen}
            closeOnDimmerClick={closeOnDimmerClick}
            onClose={() => closeModal()}
            closeIcon={
              closeIcon && { name: "close", color: "black", size: "large" }
            }
            style={{
              marginTop: window.innerWidth < 767 ? "35%" : "20%",
              marginLeft: window.innerWidth < 767 ? "2%" : "32%",
            }}
            trigger={
              <Grid.Column width={4} textAlign="left">
                <Button
                  primary
                  style={{
                    width: "180px",
                    borderRadius: "5px",
                    backgroundColor: "#00D5CC",
                  }}
                  size="large"
                  onClick={() => {
                    submit();
                  }}
                >
                  {title}
                </Button>
              </Grid.Column>
            }
          >
            <Modal.Content
              style={{
                justifyContent: "left",
              }}
            >
              <Grid centered stackable>
                <Grid.Row>
                  {title && (
                    <p
                      style={{
                        margin: 10,
                        color: "#FF664D",
                        fontFamily: "MYRIAD",
                        fontSize: "25px",
                        marginBottom: 10,
                        marginTop: 20,
                      }}
                    >
                      {message}
                    </p>
                  )}
                </Grid.Row>
                {!goToList ? (
                  <Grid.Row>
                    {!searchable && (
                      <Button
                        style={{
                          width: "180px",
                          borderRadius: "5px",
                          backgroundColor: "#00D5CC",
                          color: "#fff",
                        }}
                        size="large"
                        onClick={() => {
                          goToProfile();
                        }}
                      >
                        Go to My Profile
                      </Button>
                    )}
                  </Grid.Row>
                ) : (
                  <Grid.Row>
                    <Link to={slug ? `/room/${slug}` : "/my_rooms"}>
                      <Button
                        style={{
                          width: "180px",
                          borderRadius: "5px",
                          backgroundColor: "#00D5CC",
                          color: "#fff",
                        }}
                        size="large"
                        onClick={() => {
                          closeModal();
                          goToList && goToList();
                        }}
                      >
                        View my Listing
                      </Button>
                    </Link>
                    <Link to="/search_profile">
                      <Button
                        style={{
                          width: "180px",
                          borderRadius: "5px",
                          backgroundColor: "#00D5CC",
                          color: "#fff",
                          marginLeft: window.innerWidth < 767 ? 30 : 0,
                        }}
                        size="large"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        Find a Roommate
                      </Button>
                    </Link>
                    <Link to="/search_room">
                      <Button
                        style={{
                          width: "140px",
                          borderRadius: "5px",
                          backgroundColor: "#00D5CC",
                          color: "#fff",
                          marginLeft: window.innerWidth < 767 ? 25 : 0,
                          marginTop: window.innerWidth < 767 ? 20 : 0,
                        }}
                        size="large"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        Find a Room
                      </Button>
                    </Link>
                  </Grid.Row>
                )}
              </Grid>
            </Modal.Content>
          </Modal>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SubmitProfile;
