import React from "react";
import { Divider, Grid, Header, Image, Container } from "semantic-ui-react";

const icon_heart = require("../../assets/images/match.svg");
const icon_search = require("../../assets/images/browse.svg");
const icon_people = require("../../assets/images/connect.svg");

const theirishtimes = require("../../assets/images/theirishtimes.svg");
const newstalk = require("../../assets/images/newstalk.svg");
const sundaybusinesspost = require("../../assets/images/sundaybusinesspost.png");
const lovindublin = require("../../assets/images/lovindublin.svg");
const siliconrepublic = require("../../assets/images/siliconrepublic.png");
const studyinternational = require("../../assets/images/studyinternational.png");
const thejournal = require("../../assets/images/thejournal.png");
const rte = require("../../assets/images/rte.svg");

const HomeView = () => {
  const articles = [
    {
      logo: theirishtimes,
      articleUrl:
        "https://www.irishtimes.com/business/innovation/put-simply-i-realised-people-want-to-live-with-friends-1.3472592",
    },
    {
      logo: thejournal,
      articleUrl: "https://www.thejournal.ie/roomigo-dublin-2-4402191-Jan2019/",
    },
    {
      logo: newstalk,
      articleUrl:
        "https://www.newstalk.com/podcasts/the-home-show-with-sinead-ryan/cheap-irish-houses-mattresses-matchmaking-housemates",
    },
    {
      logo: rte,
      articleUrl:
        "https://radio.rte.ie/radio1highlights/roomigo-the-ryan-tubridy-show/",
    },
    {
      logo: sundaybusinesspost,
      articleUrl: "https://www.businesspost.ie",
    },
    {
      logo: studyinternational,
      articleUrl:
        "https://www.studyinternational.com/news/students-decent-housemates-ireland-roomigo/",
    },
    {
      logo: siliconrepublic,
      articleUrl:
        "https://www.siliconrepublic.com/start-ups/roomigo-match-housemates-rent-accommodation",
    },
    {
      logo: lovindublin,
      articleUrl: "https://lovindublin.com/news/roomigo-dublin-rent",
    },
    /* {
      logo: irishtechnews,
      articleUrl:
        "https://irishtechnews.ie/an-irish-story-how-the-heart-is-guiding-app-development/",
    }, */
  ];
  return (
    <div>
      <Container
        fluid
        textAlign="center"
        style={{
          backgroundColor: "white",
        }}
        className="home-bottom"
      >
        <div>
          <Grid stackable style={{ marginBottom: 40 }}>
            <Grid.Row>
              <Grid.Column width={1} />
              <Grid.Column style={{ color: "black" }} width={4}>
                <Image centered src={icon_search} style={{ width: "50%" }} />
                <h2
                  style={{
                    marginTop: 0,
                  }}
                >
                  Browse
                </h2>
                <Divider
                  style={{
                    borderWidth: "3px",
                    borderRadius: "15px",
                    borderColor: "#FF664D",
                  }}
                />
                <p>Browse people who are renting out or seeking rooms.</p>
              </Grid.Column>
              <Grid.Column width={1} />
              <Grid.Column style={{ color: "black" }} width={4}>
                <Image centered src={icon_heart} style={{ width: "50%" }} />
                <h2
                  style={{
                    marginTop: 0,
                  }}
                >
                  Connect
                </h2>
                <Divider
                  style={{
                    borderWidth: "3px",
                    borderRadius: "15px",
                    borderColor: "#FF664D",
                  }}
                />
                <p>Connect with people based on similar lifestyle choices.</p>
              </Grid.Column>
              <Grid.Column width={1} />
              <Grid.Column style={{ color: "black" }} width={4}>
                <Image centered src={icon_people} style={{ width: "50%" }} />
                <h2
                  style={{
                    marginTop: 0,
                  }}
                >
                  Chat
                </h2>
                <Divider
                  style={{
                    borderWidth: "3px",
                    borderRadius: "15px",
                    borderColor: "#FF664D",
                  }}
                />
                <p>Send requests and chat with compatible people.</p>
              </Grid.Column>
              <Grid.Column width={1} />
            </Grid.Row>
          </Grid>
        </div>
        <div>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as="h2" textAlign="left">
                  Featured In
                </Header>
                <Divider /* @TODO use CSS for these dividers (this is copied from other uses) */
                  style={{
                    borderWidth: "3px",
                    borderRadius: "15px",
                    borderColor: "#ff664d",
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid stackable centered className="seenin">
            {articles.map(article => {
              return (
                <Image
                  className="image"
                  as="a"
                  href={article.articleUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  src={article.logo}
                  centered
                  verticalAlign="middle"
                />
              );
            })}
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default HomeView;
