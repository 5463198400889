import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Grid,
  Label,
  Divider,
  Image,
  Responsive,
  Icon,
} from "semantic-ui-react";
import { momentToDate, renderFavouritePicture } from "../../utils/helpers";
import "../fonts/extrafonts.css";
import _ from "lodash";
import { LeftNav, RightNav } from "../Gallery/set_gallery.js";
import moment from "moment";
import RequestButton from "../../utils/request_button";
import ShareButton from "../../utils/share_button";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import GoogleMapWrapper from "../parts/GoogleMapWrapper";
import { Circle } from "react-google-maps";
import { GOOGLE_API_KEY } from "../../actions/root_url";
import speaker from "../../assets/images/speaker.png";

const RoomDetailView = ({
  room,
  windowWidth,
  sendRequest,
  request,
  inverseRequest,
  myProfile,
  renderPicturesList,
  renderItem,
  linkToPreviousPage,
  acceptRequest,
  declineRequest,
  setConnection,
}) => {
  let params = {};
  if (windowWidth > 767) {
    params = Responsive.onlyTablet;
  } else {
    params = Responsive.onlyMobile;
  }
  const renter = room.renter && room.renter.data.profile.data;

  const mapOptions = {
    mapZoom: 13,
    mapHeight: 300,
    circleRadius: 420,
    circleStyles: {
      strokeColor: "transparent",
      fillColor: "#FF664D",
    },
    defaultOptions: {
      streetViewControl: true,
      scaleControl: false,
      mapTypeControl: false,
      panControl: false,
      zoomControl: true,
      rotateControl: false,
      fullscreenControl: true,
    },
    defaultElement: <div style={{ height: "100%" }} />,
    mapUrl: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
  };

  return (
    <div>
      <Grid stackable>
        <Grid.Row>
          <Label basic style={{ border: "0" }} size="big">
            <b style={{ color: "#707070" }}>
              {room.area && `${room.area.name},`}
              &nbsp;
              {room.area_code && room.area_code.code}
            </b>
            <br />
            <font style={{ fontSize: "16px" }}> €{room.price} per month </font>
          </Label>
        </Grid.Row>
        <Grid.Row columns={12}>
          <Grid.Column width={9}>
            <Grid.Row textAlign="center">
              <div
                style={{
                  border: "0",
                  fontStyle: "italic",
                  color: "#707070",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                {room.slogan && `" ${room.slogan} "`}
              </div>
            </Grid.Row>
            <Grid.Row style={{ marginBottom: "3%" }}>
              <ImageGallery
                items={renderPicturesList(room)}
                showPlayButton={false}
                showFullscreenButton={false}
                showThumbnails={false}
                lazyLoad={false}
                renderItem={renderItem}
                renderLeftNav={(onClick, disabled) => (
                  <LeftNav onClick={onClick} disabled={disabled} left="1%" />
                )}
                renderRightNav={(onClick, disabled) => (
                  <RightNav
                    onClick={onClick}
                    disabled={disabled}
                    right={windowWidth > 767 ? "1%" : "7%"}
                  />
                )}
              />
            </Grid.Row>
          </Grid.Column>
          <Responsive {...Responsive.onlyComputer}>
            <Grid.Column width={4} style={{ marginLeft: "15%" }}>
              <Grid.Row columns={4}>
                {renter && (
                  <Link to={`/profile/${renter.slug}`}>
                    <Image
                      style={{
                        cursor: "pointer",
                        width: _.isEmpty(renter.pictures) ? "70%" : "207px",
                        height: "207px",
                        borderRadius: "8px",
                        marginBottom: "3%",
                        marginTop: room.slogan ? "12%" : "3%",
                      }}
                      src={
                        _.isEmpty(renter.pictures)
                          ? speaker
                          : renderFavouritePicture(renter.pictures)
                      }
                      size="small"
                    />
                  </Link>
                )}
                <Grid.Column style={{ marginTop: "5px" }}>
                  {renter && (
                    <label
                      style={{
                        border: "0",
                        fontfamily: "myriad",
                        color: "#a6a6a6",
                        margintop: "6%",
                        fontsize: "14px",
                      }}
                    >
                      {renter.name}, {renter.age}
                    </label>
                  )}
                </Grid.Column>
              </Grid.Row>
              {renter && (
                <Grid.Row width={4}>
                  <Grid.Column width={2} />
                  {renter.interests &&
                    renter.interests.map((interest, index) => (
                      <label
                        key={interest.id}
                        style={{
                          margin: 2,
                          fontfamily: "myriad",
                          color: "#FF664D",
                        }}
                      >
                        #{interest.name}
                        &nbsp;
                        {index === 2 || index === 5 ? <br /> : ""}
                      </label>
                    ))}
                </Grid.Row>
              )}
              <Grid.Column width={5} style={{ marginTop: "10px" }}>
                {myProfile && (
                  <Grid.Column width={4}>
                    <RequestButton
                      request={request}
                      inverseRequest={inverseRequest}
                      profile={renter}
                      sendRequest={sendRequest}
                      linkToPreviousPage={linkToPreviousPage}
                      acceptRequest={acceptRequest}
                      declineRequest={declineRequest}
                      setConnection={setConnection}
                    />
                  </Grid.Column>
                )}
              </Grid.Column>
            </Grid.Column>
          </Responsive>
          <Responsive {...params}>
            <Grid>
              <Grid.Row
                columns={16}
                style={{
                  marginTop: windowWidth < 767 ? "1%" : "5%",
                  marginBottom: "5%",
                  marginLeft: windowWidth < 767 ? "40px" : "3%",
                }}
              >
                <Grid.Column width={6}>
                  {renter && (
                    <Link to={`/profile/${renter.slug}`}>
                      <Image
                        centered
                        style={{
                          cursor: "pointer",
                          width: windowWidth < 767 ? "80px" : "90%",
                          height: windowWidth < 767 ? "80px" : "207px",
                          borderRadius: windowWidth < 767 ? "50px" : "30%",
                        }}
                        src={
                          _.isEmpty(renter.pictures)
                            ? speaker
                            : renderFavouritePicture(renter.pictures)
                        }
                        size="small"
                      />
                    </Link>
                  )}
                </Grid.Column>
                <Grid.Column width={9} textAlign="left">
                  {renter && (
                    <Label
                      basic
                      style={{
                        border: "0",
                        fontFamily: "MYRIAD",
                        color: "#A6A6A6",
                        marginTop: "2%",
                        fontSize: "14px",
                        marginLeft: "-30px",
                      }}
                    >
                      {renter.name},{renter.age}
                    </Label>
                  )}

                  {renter && (
                    <Grid.Row columns={3} style={{ marginLeft: "-20px" }}>
                      <Grid.Column width={3} />
                      {renter.interests &&
                        renter.interests.slice(0, 3).map(interest => (
                          <span
                            key={interest.id}
                            style={{
                              margin: 2,
                              fontFamily: "MYRIAD",
                              color: "#ff664d",
                            }}
                          >
                            #{interest.name}
                          </span>
                        ))}
                    </Grid.Row>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Responsive>
          <Responsive {...params}>
            <Grid.Column width={12} style={{ marginLeft: "49px" }}>
              {myProfile && (
                <Grid.Column
                  width={12}
                  style={{
                    marginBottom: "5%",
                    marginLeft: "10px",
                  }}
                >
                  <RequestButton
                    request={request}
                    inverseRequest={inverseRequest}
                    profile={renter}
                    sendRequest={sendRequest}
                    linkToPreviousPage={linkToPreviousPage}
                    acceptRequest={acceptRequest}
                    declineRequest={declineRequest}
                    setConnection={setConnection}
                  />
                </Grid.Column>
              )}
            </Grid.Column>
          </Responsive>
        </Grid.Row>
      </Grid>

      <Grid stackable style={{ marginTop: "20px" }}>
        <Grid.Column width={7}>
          <h2 style={{ color: "#707070", fontFamily: "MYRIAD" }}>
            Rent and Availability
          </h2>
          <Divider
            style={{
              borderWidth: "3px",
              borderRadius: "15px",
              borderColor: "#FF664D",
            }}
          />
        </Grid.Column>
      </Grid>
      <Grid stackable style={{ marginTop: "20px", fontFamily: "MYRIAD-BOLD" }}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column mobile={1} computer={4} textAlign="left">
            <Label
              basic
              style={{ border: "0", color: "#707070", fontSize: "16px" }}
            >
              Rent: &nbsp;&nbsp;&nbsp;&nbsp;
              <font style={{ fontSize: "14px", fontFamily: "MYRIAD" }}>
                € {room.price}
              </font>
            </Label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle">
          <Grid.Column mobile={1} computer={4} textAlign="left">
            <Label
              basic
              style={{ border: "0", color: "#707070", fontSize: "16px" }}
            >
              Deposit: &nbsp;&nbsp;&nbsp;&nbsp;
              <font style={{ fontSize: "14px", fontFamily: "MYRIAD" }}>
                € {room.deposit ? room.deposit : "not stated"}
              </font>
            </Label>
          </Grid.Column>
          <Grid.Column width={3}>
            <Label
              basic
              style={{
                border: "0",
                color: "#707070",
                fontSize: "16px",
                fontFamily: "MYRIAD-BOLD",
              }}
            >
              Bills Included? &nbsp;&nbsp;&nbsp;&nbsp;
              <font style={{ fontSize: "14px", fontFamily: "MYRIAD" }}>
                {room.bills_included ? "Yes" : "No"}
              </font>
            </Label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={4} textAlign="left">
            <Label
              basic
              style={{
                border: "0",
                color: "#707070",
                fontSize: "16px",
                fontFamily: "MYRIAD-BOLD",
              }}
            >
              Minimum stay (months): &nbsp;&nbsp;&nbsp;&nbsp;
              <font style={{ fontSize: "14px", fontFamily: "MYRIAD" }}>
                {room.minimum_stay ? room.minimum_stay : "not stated"}
              </font>
            </Label>
          </Grid.Column>
          <Grid.Column width={4}>
            <Label
              basic
              style={{
                border: "0",
                color: "#707070",
                fontSize: "16px",
                fontFamily: "MYRIAD-BOLD",
              }}
            >
              Move in date: &nbsp;&nbsp;&nbsp;&nbsp;
              <font style={{ fontSize: "14px", fontFamily: "MYRIAD" }}>
                {moment(room.move_in_date).isBefore(moment())
                  ? "Now"
                  : momentToDate(room.move_in_date)}
              </font>
            </Label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable style={{ marginTop: "40px" }}>
        <Grid.Column width={7}>
          <h2 style={{ color: "#707070", fontFamily: "MYRIAD" }}>Place</h2>
          <Divider
            style={{
              borderWidth: "3px",
              borderRadius: "15px",
              borderColor: "#FF664D",
            }}
          />
        </Grid.Column>
      </Grid>
      <Grid stackable style={{ marginTop: "20px" }}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={4} textAlign="left">
            <Label
              basic
              style={{
                border: "0",
                color: "#707070",
                fontSize: "16px",
                fontFamily: "MYRIAD-BOLD",
              }}
            >
              Bed:
              <font style={{ fontSize: "14px", fontFamily: "MYRIAD" }}>
                {room.type ? room.type : "not stated"}
              </font>
            </Label>
          </Grid.Column>
          <Grid.Column width={4}>
            <Label
              basic
              style={{
                border: "0",
                color: "#707070",
                fontFamily: "MYRIAD-BOLD",
                fontSize: "16px",
              }}
            >
              EnSuite:
              <font style={{ fontSize: "14px", fontFamily: "MYRIAD" }}>
                {room.ensuite ? room.ensuite : "not stated"}
              </font>
            </Label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={4} textAlign="left">
            <Label
              basic
              style={{
                border: "0",
                color: "#707070",
                fontFamily: "MYRIAD-BOLD",
                fontSize: "16px",
              }}
            >
              Housemates:&nbsp;
              {room.roommate_male_number}&nbsp;
              <Icon name="male" color="grey" />
              {room.roommate_female_number}&nbsp;
              <Icon name="female" color="grey" />
            </Label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={4} textAlign="left">
            <Label
              basic
              style={{
                border: "0",
                color: "#707070",
                fontFamily: "MYRIAD-BOLD",
                fontSize: "16px",
              }}
            >
              Description:{" "}
            </Label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={7}>
            <p
              style={{
                marginLeft: "5%",
                marginBottom: "10%",
                color: "#707070",
                textAlign: "justify",
                whiteSpace: "pre-wrap",
              }}
            >
              {room.description}
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {room.coordinates &&
        room.coordinates.lat !== null &&
        room.coordinates.lng !== null && (
          <>
            <Grid stackable style={{ marginTop: "40px" }}>
              <Grid.Column width={7}>
                <h2 style={{ color: "#707070", fontFamily: "MYRIAD" }}>
                  Location
                </h2>
                <Divider
                  style={{
                    borderWidth: "3px",
                    borderRadius: "15px",
                    borderColor: "#FF664D",
                  }}
                />
              </Grid.Column>
            </Grid>

            <Grid stackable style={{ marginBottom: 40 }}>
              <Grid.Column width={7}>
                <div style={{ width: "100%", height: mapOptions.mapHeight }}>
                  <GoogleMapWrapper
                    defaultZoom={mapOptions.mapZoom}
                    defaultCenter={{
                      lat: room.coordinates.lat,
                      lng: room.coordinates.lng,
                    }}
                    defaultOptions={mapOptions.defaultOptions}
                    googleMapURL={mapOptions.mapUrl}
                    loadingElement={mapOptions.defaultElement}
                    containerElement={mapOptions.defaultElement}
                    mapElement={mapOptions.defaultElement}
                  >
                    <Circle
                      defaultCenter={{
                        lat: room.coordinates.lat,
                        lng: room.coordinates.lng,
                      }}
                      radius={mapOptions.circleRadius}
                      options={mapOptions.circleStyles}
                    />
                  </GoogleMapWrapper>
                </div>
              </Grid.Column>
            </Grid>
          </>
        )}

      <Grid stackable style={{ marginTop: "10px", marginBottom: 40 }}>
        <Grid.Column width={7}>
          <h2 style={{ color: "#707070", fontFamily: "MYRIAD" }}>
            Preferences
          </h2>
          <Divider
            style={{
              borderWidth: "3px",
              borderRadius: "15px",
              borderColor: "#FF664D",
            }}
          />
        </Grid.Column>

        <Grid.Row verticalAlign="middle">
          <Grid.Column mobile={1} computer={4} textAlign="left">
            <Label
              basic
              style={{
                border: "0",
                color: "#707070",
                fontSize: "16px",
                fontFamily: "MYRIAD-BOLD",
              }}
            >
              Preferred gender &nbsp;&nbsp;&nbsp;&nbsp;
              <font style={{ fontSize: "14px", fontFamily: "MYRIAD" }}>
                {room.preferred_gender === "both" && "Male or Female"}
                {room.preferred_gender === "male" && "Male"}
                {room.preferred_gender === "female" && "Female"}
              </font>
            </Label>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row verticalAlign="middle">
          <Grid.Column mobile={1} computer={4} textAlign="left">
            <Label
              basic
              style={{
                border: "0",
                color: "#707070",
                fontSize: "16px",
                fontFamily: "MYRIAD-BOLD",
              }}
            >
              Available to Couples? &nbsp;&nbsp;&nbsp;&nbsp;
              <font style={{ fontSize: "14px", fontFamily: "MYRIAD" }}>
                {room.preferred_relationship === "couple" ||
                room.preferred_relationship === "both"
                  ? "YES"
                  : "NO"}
              </font>
            </Label>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <Label
              basic
              style={{
                border: "0",
                color: "#707070",
                fontSize: "16px",
                fontFamily: "MYRIAD-BOLD",
              }}
            >
              Available to Students? &nbsp;&nbsp;&nbsp;&nbsp;
              <font style={{ fontSize: "14px", fontFamily: "MYRIAD" }}>
                {room.preferred_occupation !== "work" ? "YES" : "NO"}
              </font>
            </Label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle">
          <Grid.Column textAlign="left">
            <Label
              basic
              style={{
                border: "0",
                color: "#707070",
                fontSize: "16px",
                fontFamily: "MYRIAD-BOLD",
              }}
            >
              Available to Professionals ? &nbsp;&nbsp;&nbsp;&nbsp;
              <font style={{ fontSize: "14px", fontFamily: "MYRIAD" }}>
                {room.preferred_occupation !== "study" ? "YES" : "NO"}
              </font>
            </Label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <Label
              basic
              style={{
                border: "0",
                color: "#707070",
                fontSize: "16px",
                fontFamily: "MYRIAD-BOLD",
              }}
            >
              Aged: &nbsp;&nbsp;&nbsp;&nbsp;
              <font style={{ fontSize: "14px", fontFamily: "MYRIAD" }}>
                {room.age_min && room.age_max
                  ? `From ${room.age_min}   to   ${room.age_max}`
                  : "No Preference"}
              </font>
            </Label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable centered className="bottom-buttons">
        <Grid.Column verticalAlign="middle">
          <Grid.Row>
            <RequestButton
              request={request}
              inverseRequest={inverseRequest}
              profile={renter}
              sendRequest={sendRequest}
              linkToPreviousPage={linkToPreviousPage}
              acceptRequest={acceptRequest}
              declineRequest={declineRequest}
              setConnection={setConnection}
            />
          </Grid.Row>
          <Grid.Row>
            <ShareButton
              text={`Check out ${
                renter ? renter.name + "'s" : "this"
              } room on RooMigo! RooMigo is a way to find like-minded housemates you share interests with.`}
              title={`${renter ? renter.name + "'s" : "this"} room on RooMigo`}
              buttonText="room"
            />
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </div>
  );
};

RoomDetailView.propTypes = {
  room: PropTypes.object.isRequired,
  windowWidth: PropTypes.number.isRequired,
  sendRequest: PropTypes.func.isRequired,
  request: PropTypes.object,
  inverseRequest: PropTypes.object,
  myProfile: PropTypes.object.isRequired,
  renderPicturesList: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  linkToPreviousPage: PropTypes.func.isRequired,
  acceptRequest: PropTypes.func.isRequired,
  declineRequest: PropTypes.func.isRequired,
  setConnection: PropTypes.func.isRequired,
};

export default RoomDetailView;
