// Main domain (frontend).
// const domain = process.env.REACT_APP_DOMAIN;

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
// Api domain (backend).
export const ROOT_URL = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_API_WS_URL;

// AWS S3 images bucket url.
export const IMAGES_S3_BUCKET_URL = process.env.REACT_APP_IMAGES_S3_BUCKET;
