/* global window */
import React from "react";
import { Form, Grid, Button, Input } from "semantic-ui-react";

const UnsubscribeView = ({ unsubscribe, email }) => {
  return (
    <div style={{ paddingTop: window.innerHeight * 0.25 }}>
      <Form onSubmit={event => event.preventDefault()}>
        <Grid container centered>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <h1
                style={{
                  color: "#FF664D",
                  fontFamily: "MYRIAD",
                  marginLeft: "1%",
                }}
              >
                Unsubscribe
              </h1>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Email address"
                value={email}
                icon="mail outline"
                iconPosition="left"
                fluid
                disabled
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column computer={5} mobile={16} verticalAlign="middle">
              <Button
                id="button"
                fluid
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#00D5CC",
                  color: "#fff",
                }}
                onClick={unsubscribe}
              >
                CONFIRM
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
};

export default UnsubscribeView;
