import React from 'react';
import {
  renderFavouritePicture,
  formatAreaName,
  formatMoney,
} from '../../../utils/helpers';

const RoomItem = ({
  room: { pictures, area, area_code, slogan, price, move_in_date, deposit },
}) => (
  <>
    <div className="choose-room__container">
      <div className="choose-room__image">
        <img src={renderFavouritePicture(pictures)} alt="room" />
      </div>
      <div className="choose-room__info">
        <h6 className="choose-room__title">
          {formatAreaName(area, area_code)}
        </h6>
        <p className="choose-room__desc">{slogan}</p>
      </div>
      <div className="choose-room__price">{formatMoney(price)}</div>
    </div>
    <div className="choose-room__annex">
      <div className="choose-room__annex--part -left">
        <strong>Move in: </strong>
        {move_in_date}
      </div>
      {deposit && (
        <div className="choose-room__annex--part -right">
          <strong>Deposit: </strong>
          {formatMoney(deposit)}
        </div>
      )}
    </div>
  </>
);

export default RoomItem;
