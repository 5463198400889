import { axiosInstance } from './configured_axios';
import { Socket } from 'phoenix';
import { SOCKET_URL } from './root_url';
import {
  JOIN_CHAT_SUCCESS,
  FETCH_PROFILE_CONNECTION_LIST,
  SET_CONNECTION,
  FETCH_CHAT_MESSAGE_LIST,
  SEND_CHAT_MESSAGE,
  FETCH_PROFILE_MESSAGE_LIST,
  READ_MESSAGE,
  EMPTY_CONNECTION,
  JOIN_NOTIFICATION_CHANNEL,
  FETCH_RECEIVED_REQUEST_LIST,
} from './action_types';

export function joinNotificationChannel(jwt, userId) {
  const socket = new Socket(SOCKET_URL, {
    params: { token: jwt },
    logger: (kind, msg, data) => {
      // console.log(`${kind}: ${msg}`, data);
    },
  });

  const channelName = `notification:${userId}`;
  socket.connect();

  return dispatch => {
    const notificationChannel = socket.channel(channelName);
    notificationChannel
      .join()
      .receive('ok', msg => {
        dispatch({
          type: JOIN_NOTIFICATION_CHANNEL,
          payload: notificationChannel,
        });
        console.log(msg);
      })
      .receive('error', reason => {
        console.log(reason);
      });
    notificationChannel.on('new:notification', data => {
      dispatch(fetchReceivedRequestList());
      dispatch(fetchProfileConnections());
      dispatch(fetchProfileMessageList());
    });
  };
}

export function fetchReceivedRequestList() {
  const request = axiosInstance.get(`/api/requests_received`);

  return {
    type: FETCH_RECEIVED_REQUEST_LIST,
    payload: request,
  };
}

export function joinChatChannel(chatId, jwt) {
  const socket = new Socket(SOCKET_URL, {
    params: { token: jwt },
  });

  socket.connect();

  return dispatch => {
    const chatChannel = socket.channel(chatId);
    chatChannel
      .join()
      .receive('ok', msg => {
        dispatch({ type: JOIN_CHAT_SUCCESS, payload: chatChannel });
        console.log(msg);
      })
      .receive('error', reason => {
        console.log(reason);
      });
    chatChannel.on('new:message', data => {
      dispatch({ type: FETCH_CHAT_MESSAGE_LIST, payload: { data } });
    });
  };
}

export function fetchProfileConnections() {
  const request = axiosInstance.get(`/api/profile/connections`);

  return {
    type: FETCH_PROFILE_CONNECTION_LIST,
    payload: request,
  };
}

export function setConnection(profile_id) {
  const request = axiosInstance.post(`/api/profile/chat_connection`, {
    profile_id,
  });

  return {
    type: SET_CONNECTION,
    payload: request,
  };
}

export function emptyConnection() {
  return {
    type: EMPTY_CONNECTION,
    payload: {},
  };
}

export function fetchChatMessageList(connectionId) {
  const request = axiosInstance.get(`/api/profile/connections/${connectionId}`);

  return {
    type: FETCH_CHAT_MESSAGE_LIST,
    payload: request,
  };
}

export function readMessages(chat_id, profile_id) {
  return dispatch => {
    axiosInstance
      .post(`/api/mark_messages_read/`, { chat_id, profile_id })
      .then(response => {
        dispatch({ type: READ_MESSAGE, payload: response });
        dispatch(fetchProfileMessageList());
      });
  };
}

export function fetchProfileMessageList() {
  const request = axiosInstance.get(`/api/profile_messages/`);
  console.log('PROFILE MESSAGE LIST:', request);
  return {
    type: FETCH_PROFILE_MESSAGE_LIST,
    payload: request,
  };
}

export function sendChatMessage(chat_id, text, receiver) {
  const request = axiosInstance.post(`/api/messages`, {
    message: {
      chat_id,
      text,
      receiver,
    },
  });

  return {
    type: SEND_CHAT_MESSAGE,
    payload: request,
  };
}
