import React, { Component } from "react";
import { connect } from "react-redux";
import { unsubscribe } from "../../actions";
import UnsubscribeView from "./unsubscribe_view";
import _ from "lodash";

class Unsubscribe extends Component {
  constructor(props) {
    super(props);
    this.unsubscribe = this.unsubscribe.bind(this);
    this.state = {
      myProfile: this.props.myProfile.data,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.myProfile, nextProps.myProfile)) {
      // When we fetch my profile
      if (!this.props.myProfile.data.id) {
        this.setState({ myProfile: nextProps.myProfile.data });
      } else {
        // After the profile has been updated
        this.props.history.push("/");
      }
    }
  }

  unsubscribe() {
    let { myProfile } = this.state;
    myProfile.email_subscription = false;
    this.props.unsubscribe(myProfile);
  }

  render() {
    const { myProfile } = this.state;
    return (
      <UnsubscribeView unsubscribe={this.unsubscribe} email={myProfile.email} />
    );
  }
}

const mapStateToProps = ({ myProfile }) => ({ myProfile });

export default connect(mapStateToProps, { unsubscribe })(Unsubscribe);
