import React from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Grid,
  Button,
  Input,
  Message,
} from "semantic-ui-react";

const RegisterView = ({
  handleFieldChange,
  session,
  register,
  errorMessage,
}) => {
  return (
    <div className="register-wrapper">
      <Form onSubmit={event => event.preventDefault()}>
        <Grid container centered>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <h1
                style={{
                  color: "#FF664D",
                  fontFamily: "MYRIAD",
                  marginLeft: "1%",
                }}
              >
                Sign Up
              </h1>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <Input
                id="name"
                name="name"
                type="text"
                onChange={event =>
                  handleFieldChange("name", event.target.value)
                }
                placeholder="Name"
                value={session.name}
                icon="user"
                iconPosition="left"
                fluid
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <Input
                id="email"
                name="email"
                type="email"
                onChange={event =>
                  handleFieldChange("email", event.target.value)
                }
                placeholder="Email address"
                value={session.email}
                icon="mail outline"
                iconPosition="left"
                fluid
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column computer={10} mobile={16}>
              <Input
                id="password"
                name="password"
                type="password"
                onChange={event =>
                  handleFieldChange("password", event.target.value)
                }
                placeholder="Password"
                value={session.password}
                icon="lock"
                iconPosition="left"
                fluid
              />
              <div style={{ margin: 10, textAlign: "center" }}>
                <font style={{ fontSize: 15, fontStyle: "italic" }}>
                  By clicking "Sign Up" you agree to our
                  <Link to="/terms">Terms & Conditions</Link> and &nbsp;
                  <Link to="/privacy">Privacy Policy.</Link>
                </font>
              </div>
            </Grid.Column>
          </Grid.Row>
          {errorMessage && (
            <Grid.Row>
              <Grid.Column computer={10} mobile={16}>
                <Message negative content={errorMessage} />
              </Grid.Column>
            </Grid.Row>
          )}
          {errorMessage ? (
            <Grid.Row centered>
              <Grid.Column computer={5} mobile={8} verticalAlign="middle">
                <Link to="/email_login">Login</Link>
              </Grid.Column>
              <Grid.Column computer={5} mobile={8} verticalAlign="middle">
                <Link to="/reset_password">Reset Password</Link>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row centered>
              <Grid.Column computer={5} mobile={16} verticalAlign="middle">
                <Button
                  id="button"
                  fluid
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#00D5CC",
                    color: "#fff",
                  }}
                  onClick={register}
                >
                  SIGN UP
                </Button>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Form>
    </div>
  );
};

export default RegisterView;
