/* global window */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import _ from "lodash";
import { acceptCookie } from "../actions";

class CookieWarning extends Component {
  closeCookie() {
    this.props.acceptCookie();
  }

  render() {
    return (
      <div
        id="cookie-warning"
        style={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: 0,
          zIndex: 1001,
          opacity: 1,
        }}
      >
        {_.isEmpty(this.props.acceptedCookie) && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: 16,
              lineSpace: 2,
              fontFamily: "MYRIAD",
              backgroundColor: "#FF664D",
              width: "100%",
              borderRadius: 0,
              minHeight: "70px",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
              padding: "10px 30px",
            }}
          >
            <div
              style={{
                textAlign: "center",
              }}
            >
              {
                "We use cookies to ensure you get the best experience. Please check out our "
              }
              <a href="/privacy">Cookie Notice</a>
              <span>{" for more information."}</span>
            </div>
            <div>
              <Button
                primary
                compact
                style={{
                  marginLeft: window.innerWidth <= 768 ? 0 : 15,
                  height: 40,
                }}
                onClick={() => this.closeCookie()}
              >
                <Icon
                  name="checkmark box"
                  style={{ cursor: "pointer", marginLeft: "-3px" }}
                />
                Got it!
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ acceptedCookie }) => ({ acceptedCookie });

export default connect(mapStateToProps, { acceptCookie })(CookieWarning);
