import {
  FETCH_INTERESTS,
} from '../actions/action_types';

export default function interestListReducer(state = [], action) {
  switch (action.type) {
    case FETCH_INTERESTS: {
      return action.payload.data.data;
    }
    default: {
      return state;
    }
  }
}
