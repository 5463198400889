export function formatAddressObj(
  addressDataArr,
  selectedCity,
  coordinates,
  cityWithArr
) {
  let payload = {};
  payload.roomObj = {};
  const errorMsg = "Couldn’t find any results.";
  const vagueAddressMsg =
    "This address isn't specific enough, please enter your street address.";

  const firstAddress = addressDataArr[0].address_components;

  payload.coordinates = coordinates;
  payload.roomObj.coordinates = coordinates;

  const responseStreet = firstAddress.find(
    address =>
      address.types.includes("street_address") ||
      address.types.includes("route")
  );
  if (responseStreet) {
    payload.roomObj.address = responseStreet.long_name;
  } else {
    throw new Error(vagueAddressMsg);
  }

  const responseHouseNumber = firstAddress.find(address =>
    address.types.includes("street_number")
  );
  if (responseHouseNumber) {
    payload.roomObj.address =
      responseHouseNumber.long_name + " " + payload.roomObj.address;
  }
  const responseArea = firstAddress.find(address =>
    address.types.includes("postal_town")
  );
  if (responseArea) {
    payload.areaFromResponse = responseArea.long_name;
  }

  const responseNeighbourhood = firstAddress.find(
    address =>
      address.types.includes("neighborhood") ||
      address.types.includes("sublocality") ||
      address.types.includes("sublocality_level_1")
  );
  if (responseNeighbourhood) {
    payload.neighbourhoodFromResponse = responseNeighbourhood.long_name;
  }

  const responseCity = firstAddress.find(address =>
    address.types.includes("administrative_area_level_1")
  );
  if (!responseCity) throw new Error(errorMsg);

  // Only Dublin have area_codes, so If array contain elements its Dublin and it has area and area_code
  if (cityWithArr.area_codes.length > 0) {
    if (
      responseCity.long_name.toUpperCase().includes(selectedCity.toUpperCase())
    ) {
      payload.availableNeighbourhoodList = cityWithArr.areas;
      payload.availableAreaList = cityWithArr.area_codes;
      if (payload.areaFromResponse) {
        const areaInList = payload.availableAreaList.find(
          area => area.code === payload.areaFromResponse
        );
        if (areaInList) {
          payload.roomObj.area_code = areaInList;
        }
      }

      if (payload.neighbourhoodFromResponse) {
        const neighbourhoodInList = payload.availableNeighbourhoodList.find(
          neighbourhood =>
            neighbourhood.code === payload.neighbourhoodFromResponse
        );
        if (neighbourhoodInList) {
          payload.roomObj.area = neighbourhoodInList;
        }
      }
    } else {
      throw new Error(errorMsg);
    }
  } else if (
    responseCity.long_name.toUpperCase() === selectedCity.toUpperCase()
  ) {
    payload.availableNeighbourhoodList = cityWithArr.areas;
    payload.availableAreaList = [];
    if (payload.neighbourhoodFromResponse) {
      // removing word Bezirk
      payload.neighbourhoodFromResponse = payload.neighbourhoodFromResponse.replace(
        "Bezirk ",
        ""
      );
      // neighbourhood arrives paired with secondary neighbourhood so we need split it at '-'
      const neighbourhoodInList = payload.availableNeighbourhoodList.find(
        neighbourhood =>
          neighbourhood.code === payload.neighbourhoodFromResponse.split("-")[0]
      );
      if (neighbourhoodInList) {
        payload.roomObj.area = neighbourhoodInList;
      }
    }
  } else {
    throw new Error(errorMsg);
  }
  return {
    ...payload,
    city: selectedCity,
  };
}

export const checkBeforeSubmit = (room, hasAreaCode) => {
  let housematesCount = 0;
  let fields = {};
  let hasAnyError = false;
  let age_min = null;
  let age_max = null;
  Object.entries(room).forEach(entry => {
    let key = entry[0];
    let value = entry[1];

    if (key === "area") {
      if (!hasAreaCode && !value) {
        hasAnyError = true;
        fields = {
          ...fields,
          [key]: "This field is required",
        };
      }
    } else {
      fields = {
        ...fields,
        [key]: null,
      };
    }
    if (key === "area_code") {
      if (hasAreaCode && !value) {
        hasAnyError = true;
        fields = {
          ...fields,
          [key]: "This field is required",
        };
      } else {
        fields = {
          ...fields,
          [key]: null,
        };
      }
    }
    if (key === "roommate_male_number" || key === "roommate_female_number") {
      if (Number.isInteger(value)) {
        housematesCount += value;
      }
    }
    if (key === "age_min") {
      age_min = value;
    }
    if (key === "age_max") {
      age_max = value;
    }

    if (key === "pictures") {
      if (value.length === 0) {
        hasAnyError = true;
        fields = {
          ...fields,
          [key]: "At least 1 image is required",
        };
      } else {
        fields = {
          ...fields,
          [key]: null,
        };
      }
    }
    if (key === "bills_included") {
      if (value === null) {
        hasAnyError = true;
        fields = {
          ...fields,
          [key]: "This field is required",
        };
      } else {
        fields = {
          ...fields,
          [key]: null,
        };
      }
    }
    if (
      key === "address" ||
      key === "price" ||
      key === "move_in_date" ||
      key === "type" ||
      key === "ensuite" ||
      key === "price" ||
      key === "slogan" ||
      key === "description" ||
      key === "slogan"
    ) {
      if (!value) {
        hasAnyError = true;
        fields = {
          ...fields,
          [key]: "This field is required",
        };
      } else {
        fields = {
          ...fields,
          [key]: null,
        };
      }
    }
  });
  if (age_min && age_max) {
    if (age_min > age_max) {
      hasAnyError = true;
      fields = {
        ...fields,
        age_min: "Age field 'To' value cannot be less than 'From'.",
      };
    } else {
      fields = {
        ...fields,
        age_min: null,
      };
    }
  }
  if (
    room.roommate_male_number !== undefined &&
    room.roommate_female_number !== undefined
  ) {
    if (housematesCount === 0) {
      hasAnyError = true;
      fields = {
        ...fields,
        roommate_male_number: "Sum of people living in house cannot be 0.",
      };
    } else {
      fields = {
        ...fields,
        roommate_male_number: null,
      };
    }
  }

  return { hasAnyError: hasAnyError, fields: fields };
};
