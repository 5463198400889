import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Form, Divider, Modal, Button } from "semantic-ui-react";
import moment from "moment";

import {
  editRoom,
  uploadPicture,
  emptyRoom,
  fetchRoomDetail,
  setPublicProfile,
  deleteRoomPicture,
  favouriteRoomPicture,
  setRoomFieldsWithError,
} from "../../actions";

import { parseError } from "../../utils/helpers";
import PopUp from "../../utils/pop_up";
import { checkBeforeSubmit } from "../../utils/roomUtils";

import Location from "./parts/Location";
import RoomDetails from "./parts/RoomDetails";
import RoomDetailsMore from "./parts/RoomDetailsMore";
import Preferences from "./parts/Preferences";
import Loader from "./parts/Loader";
import UploadImages from "./parts/UploadImages";
import { noSloganOption } from "../../assets/formOptions";

class EditRoom extends Component {
  static propTypes = {
    room: PropTypes.object.isRequired,
    myProfile: PropTypes.object.isRequired,
    fetchRoomDetail: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    file: PropTypes.object.isRequired,
    emptyRoom: PropTypes.func.isRequired,
    uploadPicture: PropTypes.func.isRequired,
    deleteRoomPicture: PropTypes.func.isRequired,
    editRoom: PropTypes.func.isRequired,
    favouriteRoomPicture: PropTypes.func.isRequired,
    setPublicProfile: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isSearching: PropTypes.bool.isRequired,
    setRoomFieldsWithError: PropTypes.func.isRequired,
    fieldsWithError: PropTypes.array,
    cities: PropTypes.array.isRequired,
    selectedCity: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      roomObj: {
        // neighbourhood
        area: "",
        // area
        area_code: "",
        city: "",
        address: "",
        price: "",
        deposit: "",
        type: "",
        ensuite: "",
        move_in_date: new Date(),
        move_out_date: null,
        minimum_stay: "",
        description: "",
        bills_included: null,
        slogan: noSloganOption,
        age_min: "",
        age_max: "",
        pictures: [],
        roommate_male_number: 0,
        roommate_female_number: 0,
        preferred_gender: "both",
        preferred_occupation: "both",
        preferred_relationship: "both",
      },
      coordinates: null,
      availableNeighbourhoodList: [],
      availableAreaList: [],
      isModalOpen: false,
      message: "",
    };
    // creating ref for scroll on error
    this.addressRef = React.createRef();
    this.areaRef = React.createRef();
    this.area_codeRef = React.createRef();

    this.roommate_male_numberRef = React.createRef();
    this.priceRef = React.createRef();
    this.typeRef = React.createRef();
    this.ensuiteRef = React.createRef();
    this.bills_includedRef = React.createRef();

    this.descriptionRef = React.createRef();
    this.sloganRef = React.createRef();
    this.age_minRef = React.createRef();
    this.picturesRef = React.createRef();

    this.props.fetchRoomDetail(this.props.match.params.id);
  }
  componentDidMount() {
    if (this.props.myProfile.profileIsOnSync) {
      this.setState({ isLoading: true });
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.myProfile.profileIsOnSync !==
        prevProps.myProfile.profileIsOnSync ||
      this.props.room.roomIsOnSync !== prevProps.room.roomIsOnSync
    ) {
      if (
        this.props.myProfile.profileIsOnSync ||
        this.props.room.roomIsOnSync
      ) {
        this.setState({ isLoading: true });
      } else {
        //component loaded with needed props

        this.setState({ isLoading: false });
        this.setInitialAvailableAreaNeighbourhood();
      }
    }
    if (!_.isEqual(this.props.file, prevProps.file)) {
      let pictures = this.state.roomObj.pictures;
      pictures = [
        ...pictures,
        { url: this.props.file.file_upload, favourite: false },
      ];
      this.setRoomSingleValue("pictures", pictures);
    }
    if (
      this.props.room.data.id &&
      !_.isEqual(this.props.room.data, prevProps.room.data)
    ) {
      this.setRoomSeveralValues({ roomObj: this.props.room.data });
    }
    if (
      !_.isEqual(this.props.room.data, prevProps.room.data) &&
      this.props.room.data.id &&
      this.state.roomObj.id
    ) {
      if (_.isEmpty(this.props.room.error)) {
        this.setState({
          message: "Room updated successfully! Where next?",
          isModalOpen: true,
        });
      } else {
        const message = parseError(this.props.room.error, "updated");
        this.setState({ message, isModalOpen: true });
      }
    }
  }

  componentWillUnmount() {
    this.props.emptyRoom();
  }
  setInitialAvailableAreaNeighbourhood = () => {
    const currentCityId = this.props.room.data.city.id;
    if (this.props.cities.length > 0) {
      const newCity = this.props.cities.find(
        cityInArr => cityInArr.id === currentCityId
      );
      this.setRoomSeveralValues({
        coordinates: null,
        availableAreaList: newCity.area_codes,
        availableNeighbourhoodList: newCity.areas,
      });
    }
  };
  setRoomSingleValue = (name, value) => {
    this.setState(prevState => ({
      roomObj: { ...prevState.roomObj, [name]: value },
    }));
  };
  setRoomSeveralValues = newValues => {
    this.setState(prevState => ({
      ...prevState,
      ...newValues,
      roomObj: {
        ...prevState.roomObj,
        ...newValues.roomObj,
      },
    }));
  };

  uploadPicture = file => {
    this.props.uploadPicture(file, "400x300");
  };

  deletePicture = picture => {
    this.props.deleteRoomPicture(picture, this.state.roomObj.slug);
  };

  editRoom = () => {
    const hasAreaCode = this.state.availableAreaList.length > 0 ? true : false;

    const formErrors = checkBeforeSubmit(this.state.roomObj, hasAreaCode);

    let room = this.state.roomObj;
    if (room.area) {
      room.area_id = room.area.id;
    }
    room.area_code_id = room.area_code;
    // room.area_code_id = room.area_code.id ? room.area_code.id : room.area_code;
    if (formErrors.hasAnyError === false) {
      if (!this.props.myProfile.data.is_searchable) {
        this.setState({
          message:
            "Sorry, you cannot edit this room because your profile is currently set to private. Do you want to activate your public profile and edit the room ?",
          isModalOpen: true,
        });
      } else {
        this.props.editRoom(room);
      }
    } else {
      this.props
        .setRoomFieldsWithError(formErrors.fields)
        .then(() => this.showSubmitError());
    }
  };

  scrollToFirstError = firstErrorRef => {
    // console.log(firstErrorRef);
    if (firstErrorRef) {
      window.scrollTo(0, this[firstErrorRef].current.offsetTop - 100);
    }
  };

  showSubmitError = () => {
    // let errorsCount = 0;
    let firstErrorRef = null;
    Object.entries(this.props.fieldsWithError).forEach(entry => {
      let key = entry[0];
      let value = entry[1];
      if (value) {
        // errorsCount++;
        if (!firstErrorRef && this[key + "Ref"]) {
          if (this[key + "Ref"].current) {
            firstErrorRef = key + "Ref";
          }
        }
      }
    });

    this.scrollToFirstError(firstErrorRef);
  };
  favouritePicture = picture => {
    this.props.favouriteRoomPicture(picture, this.state.roomObj.slug);
  };

  updateAndListRoom = () => {
    this.props.setPublicProfile(true);
    let room = this.state.roomObj;
    room.area_code_id = room.area_code.id;
    this.props.editRoom(room);
  };

  openModal = () => {
    this.setState({ isModalOpen: false });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  goToList = () => {
    this.props.history.push("/my_rooms");
  };

  handleDateChange = (name, value) => {
    const moveOutDate = this.state.roomObj.move_out_date;
    const moveInDate = this.state.roomObj.move_in_date;
    if (name === "move_in_date") {
      if (moveOutDate) {
        const isAfter = moment(value).isAfter(moveOutDate);
        if (isAfter) {
          this.setRoomSingleValue("move_in_date", moveOutDate);
          this.setRoomSingleValue("move_out_date", value);
        } else {
          this.setRoomSingleValue("move_in_date", value);
        }
      } else {
        this.setRoomSingleValue("move_in_date", value);
      }
    } else if (name === "move_out_date") {
      if (moveInDate) {
        const isAfter = moment(moveInDate).isAfter(value);
        if (isAfter) {
          this.setRoomSingleValue("move_out_date", moveInDate);
          this.setRoomSingleValue("move_in_date", value);
        } else {
          this.setRoomSingleValue("move_out_date", value);
        }
      } else {
        this.setRoomSingleValue("move_out_date", value);
      }
    } else {
      throw new Error("Date fields names are incorrect.");
    }
  };
  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }
    if (
      this.state.roomObj.user_id &&
      this.props.myProfile.user_id &&
      this.state.roomObj.user_id !== this.props.myProfile.user_id
    ) {
      return (
        <Modal
          dimmer="inverted"
          size="tiny"
          onOpen={() => this.openModal()}
          open={true}
          onClose={() => this.closeModal()}
          closeOnDimmerClick={false}
          style={{
            marginTop: window.innerWidth < 767 ? "15%" : "20%",
            marginLeft: window.innerWidth < 767 ? "2%" : "35%",
          }}
        >
          <Modal.Content style={{ textAlign: "center" }}>
            <p
              style={{
                color: "#FF664D",
                fontFamily: "MYRIAD",
                fontSize: "25px",
              }}
            >
              You are not allowed to edit this room!
            </p>
            <Button
              style={{
                color: "#fff",
                width: "180px",
                borderRadius: "5px",
                backgroundColor: "#00D5CC",
              }}
              size="large"
              onClick={() => this.props.history.push("/")}
            >
              Close
            </Button>
          </Modal.Content>
        </Modal>
      );
    }

    const currentRoomObj = this.state.roomObj;
    return (
      <div className="site-container">
        <Form
          onSubmit={event => event.preventDefault()}
          style={{ marginBottom: 10 }}
        >
          <Location
            formType="edit"
            cities={this.props.cities}
            setRoomSeveralValues={this.setRoomSeveralValues}
            setRoomFieldsWithError={this.props.setRoomFieldsWithError}
            addressRef={this.addressRef}
            areaRef={this.areaRef}
            area_codeRef={this.area_codeRef}
            room={currentRoomObj}
            coordinates={currentRoomObj.coordinates}
            setListingRoomValue={this.setRoomSingleValue}
            fieldsWithError={this.props.room.currentRoom.fieldsWithError}
            openNextSection={this.openNextSection}
            availableNeighbourhoodList={this.state.availableNeighbourhoodList}
            availableAreaList={this.state.availableAreaList}
          />
          <div className="pt-25" />
          <RoomDetails
            roomDetailsRef={this.roomDetailsRef}
            priceRef={this.priceRef}
            ensuiteRef={this.ensuiteRef}
            typeRef={this.typeRef}
            roommate_male_numberRef={this.roommate_male_numberRef}
            bills_includedRef={this.bills_includedRef}
            handleDateChange={this.handleDateChange}
            setListingRoomValue={this.setRoomSingleValue}
            room={currentRoomObj}
            fieldsWithError={this.props.room.currentRoom.fieldsWithError}
          />

          <div className="pt-25" />
          <div className="mx-20">
            <h2 style={{ marginTop: "15px", color: "#707070" }}>
              More Room Details
            </h2>
            <Divider
              style={{
                borderWidth: "3px",
                borderRadius: "15px",
                borderColor: "#FF664D",
              }}
            />
            {/* <span className="title-16">More Room Details</span> */}
          </div>
          <div className="desktop-row">
            <div className="flex-1 room-list-flex-property">
              <RoomDetailsMore
                setListingRoomValue={this.setRoomSingleValue}
                room={currentRoomObj}
                fieldsWithError={this.props.room.currentRoom.fieldsWithError}
                descriptionRef={this.descriptionRef}
                sloganRef={this.sloganRef}
              />
            </div>
            <div className="flex-1 room-list-flex-property">
              <UploadImages
                picturesRef={this.picturesRef}
                pictures={currentRoomObj.pictures}
                uploadPicture={this.uploadPicture}
                errorMessageUpload={this.state.errorMessageUpload}
                isSearching={this.props.isSearching}
                deletePicture={this.deletePicture}
                favouritePicture={this.favouritePicture}
                fieldsWithError={this.props.room.currentRoom.fieldsWithError}
                setRoomFieldsWithError={this.props.setRoomFieldsWithError}
              />
            </div>
          </div>

          <div className="pt-25" />
          <Preferences
            preferencesRef={this.preferencesRef}
            age_minRef={this.age_minRef}
            setAge={this.setAge}
            setListingRoomValue={this.setRoomSingleValue}
            room={currentRoomObj}
            fieldsWithError={this.props.room.currentRoom.fieldsWithError}
          />

          <div className="pt-25" />

          <PopUp
            title="Save Changes"
            message={this.state.message}
            submit={this.editRoom}
            openModal={this.openModal}
            closeModal={this.closeModal}
            isModalOpen={this.state.isModalOpen}
            goToList={
              this.state.message === "Room updated successfully! Where next?" &&
              this.goToList
            }
            updateAndList={
              !this.props.myProfile.data.is_searchable &&
              !_.isEmpty(this.state.roomObj.pictures)
            }
            updateAndListRoom={this.updateAndListRoom}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = ({
  myProfile,
  room,
  file,
  areas,
  areaCodes,
  cities,
  isSearching,
}) => ({
  myProfile,
  room,
  file,
  areas,
  areaCodes,
  isSearching,

  fieldsWithError: room.currentRoom.fieldsWithError,
  cities: cities.cities,
  selectedCity: cities.selectedCity,
});

const actions = {
  editRoom,
  uploadPicture,
  emptyRoom,
  fetchRoomDetail,
  favouriteRoomPicture,
  setPublicProfile,
  deleteRoomPicture,
  setRoomFieldsWithError,
};

export default connect(
  mapStateToProps,
  actions
)(EditRoom);
