import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Grid, Button, Message } from "semantic-ui-react";

const ResetPasswordView = ({
  handleFieldChange,
  session,
  resetPassword,
  message,
}) => {
  return (
    <div className="reset-password-wrapper">
      <Form onSubmit={event => event.preventDefault()}>
        <Grid centered container padded="vertically">
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <h1
                style={{
                  color: "#FF664D",
                  fontFamily: "MYRIAD",
                  marginLeft: "1%",
                }}
              >
                Reset Password
              </h1>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <Input
                onChange={event =>
                  handleFieldChange("email", event.target.value)
                }
                type="text"
                placeholder="Email address"
                value={session.email}
                icon="mail outline"
                iconPosition="left"
                fluid
                disabled
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <Input
                onChange={event =>
                  handleFieldChange("password", event.target.value)
                }
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                value={session.password}
                icon="key"
                iconPosition="left"
                fluid
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <Input
                onChange={event =>
                  handleFieldChange("password_confirm", event.target.value)
                }
                type="password"
                name="password confirm"
                id="password_confirm"
                placeholder="Confirm Password"
                value={session.password_confirm}
                icon="key"
                iconPosition="left"
                fluid
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered>
            <Grid.Column computer={5} mobile={16} verticalAlign="middle">
              <Button
                id="button"
                fluid
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#00D5CC",
                  color: "#fff",
                }}
                onClick={resetPassword}
              >
                RESET PASSWORD
              </Button>
              {message && message === "Password updated." && (
                <Message positive>
                  <Message.Content>{message}</Message.Content>
                  <Link to="/email_login">Login</Link>
                </Message>
              )}
              {message && message !== "Password updated." && (
                <Message negative>
                  <Message.Content>{message}</Message.Content>
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
};

export default ResetPasswordView;
