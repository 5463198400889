import React from "react";
import HomeView from "./home_view";
import PropTypes from "prop-types";
import RoomList from "../../components/Room/room_list";
import ProfileList from "../../components/Profile/profile_list";

import { Component } from "react";
import { connect } from "react-redux";
import { MainBanner } from "./MainBanner";
import { citySelect } from "../../actions";
import { FloatingCTA } from "./floating_cta.js";
import { Redirect } from "react-router-dom";
import { findCityById, createCityRedirect } from "../../utils/city_select";

export class Home extends Component {
  constructor(props) {
    super(props);
    const { selectedCity, match } = props;
    const cityRedirect = createCityRedirect(selectedCity, match);
    this.state = {
      cityRedirect,
    };
  }

  onCitySelect = event => {
    const { cities, match, citySelect } = this.props;
    const {
      target: { value: cityId },
    } = event;
    const matchedCity = findCityById(cities, cityId);
    if (matchedCity) {
      citySelect(matchedCity);
    } else {
      citySelect(null);
    }
    this.setState({
      cityRedirect: createCityRedirect(matchedCity, match) || "/",
    });
  };

  render() {
    const { cityRedirect } = this.state;
    const { selectedCity, citiesIsOnSync, cities } = this.props;

    if (cityRedirect) {
      return <Redirect to={cityRedirect} />;
    }

    return (
      <div>
        <MainBanner
          cities={cities}
          city={selectedCity}
          citiesIsOnSync={citiesIsOnSync}
          onCitySelect={this.onCitySelect}
        />
        <div className="site-container">
          <RoomList city={selectedCity} />
          <ProfileList city={selectedCity} />
          <HomeView />
          <FloatingCTA />
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  selectedCity: PropTypes.object,
  match: PropTypes.object.isRequired,
  cities: PropTypes.array.isRequired,
  citySelect: PropTypes.func.isRequired,
  citiesIsOnSync: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ cities }) => ({
  cities: cities.cities,
  selectedCity: cities.selectedCity,
  citiesIsOnSync: cities.citiesIsOnSync,
});

const actions = {
  citySelect,
};

export default connect(
  mapStateToProps,
  actions
)(Home);
