import React, { Component, Fragment } from 'react';
import './App.scss';
import Intercom from 'react-intercom';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Footer from './layouts/footer';
/* Layouts */
import AppLayout from './layouts/app_layout';
import './semantic/dist/semantic.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/* Session */
import Login from './components/Session/login';
import Register from './components/Session/register';
import EmailLogin from './components/Session/email_login';
import ResetPassword from './components/Session/reset_password';
import Unsubscribe from './components/Session/unsubscribe';

/* Profile */
import Profile from './components/Profile/profile';
import ProfileDetail from './components/Profile/profile_detail';
import MyRooms from './components/Profile/my_rooms';

/* Room */
import CreateRoom from './components/Room/create_room';
import EditRoom from './components/Room/edit_room';
import RoomDetail from './components/Room/room_detail';

/* Search */
import WaitableSearchRoom from './components/Room/Search/waitable_search_room';
import WaitableSearchProfile from './components/Profile/Search/waitable_search_profile';

/* Request */
import RequestList from './components/Profile/Request/request_list';

/* Booking */
import BookingRequest from './components/Booking/booking_request';

import Home from './pages/home/home';

import store from './store';
import cookie from 'react-cookies';

import TermsAndConditions from './components/terms_and_conditions';
import Privacy from './components/privacy';
import AboutUs from './components/about_us';
import ContactUs from './components/contact_us';

import Raven from 'raven-js';
import MatchedHome from './pages/home/matched_home';

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production') {
  Raven.config(
    'https://fa409efbf5fb48608192a71059ad196c@sentry.io/1208475'
  ).install();
}

function isUserAuthenticated() {
  const jwt = cookie.load('jwt');
  return jwt ? true : false;
}

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isUserAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  componentDidMount() {
    if ('ontouchstart' in document.body) {
      document.body.style.cursor = 'pointer';
    }
  }

  render() {
    return (
      <Fragment>
        <Intercom appID="phvb13hc" />
        <Provider store={store}>
          <Router>
            <div className="root-container">
              <AppLayout />
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/email_login" component={EmailLogin} />
                <Route path="/register" component={Register} />
                <Route path="/unsubscribe" component={Unsubscribe} />
                <Route path="/reset_password" component={ResetPassword} />
                <Route path="/search_room" component={WaitableSearchRoom} />
                <Route path="/terms" component={TermsAndConditions} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/about_us" component={AboutUs} />
                <Route path="/contact_us" component={ContactUs} />
                <Route
                  path="/search_profile"
                  component={WaitableSearchProfile}
                />
                <Route path="/profile/:id" component={ProfileDetail} />
                <Route exact path="/room/:id" component={RoomDetail} />
                <ProtectedRoute exact path="/profile" component={Profile} />
                <ProtectedRoute exact path="/my_rooms" component={MyRooms} />
                <ProtectedRoute path="/rooms/new" component={CreateRoom} />
                <ProtectedRoute path="/room/:id/edit" component={EditRoom} />
                <ProtectedRoute path="/requests" component={RequestList} />
                <ProtectedRoute
                  exact
                  path="/booking/request/:cityId"
                  component={BookingRequest}
                />
                <Route exact path="/:city" component={MatchedHome} />
                <Route exact path="/" component={Home} />
              </Switch>
              {window.location.pathname !== '/requests' && (
                <div className="footer-flex-container">
                  <Footer
                    style={{
                      minHeight: '75px',
                    }}
                  />
                </div>
              )}
            </div>
          </Router>
        </Provider>
      </Fragment>
    );
  }
}

export default App;
