/* global window */
import React from "react";
import { Grid, Button, Input, Image, Message } from "semantic-ui-react";

const FacebookLoginView = ({ session, handleFieldChange, register, error }) => {
  return (
    <div style={{ paddingTop: window.innerHeight * 0.15 }}>
      <Grid container centered>
        <Grid.Row verticalAlign="middle">
          <Grid.Column computer={2} mobile={8}>
            <Image
              centered
              circular
              style={{ margin: 15, height: 100 }}
              src={session.picture_url}
            />
          </Grid.Column>

          <Grid.Column computer={10} mobile={16}>
            <Grid.Row centered>
              <Grid.Column computer={10} mobile={16}>
                <h1
                  style={{
                    color: "#FF664D",
                    fontFamily: "MYRIAD",
                    marginLeft: "1%",
                    marginBottom: 10,
                  }}
                >
                  Login with Facebook
                </h1>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginBottom: 10 }}>
              <Grid.Column computer={10} mobile={16}>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={session.name}
                  icon="user"
                  iconPosition="left"
                  fluid
                  disabled
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginBottom: 10 }}>
              <Grid.Column computer={10} mobile={16}>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  onChange={event =>
                    handleFieldChange("email", event.target.value)
                  }
                  placeholder="Email address"
                  value={session.email}
                  icon="mail outline"
                  iconPosition="left"
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Message style={{ marginTop: 10, marginBottom: 10 }} negative>
                {error
                  ? error
                  : "You need an email address to sign up, but none was found. Please enter an email address to complete your registration."}
              </Message>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column computer={2} mobile={8} />
          <Grid.Column computer={5} mobile={16} verticalAlign="middle">
            <Button
              fluid
              id="button"
              style={{
                borderRadius: "5px",
                backgroundColor: "#00D5CC",
                color: "#fff",
              }}
              onClick={() => register()}
            >
              SIGN UP
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default FacebookLoginView;
