import { axiosInstance } from './configured_axios';
import {
  FETCH_ROOM_LIST,
  FETCH_MY_ROOM_LIST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  FETCH_PROFILE_LIST,
  CREATE_PROFILE_SEARCH,
  FETCH_USER_PROFILE_SEARCH_PARAMS,
  FIND_PROFILES,
  FETCH_PROFILE_DETAIL,
  EMPTY_PROFILE,
  IS_SEARCHING,
  EMPTY_MY_PROFILE,
  EMPTY_PROFILE_LIST,
  PROFILE_SEARCH_PAGE,
  FETCH_HOME_PROFILE_LIST,
} from './action_types';

export function updateUserProfile(userId, profileInfo, interests, preferences) {
  return dispatch =>
    axiosInstance
      .put(`/api/users/${userId}/profile`, {
        profile: profileInfo,
        interests,
        preferences,
      })
      .then(response => {
        dispatch({ type: UPDATE_USER_PROFILE_SUCCESS, payload: response });
      })
      .catch(error => {
        dispatch({ type: UPDATE_USER_PROFILE_ERROR, payload: error });
      });
}

export function setPublicProfile(is_searchable) {
  return dispatch =>
    axiosInstance
      .post(`/api/public_profile`, { is_searchable })
      .then(response =>
        dispatch({ type: UPDATE_USER_PROFILE_SUCCESS, payload: response })
      )
      .catch(error =>
        dispatch({ type: UPDATE_USER_PROFILE_ERROR, payload: error })
      );
}

export function fetchHomeProfileList(page = 1, page_size = 4) {
  const request = axiosInstance.get('/api/profiles', {
    params: { page, page_size },
  });

  return {
    type: FETCH_HOME_PROFILE_LIST,
    payload: request,
  };
}

export function fetchHomeProfileListByCity(
  city,
  page = 1,
  includePrevious = false
) {
  return dispatch => {
    axiosInstance
      .post('/api/find_profiles', {
        search_params: {
          show_me: 'No preference',
          preferred_relationship: 'both',
          preferred_occupation: 'both',
          preferred_gender: 'both',
          preferred_city: city,
          id: 668,
          age_min: 18,
          age_max: 99,
        },
        include_previous: includePrevious,
        page,
      })
      .then(response => {
        dispatch({ type: FETCH_HOME_PROFILE_LIST, payload: response });
      });
  };
}

export function fetchProfileList(page = 1) {
  const request = axiosInstance.get('/api/profiles', { params: { page } });

  return {
    type: FETCH_PROFILE_LIST,
    payload: request,
  };
}

export function createProfileSearch(searchParams) {
  return dispatch => {
    axiosInstance
      .post(`/api/profile_search`, { profile_search: searchParams })
      .then(response => {
        dispatch({ type: CREATE_PROFILE_SEARCH, payload: response });
        dispatch(findProfiles(response.data.data));
      });
  };
}

export function fetchUserProfileSearchParams(userId) {
  return dispatch => {
    axiosInstance.get(`/api/users/${userId}/profile_search`).then(response => {
      dispatch({ type: FETCH_USER_PROFILE_SEARCH_PARAMS, payload: response });
      if (response.data.data) {
        dispatch(findProfiles(response.data.data));
      }
    });
  };
}

export function findProfiles(searchParams, page = 1, includePrevious = false) {
  return dispatch => {
    dispatch({ type: IS_SEARCHING, payload: true });
    axiosInstance
      .post('/api/find_profiles', {
        search_params: searchParams,
        include_previous: includePrevious,
        page,
      })
      .then(response => {
        dispatch({ type: FIND_PROFILES, payload: response });
        dispatch({ type: IS_SEARCHING, payload: false });
      });
  };
}

export function fetchProfileRoomList(user) {
  return dispatch => {
    axiosInstance
      .post(`/api/profile_rooms`, { user })
      .then(request =>
        dispatch({
          type: FETCH_ROOM_LIST,
          payload: request,
        })
      )
      .catch(err => console.log(err));
  };
}

export function fetchMyRoomList(user) {
  return dispatch => {
    axiosInstance
      .post(`/api/profile_rooms`, { user })
      .then(request =>
        dispatch({
          type: FETCH_MY_ROOM_LIST,
          payload: request,
        })
      )
      .catch(err => console.log(err));
  };
}

export function fetchProfileDetail(userId) {
  return dispatch =>
    axiosInstance.get(`/api/users/${userId}/profile`).then(response => {
      dispatch({ type: FETCH_PROFILE_DETAIL, payload: response });
    });
}

export function emptyProfile() {
  return {
    type: EMPTY_PROFILE,
    payload: {},
  };
}

export function emptyMyProfile() {
  return {
    type: EMPTY_MY_PROFILE,
    payload: {},
  };
}

export function emptyProfileList() {
  return {
    type: EMPTY_PROFILE_LIST,
    payload: [],
  };
}

export function unsubscribe() {
  const request = axiosInstance.post(`/api/unsubscribe`);

  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: request,
  };
}

export function setProfileSearchPage(pageNum) {
  return {
    type: PROFILE_SEARCH_PAGE,
    payload: pageNum,
  };
}
