import React from "react";
import {
  Grid,
  Label,
  Icon,
  Accordion,
  Message,
  Image,
} from "semantic-ui-react";
import _ from "lodash";
import "../fonts/extrafonts.css";

const Lifestyle = ({
  profile,
  interestList,
  addOrRemoveInterest,
  searchTermChange,
  filteredInterests,
  indexList,
  closeIndexList,
  openIndexList,
  errorMessage,
}) => {
  return (
    <Grid>
      <Grid.Row verticalAlign="middle" />
      <Grid.Row style={{ marginTop: 20 }}>
        <Grid.Column textAlign="left">
          <font color="#707070" size="14px" style={{ fontFamily: "MYRIAD" }}>
            <b>Lifestyle </b>
          </font>
          <font color="#A6A6A6" size="14px" style={{ fontFamily: "MYRIAD" }}>
            (select the lifestyle choices that best describe you)
          </font>
          {indexList === false ? (
            <Image
              style={{ cursor: "pointer", width: 20 }}
              src={require("../../assets/images/More.svg")}
              onClick={() => openIndexList()}
            />
          ) : (
            <Image
              style={{ cursor: "pointer", width: 20 }}
              src={require("../../assets/images/Less.svg")}
              onClick={() => closeIndexList()}
            />
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ padding: 10 }} textAlign="left" verticalAlign="middle">
        <Label.Group size="medium">
          {interestList.map(
            interest =>
              _.find(profile.interests, { id: interest.id }) && (
                <Label
                  basic
                  style={{ borderColor: "#FF664D" }}
                  key={interest.id}
                  onClick={() => addOrRemoveInterest(interest)}
                >
                  {interest.name} &ensp;
                  <Icon name="remove" style={{ cursor: "pointer" }} />
                </Label>
              )
          )}
        </Label.Group>
      </Grid.Row>
      {errorMessage && (
        <Grid.Row>
          <Grid.Column width={16}>
            <Message negative content={errorMessage} />
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row style={{ padding: 10 }}>
        <Accordion>
          <Accordion.Title active={indexList === true}>
            {(indexList || profile.is_new) && (
              <div>
                <font
                  color="#707070"
                  size="14px"
                  style={{ fontFamily: "MYRIAD" }}
                >
                  <b>Add Tags </b>
                </font>
                <Label.Group size="medium">
                  {filteredInterests.map(
                    interest =>
                      !_.find(profile.interests, { id: interest.id }) && (
                        <Label
                          as="a"
                          key={interest.id}
                          basic
                          style={{ borderColor: "#008080" }}
                          onClick={() => addOrRemoveInterest(interest)}
                        >
                          <Icon name="plus" style={{ color: "#00D5CC" }} />
                          {interest.name}
                        </Label>
                      )
                  )}
                </Label.Group>
              </div>
            )}
          </Accordion.Title>
        </Accordion>
      </Grid.Row>
    </Grid>
  );
};

export default Lifestyle;
