import React from "react";
import cookie from "react-cookies";
import Loader from "../../parts/Loader";
import SearchProfile from "./search_profile";
import WaitForState from "../../parts/WaitForState";

import { fetchUserProfileSearchParams } from "../../../actions";
import { connect } from "react-redux";

const WaitableSearchProfile = props => {
  if (!cookie.load("jwt")) {
    return <SearchProfile {...props} />;
  }
  const userId = props.myProfile.data.user_id;
  if (userId) {
    return (
      <WaitForState
        {...props}
        component={SearchProfile}
        initialActions={[fetchUserProfileSearchParams(userId)]}
        mapStateToProps={({ profileSearch }) =>
          !profileSearch._is_initial && profileSearch
        }
      />
    );
  } else {
    return <Loader />;
  }
};

const mapStateToProps = ({ myProfile }) => ({
  myProfile,
});

export default connect(mapStateToProps)(WaitableSearchProfile);
