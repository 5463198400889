import React from "react";
import PropTypes from "prop-types";
import { Image, Grid, Divider, Visibility } from "semantic-ui-react";
import Loader from "../../parts/Loader";
import { renderFavouritePicture } from "../../../utils/helpers";

import Room from "./room";

import filter_icon from "../../../assets/images/search.svg";

const SearchRoomResult = ({
  roomList,
  handleSearchStatus,
  searchStatus,
  renderNoResults,
  loadMore,
  loadingMore,
}) => {
  return (
    <div>
      {!searchStatus && (
        <Grid verticalAlign="middle">
          <Grid.Row
            columns={2}
            textAlign="left"
            style={{ marginTop: "20px", marginBottom: 30 }}
          >
            <Grid.Column>
              <h2>Rooms</h2>
              <Image
                verticalAlign="middle"
                src={filter_icon}
                style={{
                  width: "25px",
                  cursor: "pointer",
                  position: "absolute",
                  top: 5,
                  right: 15,
                  zIndex: 1,
                }}
                onClick={() => handleSearchStatus()}
              />
              <Divider
                style={{
                  borderWidth: "3px",
                  borderRadius: "15px",
                  borderColor: "#FF664D",
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              {roomList.length === 0 && renderNoResults()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      <Visibility
        offset={350}
        continuous={false}
        once={false}
        onBottomVisible={loadMore}
      >
        <Grid stackable columns={4} style={{ marginBottom: 70 }}>
          <Grid.Row>
            {roomList.map(room => {
              return (
                <Room
                  key={room.id}
                  room={room}
                  renderFavouritePicture={renderFavouritePicture}
                />
              );
            })}
          </Grid.Row>
          {loadingMore && (
            <Grid.Row centered>
              <Loader />
            </Grid.Row>
          )}
        </Grid>
      </Visibility>
    </div>
  );
};

SearchRoomResult.propTypes = {
  roomList: PropTypes.array,
  handleSearchStatus: PropTypes.func,
  searchStatus: PropTypes.bool,
  renderNoResults: PropTypes.func,
  loadMore: PropTypes.func,
};

export default SearchRoomResult;
