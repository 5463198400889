/* global window */
import React from 'react';
import {
  Button,
  Container,
  Grid,
  Image,
  Label,
  Message,
} from 'semantic-ui-react';
import { renderFavouritePicture } from '../../../utils/helpers';

const windowWidth = window.innerWidth;
const RequestTab = ({
  requests,
  type,
  acceptRequest,
  declineRequest,
  viewProfileDetail,
  profile,
}) => {
  return (
    <Container fluid>
      {requests.length ? (
        <Grid>
          {requests.map(request => (
            <Grid.Row key={request.id} verticalAlign="middle">
              <Grid.Column computer={3} mobile={5}>
                <Image
                  verticalAlign="middle"
                  style={{
                    width: windowWidth > 768 ? '100px' : '75px',
                    height: windowWidth > 768 ? '100px' : '75px',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    viewProfileDetail(
                      request.receiver
                        ? request.receiver.slug
                        : request.sender.slug
                    )
                  }
                  floated="left"
                  src={
                    request[type].pictures[0]
                      ? renderFavouritePicture(request[type].pictures)
                      : 'https://react.semantic-ui.com/assets/images/wireframe/image.png'
                  }
                />
              </Grid.Column>
              <Grid.Column computer={4} mobile={6}>
                <Grid.Row>
                  <Label
                    basic
                    style={{
                      color: '#707070',
                      border: '0px',
                      fontWeight: '900',
                      fontSize: '19px',
                      marginLeft: '-14%',
                    }}
                  >
                    {request[type].name}, &nbsp;
                    {request[type].age}
                  </Label>
                </Grid.Row>
                <Grid.Row stretched style={{ marginLeft: '-10%' }}>
                  {request[type].interests &&
                    request[type].interests
                      .slice(0, windowWidth > 768 ? 4 : 2)
                      .map(interest => (
                        <font
                          key={interest.id}
                          style={{
                            color: '#00D5CC',
                            marginRight: '2%',
                            fontSize: '15px',
                          }}
                        >
                          #{interest.name}
                        </font>
                      ))}
                </Grid.Row>
              </Grid.Column>
              {type === 'receiver' ? (
                <Grid.Column verticalAlign="top" computer={2} mobile={5}>
                  <Button basic>Pending</Button>
                </Grid.Column>
              ) : (
                <Grid.Column
                  computer={2}
                  mobile={5}
                  floated="left"
                  verticalAlign="middle"
                >
                  {windowWidth > 768 ? (
                    <Grid.Row>
                      <Grid.Column
                        floated="left"
                        verticalAlign="bottom"
                        style={{ marginBottom: '3%', marginLeft: '-100%' }}
                      >
                        <Label
                          size="large"
                          basic
                          onClick={() => acceptRequest(request)}
                          style={{
                            borderRadius: '6px',
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          Accept
                        </Label>
                        <Label
                          basic
                          size="large"
                          onClick={() => declineRequest(request)}
                          style={{
                            borderRadius: '6px',
                            witdh: '120',
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          Decline
                        </Label>
                      </Grid.Column>
                    </Grid.Row>
                  ) : (
                    <Grid.Row>
                      <Grid.Column>
                        <Grid.Row>
                          <Button
                            fluid
                            compact
                            basic
                            style={{ margin: 3 }}
                            onClick={() => acceptRequest(request)}
                          >
                            Accept
                          </Button>
                        </Grid.Row>
                        <Grid.Row>
                          <Button
                            fluid
                            compact
                            basic
                            style={{ margin: 3 }}
                            onClick={() => declineRequest(request)}
                          >
                            Decline
                          </Button>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Grid.Column>
              )}
            </Grid.Row>
          ))}
        </Grid>
      ) : (
        <Message info>No pending requests.</Message>
      )}
    </Container>
  );
};

export default RequestTab;
