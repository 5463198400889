import React, { Component } from "react";
import PropTypes from "prop-types";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { formatAddressObj } from "../../../utils/roomUtils";
export default class GooglePlaceAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef(); // Create a ref object
  }
  state = {
    searchAddress: "",
    searchAddressWithCity: "",
    searchOptions: {},
  };
  componentDidMount() {
    this.setSearchOptions();
  }
  componentDidUpdate(prevProps) {
    if (this.props.city !== prevProps.city) {
      this.setSearchOptions();
      this.setState({ searchAddress: "", searchAddressWithCity: "" });
    }
  }
  setSearchOptions = () => {
    // TODO CHANGE IT, WHEN API WILL SEND COORDINATES AND COUNTRY CODE
    let cityCenterCoordinates = [53.3498, -6.2603];
    let countryCode = "ie";
    if (this.props.city === "Berlin") {
      cityCenterCoordinates = [52.52, 13.405];
      countryCode = "de";
    }
    this.setState({
      searchOptions: {
        // eslint-disable-next-line no-undef
        location: new google.maps.LatLng([...cityCenterCoordinates]),
        radius: 100000,
        componentRestrictions: {
          country: countryCode,
        },
        types: ["address"],
      },
    });
  };
  onError = (status, clearSuggestions) => {
    this.props.setRoomFieldsWithError({ address: "Couldn’t find any results" });
    clearSuggestions();
  };

  handleAddressSearchInput = address => {
    this.setState({
      searchAddress: address,
      searchAddressWithCity: this.props.city + " " + address,
    });
  };

  handleAddressSearchSubmit = address => {
    this.setState({ searchAddress: address });
    this.searchRoomListingAddressAction(address);
  };

  searchRoomListingAddressAction = address => {
    const getAddress = geocodeByAddress(address);
    const coordinates = getAddress.then(response => {
      return getLatLng(response[0]);
    });
    Promise.all([getAddress, coordinates])
      .then(([addressObj, coordinates]) =>
        formatAddressObj(
          addressObj,
          this.props.city,
          coordinates,
          this.props.cityWithArr
        )
      )
      .then(data => {
        this.props.setRoomSeveralValues(data);
        this.props.setRoomFieldsWithError({ address: null });
      })
      .catch(e => {
        this.props.setRoomFieldsWithError({
          address: e.message,
        });
      });
  };
  render() {
    const { searchAddress, searchAddressWithCity } = this.state;
    return (
      <PlacesAutocomplete
        value={searchAddressWithCity}
        onChange={value => this.handleAddressSearchInput(value)}
        onSelect={value => this.handleAddressSearchSubmit(value)}
        shouldFetchSuggestions={searchAddress.length >= 2 ? true : false}
        debounce={500}
        onError={(status, clearSuggestions) =>
          this.onError(status, clearSuggestions)
        }
        searchOptions={this.state.searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ position: "relative" }}>
            <input
              {...getInputProps({
                placeholder: "House number, street...",
                className: "location-search-input",
              })}
              disabled={this.props.city ? false : true}
              ref={this.searchRef}
              value={searchAddress}
              onFocus={() => {
                window.scrollTo(0, this.searchRef.current.offsetTop - 100);
              }}
            />

            <div
              className={`autocomplete-dropdown-container ${
                suggestions.length === 0 ? "hidden-autocomplete-dropdown" : ""
              }`}
            >
              {loading ? (
                <div>Loading...</div>
              ) : (
                suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? "google-suggestion-item suggestion-item--active"
                    : "google-suggestion-item";
                  // if (suggestion.description.includes(this.props.city)) {
                  return (
                    <div
                      key={suggestion}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                  // }
                })
              )}
            </div>
            <span className="search-address-note">
              {`
              Don't worry, the exact location won't be visible. This is just to
              give seekers an idea of the area.
              `}
            </span>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

GooglePlaceAutocomplete.propTypes = {
  cityWithArr: PropTypes.object,
  setRoomSeveralValues: PropTypes.func.isRequired,
  setRoomFieldsWithError: PropTypes.func.isRequired,
  city: PropTypes.string,
};
