/* global window */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import _ from "lodash";
import { Button, Modal } from "semantic-ui-react";
import { setPublicProfile } from "../actions";

class RequestButton extends Component {
  state = { isModalOpen: false };

  editRoom = () => {
    this.props.history.push(`/room/${this.props.match.params.id}/edit`);
  };

  goToProfile = () => {
    this.props.history.push("/profile");
  };

  render() {
    const {
      request,
      inverseRequest,
      profile,
      sendRequest,
      linkToPreviousPage,
      acceptRequest,
      declineRequest,
      setConnection,
      myProfile,
    } = this.props;
    let button;
    if (profile && _.isEqual(myProfile.data.id, profile.id)) {
      button = (
        <Button
          onClick={() => this.editRoom()}
          style={{
            borderRadius: "15px",
            backgroundColor: "#00D5CC",
            color: "#fff",
          }}
          size="tiny"
        >
          EDIT ROOM
        </Button>
      );
    } else {
      if (!myProfile.data.id) {
        button = (
          <Link to="/login">
            <Button
              onClick={linkToPreviousPage}
              style={{
                borderRadius: "15px",
                backgroundColor: "#00D5CC",
                color: "#fff",
              }}
              size="tiny"
            >
              LOG IN TO SEND A REQUEST
            </Button>
          </Link>
        );
      } else if (!request && !inverseRequest) {
        button = (
          <Button
            style={{
              borderRadius: "15px",
              backgroundColor: "#00D5CC",
              color: "#fff",
            }}
            onClick={() => {
              myProfile.data.is_searchable && sendRequest(profile);
            }}
            size="tiny"
          >
            Send Chat Request
          </Button>
        );
      } else if (!request && inverseRequest) {
        if (inverseRequest.status === "Pending") {
          button = (
            <div>
              <Button
                size="tiny"
                color="green"
                style={{ borderRadius: "15px" }}
                onClick={() => {
                  myProfile.data.is_searchable &&
                    acceptRequest(inverseRequest.id);
                }}
              >
                ACCEPT
              </Button>
              <Button
                size="tiny"
                color="#FF664D"
                style={{ borderRadius: "15px" }}
                onClick={() => {
                  myProfile.data.is_searchable &&
                    declineRequest(inverseRequest.id);
                }}
              >
                DECLINE
              </Button>
            </div>
          );
        } else if (inverseRequest.status === "Declined") {
          button = (
            <Button
              onClick={() => {
                myProfile.data.is_searchable && sendRequest(profile);
              }}
              style={{
                borderRadius: "15px",
                backgroundColor: "#00D5CC",
                color: "#fff",
              }}
              size="tiny"
            >
              Send chat request
            </Button>
          );
        } else {
          button = (
            <Button
              onClick={() => {
                myProfile.data.is_searchable && setConnection(profile);
              }}
              style={{
                borderRadius: "15px",
                backgroundColor: "#00D5CC",
                color: "#fff",
              }}
              size="tiny"
            >
              MESSAGE
            </Button>
          );
        }
      } else if (request && !inverseRequest) {
        if (request.status === "Declined") {
          button = (
            <Button
              onClick={() => {
                myProfile.data.is_searchable && sendRequest(profile);
              }}
              style={{
                borderRadius: "15px",
                backgroundColor: "#00D5CC",
                color: "#fff",
              }}
              size="tiny"
            >
              Send chat request
            </Button>
          );
        } else {
          button = (
            <Button
              onClick={() => {
                request.status === "Accepted" &&
                  myProfile.data.is_searchable &&
                  setConnection(profile);
              }}
              style={{
                borderRadius: "15px",
                backgroundColor: "#00D5CC",
                color: "#fff",
              }}
              size="tiny"
            >
              {request.status === "Pending" ? "Request Sent..." : "MESSAGE"}
            </Button>
          );
        }
      } else if (request && inverseRequest && request.status === "Accepted") {
        button = (
          <Button
            onClick={() => {
              myProfile.data.is_searchable && setConnection(profile);
            }}
            style={{
              borderRadius: "15px",
              backgroundColor: "#00D5CC",
              color: "#fff",
            }}
            size="tiny"
          >
            MESSAGE
          </Button>
        );
      }
    }

    button = <div className="request-button">{button}</div>;

    if (!myProfile.data.is_searchable) {
      return (
        <Modal
          dimmer="inverted"
          size="tiny"
          onOpen={() => this.setState({ isModalOpen: true })}
          open={this.state.isModalOpen}
          onClose={() => this.setState({ isModalOpen: false })}
          closeOnDimmerClick={false}
          trigger={button}
          style={{
            marginTop: window.innerWidth < 767 ? "15%" : "20%",
            marginLeft: window.innerWidth < 767 ? "2%" : "35%",
          }}
        >
          <Modal.Content style={{ textAlign: "center" }}>
            <p
              style={{
                color: "#FF664D",
                fontFamily: "MYRIAD",
                fontSize: "25px",
              }}
            >
              Sorry, you need to complete your profile and make it visible on
              RooMigo to send a chat request
            </p>
            <Button
              style={{
                width: "180px",
                borderRadius: "5px",
                backgroundColor: "#00D5CC",
                color: "#fff",
              }}
              size="large"
              onClick={() => {
                this.goToProfile();
              }}
            >
              Go to My Profile
            </Button>
          </Modal.Content>
        </Modal>
      );
    } else {
      return button;
    }
  }
}

const mapStateToProps = ({ myProfile }) => ({ myProfile });

const actions = { setPublicProfile };
export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(RequestButton)
);
