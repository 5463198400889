import React from "react";
import PropTypes from "prop-types";
import { Container, Image, Grid, Divider, Label } from "semantic-ui-react";
import _ from "lodash";
import { renderFavouritePicture } from "../../utils/helpers";

import { Link } from "react-router-dom";
const filter_icon = require("../../assets/images/search.svg");

const RoomListView = ({ rooms }) => {
  return (
    <Container style={{ margin: 15 }}>
      <Grid stackable>
        <Grid.Row columns={2} textAlign="left" style={{ marginTop: 20 }}>
          <Grid.Column>
            <Link to="/search_room">
              <span
                style={{
                  marginRight: 10,
                  fontSize: 25,
                  fontWeight: "bold",
                  textDecoration: "none",
                  color: "#272727",
                  cursor: "pointer",
                  top: 5,
                  right: window.innerWidth > 767 ? 40 : 50,
                  zIndex: 1,
                }}
              >
                Rooms
              </span>
              <Image
                verticalAlign="middle"
                src={filter_icon}
                style={{
                  marginBottom: 10,
                  width: "25px",
                  cursor: "pointer",
                  top: window.innerWidth > 767 ? 2 : 6,
                  zIndex: 1,
                }}
              />
            </Link>
            <Divider
              style={{
                borderWidth: "3px",
                borderRadius: "15px",
                borderColor: "#ff664d",
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable columns={4}>
        <Grid.Row>
          {_.slice(rooms, 0, window.innerWidth > 674 ? 4 : 3).map(room => (
            <Grid.Column key={room.id} style={{ padding: 25 }} width={4}>
              <Grid.Row style={{ position: "relative" }}>
                <Label
                  size="huge"
                  as="a"
                  style={{
                    right: 0,
                    margin: 0,
                    zIndex: 1,
                    borderRadius: "0px 0px 0px 8px",
                    position: "absolute",
                    backgroundColor: "#00D5CC",
                    color: "#FFFFFF",
                    opacity: 0.8,
                  }}
                >
                  <span style={{ opacity: 1.5 }}>€{room.price}</span>
                </Label>
                <Link to={`/room/${room.slug}`}>
                  <Image
                    centered
                    style={{
                      marginBottom: "10%",
                      borderRadius: "8px 0px 8px 8px",
                      cursor: "pointer",
                      objectFit: "cover",
                      width: 210,
                      height: 157.5,
                    }}
                    src={
                      room.pictures[0]
                        ? renderFavouritePicture(room.pictures)
                        : "https://vignette.wikia.nocookie.net/pineapplepedia/images/3/3c/No-images-placeholder.png/revision/latest?cb=20171113170721"
                    }
                  />
                </Link>
              </Grid.Row>
              <Grid.Row>
                <Grid columns={2}>
                  <Grid.Column width={6}>
                    <Image
                      style={{
                        borderRadius: 50,
                        marginLeft: "15px",
                        marginTop: "8px",
                        width: 55,
                        height: 55,
                      }}
                      src={
                        room.renter.data.profile.data.pictures[0]
                          ? renderFavouritePicture(
                              room.renter.data.profile.data.pictures
                            )
                          : "https://react.semantic-ui.com/assets/images/wireframe/image.png"
                      }
                    />
                  </Grid.Column>
                  <Grid.Column
                    width={10}
                    verticalAlign="middle"
                    textAlign="left"
                  >
                    <p style={{ fontWeight: "bold", fontSize: 18 }}>
                      {room.area && `${room.area.name},`}{" "}
                      {room.area_code && `${room.area_code.code}`}
                      <span style={{ fontWeight: "300", fontSize: 15 }}>
                        <br />
                        {room.renter.data.profile.data.name},{" "}
                        {room.renter.data.profile.data.age}
                      </span>
                    </p>
                  </Grid.Column>
                </Grid>
                <Grid.Row>
                  <p
                    style={{
                      fontSize: 15,
                      fontStyle: "italic",
                      textAlign: "center",
                      marginTop: "3%",
                      color: "#FF664D",
                    }}
                  >
                    {room.renter.data.profile.data.interests
                      .slice(0, 7)
                      .map(interest => `#${interest.name} `)}
                  </p>
                </Grid.Row>
              </Grid.Row>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Container>
  );
};

RoomListView.propTypes = {
  rooms: PropTypes.array.isRequired,
};

export default RoomListView;
