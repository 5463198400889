import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const MainBanner = ({ onCitySelect, city, cities }) => {
  return (
    <div className="main-page-banner">
      <div className="background-image" />
      <div className="dark-banner-overlay" />
      <div className="banner-text-container">
        <div className="banner-text-container-inner">
          {city ? (
            <>
              <p className="main-title light">Rooms and roommates in</p>
              <p className="main-title">{city.name}</p>
              <div className="banner-nav-buttons">
                <Link to="/search_room">
                  <button className="banner-nav-btn">Find Rooms</button>
                </Link>
                <Link to="/search_profile">
                  <button className="banner-nav-btn green">
                    Find Roommates
                  </button>
                </Link>
              </div>
              <div className="banner-not-in-city">
                Not in {city.name}?&nbsp;
                <span
                  className="link link--light link--decorated"
                  onClick={onCitySelect}
                  value={null}
                >
                  Change City
                </span>
              </div>
            </>
          ) : (
            <>
              <p className="main-title">Roommates, Rooms, Team-Ups</p>
              <p>#LiveWithFriends</p>
              <div className="city-select">
                <p>Where would you like to search?</p>
                <div className="city-buttons-container">
                  {cities.map(city => (
                    <Link key={city.id} to={`/${city.name.toLowerCase()}`}>
                      <button
                        key={city.id}
                        className="city-btn"
                        value={city.id}
                        onClick={onCitySelect}
                      >
                        {city.name}
                      </button>
                    </Link>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

MainBanner.propTypes = {
  onCitySelect: PropTypes.func.isRequired,
  city: PropTypes.object,
  cities: PropTypes.array.isRequired,
};

export default MainBanner;
