import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import {
  fetchUserRoomList,
  deleteRoom,
  editRoom,
  setPublicProfile,
  emptyRoomList,
} from "../../actions/index";

import MyRoomsView from "./my_rooms_view";

class MyRooms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomList: this.props.roomList,
      isModalOpen: false,
      message: "",
      myProfile: this.props.myProfile.data,
    };
    this.props.fetchUserRoomList();
    this.showRoomDetail = this.showRoomDetail.bind(this);
    this.deleteRoom = this.deleteRoom.bind(this);
    this.updateRoom = this.updateRoom.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateAndActivateRoom = this.updateAndActivateRoom.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.roomList, nextProps.roomList)) {
      this.setState({ roomList: nextProps.roomList });
    }
    if (!_.isEqual(this.props.myProfile, nextProps.myProfile)) {
      this.setState({ myProfile: nextProps.myProfile.data });
    }
  }

  componentWillUnmount() {
    this.props.emptyRoomList();
  }

  showRoomDetail(slug) {
    this.props.history.push(`/room/${slug}`);
  }

  deleteRoom(slug) {
    this.props.deleteRoom(slug);
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  updateRoom(room) {
    if (!this.state.myProfile.is_searchable && room.deactivate) {
      this.setState({
        isModalOpen: true,
        message:
          "Sorry, you cannot reactivate this room because your profile is currently set to private. Do you want to activate your public profile and reactivate this room ?",
      });
    } else {
      let newRoom = room;
      newRoom.deactivate = !room.deactivate;
      this.props.editRoom(newRoom);
    }
  }

  updateAndActivateRoom(room) {
    let newRoom = room;
    newRoom.deactivate = !room.deactivate;
    this.props.setPublicProfile(true);
    this.props.editRoom(newRoom);
  }

  render() {
    const { roomList, isModalOpen, message } = this.state;
    return (
      <div className="site-container">
        <MyRoomsView
          rooms={roomList}
          showRoomDetail={this.showRoomDetail}
          deleteRoom={this.deleteRoom}
          updateRoom={this.updateRoom}
          isModalOpen={isModalOpen}
          openModal={this.openModal}
          closeModal={this.closeModal}
          message={message}
          updateAndActivateRoom={this.updateAndActivateRoom}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ room, roomList, myProfile }) => ({
  room,
  roomList,
  myProfile,
});

const actions = {
  fetchUserRoomList,
  deleteRoom,
  editRoom,
  setPublicProfile,
  emptyRoomList,
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(MyRooms)
);
