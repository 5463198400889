import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Image, Grid, Divider, Label, Visibility } from "semantic-ui-react";
import Loader from "../../parts/Loader";
import { renderFavouritePicture } from "../../../utils/helpers";

import filter_icon from "../../../assets/images/search.svg";

const SearchRoomResultMobile = ({
  roomList,
  handleSearchStatus,
  searchStatus,
  renderNoResults,
  loadMore,
  loadingMore,
}) => {
  return (
    <div>
      {searchStatus === false && (
        <Grid stackable verticalAlign="middle">
          <Grid.Row
            columns={1}
            textAlign="left"
            style={{ marginTop: "20px", marginLeft: 10, marginBottom: 30 }}
          >
            <Grid.Column>
              <h2>Rooms</h2>
              <Divider
                style={{
                  borderWidth: "3px",
                  borderRadius: "15px",
                  borderColor: "#FF664D",
                }}
              />
              {searchStatus === false && (
                <Image
                  verticalAlign="middle"
                  src={filter_icon}
                  style={{
                    width: "25px",
                    cursor: "pointer",
                    position: "absolute",
                    top: 6,
                    right: 25,
                    zIndex: 1,
                  }}
                  onClick={() => handleSearchStatus()}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              {roomList.length === 0 && renderNoResults()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      <Visibility
        offset={325}
        continuous={false}
        once={false}
        onBottomVisible={loadMore}
      >
        {roomList.map(room => (
          <div key={room.id} style={{ marginBottom: 20 }}>
            <Grid stackable columns={4}>
              <Grid.Column key={room.id} verticalAlign="middle">
                <Grid.Row verticalAlign="middle">
                  <Grid.Column width={11} verticalAlign="middle">
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        marginBottom: 5,
                      }}
                    >
                      {room.area && `${room.area.name}, `}
                      {room.area_code && room.area_code.code}
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        marginBottom: "5%",
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid>
            <Label
              size="huge"
              floated="right"
              as="a"
              color="teal"
              style={{
                zIndex: 1,
                right: "-3px",
                position: "absolute",
                borderRadius: "0px 0px 0px 8px",
                backgroundColor: "#9ED6C9",
                opacity: 0.8,
                color: "#FFFFFF",
              }}
            >
              €{room.price}
            </Label>
            <Link to={`/room/${room.slug}`}>
              <Image
                style={{
                  width: "100%",
                  marginBottom: "2%",
                  cursor: "pointer",
                }}
                src={
                  room.pictures[0]
                    ? renderFavouritePicture(room.pictures)
                    : "https://vignette.wikia.nocookie.net/pineapplepedia/images/3/3c/No-images-placeholder.png/revision/latest?cb=20171113170721"
                }
              />
            </Link>
            <Grid stackable>
              <Grid.Row>
                <Grid columns={2}>
                  <Grid.Column width={5}>
                    <Image
                      style={{
                        width: 80,
                        height: 80,
                        borderRadius: 50,
                        marginLeft: 20,
                      }}
                      src={
                        room.renter.data.profile.data.pictures[0]
                          ? renderFavouritePicture(
                              room.renter.data.profile.data.pictures
                            )
                          : "https://react.semantic-ui.com/assets/images/wireframe/image.png"
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={10} verticalAlign="middle">
                    <p
                      style={{
                        fontWeight: "300",
                        fontSize: 18,
                        marginLeft: "6%",
                      }}
                    >
                      {room.renter.data.profile.data.name},{" "}
                      {room.renter.data.profile.data.age}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontStyle: "italic",
                        textAlign: "left",
                        marginBottom: "5%",
                        marginTop: "-15px",
                        marginRight: 20,
                        color: "#FF664D",
                      }}
                    >
                      {room.renter.data.profile.data.interests
                        .slice(0, 7)
                        .map(interest => `#${interest.name} `)}
                    </p>
                  </Grid.Column>
                </Grid>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4} verticalAlign="middle" />
              </Grid.Row>
            </Grid>
          </div>
        ))}
      </Visibility>

      <Grid>
        {loadingMore && (
          <Grid.Row centered>
            <Loader />
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
};

SearchRoomResultMobile.propTypes = {
  roomList: PropTypes.array,
  handleSearchStatus: PropTypes.func,
  searchStatus: PropTypes.bool,
  renderNoResults: PropTypes.func,
  loadMore: PropTypes.func,
};

export default SearchRoomResultMobile;
