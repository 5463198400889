import React from "react";
import { Link } from "react-router-dom";
import {
  Label,
  Icon,
  Image,
  Grid,
  Divider,
  Container,
  Message,
  Visibility,
} from "semantic-ui-react";
import { renderFavouritePicture } from "../../../utils/helpers";
import Loader from "../../parts/Loader";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import personPlaceholder from "../../../assets/images/person-placeholder.jpg";

const filter_icon = require("../../../assets/images/search.svg");

const SearchProfileResult = ({
  profileList,
  emptyListMessage,
  sendRequest,
  handleSearchStatus,
  searchStatus,
  loadMore,
  loadingMore,
}) => {
  return (
    <div>
      <Container fluid style={{ marginBottom: 110 }}>
        {searchStatus === false && (
          <Grid verticalAlign="middle">
            <Grid.Row
              columns={2}
              textAlign="left"
              style={{ marginTop: "20px" }}
            >
              <Grid.Column>
                <h2>People</h2>
                <Image
                  verticalAlign="middle"
                  src={filter_icon}
                  style={{
                    width: "25px",
                    cursor: "pointer",
                    position: "absolute",
                    top: window.innerWidth > 767 ? 5 : 6,
                    right: window.innerWidth > 767 ? 15 : 25,
                    zIndex: 1,
                  }}
                  onClick={() => handleSearchStatus()}
                />
                <Divider
                  style={{
                    borderWidth: "3px",
                    borderRadius: "15px",
                    borderColor: "#FF664D",
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                {profileList.length === 0 && (
                  <Message info style={{ marginTop: 10 }}>
                    Sorry, there are no roomigos with these characteristics.
                    Please try again with different filters.
                  </Message>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <Visibility
          offset={350}
          continuous={false}
          once={false}
          onBottomVisible={loadMore}
        >
          <Grid stackable columns={4}>
            <Grid.Row style={{ marginTop: 20 }}>
              {profileList.map(profile => (
                <Grid.Column
                  key={profile.id}
                  style={{
                    marginBottom: 40,
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: " absolute",
                        top: 10,
                        left: 10,
                        zIndex: 1,
                      }}
                    >
                      {profile.room_count > 0 && (
                        <div>
                          <Label
                            size="huge"
                            floated="left"
                            as="a"
                            style={{
                              zIndex: 1,
                              position: "absolute",
                              backgroundColor: "rgb(255, 102, 77)",
                              color: "#fff",
                              opacity: 0.8,
                              width: 45,
                              borderRadius: 7,
                            }}
                          >
                            <Icon
                              name="home"
                              style={{
                                marginLeft: -6,
                                opacity: 1.5,
                                backgroundColor: "rgb(255, 102, 77)",
                              }}
                            />
                          </Label>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        zIndex: 1,
                      }}
                    >
                      {profile.team_with_others === true &&
                        profile.looking_for_a_room === false && (
                          <Label
                            size="huge"
                            floated="right"
                            as="a"
                            style={{
                              backgroundColor: "#00D5CC",
                              color: "#fff",
                              opacity: 0.8,
                              width: 45,
                              borderRadius: 7,
                            }}
                          >
                            <Icon
                              name="users"
                              style={{ marginLeft: -6, opacity: 1.5 }}
                            />
                          </Label>
                        )}
                      {profile.team_with_others === false &&
                        profile.looking_for_a_room === true && (
                          <Label
                            size="huge"
                            floated="right"
                            as="a"
                            style={{
                              backgroundColor: "#00D5CC",
                              color: "#fff",
                              opacity: 0.8,
                              width: 45,
                              borderRadius: 7,
                            }}
                          >
                            <Icon
                              name="binoculars"
                              style={{ marginLeft: -3, opacity: 1.5 }}
                            />
                          </Label>
                        )}
                      {profile.team_with_others === true &&
                        profile.looking_for_a_room === true && (
                          <div>
                            <Label
                              size="huge"
                              floated="right"
                              as="a"
                              style={{
                                backgroundColor: "#00D5CC",
                                color: "#fff",
                                opacity: 0.8,
                                width: 45,
                                borderRadius: 7,
                              }}
                            >
                              <Icon
                                name="users"
                                style={{ marginLeft: -6, opacity: 1.5 }}
                              />
                            </Label>
                            <Label
                              size="huge"
                              floated="right"
                              as="a"
                              style={{
                                right: "46px",
                                backgroundColor: "#00D5CC",
                                color: "#fff",
                                opacity: 0.8,
                                width: 45,
                                borderRadius: 7,
                              }}
                            >
                              <Icon
                                name="binoculars"
                                style={{ marginLeft: -3, opacity: 1.5 }}
                              />
                            </Label>
                          </div>
                        )}
                    </div>
                  </div>
                  <Link to={`/profile/${profile.slug}`}>
                    <Image
                      className="profilefoto"
                      style={{
                        borderRadius: "8px",
                        position: "relative",
                        cursor: "pointer",
                        marginBottom: 20,
                      }}
                      src={
                        profile.pictures[0]
                          ? renderFavouritePicture(profile.pictures)
                          : { personPlaceholder }
                      }
                    />
                  </Link>
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "#707070",
                      fontSize: 20,
                    }}
                  >
                    {profile.name && `${profile.name}, ${profile.age}`}
                  </p>
                  <Grid.Row>
                    <p
                      style={{
                        fontSize: 15,
                        fontStyle: "italic",
                        textAlign: "left",
                        marginTop: "3%",
                        color: "#FF664D",
                      }}
                    >
                      {profile.interests[0] ? (
                        profile.interests.map(interest => `#${interest.name} `)
                      ) : (
                        <label style={{ color: "grey" }}>No interest</label>
                      )}
                    </p>
                  </Grid.Row>
                </Grid.Column>
              ))}
            </Grid.Row>
            {loadingMore && (
              <Grid.Row centered>
                <Loader />
              </Grid.Row>
            )}
          </Grid>
        </Visibility>
      </Container>
    </div>
  );
};

export default SearchProfileResult;
