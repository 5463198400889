import React, { Component } from "react";
import { Icon, Button } from "semantic-ui-react";

export default class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = { file: "" };
    this.change = this.change.bind(this);
  }

  change(event) {
    let file = event.target.files[0];
    if (file) {
      this.props.upload(file);
      file = "";
    }
  }

  render() {
    return (
      <div>
        <input
          type="file"
          style={{ display: "none" }}
          ref={ref => (this.upload = ref)}
          onChange={this.change}
        />
        <Button
          basic
          style={{
            borderColor: "#FF664D",
            borderWidth: "1px",
            borderStyle: "solid",
          }}
          icon
          circular
          onClick={e => this.upload.click()}
        >
          <Icon
            disabled
            style={{ color: "#FF664D" }}
            name="plus"
            size="large"
          />
        </Button>
      </div>
    );
  }
}
