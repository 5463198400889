// Session
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const PREVIOUS_PAGE = 'PREVIOUS_PAGE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_CREDENTIAL_SUCCESS = 'UPDATE_CREDENTIAL_SUCCESS';
export const UPDATE_CREDENTIAL_ERROR = 'UPDATE_CREDENTIAL_ERROR';
export const ACCEPT_COOKIE = 'ACCEPT_COOKIE';

// Profile
export const SET_PROFILE_ON_SYNC_FLAG = 'SET_PROFILE_ON_SYNC_FLAG';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR';
export const FETCH_MY_PROFILE = 'FETCH_MY_PROFILE';
export const FETCH_INTERESTS = 'FETCH_INTERESTS';
export const FETCH_PROFILE_DETAIL = 'FETCH_PROFILE_DETAIL';
export const FETCH_PROFILE_LIST = 'FETCH_PROFILE_LIST';
export const EMPTY_PROFILE = 'EMPTY_PROFILE';
export const EMPTY_MY_PROFILE = 'EMPTY_MY_PROFILE';
export const PROFILE_SEARCH_PAGE = 'PROFILE_SEARCH_PAGE';
export const EMPTY_PROFILE_LIST = 'EMPTY_PROFILE_LIST';
export const FETCH_HOME_PROFILE_LIST = 'FETCH_HOME_PROFILE_LIST';

// Picture
export const UPLOAD_PICTURE = 'UPLOAD_PICTURE';
export const DELETE_ROOM_PICTURE = 'DELETE_ROOM_PICTURE';
export const DELETE_PROFILE_PICTURE = 'DELETE_PROFILE_PICTURE';
export const FAVOURITE_PROFILE_PICTURE = 'FAVOURITE_PROFILE_PICTURE';
export const FAVOURITE_ROOM_PICTURE = 'FAVOURITE_ROOM_PICTURE';

// Room
export const SET_CITIES_ON_SYNC_FLAG = 'SET_CITIES_ON_SYNC_FLAG';
export const SET_CITIES = 'SET_CITIES';

export const CITY_SELECT = 'CITY_SELECT';

export const SET_ROOM_ON_SYNC_FLAG = 'SET_ROOM_ON_SYNC_FLAG';
export const SET_ROOM_FIELDS_ERRORS = 'SET_ROOM_FIELDS_ERRORS';
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS';
export const CREATE_ROOM_ERROR = 'CREATE_ROOM_ERROR';
export const EDIT_ROOM_SUCCESS = 'EDIT_ROOM_SUCCESS';
export const EDIT_ROOM_ERROR = 'EDIT_ROOM_ERROR';
export const FETCH_ROOM_LIST = 'FETCH_ROOM_LIST';
export const FETCH_MY_ROOM_LIST = 'FETCH_MY_ROOM_LIST';
export const FETCH_HOME_ROOM_LIST = 'FETCH_HOME_ROOM_LIST';
export const FETCH_ROOM_DETAIL = 'FETCH_ROOM_DETAIL';
export const SET_ROOM_TO_BOOK = 'SET_ROOM_TO_BOOK';
export const EMPTY_ROOM = 'EMPTY_ROOM';
export const EMPTY_ROOM_LIST = 'EMPTY_ROOM_LIST';
export const DELETE_ROOM = 'DELETE_ROOM';
export const ROOM_SEARCH_PAGE = 'ROOM_SEARCH_PAGE';

// Search
export const CREATE_ROOM_SEARCH = 'CREATE_ROOM_SEARCH';
export const FIND_ROOMS = 'FIND_ROOMS';
export const FETCH_USER_SEARCH_PARAMS = 'FETCH_USER_SEARCH_PARAMS';
export const SET_USER_SEARCH_PARAMS_ON_SYNC_FLAG =
  'SET_USER_SEARCH_PARAMS_ON_SYNC_FLAG';

export const CREATE_PROFILE_SEARCH = 'CREATE_PROFILE_SEARCH';
export const FIND_PROFILES = 'FIND_PROFILES';
export const FETCH_USER_PROFILE_SEARCH_PARAMS =
  'FETCH_USER_PROFILE_SEARCH_PARAMS';

export const IS_SEARCHING = 'IS_SEARCHING';

// Request
export const SEND_REQUEST = 'SEND_REQUEST';
export const FETCH_SENT_REQUEST_LIST = 'FETCH_SENT_REQUEST_LIST';
export const FETCH_RECEIVED_REQUEST_LIST = 'FETCH_RECEIVED_REQUEST_LIST';
export const ACCEPT_REQUEST = 'ACCEPT_REQUEST';
export const DECLINE_REQUEST = 'DECLINE_REQUEST';
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const EMPTY_REQUEST = 'EMPTY_REQUEST';
export const FETCH_INVERSE_REQUEST = 'FETCH_INVERSE_REQUEST';

// Socket
export const CONNECT_SOCKET = 'CONNECT_SOCKET';
export const JOIN_CHAT_SUCCESS = 'JOIN_CHAT_SUCCESS';
export const JOIN_NOTIFICATION_CHANNEL = 'JOIN_NOTIFICATION_CHANNEL';

// Connections / Chat
export const FETCH_PROFILE_CONNECTION_LIST = 'FETCH_PROFILE_CONNECTION_LIST';
export const SET_CONNECTION = 'SET_CONNECTION';
export const EMPTY_CONNECTION = 'EMPTY_CONNECTION';
export const FETCH_CHAT_MESSAGE_LIST = 'FETCH_CHAT_MESSAGE_LIST';
export const FETCH_PROFILE_MESSAGE_LIST = 'FETCH_PROFILE_MESSAGE_LIST';
export const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE';
export const READ_MESSAGE = 'READ_MESSAGE';

// Locations
export const FETCH_AREA_LIST = 'FETCH_AREA_LIST';
export const FETCH_AREA_CODE_LIST = 'FETCH_AREA_CODE_LIST';
