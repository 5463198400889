import React from "react";
import { momentToDate } from "../../../utils/helpers";
import Datetime from "react-datetime";
import "../../../utils/react-datetime.css";
import { Dropdown, Button, Input, Divider } from "semantic-ui-react";
import FieldError from "./FieldError";
import PropTypes from "prop-types";
import {
  maleNumberOptions,
  femaleNumberOptions,
  minStayOptions,
  bedOptions,
  ensuiteOptions,
  billOptions,
} from "../../../assets/formOptions";
export default function RoomDetails({
  handleDateChange,
  setListingRoomValue,
  room,
  fieldsWithError,
  priceRef,
  ensuiteRef,
  typeRef,
  roommate_male_numberRef,
  bills_includedRef,
  // eslint-disable-next-line react/prop-types
  roomDetailsRef,
}) {
  return (
    <div className="roomigo-container">
      <div className="mx-20">
        <h2
          style={{ marginTop: "15px", color: "#707070" }}
          ref={roomDetailsRef}
        >
          Room Details
        </h2>
        <Divider
          style={{
            borderWidth: "3px",
            borderRadius: "15px",
            borderColor: "#FF664D",
          }}
        />
        {/* <span className="title-16">Room Details</span> */}
      </div>
      <div className="desktop-row">
        <div className="flex-1 room-list-flex-property">
          <span className="title-16 light">Availability</span>
          <div className="flex-row p-relative">
            <span className="title-16 light my-auto">From</span>
            <Datetime
              inputProps={{ readOnly: true }}
              id="move_in_date"
              className="room-listing-date-input my-auto leftField"
              onChange={value => handleDateChange("move_in_date", value)}
              value={momentToDate(room.move_in_date)}
              closeOnSelect
              dateFormat="DD/MM/YYYY"
              viewMode="months"
            />

            <span className="title-16 light my-auto">To</span>

            {room.move_out_date && (
              <span
                className="remove-to-date-btn"
                onClick={() => setListingRoomValue("move_out_date", null)}
              >
                Remove &quot;To&quot; Date
              </span>
            )}

            <Datetime
              inputProps={{ placeholder: "Optional", readOnly: true }}
              id="move_out_date"
              className="room-listing-date-input my-auto"
              onChange={value => handleDateChange("move_out_date", value)}
              value={momentToDate(room.move_out_date)}
              closeOnSelect
              viewMode="months"
            />
          </div>
        </div>

        <div className="flex-1 room-list-flex-property">
          <span className="title-16 light" ref={typeRef}>
            Min Stay (months)
          </span>
          <Dropdown
            className="flex-1 ml-6"
            fluid
            value={
              room.minimum_stay !== undefined && room.minimum_stay !== null
                ? room.minimum_stay.toString()
                : "0"
            }
            selection
            placeholder="Optional"
            onChange={(event, data) =>
              setListingRoomValue("minimum_stay", data.value)
            }
            options={minStayOptions}
          />
        </div>

        <div className="flex-1 room-list-flex-property">
          <span className="title-16 light" ref={typeRef}>
            Bed *
          </span>

          <Button.Group widths={2}>
            {bedOptions.map(bed => (
              <Button
                key={bed}
                className="roomigo-btn"
                active={room.type === bed}
                value={bed}
                onClick={() => setListingRoomValue("type", bed)}
              >
                {bed}
              </Button>
            ))}
          </Button.Group>
          {fieldsWithError.type && (
            <FieldError message={fieldsWithError.type} />
          )}
        </div>
        <div className="flex-1 room-list-flex-property">
          <span className="title-16 light " ref={ensuiteRef}>
            EnSuite *
          </span>

          <Button.Group widths={2}>
            {ensuiteOptions.map(ensuite => (
              <Button
                key={ensuite}
                className="roomigo-btn"
                active={room.ensuite === ensuite}
                value={ensuite}
                onClick={() => setListingRoomValue("ensuite", ensuite)}
              >
                {ensuite}
              </Button>
            ))}
          </Button.Group>
          {fieldsWithError.ensuite && (
            <FieldError message={fieldsWithError.ensuite} />
          )}
        </div>
        <div className="flex-1 room-list-flex-property">
          <span className="title-16 light" ref={roommate_male_numberRef}>
            Housemates (Including You) *
          </span>
          <div className="flex-row">
            <Dropdown
              id="roommate_male_number"
              className="flex-1 mr-6"
              fluid
              value={room.roommate_male_number}
              selection
              placeholder="Male"
              onChange={(event, data) =>
                setListingRoomValue("roommate_male_number", data.value)
              }
              options={maleNumberOptions}
            />
            <Dropdown
              id="roommate_famale_number"
              className="flex-1 ml-6"
              fluid
              value={room.roommate_female_number}
              selection
              placeholder="Female"
              onChange={(event, data) =>
                setListingRoomValue("roommate_female_number", data.value)
              }
              options={femaleNumberOptions}
            />
          </div>
          {fieldsWithError.roommate_male_number && (
            <FieldError message={fieldsWithError.roommate_male_number} />
          )}
        </div>
        <div className="flex-1 room-list-flex-property room-list-flex-property--desktop-half">
          <span className="title-16 light" ref={priceRef}>
            Rent *
          </span>
          <Input
            value={room.price}
            fluid
            type="number"
            onChange={event => setListingRoomValue("price", event.target.value)}
            placeholder="Price per month (€)"
          />
          {fieldsWithError.price && (
            <FieldError message={fieldsWithError.price} />
          )}
        </div>
        <div className="flex-1 room-list-flex-property room-list-flex-property--desktop-half">
          <span className="title-16 light">Deposit</span>
          <Input
            value={room.deposit}
            fluid
            type="number"
            onChange={event =>
              setListingRoomValue("deposit", event.target.value)
            }
            placeholder="Optional"
          />
        </div>
        <div className="flex-1 room-list-flex-property room-list-flex-property--desktop-half">
          <span className="title-16 light" ref={bills_includedRef}>
            Bills Included? *
          </span>

          <Button.Group widths={2}>
            {billOptions.map(bills_included => (
              <Button
                key={bills_included}
                className="roomigo-btn"
                active={room.bills_included === bills_included}
                value={bills_included.value}
                onClick={() =>
                  setListingRoomValue("bills_included", bills_included)
                }
              >
                {bills_included ? "Yes" : "No"}
              </Button>
            ))}
          </Button.Group>
          {fieldsWithError.bills_included && (
            <FieldError message={fieldsWithError.bills_included} />
          )}
        </div>
      </div>
    </div>
  );
}

RoomDetails.propTypes = {
  priceRef: PropTypes.object.isRequired,
  ensuiteRef: PropTypes.object.isRequired,
  typeRef: PropTypes.object.isRequired,
  roommate_male_numberRef: PropTypes.object.isRequired,
  bills_includedRef: PropTypes.object.isRequired,

  setListingRoomValue: PropTypes.func.isRequired,
  room: PropTypes.object.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  fieldsWithError: PropTypes.object.isRequired,
};
