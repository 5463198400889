export function findCityByName(cities, cityName) {
  if (Array.isArray(cities)) {
    return cities.find(
      city => city.name.toUpperCase() === cityName.toUpperCase()
    );
  } else {
    return undefined;
  }
}

export function findCityById(cities, cityId) {
  if (Array.isArray(cities)) {
    return cities.find(city => city.id === +cityId);
  } else {
    return undefined;
  }
}

export function getCityName(city) {
  return city ? city.name : null;
}

export function hasCities(cities) {
  return Array.isArray(cities) && cities.length > 0;
}

export function getParamsCityName(match) {
  if (!match || !match.params) {
    return null;
  }
  const {
    params: { city: cityName },
  } = match;
  return cityName;
}

export function getCityFromParams(cities, match) {
  const cityName = getParamsCityName(match);
  if (hasCities(cities) && cityName) {
    return findCityByName(cities, cityName) || null;
  } else {
    return null;
  }
}

export function createCityRedirect(city, match) {
  let cityRedirect = null;
  if (city) {
    cityRedirect = `/${getCityName(city).toLowerCase()}`;
  }
  if (cityRedirect === match.url) {
    return null;
  } else {
    return cityRedirect;
  }
}
