import React from "react";
import { Grid, Input, Image, Button, Dropdown } from "semantic-ui-react";
import Datetime from "react-datetime";
import "../../utils/react-datetime.css";
import { momentToDate } from "../../utils/helpers";

// Gender
const grey_male = require("../../assets/images/male_nonselect.svg");
const orange_male = require("../../assets/images/male_select.svg");
const grey_female = require("../../assets/images/female_nonselect.svg");
const orange_female = require("../../assets/images/female_select.svg");
const grey_genders = require("../../assets/images/other_nonselect.svg");
const orange_genders = require("../../assets/images/other_select.svg");

// Relationship
const grey_single = require("../../assets/images/grey_single.png");
const orange_single = require("../../assets/images/orange_single.png");
const grey_couple = require("../../assets/images/grey_couple.png");
const orange_couple = require("../../assets/images/orange_couple.png");

// Occupation
const grey_student = require("../../assets/images/student_nonselect.svg");
const orange_student = require("../../assets/images/student_select.svg");
const orange_professional = require("../../assets/images/professional_select.svg");
const grey_professional = require("../../assets/images/professional_nonselect.svg");

const Personal = ({ profile, handleFieldChange, changeButton, cities }) => {
  return (
    <Grid style={{ marginBottom: 20 }}>
      <Grid.Row
        verticalAlign="middle"
        style={{ marginTop: window.innerWidth > 767 ? 0 : 20 }}
      >
        <Grid.Column width={1} />
        <Grid.Column textAlign="left" width={5}>
          <font color="#707070" size="14px" style={{ fontFamily: "MYRIAD" }}>
            <b>
              Name &nbsp;
              <font color="#FF664D" style={{ fontSize: 20 }}>
                *
              </font>
            </b>
          </font>
        </Grid.Column>
        <Grid.Column width={9}>
          <Input
            id="name"
            value={profile.name}
            placeholder="Type your name"
            onChange={event => handleFieldChange("name", event.target.value)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row
        verticalAlign="middle"
        style={{ marginTop: window.innerWidth > 767 ? 0 : 20 }}
      >
        <Grid.Column width={1} />
        <Grid.Column textAlign="left" width={5}>
          <font color="#707070" size="14px" style={{ fontFamily: "MYRIAD" }}>
            <b>
              City &nbsp;
              <font color="#FF664D" style={{ fontSize: 20 }}>
                *
              </font>
            </b>
          </font>
        </Grid.Column>
        <Grid.Column width={9}>
          <Dropdown
            id="city"
            style={{
              color: "#FF664D",
              marginRight: 15,
              textAlign: "center",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "#FF664D",
            }}
            value={profile.city ? profile.city.id : null}
            placeholder="Select City"
            fluid
            selection
            options={cities.map(city => ({
              value: city.id,
              text: city.name,
            }))}
            onChange={(event, data) => handleFieldChange("city", data.value)}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row
        verticalAlign="middle"
        style={{ marginTop: window.innerWidth > 767 ? 0 : 20 }}
      >
        <Grid.Column width={1} />
        <Grid.Column textAlign="left" width={5}>
          <font color="#707070" size="14px" style={{ fontFamily: "MYRIAD" }}>
            <b>Email </b>
          </font>
        </Grid.Column>
        <Grid.Column width={9}>
          <Input id="name" disabled="true" type="email" value={profile.email} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row
        verticalAlign="middle"
        style={{ marginTop: window.innerWidth > 767 ? 0 : 15 }}
      >
        <Grid.Column width={1} />
        <Grid.Column textAlign="left" width={5}>
          <font color="#707070" size="14px" style={{ fontFamily: "MYRIAD" }}>
            <b>
              Date of Birth&nbsp;
              <font color="#FF664D" style={{ fontSize: 20 }}>
                *
              </font>
            </b>
          </font>
        </Grid.Column>
        <Grid.Column width={9}>
          <Datetime
            inputProps={{ readOnly: true }}
            onChange={value => handleFieldChange("date_of_birth", value)}
            id="date_of_birth"
            value={momentToDate(profile.date_of_birth)}
            dateFormat="Do-MMM-YYYY"
            closeOnSelect
            viewMode="years"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row
        verticalAlign="middle"
        style={{ marginTop: window.innerWidth > 767 ? 0 : 15 }}
      >
        <Grid.Column width={1} />
        <Grid.Column textAlign="left" width={5} />
        <Grid.Column width={9}>
          <p
            style={{
              color: "#707070",
              fontStyle: "italic",
              borderRadius: "8px",
            }}
          >
            We use this to display your age on your profile
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={1} />
        <Grid.Column textAlign="left" width={5}>
          <font
            color="#707070"
            size="14px"
            style={{ fontFamily: "MYRIAD", marginTop: 10 }}
          >
            <b>
              {" "}
              Gender &nbsp;
              <font color="#FF664D" style={{ fontSize: 20 }}>
                *
              </font>
            </b>
          </font>
        </Grid.Column>
        <Grid.Column width={3} textAlign="center">
          <Image
            style={{
              cursor: "pointer",
              height: 25,
              margin: 10,
              marginTop: "10px",
              marginBottom: "9px",
              marginLeft: "4px",
            }}
            src={profile.gender === "male" ? orange_male : grey_male}
            onClick={(event, data) => handleFieldChange("gender", "male")}
          />
          <span
            style={{
              fontSize: "11px",
              fontWeight: "bold",
              color: profile.gender === "male" ? "#FF664D" : "grey",
            }}
          >
            Male
          </span>
        </Grid.Column>
        <Grid.Column width={3} textAlign="center">
          <Image
            style={{
              cursor: "pointer",
              height: 30,
              margin: 10,
              marginTop: "10px",
              marginBottom: "4px",
              marginLeft: "6px",
            }}
            src={profile.gender === "female" ? orange_female : grey_female}
            onClick={(event, data) => handleFieldChange("gender", "female")}
          />

          <span
            style={{
              fontSize: "11px",
              fontWeight: "bold",
              color: profile.gender === "female" ? "#FF664D" : "grey",
            }}
          >
            Female
          </span>
        </Grid.Column>
        <Grid.Column width={3} textAlign="center">
          <Image
            style={{
              cursor: "pointer",
              height: 30,
              margin: 10,
              marginTop: "10px",
              marginBottom: "4px",
              marginLeft: "5px",
            }}
            src={profile.gender === "both" ? orange_genders : grey_genders}
            onClick={(event, data) => handleFieldChange("gender", "both")}
          />

          <span
            style={{
              fontSize: "11px",
              fontWeight: "bold",
              color: profile.gender === "both" ? "#FF664D" : "grey",
            }}
          >
            Other
          </span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={1} />
        <Grid.Column textAlign="left" width={5}>
          <font
            color="#707070"
            size="14px"
            style={{ fontFamily: "MYRIAD", marginTop: 1 }}
          >
            <b>
              Status&nbsp;
              <font color="#FF664D" style={{ fontSize: 20 }}>
                *
              </font>
            </b>
          </font>
        </Grid.Column>
        <Grid.Column width={4} textAlign="center">
          <Image
            style={{
              cursor: "pointer",
              height: 30,
              marginTop: "10px",
              marginBottom: "5px",
              marginLeft: "5px",
            }}
            src={profile.occupation === "study" ? orange_student : grey_student}
            onClick={(event, data) => handleFieldChange("occupation", "study")}
          />
          <span
            style={{
              fontSize: "11px",
              marginLeft: "-25%",
              fontWeight: "bold",
              color: profile.occupation === "study" ? "#FF664D" : "grey",
            }}
          >
            Student
          </span>
        </Grid.Column>
        <Grid.Column width={4} textAlign="center">
          <Image
            style={{
              cursor: "pointer",
              height: 30,
              marginTop: "10px",
              marginBottom: "5px",
              marginLeft: "3px",
            }}
            src={
              profile.occupation === "work"
                ? orange_professional
                : grey_professional
            }
            onClick={(event, data) => handleFieldChange("occupation", "work")}
          />
          <span
            style={{
              fontSize: "11px",
              marginLeft: "-35%",
              marginTop: "20px",
              fontWeight: "bold",
              color: profile.occupation === "work" ? "#FF664D" : "grey",
            }}
          >
            Professional
          </span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={1} />
        <Grid.Column textAlign="left" width={5}>
          <font color="#707070" size="14px" style={{ fontFamily: "MYRIAD" }}>
            <b>
              Living as &nbsp;
              <font color="#FF664D" style={{ fontSize: 20 }}>
                *
              </font>
            </b>
          </font>
        </Grid.Column>
        <Grid.Column width={4} textAlign="center">
          <Image
            style={{ cursor: "pointer", height: 50, marginLeft: "-1px" }}
            src={
              profile.relationship_status === "single"
                ? orange_single
                : grey_single
            }
            onClick={(event, data) =>
              handleFieldChange("relationship_status", "single")
            }
          />
        </Grid.Column>
        <Grid.Column width={4} textAlign="center">
          <Image
            style={{ cursor: "pointer", height: 50, marginLeft: "-7px" }}
            src={
              profile.relationship_status === "couple"
                ? orange_couple
                : grey_couple
            }
            onClick={(event, data) =>
              handleFieldChange("relationship_status", "couple")
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="middle" sytle={{ marginTop: 10 }}>
        <Grid.Column width={1} />
        <Grid.Column textAlign="left" width={5}>
          <font color="#707070" size="14px" style={{ fontFamily: "MYRIAD" }}>
            <b>Looking for rooms?</b>
          </font>
        </Grid.Column>
        <Grid.Column>
          <Button.Group fluid style={{ marginTop: "3px" }}>
            <Button
              onClick={() => handleFieldChange("looking_for_a_room", true)}
              size="mini"
              style={{
                height: "35px",
                borderRadius: "8px 0px 0px 8px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#FF664D",
                backgroundColor:
                  profile.looking_for_a_room === true ? "#FF664D" : "#FFFFFF",
              }}
            >
              <h1
                style={{
                  color:
                    profile.looking_for_a_room === true ? "#FFFFFF" : "grey",
                  fontSize: profile.looking_for_a_room === true ? 15 : 12,
                  marginLeft: "-5px",
                  marginTop:
                    profile.looking_for_a_room === true ? "-5px" : "-3px",
                  fontWeight: profile.looking_for_a_room === true && "bold",
                }}
              >
                YES
              </h1>
            </Button>
            <Button
              onClick={() => handleFieldChange("looking_for_a_room", false)}
              style={{
                height: "35px",
                borderRadius: "0px 8px 8px 0px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#FF664D",
                backgroundColor:
                  profile.looking_for_a_room === true ? "#FFFFFF" : "#FF664D",
              }}
            >
              <h1
                style={{
                  color:
                    (profile.looking_for_a_room === true) === true
                      ? "grey"
                      : "#FFFFFF",
                  fontSize:
                    (profile.looking_for_a_room === true) === true ? 12 : 15,
                  marginLeft: "-5px",
                  marginTop:
                    (profile.looking_for_a_room === true) === true
                      ? "-3px"
                      : "-5px",
                  fontWeight:
                    (profile.looking_for_a_room === true) === true && "bold",
                }}
              >
                NO
              </h1>
            </Button>
          </Button.Group>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row verticalAlign="middle" style={{ marginTop: 20 }}>
        <Grid.Column width={1} />
        <Grid.Column textAlign="left" width={5}>
          <font color="#707070" size="14px" style={{ fontFamily: "MYRIAD" }}>
            <b>Team up with others to find a place</b>
          </font>
        </Grid.Column>
        <Grid.Column>
          <Button.Group fluid style={{ marginTop: "3px" }}>
            <Button
              onClick={() => handleFieldChange("team_with_others", true)}
              size="mini"
              style={{
                height: "35px",
                borderRadius: "8px 0px 0px 8px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#FF664D",
                backgroundColor: profile.team_with_others
                  ? "#FF664D"
                  : "#FFFFFF",
              }}
            >
              <h1
                style={{
                  color: profile.team_with_others === true ? "#FFFFFF" : "grey",
                  fontSize: profile.team_with_others === true ? 15 : 12,
                  marginLeft: "-5px",
                  marginTop:
                    profile.team_with_others === true ? "-5px" : "-3px",
                  fontWeight: profile.team_with_others === true && "bold",
                }}
              >
                YES
              </h1>
            </Button>
            <Button
              onClick={() => handleFieldChange("team_with_others", false)}
              style={{
                height: "35px",
                borderRadius: "0px 8px 8px 0px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#FF664D",
                backgroundColor:
                  profile.team_with_others === true ? "#FFFFFF" : "#FF664D",
              }}
            >
              <h1
                style={{
                  color: profile.team_with_others === true ? "grey" : "#FFFFFF",
                  fontSize: profile.team_with_others === true ? 12 : 15,
                  marginLeft: "-5px",
                  marginTop:
                    profile.team_with_others === true ? "-3px" : "-5px",
                  fontWeight: profile.team_with_others === true && "bold",
                }}
              >
                NO
              </h1>
            </Button>
          </Button.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Personal;
