import React from 'react';

import Backdrop from '../Backdrop/Backdrop';
import { ReactComponent as IconCross } from './cross.svg';

const ModalLayout = ({ children, onCancel }) => {
  return (
    <>
      <div className="default-modal">
        <button className="default-modal__close" onClick={onCancel}>
          <IconCross />
        </button>
        {children}
      </div>
      <Backdrop onCancel={onCancel} />
    </>
  );
};

export default ModalLayout;
