import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Message, Button, Form, Grid, Dimmer, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import {
  createRoomSearch,
  findRooms,
  fetchRoomDetail,
  fetchRoomList,
  sendRequest,
  setRoomSearchPage,
  emptyRoomList,
} from "../../../actions";

import SearchRoomView from "./search_room_view";
import SearchRoomResult from "./search_room_result";
import SearchRoomResultMobile from "./search_room_result_mobile";

class SearchRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      // loading more rooms
      loadingMore: false,
      search: { ...props.roomSearch },
      roomList: [],
      emptyListMessage: "",
      searchStatus: true,
      windowWidth: window.innerWidth,
      areas: [],
      areaCodes: [],
    };
  }

  componentDidMount() {
    this.checkIfLoading();
    this.loadRoomsInitially();
  }

  componentDidUpdate(prevProps) {
    this.checkIfLoading();
    this.checkQueryParams(prevProps, this.props);

    if (!_.isEqual(this.props.roomList, prevProps.roomList)) {
      this.setState({ loadingMore: false });
      if (this.props.roomSearchPage === 1) {
        this.setState({ roomList: this.props.roomList });
      } else {
        if (this.props.roomList.length === 0) {
          // if there's no more rooms of this search, reset pageNum back to the previous one
          // this happens when the user click load more, but there's no more resutls
          this.props.setRoomSearchPage(prevProps.roomSearchPage - 1);
        } else {
          this.setState({
            roomList: [...this.state.roomList, ...this.props.roomList],
          });
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.emptyRoomList();
  }

  loadRoomsInitially = () => {
    const { selectedCity: propSelectedCity, roomSearch } = this.props;
    const selectedCity = propSelectedCity || roomSearch.city;
    if (selectedCity) {
      let tempArea = this.state.search.area;
      let tempAreaCode = this.state.search.area_code;
      if (propSelectedCity && roomSearch.city) {
        if (propSelectedCity.id !== roomSearch.city.id) {
          tempArea = [];
          tempAreaCode = [];
        }
      }
      this.setAreaOrNeighbourhoodList(selectedCity);
      this.setState(
        {
          search: {
            ...this.state.search,
            city: selectedCity,
            area: tempArea,
            area_code: tempAreaCode,
          },
        },
        () => this.createRoomSearch()
      );
    } else {
      this.createRoomSearch();
    }
  };

  checkQueryParams = (prevProps, props) => {
    const {
      location: { search: prevSearch },
    } = prevProps;
    const {
      location: { search },
    } = props;

    const targetOpen = "open=true";
    const prevOpen =
      prevSearch && prevSearch.toLowerCase().indexOf(targetOpen) !== -1;
    const open = search && search.toLowerCase().indexOf(targetOpen) !== -1;

    const targetClosed = "open=false";
    const prevClosed =
      prevSearch && prevSearch.toLowerCase().indexOf(targetClosed) !== -1;
    const closed = search && search.toLowerCase().indexOf(targetClosed) !== -1;

    if ((!prevOpen && open) || (prevClosed && !closed)) {
      this.setState({
        searchStatus: true,
      });
      window.scrollTo(0, 0);
    }
  };

  checkIfLoading = () => {
    if (
      this.props.profileIsOnSync ||
      this.props.citiesIsOnSync ||
      this.props.userSearchParamsIsOnSync
    ) {
      if (this.state.loading === false) {
        this.setState({ loading: true });
      }
    } else {
      if (this.state.loading === true) {
        this.setState({ loading: false });
      }
    }
  };

  setUserLastSearch = () => {
    // console.log("SET USER LAST SEARCH, city:", this.props.selectedCity);
    if (this.props.roomSearch.id) {
      let roomSearch = Object.assign({}, this.props.roomSearch);
      this.setAreaOrNeighbourhoodList(roomSearch.city)
        .then(() => {
          if (
            roomSearch.area_code.length &&
            roomSearch.area_code.length === this.state.areaCodes.length
          ) {
            roomSearch.area_code = ["all"];
          } else if (
            roomSearch.area.length &&
            roomSearch.area.length === this.state.areas.length
          ) {
            roomSearch.area = ["all"];
          }
          // TEMPORARY SOLUTION IF THERE IS MOVE IN DATE REMOVE IT
          let { move_in_date, ...roomSearchWithoutMoveIn } = roomSearch;
          this.setState({ search: roomSearchWithoutMoveIn });
        })
        // eslint-disable-next-line no-console
        .catch(() => console.log("Error happened in area dropdown"));
      this.props.findRooms(this.props.roomSearch);
    } else {
      // If the user has never done search before,
      // the roomSearch params will be null, so we will just fetch the first 20 rooms
      this.props.fetchRoomList(this.props.roomSearchPage);
    }
  };

  setAreaOrNeighbourhoodList = newCity => {
    return new Promise((resolve, reject) => {
      if (newCity) {
        const cityObj = this.props.cities.find(city => city.id === newCity.id);
        if (cityObj) {
          this.setState({
            areas: cityObj.areas,
            areaCodes: cityObj.area_codes,
            search: Object.assign({}, this.state.search, {
              city: { ...newCity },
            }),
          });
          return resolve();
        }
      }
      return reject();
    });
  };
  handleFieldChange = (field, value) => {
    if (field === "city") {
      const cityObj = this.props.cities.find(city => city.id === value.id);
      if (cityObj && value.id !== this.state.search.city) {
        this.setState({
          areas: cityObj.areas,
          areaCodes: cityObj.area_codes,
          search: {
            ...this.state.search,
            city: value,
            area: [],
            area_code: [],
          },
        });
      }
    } else {
      this.setState({
        search: Object.assign({}, this.state.search, { [field]: value }),
      });
    }
  };

  loadMore = () => {
    if (!this.state.loadingMore) {
      // console.log("loadMore fired");
      if (this.props.roomSearch.id === null && this.props.myProfile.data.id) {
        this.createRoomSearch();
      }

      if (this.props.roomList.length !== 0) {
        this.setState({ loadingMore: true });
        const newPageNum = this.props.roomSearchPage + 1;
        this.props.setRoomSearchPage(newPageNum);
        this.props.findRooms(this.state.search, newPageNum);
      }
    }
  };

  createRoomSearch = (state = this.state) => {
    const search = state.search;
    let areaCodes = [];
    let areas = [];

    if (search.area_code.indexOf("all") > -1) {
      areaCodes = state.areaCodes;
    } else {
      areaCodes = search.area_code;
    }
    if (search.area.indexOf("all") > -1) {
      areas = state.areas;
    } else {
      areas = search.area;
    }
    const searchParams = {
      id: search.id,
      user_id: this.props.myProfile.data.user_id,
      city: search.city,
      area: areas,
      area_code: areaCodes,
      price_min: search.price_min,
      price_max: search.price_max,
      age_min: parseInt(search.age_min, 10),
      age_max: parseInt(search.age_max, 10),
      type: search.type === "" ? "No preference" : search.type,
      ensuite: search.ensuite === "" ? "No preference" : search.ensuite,
      preferred_gender:
        search.preferred_gender === ""
          ? "No preference"
          : search.preferred_gender,
      preferred_relationship:
        search.preferred_relationship === ""
          ? "No preference"
          : search.preferred_relationship,
      preferred_occupation:
        search.preferred_occupation === ""
          ? "No preference"
          : search.preferred_occupation,
    };

    if (this.props.myProfile.data.id) {
      this.props.createRoomSearch(searchParams);
    } else {
      this.props.findRooms(searchParams);
    }
    this.props.setRoomSearchPage(1);
  };

  sendRequest = receiver_id => {
    const request = {
      receiver_id,
      status: "Pending",
    };
    this.props.sendRequest(request);
  };

  handleSearchStatus = () => {
    let searchStatus = !this.state.searchStatus;
    this.setState({ searchStatus });
    if (!searchStatus) {
      this.props.history.push("search_room?open=false");
    } else {
      window.scrollTo(0, 0);
    }
  };

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };
  handleAreaCodeChange = (field, value) => {
    if (value[value.length - 1] === "all") {
      this.setState({
        search: Object.assign({}, this.state.search, { [field]: ["all"] }),
      });
    } else {
      if (value.indexOf("all") > -1) {
        value.splice(value.indexOf("all"), value.indexOf("all") + 1);
      }
      if (field === "area_code") {
        let areaCodes = _.filter(this.state.areaCodes, function(area) {
          return value.indexOf(area.id) > -1;
        });
        this.setState({
          search: Object.assign({}, this.state.search, { [field]: areaCodes }),
        });
      } else if (field === "area") {
        let areas = _.filter(this.state.areas, function(area) {
          return value.indexOf(area.id) > -1;
        });
        this.setState({
          search: Object.assign({}, this.state.search, { [field]: areas }),
        });
      }
    }
  };
  renderLoader = () => {
    return (
      <Dimmer active inverted>
        <Loader size="large">Searching</Loader>
      </Dimmer>
    );
  };

  renderNoResults() {
    return (
      <Message info style={{ marginTop: 10 }}>
        Sorry, but your search yielded no results. Please try again with
        different filters.
      </Message>
    );
  }
  submit = e => {
    e.preventDefault();
    this.createRoomSearch();
    this.handleSearchStatus();
  };

  render() {
    const {
      loading,
      search,
      windowWidth,
      searchStatus,
      areas,
      areaCodes,
      roomList,
      emptyListMessage,
    } = this.state;
    if (loading) {
      return <div />;
    }
    return (
      <div className="site-container">
        {searchStatus === true && (
          <Form onSubmit={this.submit}>
            <SearchRoomView
              search={search}
              window={windowWidth}
              handleFieldChange={this.handleFieldChange}
              handleAreaCodeChange={this.handleAreaCodeChange}
              handleSearchStatus={this.handleSearchStatus}
              searchStatus={searchStatus}
              areas={areas}
              areaCodes={areaCodes}
              cities={this.props.cities}
            />

            <Grid stackable>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={2} />
                <Grid.Column width={4}>
                  <Button
                    size="big"
                    fluid
                    type="submit"
                    style={{
                      backgroundColor: "#00D5CC",
                      color: "#fff",
                      borderRadius: "10px",
                      marginBottom: 40,
                      marginTop: 20,
                    }}
                  >
                    Find me a room!
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}

        {windowWidth > 768 ? (
          <SearchRoomResult
            roomList={roomList}
            emptyListMessage={emptyListMessage}
            sendRequest={this.sendRequest}
            handleSearchStatus={this.handleSearchStatus}
            searchStatus={this.state.searchStatus}
            renderNoResults={this.renderNoResults}
            loadMore={this.loadMore}
            loadingMore={this.state.loadingMore}
          />
        ) : (
          <SearchRoomResultMobile
            roomList={roomList}
            emptyListMessage={emptyListMessage}
            sendRequest={this.sendRequest}
            handleSearchStatus={this.handleSearchStatus}
            searchStatus={this.state.searchStatus}
            renderNoResults={this.renderNoResults}
            loadMore={this.loadMore}
            loadingMore={this.state.loadingMore}
          />
        )}
      </div>
    );
  }
}
SearchRoom.propTypes = {
  profileIsOnSync: PropTypes.bool.isRequired,
  citiesIsOnSync: PropTypes.bool.isRequired,
  userSearchParamsIsOnSync: PropTypes.bool.isRequired,
  cities: PropTypes.array.isRequired,
  emptyRoomList: PropTypes.func.isRequired,
  selectedCity: PropTypes.object,
  myProfile: PropTypes.object.isRequired,
  roomSearch: PropTypes.object.isRequired,
  findRooms: PropTypes.func.isRequired,
  roomList: PropTypes.array.isRequired,
  fetchRoomList: PropTypes.func.isRequired,
  roomSearchPage: PropTypes.number.isRequired,
  setRoomSearchPage: PropTypes.func.isRequired,
  createRoomSearch: PropTypes.func.isRequired,
  sendRequest: PropTypes.func.isRequired,
};
const mapStateToProps = ({
  roomList,
  myProfile,
  isSearching,
  roomSearchPage,
}) => ({
  roomList,
  profileIsOnSync: myProfile.profileIsOnSync,
  myProfile,
  isSearching,
  roomSearchPage,
});

const actions = {
  createRoomSearch,
  findRooms,
  fetchRoomList,
  fetchRoomDetail,
  sendRequest,
  setRoomSearchPage,
  emptyRoomList,
};

export default connect(
  mapStateToProps,
  actions
)(SearchRoom);
