import React from "react";
import { Link } from "react-router-dom";

import FacebookLogin
  from "react-facebook-login/dist/facebook-login-render-props";
/* import FacebookLogin from "react-facebook-login"; */
import { Segment, Grid, Button, Icon, Image } from "semantic-ui-react";

const logo = require("../../assets/images/roomigo.svg");

// Docker
/* const appId = process.env.REACT_APP_FB_APP_ID; */

// Test Localhost
/* const appId = "935048833330509"; */

// Staging
// const appId = "303610970174059";
const appId = "448683005483781";

const LoginView = ({ socialLogin }) => {
  return (
    <div className="login-wrapper">
      <Grid container centered columns={1}>
        <Segment
          raised
          padded
        >
          <Grid.Row>
            <Grid.Column>
              <Image src={logo} size="small" centered />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <font style={{ fontSize: 12, fontStyle: "italic" }}>
              By clicking "Login" you agree to our<br />
              <Link to="/terms">Terms & Conditions</Link> and &nbsp;
              <Link to="/privacy">Privacy Policy.</Link>
            </font>
          </Grid.Row>
          <Grid.Row centered columns={1}>
            <Grid.Column>
              <FacebookLogin
                appId={appId}
                fields="name,email,picture"
                scope="email,public_profile"
                callback={response => socialLogin(response.accessToken)}
                render={renderProps => (
                  <Button
                    onClick={renderProps.onClick}
                    size="big"
                    fluid
                    color="facebook"
                    style={{ marginTop: "50px" }}
                  >
                    <Icon name="facebook" />
                    Login with Facebook
                  </Button>
                )}
                disableMobileRedirect={true}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered columns={1}>
            <Grid.Column>
              <Link to="/email_login">
                <Button
                  size="big"
                  fluid
                  style={{
                    color: "#FFF",
                    backgroundColor: "#FF664D",
                    marginTop: "30px"
                  }}
                >
                  <Icon name="mail" />
                  Login with Email
                </Button>
              </Link>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered columns={1} style={{ marginTop: "30px" }}>
            <Grid.Column textAlign="center">
              Don't have an account yet ?
              <br />
              <Link to="/register">Sign up</Link>
            </Grid.Column>
          </Grid.Row>
        </Segment>
      </Grid>
    </div>
  );
};

export default LoginView;
