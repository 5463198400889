import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { login, getUserInfo, connectSocket, socialLogin } from "../../actions";
import LoginView from "./login_view";
import FacebookLoginView from "./facebook_login_view";
import _ from "lodash";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { session: {}, error: "" };
    this.socialLogin = this.socialLogin.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.register = this.register.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.session.error) {
      if (!nextProps.session.error.name) {
        this.setState({ error: "This email address is already in use." });
      } else {
        this.setState({ session: nextProps.session.error });
      }
    }
    if (
      !_.isEqual(this.props.session, nextProps.session) &&
      !_.isEmpty(nextProps.session.data)
    ) {
      this.props.connectSocket(nextProps.session.data.jwt);
      if (nextProps.session.data.user.is_new) {
        this.props.history.push("/profile");
      } else {
        if (this.props.previousPage) {
          this.props.history.push(this.props.previousPage);
        } else {
          this.props.history.push("/profile");
        }
      }
    }
  }

  handleFieldChange(field, value) {
    this.setState({
      error: "",
      session: Object.assign({}, this.state.session, { [field]: value }),
    });
  }

  socialLogin(token) {
    this.props.getUserInfo(token);
  }

  register() {
    const { session } = this.state;
    this.props.socialLogin(
      session.email,
      session.name,
      "",
      "",
      session.picture_url,
      session.token,
      session.provider,
      true // new_user
    );
  }

  render() {
    const { session, error } = this.state;
    if (!_.isEmpty(session)) {
      return (
        <FacebookLoginView
          session={session}
          handleFieldChange={this.handleFieldChange}
          register={this.register}
          error={error}
        />
      );
    }
    return <LoginView socialLogin={this.socialLogin} />;
  }
}

const mapStateToProps = ({ session, previousPage }) => ({
  session,
  previousPage,
});

const actions = {
  login,
  getUserInfo,
  connectSocket,
  socialLogin,
};

export default withRouter(connect(mapStateToProps, actions)(Login));
