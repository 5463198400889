import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Grid, Container, Image, Icon, Label } from "semantic-ui-react";
import _ from "lodash";
import {
  fetchUserRoomList,
  fetchInterestList,
  updateUserProfile,
  uploadPicture,
  fetchLoggedInUserProfile,
  setPublicProfile,
  favouriteProfilePicture,
  updateCredential,
  deleteProfilePicture,
  citySelect,
} from "../../actions/index";

import Personal from "./personal";
import Pictures from "./pictures";
import Lifestyle from "./lifestyle";
import Bio from "./bio";
import Searchable from "./searchable";
import PopUp from "../../utils/pop_up_profile.js";
import filterList, {
  renderPictures,
  parseProfileError,
} from "../../utils/helpers";
import "../fonts/extrafonts.css";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myProfile: this.props.myProfile,
      interestList: this.props.interestList || [],
      isUpdated: false,
      filteredInterests: this.props.interestList || [],
      isModalOpen: false,
      indexList: false,
      errorMessage: "",
      filePictures: [],
      message: "",
      lookingForARoom: true,
      teamWithOthers: true,
      changedVisible: false,
      errorList: [],
    };
    this.props.fetchUserRoomList();
    this.props.fetchInterestList();
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.updateUserProfile = this.updateUserProfile.bind(this);
    this.addOrRemoveInterest = this.addOrRemoveInterest.bind(this);
    this.uploadPicture = this.uploadPicture.bind(this);
    this.searchTermChange = this.searchTermChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.props.fetchLoggedInUserProfile();
    this.openIndexList = this.openIndexList.bind(this);
    this.closeIndexList = this.closeIndexList.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.renderLeftNav = this.renderLeftNav.bind(this);
    this.renderRightNav = this.renderRightNav.bind(this);
    this.changeButton = this.changeButton.bind(this);
    this.favouritePicture = this.favouritePicture.bind(this);
    this.setNotSearchable = this.setNotSearchable.bind(this);
    this.reloadModal = this.reloadModal.bind(this);
    this.resetMessage = this.resetMessage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.interestList, nextProps.interestList)) {
      this.setState({
        filteredInterests: nextProps.interestList,
        interestList: nextProps.interestList,
      });
    }
    if (!_.isEmpty(nextProps.myProfile.error)) {
      const message = parseProfileError(nextProps.myProfile.error.errors);

      this.setState({
        message,
        isModalOpen: true,
        errorList: nextProps.myProfile.error.errors,
      });
    }
    if (!_.isEqual(this.props.myProfile.data, nextProps.myProfile.data)) {
      if (!this.state.myProfile.data.id) {
        this.setState({
          myProfile: nextProps.myProfile,
          indexList: nextProps.myProfile.data.is_new === true && true,
        });
      } else {
        if (
          _.isEmpty(nextProps.myProfile.error) &&
          nextProps.myProfile.data.is_searchable
        ) {
          this.setState({
            profile: nextProps.myProfile,
            filePictures: renderPictures(nextProps.myProfile.data.pictures),
            message: "Profile updated succesfully! Where next?",
            isUpdated: true,
            isModalOpen: true,
            errorList: [],
          });
        }
      }
    }
    if (!_.isEqual(this.props.file, nextProps.file)) {
      let pictures = this.state.myProfile.data.pictures;
      pictures = [
        ...pictures,
        { url: nextProps.file.file_upload, favourite: false },
      ];
      const data = Object.assign({}, this.state.myProfile.data, { pictures });
      this.setState({
        myProfile: Object.assign({}, this.state.myProfile, { data }),
        filePictures: renderPictures(pictures),
      });
    }
    if (!_.isEqual(this.props.session, nextProps.session)) {
      if (!nextProps.session.error) {
        const myProfile = this.state.myProfile.data;
        const myProfileInfo = {
          user_id: myProfile.user_id,
          name: myProfile.name,
          city: myProfile.city,
          gender: myProfile.gender,
          date_of_birth: myProfile.date_of_birth,
          occupation: myProfile.occupation,
          bio: myProfile.bio,
          pictures: myProfile.pictures,
          is_searchable: myProfile.is_searchable,
          relationship_status: myProfile.relationship_status,
        };
        const interestIds = myProfile.interests.map(interest => interest.id);
        this.props.updateUserProfile(myProfile.id, myProfileInfo, interestIds);
      } else {
        this.setState({
          isModalOpen: true,
          message:
            "Your profile could not be updated because this email address is already in use",
        });
      }
    }
  }

  resetMessage() {
    this.setState({
      message: "",
      isModalOpen: false,
      isUpdated: false,
    });
  }

  addOrRemoveInterest(interest) {
    let interests = this.state.myProfile.data.interests;
    const isInterestHighlighted = _.find(interests, { id: interest.id });
    if (isInterestHighlighted) {
      interests = _.filter(interests, int => int.id !== interest.id);
    } else {
      if (interests.length >= 8) {
        this.setState({
          errorMessage:
            "RooMigo is all about matching compatible housemates. Select 3 to 8 lifestyle choices to help you match with the right people",
        });
        //this.setState({ indexList: false });
      } else {
        interests = [...interests, interest];
      }
    }
    let { myProfile } = this.state;
    myProfile.data = Object.assign({}, this.state.myProfile.data, {
      interests,
    });
    this.setState({ myProfile });
  }

  handleFieldChange(field, value) {
    let { myProfile } = this.state;
    if (field === "city") {
      const city = this.props.cities.find(cityInArr => cityInArr.id === value);
      myProfile.data = Object.assign({}, myProfile.data, {
        city: { id: city.id, name: city.name },
      });
      this.setState({ myProfile, isUpdated: false, errorList: [] });
    } else {
      myProfile.data = Object.assign({}, myProfile.data, { [field]: value });
      this.setState({ myProfile, isUpdated: false, errorList: [] });
    }
  }

  updateUserProfile() {
    const myProfile = this.state.myProfile.data;
    if (myProfile.city) {
      if (this.props.myProfile.data.city) {
        if (myProfile.city.id !== this.props.myProfile.data.city.id) {
          this.props.citySelect({
            name: myProfile.city.name,
            id: myProfile.city.id,
          });
        }
      } else {
        this.props.citySelect({
          name: myProfile.city.name,
          id: myProfile.city.id,
        });
      }
    }
    const myProfileInfo = {
      user_id: myProfile.user_id,
      name: myProfile.name,
      city: myProfile.city,
      gender: myProfile.gender,
      date_of_birth: myProfile.date_of_birth,
      occupation: myProfile.occupation,
      bio: myProfile.bio,
      pictures: myProfile.pictures,
      is_searchable: myProfile.is_searchable,
      relationship_status: myProfile.relationship_status,
      looking_for_a_room: myProfile.looking_for_a_room,
      team_with_others: myProfile.team_with_others,
    };
    const interestIds = myProfile.interests.map(interest => interest.id);
    if (_.isEmpty(myProfile.pictures)) {
      const message = "Sorry you can't save your profile without a photo";
      this.setState({
        message,
        isModalOpen: true,
      });
    } else if (myProfile.city === null) {
      const message = "Sorry you can't save your profile without a city";
      this.setState({
        message,
        isModalOpen: true,
      });
    } else {
      if (interestIds.length > 2) {
        if (myProfileInfo.is_searchable === false) {
          const message =
            "Your profile is currently hidden. This means you cannot connect with roommates or list a room. Do you wish to make your profile visible?";
          this.props.updateUserProfile(
            myProfile.user_id,
            myProfileInfo,
            interestIds
          );

          this.setState({
            message,
            isModalOpen: true,
            changedVisible: false,
          });
        } else {
          const message = "Profile updated succesfully! Where next?";
          this.setState({ errorMessage: "", indexList: false });

          if (
            this.props.myProfile.data.email &&
            !_.isEqual(
              this.props.myProfile.data.email,
              this.state.myProfile.data.email
            )
          ) {
            this.props.updateCredential(
              this.props.myProfile.data.email,
              this.state.myProfile.data.email
            );
          } else {
            this.props.updateUserProfile(
              myProfile.user_id,
              myProfileInfo,
              interestIds
            );

            this.setState({
              message,
              isModalOpen: true,
              isUpdated: true,
            });
          }
        }
      } else {
        this.setState({
          errorMessage:
            "RooMigo is all about matching compatible housemates. Select 3 to 8 lifestyle choices to help you match with the right people",
        });
        this.setState({ indexList: false });
      }
    }
  }

  uploadPicture(file) {
    this.props.uploadPicture(file, "400x400");
  }

  searchTermChange(searchTerm) {
    this.setState({ searchTerm });
    const filteredInterests = filterList(
      searchTerm,
      "name",
      this.props.interestList
    );
    this.setState({ filteredInterests });
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal(value) {
    this.setState({ isModalOpen: false });
  }

  openIndexList() {
    this.setState({ indexList: true });
  }

  closeIndexList() {
    this.setState({ indexList: false });
  }

  changeButton(field, value) {
    if (value === true) {
      this.setState({ [field]: false });
    } else {
      this.setState({ [field]: true });
    }
  }

  reloadModal(value) {
    this.handleFieldChange("is_searchable", value);
    this.updateUserProfile();
  }

  favouritePicture(picture) {
    this.props.favouriteProfilePicture(picture);
  }

  setNotSearchable() {
    if (this.props.roomList.length > 0) {
      const message =
        "Switching your profile to hidden will also deactivate your room. Do you want to continue ?";
      this.setState({
        message,
        isModalOpen: true,
      });
    } else {
      this.handleFieldChange("is_searchable", false);
      this.props.setPublicProfile(false);
      this.setState({
        changedVisible: true,
      });
    }
  }
  renderItem(item) {
    return (
      <div>
        <Image
          centered
          style={{
            borderRadius: "8px",
            width: 300,
            height: 300,
          }}
          src={item.original}
          label={
            this.props.myProfile.data.pictures.length > 1 && (
              <Label
                floating
                color="red"
                style={{
                  cursor: "pointer",
                  zIndex: 10,
                  left: 280,
                  top: 10,
                }}
                size="large"
                onClick={() => this.props.deleteProfilePicture(item.original)}
              >
                X
              </Label>
            )
          }
        />
        {this.props.myProfile.data.pictures.length > 0 && (
          <span
            style={{
              zIndex: 10,
              position: "absolute",
              left: "35px",
              top: "25px",
            }}
          >
            <Label
              floating
              size="small"
              style={{
                backgroundColor: "#FF664D",
                zIndex: 10,
                marginTop: 100,
                color: "#fff",
                cursor: "pointer",
              }}
              circular
              onClick={() => this.favouritePicture(item.original)}
            >
              Make feature photo{" "}
            </Label>
          </span>
        )}
      </div>
    );
  }

  renderLeftNav(onClick, disabled) {
    return (
      <button
        className="image-gallery-custom-left-nav"
        disabled={disabled}
        onClick={onClick}
        style={{
          height: "98%",
          width: "10%",
          backgroundColor: "rgba(52, 52, 52, 0.0)",
          position: "absolute",
          left: "5%",
          zIndex: 1,
          border: 0,
          outline: "none",
        }}
      >
        <Icon
          name="chevron left"
          size={window.innerWidth > 767 ? "small" : "large"}
          circular
          style={{
            zIndex: 1,
            opacity: ".8",
            backgroundColor: "#00D5CC",
            color: "#fff",
          }}
        />
      </button>
    );
  }

  renderRightNav(onClick, disabled) {
    return (
      <button
        className="image-gallery-custom-right-nav"
        disabled={disabled}
        onClick={onClick}
        style={{
          height: "98%",
          width: "11%",
          backgroundColor: "rgba(52, 52, 52, 0.0)",
          position: "absolute",
          right: "10%",
          zIndex: 1,
          border: 0,
          outline: "none",
        }}
      >
        <Icon
          name="chevron right"
          size={window.innerWidth > 767 ? "small" : "large"}
          circular
          style={{
            zIndex: 1,
            opacity: ".8",
            backgroundColor: "#00D5CC",
            color: "#fff",
          }}
        />
      </button>
    );
  }

  render() {
    const {
      myProfile,
      filePictures,
      lookingForARoom,
      teamWithOthers,
      interestList,
      filteredInterests,
      indexList,
      isUpdated,
      isModalOpen,
      errorMessage,
      message,
      errorList,
    } = this.state;
    if (this.props.cities.length === 0) {
      return <div />;
    }
    return (
      <Container style={{ margin: "0 15px 15px 15px", maxWidth: "1250px" }}>
        <Form onSubmit={event => event.preventDefault()}>
          <Grid stackable style={{ marginTop: 30 }}>
            <Grid.Row style={{ zIndex: 10 }}>
              <Grid.Column textAlign="center" width={4}>
                <Pictures
                  profile={myProfile.data}
                  uploadPicture={this.uploadPicture}
                  renderItem={this.renderItem}
                  renderLeftNav={this.renderLeftNav}
                  renderRightNav={this.renderRightNav}
                  filePictures={filePictures}
                  isSearching={this.props.isSearching}
                />
                <Searchable
                  profile={myProfile.data}
                  handleFieldChange={this.handleFieldChange}
                  setPublicProfile={this.props.setPublicProfile}
                  setNotSearchable={this.setNotSearchable}
                />
              </Grid.Column>
              <Grid.Column textAlign="left" width={5}>
                <Grid.Row>
                  <Personal
                    cities={this.props.cities}
                    profile={myProfile.data}
                    handleFieldChange={this.handleFieldChange}
                    lookingForARoom={lookingForARoom}
                    teamWithOthers={teamWithOthers}
                    changeButton={this.changeButton}
                  />
                </Grid.Row>
              </Grid.Column>
              <Grid.Column textAlign="left" width={7}>
                <Grid.Row>
                  <Bio
                    profile={myProfile.data}
                    handleFieldChange={this.handleFieldChange}
                  />
                </Grid.Row>
                <Grid.Row style={{ marginBottom: indexList && 150 }}>
                  <Lifestyle
                    profile={myProfile.data}
                    interestList={interestList}
                    addOrRemoveInterest={this.addOrRemoveInterest}
                    searchTermChange={this.searchTermChange}
                    filteredInterests={filteredInterests}
                    indexList={indexList}
                    openIndexList={this.openIndexList}
                    closeIndexList={this.closeIndexList}
                    errorMessage={errorMessage}
                  />
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row
              style={{
                marginBottom: 50,
                marginTop: window.innerWidth > 767 ? "-13%" : 30,
                marginLeft: window.innerWidth > 767 ? 0 : "8%",
              }}
            >
              <Grid.Column width={4} floated="right">
                <PopUp
                  title="SAVE PROFILE"
                  isUpdated={isUpdated}
                  submit={this.updateUserProfile}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  reloadModal={this.reloadModal}
                  isModalOpen={isModalOpen}
                  message={message}
                  handleFieldChange={this.handleFieldChange}
                  setPublicProfile={this.props.setPublicProfile}
                  profile={myProfile}
                  errorList={errorList}
                  resetMessage={this.resetMessage}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = ({
  session,
  interestList,
  myProfile,
  file,
  isSearching,
  roomList,
  cities,
}) => ({
  session,
  cities: cities.cities,
  interestList,
  myProfile,
  file,
  isSearching,
  roomList,
});

const actions = {
  fetchInterestList,
  updateUserProfile,
  uploadPicture,
  fetchLoggedInUserProfile,
  setPublicProfile,
  favouriteProfilePicture,
  updateCredential,
  fetchUserRoomList,
  deleteProfilePicture,
  citySelect,
};

export default connect(
  mapStateToProps,
  actions
)(Profile);
