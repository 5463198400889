/* global window */
import React from "react";
import { Image, Grid, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";

import ProfileCard from "./profile_card";

const filter_icon = require("../../assets/images/search.svg");

const ProfileListViewMobile = ({ profileList }) => {
  return (
    <div>
      <Grid stackable>
        <Grid.Row columns={1} textAlign="left" style={{ marginTop: "15%" }}>
          <Grid.Column>
            <Link to="/search_profile">
              <span
                style={{
                  marginRight: 10,
                  fontSize: 25,
                  fontWeight: "bold",
                  textDecoration: "none",
                  color: "#272727",
                  cursor: "pointer",
                  top: 5,
                  right: window.innerWidth > 767 ? 40 : 50,
                  zIndex: 1,
                }}
              >
                People
              </span>
              <Image
                verticalAlign="middle"
                src={filter_icon}
                style={{
                  marginBottom: 20,
                  width: "25px",
                  cursor: "pointer",
                  top: window.innerWidth > 767 ? 2 : 6,
                  zIndex: 1,
                }}
              />
            </Link>
            <Divider
              style={{
                marginTop: -5,
                borderWidth: "3px",
                borderRadius: "15px",
                borderColor: "#ff664d",
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {profileList.slice(0, window.innerWidth > 674 ? 4 : 3).map(profile => (
        <ProfileCard key={profile.user_id} profile={profile} />
      ))}
    </div>
  );
};

export default ProfileListViewMobile;
