import React from "react";
import { Link } from "react-router-dom";
import { Grid, Label, Image } from "semantic-ui-react";
import "../fonts/extrafonts.css";
import {
  renderOccupation,
  getJoinedFromDate,
  renderFavouritePicture,
} from "../../utils/helpers";

import RequestButton from "../../utils/request_button";
import ShareButton from "../../utils/share_button";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const ProfileDetailView = ({
  myProfile,
  profile,
  sendRequest,
  request,
  inverseRequest,
  linkToPreviousPage,
  renderItem,
  renderLeftNav,
  renderRightNav,
  renderPicturesList,
  acceptRequest,
  declineRequest,
  setConnection,
  rooms,
}) => {
  return (
    <div>
      <Grid stackable padded style={{ fontFamily: "MYRIAD", marginTop: 25 }}>
        <Grid.Row>
          <Grid.Column textAlign="center" width={4}>
            <Grid.Row>
              <ImageGallery
                items={renderPicturesList(profile)}
                showPlayButton={false}
                showFullscreenButton={false}
                showThumbnails={false}
                lazyLoad={false}
                renderItem={item => renderItem(item, profile)}
                renderLeftNav={renderLeftNav}
                renderRightNav={renderRightNav}
              />
            </Grid.Row>
            <Grid.Row>
              <br />
              <Grid.Column width={3}>
                {profile.id !== myProfile.id && (
                  <RequestButton
                    request={request}
                    inverseRequest={inverseRequest}
                    profile={profile}
                    sendRequest={sendRequest}
                    linkToPreviousPage={linkToPreviousPage}
                    acceptRequest={acceptRequest}
                    declineRequest={declineRequest}
                    setConnection={setConnection}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column textAlign="left" width={8}>
            <Grid.Row>
              <h1 style={{ color: "#FF664D", fontFamily: "MYRIAD-BOLD" }}>
                Hey, I'm {profile.name}!
              </h1>
            </Grid.Row>
            <Grid.Row style={{ marginTop: "20px", fontFamily: "myriad" }}>
              <b>
                <font size="3">
                  {profile.name}, &nbsp;
                  {profile.age}
                </font>{" "}
              </b>
            </Grid.Row>
            <Grid.Row style={{ marginTop: "5px" }}>
              {renderOccupation(profile.occupation)}
              {profile.relationship_status === "couple" &&
                " | Living as a couple. "}
              {profile.relationship_status === "single" &&
                " | Living on my own. "}
              <br />
              {profile.looking_for_a_room && "I am looking for a room. "}
              {profile.team_with_others && "I  want to team up."}
              <br />
              Joined {getJoinedFromDate(profile.joined_in)}
            </Grid.Row>
            <Grid.Row>
              <br />
              <p style={{ textAlign: "justify", whiteSpace: "pre-wrap" }}>
                {profile.bio}
              </p>
            </Grid.Row>
            <Grid.Row style={{ marginTop: "15px" }}>
              <b>
                <font size="3">Lifestyle</font>{" "}
              </b>
            </Grid.Row>
            <Grid.Row style={{ marginTop: "15px" }}>
              <Label.Group size="medium">
                {profile.interests.map(interest => (
                  <Label
                    basic
                    key={interest.id}
                    style={{ borderColor: "#FF664D" }}
                  >
                    <font color="black">{interest.name}</font>
                  </Label>
                ))}
              </Label.Group>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
        {rooms.length !== 0 && (
          <Grid.Row columns={2} textAlign="left">
            <h1
              style={{
                color: "#FF664D",
                marginTop: 50,
                //marginLeft: 35,
                fontFamily: "MYRIAD",
              }}
            >
              {profile.name}
              's rooms
            </h1>
          </Grid.Row>
        )}
      </Grid>
      {window.innerWidth > 768 ? (
        <Grid stackable columns={4} style={{ marginBottom: 50 }}>
          <Grid.Row>
            {rooms.map(room => (
              <Grid.Column
                key={room.id}
                style={{ paddingLeft: 38, paddingRigt: 38 }}
                width={4}
              >
                <Grid.Row verticalAlign="middle">
                  <Grid.Column
                    width={11}
                    verticalAlign="middle"
                    style={{ marginTop: 0 }}
                  >
                    <p
                      style={{
                        color: "#666",
                        fontWeight: "bold",
                        fontSize: 18,
                        marginBottom: "-2%",
                      }}
                    >
                      {room.area && room.area.name},
                    </p>
                    <p
                      style={{
                        color: "#666",
                        fontWeight: "bold",
                        fontSize: 18,
                        marginBottom: "5%",
                      }}
                    >
                      {room.city.name}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ position: "relative" }}>
                  <Label
                    size="huge"
                    floated="right"
                    as="a"
                    style={{
                      right: 0,
                      margin: 0,
                      marginTop: "0px",
                      zIndex: 1,
                      position: "absolute",
                      backgroundColor: "#00D5CC",
                      color: "#FFFFFF",
                      opacity: 0.8,
                      borderRadius: "0px 0px 0px 8px",
                    }}
                  >
                    €{room.price}
                  </Label>
                  <Link to={`/room/${room.slug}`}>
                    <Image
                      centered
                      style={{
                        marginBottom: "10%",
                        width: "100%",
                        height: "207px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      src={
                        room.pictures[0]
                          ? renderFavouritePicture(room.pictures)
                          : "https://react.semantic-ui.com/assets/images/wireframe/image.png"
                      }
                    />
                  </Link>
                </Grid.Row>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      ) : (
        <div>
          {rooms.map(room => (
            <div key={room.id}>
              <Grid.Row verticalAlign="middle" style={{ marginBottom: -20 }}>
                <Grid.Column
                  width={11}
                  verticalAlign="middle"
                  style={{ marginTop: 20 }}
                >
                  <p
                    style={{
                      color: "#666",
                      fontWeight: "bold",
                      fontSize: 18,
                      marginBottom: "-2%",
                    }}
                  >
                    {room.area && room.area.name},
                  </p>
                  <p
                    style={{
                      color: "#666",
                      fontWeight: "bold",
                      fontSize: 18,
                      marginBottom: "5%",
                    }}
                  >
                    {room.city.name}
                  </p>
                </Grid.Column>
              </Grid.Row>
              <div>
                <Label
                  size="huge"
                  floated="right"
                  as="a"
                  style={{
                    right: 0,
                    margin: 0,
                    marginTop: "0px",
                    zIndex: 1,
                    position: "absolute",
                    backgroundColor: "#00D5CC",
                    color: "#FFFFFF",
                    opacity: 0.8,
                    borderRadius: "0px 0px 0px 8px",
                  }}
                >
                  €{room.price}
                </Label>
                <Link to={`/room/${room.slug}`}>
                  <Image
                    centered
                    style={{
                      marginBottom: "10%",
                      //marginTop: "10%",
                      maxWidth: "120%",
                      //boxShadow: "5px 5px 5px 1px #dbdbdb",
                      width: "100%",
                      height: "207px",
                      cursor: "pointer",
                    }}
                    src={
                      room.pictures[0]
                        ? renderFavouritePicture(room.pictures)
                        : "https://react.semantic-ui.com/assets/images/wireframe/image.png"
                    }
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
      <Grid centered stackable className="bottom-buttons">
        <Grid.Row>
          <ShareButton
            text={`Check out ${
              profile ? profile.name + "'s" : "this"
            } profile on RooMigo! RooMigo is a way to find like-minded housemates you share interests with.`}
            title={`${
              profile ? profile.name + "'s" : "this"
            } profile on RooMigo`}
            buttonText="profile"
          />
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default ProfileDetailView;
