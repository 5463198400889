import React, { Component } from "react";
import { connect } from "react-redux";
import { login, connectSocket } from "../../actions";
import EmailLoginView from "./email_login_view";
import _ from "lodash";

class EmailLogin extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);

    this.state = {
      session: { email: "", password: "" },
      error: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.session && !_.isEmpty(nextProps.session.data)) {
      this.props.connectSocket(nextProps.session.data.jwt);
      if (this.props.previousPage) {
        this.props.history.push(this.props.previousPage);
      } else {
        this.props.history.push("/profile");
      }
    } else {
      this.setState({ error: nextProps.session.error });
    }
  }

  handleFieldChange(field, value) {
    this.setState({
      session: Object.assign({}, this.state.session, { [field]: value }),
      error: "",
    });
  }

  login(user) {
    this.props.login(this.state.session);
  }

  render() {
    const { session, error } = this.state;
    return (
      <EmailLoginView
        handleFieldChange={this.handleFieldChange}
        login={this.login}
        session={session}
        error={error}
      />
    );
  }
}

const mapStateToProps = ({ session, previousPage }) => ({
  session,
  previousPage,
});

export default connect(mapStateToProps, { login, connectSocket })(EmailLogin);
