/* global window */
import React from "react";
import PropTypes from "prop-types";
import { Image, Grid, Divider, Label } from "semantic-ui-react";
import { renderFavouritePicture } from "../../utils/helpers";

import { Link } from "react-router-dom";
const filter_icon = require("../../assets/images/search.svg");

const RoomListViewMobile = ({ rooms }) => {
  return (
    <div>
      <Grid padded stackable>
        <Grid.Row columns={1} textAlign="left" style={{ marginTop: 20 }}>
          <Grid.Column>
            <Link to="/search_room">
              <span
                style={{
                  marginRight: 10,
                  fontSize: 25,
                  fontWeight: "bold",
                  textDecoration: "none",
                  color: "#272727",
                  cursor: "pointer",
                  top: 5,
                  right: window.innerWidth > 767 ? 40 : 50,
                  zIndex: 1,
                }}
              >
                Rooms
              </span>
              <Image
                verticalAlign="middle"
                src={filter_icon}
                style={{
                  marginBottom: 20,
                  width: "25px",
                  cursor: "pointer",
                  top: window.innerWidth > 767 ? 2 : 6,
                  zIndex: 1,
                }}
              />
            </Link>
            <Divider
              style={{
                marginTop: -5,
                borderWidth: "3px",
                borderRadius: "15px",
                borderColor: "#ff664d",
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {rooms.slice(0, 3).map(room => (
        <div key={room.id}>
          <Label
            size="huge"
            floated="right"
            as="a"
            style={{
              right: 0,
              margin: 0,
              marginTop: "0px",
              zIndex: 1,
              position: "absolute",
              backgroundColor: "#00D5CC",
              color: "#FFFFFF",
              opacity: 0.8,
              borderRadius: "0px 0px 0px 8px",
            }}
          >
            €{room.price}
          </Label>
          <Link to={`/room/${room.slug}`}>
            <Image
              centered
              style={{
                width: "100%",
                height: (window.innerWidth * 3) / 4,
                position: "relative",
                cursor: "pointer",
                objectFit: "cover",
                marginBottom: 20,
                marginTop: 40,
              }}
              src={
                room.pictures[0]
                  ? renderFavouritePicture(room.pictures)
                  : "https://react.semantic-ui.com/assets/images/wireframe/image.png"
              }
            />
          </Link>
          <Grid stackable>
            <Grid.Row columns={8}>
              <Grid.Column width={2}>
                <Image
                  style={{
                    borderRadius: 50,
                    marginLeft: 20,
                    width: 80,
                    height: 80,
                  }}
                  src={
                    room.renter.data.profile.data.pictures[0]
                      ? renderFavouritePicture(
                          room.renter.data.profile.data.pictures
                        )
                      : "https://react.semantic-ui.com/assets/images/wireframe/image.png"
                  }
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Grid.Row columns={8}>
                  <Grid.Column width={4} verticalAlign="middle">
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        marginLeft: "40%",
                        marginTop: "-20%",
                        marginBottom: "10%",
                      }}
                    >
                      {room.area && room.area.name}
                      <span style={{ fontWeight: "300", fontSize: 15 }}>
                        <br />
                        {room.renter.data.profile.data.name},
                        {room.renter.data.profile.data.age}
                      </span>
                    </p>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <p
                      style={{
                        fontSize: 15,
                        fontStyle: "italic",
                        textAlign: "center",
                        color: "#FF664D",
                      }}
                    >
                      {room.renter.data.profile.data.interests
                        .slice(0, 7)
                        .map(interest => `#${interest.name} `)}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      ))}
    </div>
  );
};

RoomListViewMobile.propTypes = {
  rooms: PropTypes.array.isRequired,
};

export default RoomListViewMobile;
