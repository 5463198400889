import React from "react";
import { Dropdown, TextArea } from "semantic-ui-react";
import PropTypes from "prop-types";
import FieldError from "./FieldError";
import { sloganOptions } from "../../../assets/formOptions";
export default function RoomDetailsMore({
  setListingRoomValue,
  room,
  fieldsWithError,

  descriptionRef,
  sloganRef,
}) {
  return (
    <div className="roomigo-container">
      <span className="title-16 light" ref={sloganRef}>
        Slogan
      </span>
      <Dropdown
        fluid
        selection
        id="slogan"
        value={room.slogan}
        placeholder="choose a slogan"
        onChange={(event, data) => setListingRoomValue("slogan", data.value)}
        options={sloganOptions}
      />
      {fieldsWithError.slogan && (
        <FieldError message={fieldsWithError.slogan} />
      )}
      <span className="title-16 light" ref={descriptionRef}>
        Description *
      </span>
      <TextArea
        placeholder="Use this space to tell people more about you, the house and your neighbourhood! People care."
        id="description"
        rows={10}
        value={room.description}
        onChange={event =>
          setListingRoomValue("description", event.target.value)
        }
      />
      {fieldsWithError.description && (
        <FieldError message={fieldsWithError.description} />
      )}
    </div>
  );
}

RoomDetailsMore.propTypes = {
  descriptionRef: PropTypes.object.isRequired,
  sloganRef: PropTypes.object.isRequired,
  setListingRoomValue: PropTypes.func.isRequired,
  room: PropTypes.object.isRequired,
  fieldsWithError: PropTypes.object.isRequired,
};
