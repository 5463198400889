import {
  FETCH_PROFILE_CONNECTION_LIST,
  FETCH_CHAT_MESSAGE_LIST,
  FETCH_PROFILE_MESSAGE_LIST,
  SET_CONNECTION,
  EMPTY_CONNECTION,
} from "../actions/action_types";

export function connectionListReducer(state = [], action) {
  switch (action.type) {
    case FETCH_PROFILE_CONNECTION_LIST: {
      return action.payload.data.data;
    }
    default: {
      return state;
    }
  }
}

export function messageListReducer(state = [], action) {
  switch (action.type) {
    case FETCH_CHAT_MESSAGE_LIST: {
      return action.payload.data.data;
    }
    default: {
      return state;
    }
  }
}

export function profileMessageListReducer(state = [], action) {
  switch (action.type) {
    case FETCH_PROFILE_MESSAGE_LIST: {
      return action.payload.data.data;
    }
    default: {
      return state;
    }
  }
}

export function connectionReducer(state = {}, action) {
  switch (action.type) {
    case SET_CONNECTION: {
      return action.payload.data.data;
    }
    case EMPTY_CONNECTION: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
