import React from "react";
import { withRouter } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import NavBar from "./navigation_bar";
import CookieWarning from "./cookie_warning";
const WebAppLayout = ({
  myProfile,
  location,
  linkToPreviousPage,
  receivedRequests,
  profileMessageList,
}) => {
  return (
    <div>
      <Menu
        fixed="top"
        visible="true"
        width="very wide"
        style={{
          minHeight: "75px",
          position: "fixed",
          zIndex: 1000,
        }}
      >
        <NavBar
          location={location}
          myProfile={myProfile}
          linkToPreviousPage={linkToPreviousPage}
          receivedRequests={receivedRequests}
          profileMessageList={profileMessageList}
        />
      </Menu>
      <CookieWarning />
    </div>
  );
};

export default withRouter(WebAppLayout);
