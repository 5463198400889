import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Form, Divider } from "semantic-ui-react";
import {
  publishRoom,
  uploadPicture,
  emptyRoom,
  setPublicProfile,
  setRoomFieldsWithError,
} from "../../actions";

import { parseError } from "../../utils/helpers";
import PopUp from "../../utils/pop_up";
import moment from "moment";
import { checkBeforeSubmit } from "../../utils/roomUtils";

import Location from "./parts/Location";
import RoomDetails from "./parts/RoomDetails";
import RoomDetailsMore from "./parts/RoomDetailsMore";
import Preferences from "./parts/Preferences";
import Loader from "./parts/Loader";
import UploadImages from "./parts/UploadImages";
import { noSloganOption } from "../../assets/formOptions";

class CreateRoom extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      roomObj: {
        // neighbourhood
        area: null,
        // area
        area_code: null,
        city: "",
        address: "",
        move_in_date: new Date(),
        move_out_date: null,
        type: "",
        ensuite: "",
        roommate_male_number: null,
        roommate_female_number: null,
        price: "",
        deposit: "",
        bills_included: null,
        slogan: noSloganOption,
        description: "",
        pictures: [],
        minimum_stay: "",
        age_min: "",
        age_max: "",
        preferred_gender: "both",
        preferred_occupation: "both",
        preferred_relationship: "both",
      },
      availableNeighbourhoodList: [],
      availableAreaList: [],
      isModalOpen: false,
      closeIcon: false,
      message: "",
      errorMessageUpload: "",
      isUpdated: false,
      closeOnDimmerClick: true,
      searchAddress: "",
      searchAddressCity: "",
      sectionsOpened: 0,
    };
    // creating ref for scroll on error
    this.addressRef = React.createRef();
    this.areaRef = React.createRef();
    this.area_codeRef = React.createRef();

    this.roommate_male_numberRef = React.createRef();
    this.priceRef = React.createRef();
    this.typeRef = React.createRef();
    this.ensuiteRef = React.createRef();
    this.bills_includedRef = React.createRef();

    this.descriptionRef = React.createRef();
    this.sloganRef = React.createRef();
    this.age_minRef = React.createRef();
    this.picturesRef = React.createRef();

    // success next scroll
    this.roomDetailsRef = React.createRef();
    this.roomDetailsMoreRef = React.createRef();
    this.preferencesRef = React.createRef();
  }
  componentDidMount() {
    if (this.props.myProfile.profileIsOnSync) {
      this.setState({ isLoading: true });
    } else {
      this.setState({ isLoading: false });
    }
    if (this.props.selectedCity) {
      this.setState({
        roomObj: { ...this.state.roomObj, city: this.props.selectedCity },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //on search address success lets scroll below to see data
    if (
      this.state.roomObj.address &&
      this.state.roomObj.address !== prevState.roomObj.address
    ) {
      if (this.state.city === "Dublin") {
        window.scrollTo(0, this.area_codeRef.current.offsetTop - 100);
      } else {
        window.scrollTo(0, this.areaRef.current.offsetTop - 100);
      }
    }

    if (
      this.props.myProfile.profileIsOnSync !==
      prevProps.myProfile.profileIsOnSync
    ) {
      if (this.props.myProfile.profileIsOnSync) {
        this.setState({ isLoading: true });
      } else {
        //component loaded with needed props

        this.setState({ isLoading: false });
      }
    }

    if (!_.isEqual(this.props.file, prevProps.file)) {
      let pictures = this.state.roomObj.pictures;
      pictures = [
        ...pictures,
        { url: this.props.file.file_upload, favourite: false },
      ];
      this.setRoomSingleValue("pictures", pictures);
    }

    if (
      !_.isEqual(this.props.room.data, prevProps.room.data) ||
      !_.isEmpty(this.props.room.error)
    ) {
      if (this.props.room.data.id) {
        this.setState({
          message: "Room published successfully!  Where next?",
          isModalOpen: true,
          isUpdated: true,
          room: this.props.room.data,
        });
      } else if (
        !_.isEmpty(this.props.room.error) &&
        this.props.room.error !== prevProps.room.error
      ) {
        const message = parseError(this.props.room.error, "published");
        this.setState({
          message,
          isModalOpen: true,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.emptyRoom();
  }

  uploadPicture = file => {
    if (this.state.roomObj.pictures.length >= 10) {
      this.setState({ errorMessageUpload: "You can add only 10 Photos" });
    } else {
      this.props.uploadPicture(file, "400x300");
    }
  };

  publishRoom = () => {
    const hasAreaCode = this.state.availableAreaList.length > 0 ? true : false;

    const formErrors = checkBeforeSubmit(this.state.roomObj, hasAreaCode);

    let room = this.state.roomObj;
    room.user_id = this.props.myProfile.data.user_id;
    if (room.area) {
      room.area_id = room.area.id;
    }
    room.area_code_id = room.area_code;
    if (formErrors.hasAnyError === false) {
      if (this.props.myProfile.data.is_searchable) {
        if (this.state.roomObj.roommate_male_number === null) {
          this.setState(
            {
              roomObj: { ...this.state.roomObj, roommate_male_number: 0 },
            },
            () => this.props.publishRoom(this.state.roomObj)
          );
        } else if (this.state.roomObj.roommate_female_number === null) {
          this.setState(
            {
              roomObj: { ...this.state.roomObj, roommate_female_number: 0 },
            },
            () => this.props.publishRoom(this.state.roomObj)
          );
        } else {
          this.props.publishRoom(this.state.roomObj);
        }
      }
    } else {
      this.setState({
        message:
          "Please check form fields again. Some required information is missing.",
        isModalOpen: true,
        closeIcon: true,
      });
      this.props
        .setRoomFieldsWithError(formErrors.fields)
        .then(() => this.showSubmitError());
    }
  };
  scrollToFirstError = firstErrorRef => {
    // console.log(firstErrorRef);
    if (firstErrorRef) {
      window.scrollTo(0, this[firstErrorRef].current.offsetTop - 100);
    }
  };

  showSubmitError = () => {
    // let errorsCount = 0;
    let firstErrorRef = null;
    Object.entries(this.props.fieldsWithError).forEach(entry => {
      let key = entry[0];
      let value = entry[1];
      if (value) {
        // errorsCount++;
        if (!firstErrorRef && this[key + "Ref"]) {
          if (this[key + "Ref"].current) {
            firstErrorRef = key + "Ref";
          }
        }
      }
    });

    this.scrollToFirstError(firstErrorRef);
  };

  updateAndListRoom = () => {
    let { room } = this.state;
    room.user_id = this.props.myProfile.data.user_id;
    room.area_id = room.area.id;
    room.area_code_id = room.area_code.id;
    this.props.setPublicProfile(true);
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  goToList = () => {
    this.props.history.push("/my_rooms");
  };

  goToProfile = () => {
    this.props.history.push("/profile");
  };

  deletePicture = picture => {
    // TODO what about BE, is the photo deleted after time out ?????

    const pictures = this.state.roomObj.pictures.filter(
      p => p.url !== picture.split("/")[3]
    );
    this.setRoomSingleValue("pictures", pictures);
  };

  favouritePicture = picture => {
    const pictures = this.state.roomObj.pictures.forEach(p => {
      if (p.url === picture.split("/")[3]) {
        p.favourite = true;
      } else {
        p.favourite = false;
      }
    });
    this.setRoomSingleValue("pictures", pictures);
  };

  handleDateChange = (name, value) => {
    const moveOutDate = this.state.roomObj.move_out_date;
    const moveInDate = this.state.roomObj.move_in_date;
    if (name === "move_in_date") {
      if (moveOutDate) {
        const isAfter = moment(value).isAfter(moveOutDate);
        if (isAfter) {
          this.setRoomSingleValue("move_in_date", moveOutDate);
          this.setRoomSingleValue("move_out_date", value);
        } else {
          this.setRoomSingleValue("move_in_date", value);
        }
      } else {
        this.setRoomSingleValue("move_in_date", value);
      }
    } else if (name === "move_out_date") {
      if (moveInDate) {
        const isAfter = moment(moveInDate).isAfter(value);
        if (isAfter) {
          this.setRoomSingleValue("move_out_date", moveInDate);
          this.setRoomSingleValue("move_in_date", value);
        } else {
          this.setRoomSingleValue("move_out_date", value);
        }
      } else {
        this.setRoomSingleValue("move_out_date", value);
      }
    } else {
      throw new Error("Date fields names are incorrect.");
    }
  };

  openNextSection = () => {
    const room = this.state.roomObj;
    const valuesObjToCheckArr = [
      {
        area: room.area,
        area_code: room.area_code,
        city: room.city,
        address: room.address,
      },
      {
        type: room.type,
        ensuite: room.ensuite,
        roommate_male_number: room.roommate_male_number,
        roommate_female_number: room.roommate_female_number,
        price: room.price,
        bills_included: room.bills_included,
      },
      {
        slogan: room.slogan,
        description: room.description,
        pictures: room.pictures,
      },
    ];

    const hasAreaCode = this.state.availableAreaList.length > 0 ? true : false;
    const checkResponse = checkBeforeSubmit(
      valuesObjToCheckArr[this.state.sectionsOpened],
      hasAreaCode
    );
    if (checkResponse.hasAnyError === false) {
      this.setState(
        prevState => ({
          sectionsOpened: prevState.sectionsOpened + 1,
        }),
        () => this.scrollToNextSection()
      );
    }
    this.props
      .setRoomFieldsWithError(checkResponse.fields)
      .then(() => this.showSubmitError());
  };
  scrollToNextSection = () => {
    switch (this.state.sectionsOpened) {
      case 1:
        window.scrollTo(0, this.roomDetailsRef.current.offsetTop);
        break;
      case 2:
        window.scrollTo(0, this.roomDetailsMoreRef.current.offsetTop);
        break;
      case 3:
        window.scrollTo(0, this.preferencesRef.current.offsetTop);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log("Something wrong with next button scroll on success");
    }
  };

  setRoomSingleValue = (name, value) => {
    this.setState(prevState => ({
      roomObj: { ...prevState.roomObj, [name]: value },
    }));
  };
  setRoomSeveralValues = newValues => {
    this.setState(prevState => ({
      ...prevState,
      ...newValues,
      roomObj: {
        ...prevState.roomObj,
        ...newValues.roomObj,
      },
    }));
  };
  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else if (!this.isProfileVisible()) {
      return this.renderHiddenProfilePopup();
    }
    const currentRoomObj = this.state.roomObj;
    if (this.props.cities.length === 0) {
      return <div />;
    }
    return (
      <div className="site-container">
        <Form
          onSubmit={event => event.preventDefault()}
          style={{ marginBottom: 10 }}
        >
          <Location
            formType="create"
            cities={this.props.cities}
            setRoomSeveralValues={this.setRoomSeveralValues}
            setRoomFieldsWithError={this.props.setRoomFieldsWithError}
            addressRef={this.addressRef}
            areaRef={this.areaRef}
            area_codeRef={this.area_codeRef}
            room={currentRoomObj}
            coordinates={this.state.coordinates}
            setListingRoomValue={this.setRoomSingleValue}
            fieldsWithError={this.props.room.currentRoom.fieldsWithError}
            openNextSection={this.openNextSection}
            availableNeighbourhoodList={this.state.availableNeighbourhoodList}
            availableAreaList={this.state.availableAreaList}
          />
          <div
            className={`room-list-section ${
              this.state.sectionsOpened >= 1
                ? "visible-section"
                : "invisible-section"
            }`}
          >
            <div className="pt-25" />
            <RoomDetails
              roomDetailsRef={this.roomDetailsRef}
              priceRef={this.priceRef}
              ensuiteRef={this.ensuiteRef}
              typeRef={this.typeRef}
              roommate_male_numberRef={this.roommate_male_numberRef}
              bills_includedRef={this.bills_includedRef}
              handleDateChange={this.handleDateChange}
              setListingRoomValue={this.setRoomSingleValue}
              room={currentRoomObj}
              fieldsWithError={this.props.room.currentRoom.fieldsWithError}
            />
          </div>

          <div
            className={`room-list-section ${
              this.state.sectionsOpened >= 2
                ? "visible-section"
                : "invisible-section"
            }`}
          >
            <div className="pt-25" />
            <div className="mx-20">
              <h2
                style={{ marginTop: "15px", color: "#707070" }}
                ref={this.roomDetailsMoreRef}
              >
                More Room Details
              </h2>
              <Divider
                style={{
                  borderWidth: "3px",
                  borderRadius: "15px",
                  borderColor: "#FF664D",
                }}
              />
              {/* <span className="title-16">More Room Details</span> */}
            </div>
            <div className="desktop-row">
              <div className="flex-1 room-list-flex-property">
                <RoomDetailsMore
                  setListingRoomValue={this.setRoomSingleValue}
                  room={currentRoomObj}
                  fieldsWithError={this.props.room.currentRoom.fieldsWithError}
                  descriptionRef={this.descriptionRef}
                  sloganRef={this.sloganRef}
                />
              </div>
              <div className="flex-1 room-list-flex-property">
                <UploadImages
                  picturesRef={this.picturesRef}
                  pictures={currentRoomObj.pictures}
                  uploadPicture={this.uploadPicture}
                  errorMessageUpload={this.state.errorMessageUpload}
                  isSearching={this.props.isSearching}
                  deletePicture={this.deletePicture}
                  favouritePicture={this.favouritePicture}
                  fieldsWithError={this.props.room.currentRoom.fieldsWithError}
                  setRoomFieldsWithError={this.props.setRoomFieldsWithError}
                />
              </div>
            </div>
          </div>

          <div
            className={`room-list-section ${
              this.state.sectionsOpened >= 3
                ? "visible-section"
                : "invisible-section"
            }`}
          >
            <div className="pt-25" />
            <Preferences
              preferencesRef={this.preferencesRef}
              age_minRef={this.age_minRef}
              setAge={this.setAge}
              setListingRoomValue={this.setRoomSingleValue}
              room={currentRoomObj}
              fieldsWithError={this.props.room.currentRoom.fieldsWithError}
            />
          </div>

          <div className="pt-25" />

          {this.state.roomObj.address ? (
            this.state.sectionsOpened < 3 ? (
              <div className="roomigo-container">
                <div className="mx-20">
                  <button
                    onClick={this.openNextSection}
                    className="roomigo-submit-btn next-btn"
                  >
                    Next
                  </button>
                </div>
              </div>
            ) : (
              <div className="roomigo-container">
                {this.renderPopup(
                  this.state.message,
                  this.state.isModalOpen,
                  this.state.closeOnDimmerClick,
                  currentRoomObj
                )}
              </div>
            )
          ) : null}
        </Form>
      </div>
    );
  }

  isProfileVisible = () => {
    const {
      myProfile: {
        data: { is_searchable },
      },
    } = this.props;
    return is_searchable;
  };

  renderHiddenProfilePopup = () => {
    return this.renderPopup(
      "Sorry, you need to complete your profile and make it visible on RooMigo to list a room.",
      true,
      true,
      this.state.roomObj
    );
  };

  renderPopup = (message, isModalOpen, closeOnDimmerClick, currentRoomObj) => (
    <PopUp
      title="List my room!"
      message={message}
      submit={this.publishRoom}
      openModal={this.openModal}
      closeModal={this.closeModal}
      isModalOpen={isModalOpen}
      closeIcon={this.state.closeIcon}
      searchable={this.props.myProfile.data.is_searchable}
      goToList={
        this.state.message === "Room published successfully!  Where next?" &&
        this.goToList
      }
      updateAndList={
        !this.props.myProfile.data.is_searchable &&
        !_.isEmpty(currentRoomObj.pictures)
      }
      updateAndListRoom={this.updateAndListRoom}
      isUpdated={this.state.isUpdated}
      closeOnDimmerClick={closeOnDimmerClick}
      goToProfile={this.goToProfile}
      slug={currentRoomObj.slug}
    />
  );
}

CreateRoom.propTypes = {
  cities: PropTypes.array.isRequired,
  myProfile: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  fieldsWithError: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired,
  emptyRoom: PropTypes.func.isRequired,
  uploadPicture: PropTypes.func.isRequired,
  publishRoom: PropTypes.func.isRequired,
  setPublicProfile: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isSearching: PropTypes.bool.isRequired,
  setRoomFieldsWithError: PropTypes.func.isRequired,
  selectedCity: PropTypes.object,
};
const mapStateToProps = ({
  room,
  file,
  myProfile,
  areas,
  areaCodes,
  isSearching,
  cities,
}) => ({
  room,
  fieldsWithError: room.currentRoom.fieldsWithError,
  file,
  myProfile,
  areas,
  areaCodes,
  isSearching,
  cities: cities.cities,
  selectedCity: cities.selectedCity,
});

const actions = {
  publishRoom,
  uploadPicture,
  emptyRoom,
  setPublicProfile,
  setRoomFieldsWithError,
};

export default connect(
  mapStateToProps,
  actions
)(CreateRoom);
