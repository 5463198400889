import React from "react";
import cookie from "react-cookies";
import SearchRoom from "./search_room";
import Loader from "../../parts/Loader";
import WaitForState from "../../parts/WaitForState";

import { fetchUserSearchParams } from "../../../actions";
import { connect } from "react-redux";

const mapAnonymousStateToProps = state => {
  const {
    cities: { citiesIsOnSync, cities },
  } = state;
  if (citiesIsOnSync) {
    return false;
  }
  if (!cities || cities.length < 1) {
    return false;
  }
  return {
    roomSearch: state.roomSearch.searchParams,
    ...state.cities,
  };
};

const mapAuthenticatedStateToProps = state => {
  const {
    roomSearch: { userSearchParamsIsOnSync, searchParams },
  } = state;
  if (userSearchParamsIsOnSync) {
    return false;
  }
  const anonymousState = mapAnonymousStateToProps(state);
  if (!anonymousState || !searchParams) {
    return false;
  }
  return {
    ...anonymousState,
    roomSearch: state.roomSearch.searchParams,
    userSearchParamsIsOnSync: state.roomSearch.userSearchParamsIsOnSync,
  };
};

/**
 * Waitable Search room
 * @param {*} props
 */
const WaitableSearchRoom = props => {
  const userId = props.myProfile.data.user_id;

  if (userId) {
    const initialActions = [fetchUserSearchParams(userId)];

    return (
      <WaitForState
        {...props}
        component={SearchRoom}
        initialActions={initialActions}
        mapStateToProps={mapAuthenticatedStateToProps}
      />
    );
  } else if (!cookie.load("jwt")) {
    return (
      <WaitForState
        {...props}
        component={SearchRoom}
        initialActions={[]}
        mapStateToProps={mapAnonymousStateToProps}
      />
    );
  } else {
    return <Loader />;
  }
};

const mapStateToProps = ({ myProfile }) => ({
  myProfile,
});

export default connect(mapStateToProps)(WaitableSearchRoom);
