import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import {
  fetchProfileDetail,
  sendRequest,
  fetchLoggedInUserProfile,
  emptyRequest,
  getPreviousPage,
  fetchMyProfileAndRequests,
  acceptRequest,
  declineRequest,
  setConnection,
  fetchProfileRoomList,
  fetchRoomDetail,
  connectSocket,
  emptyProfile,
  emptyRoomList,
} from "../../actions";
import { Container, Form, Icon, Image, Label } from "semantic-ui-react";
import ProfileDetailView from "./profile_detail_view";
import cookie from "react-cookies";
import { IMAGES_S3_BUCKET_URL } from "../../actions/root_url";

class ProfileDetail extends Component {
  constructor(props) {
    super(props);
    const jwt = cookie.load("jwt");
    this.state = {
      myProfile: this.props.myProfile,
      profile: this.props.profile,
      request: this.props.request,
      inverseRequest: this.props.inverseRequest,
      filePicturesList: [],
      roomList: [],
      jwt,
    };
    this.props.fetchProfileDetail(this.props.match.params.id);
    this.sendRequest = this.sendRequest.bind(this);
    this.linkToPreviousPage = this.linkToPreviousPage.bind(this);
    this.renderPicturesList = this.renderPicturesList.bind(this);
    this.setConnection = this.setConnection.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.myProfile, nextProps.myProfile)) {
      this.setState({ myProfile: nextProps.myProfile });
    }
    if (!_.isEqual(this.props.profile, nextProps.profile)) {
      this.setState({ profile: nextProps.profile });
      this.props.fetchMyProfileAndRequests(nextProps.profile.data);
      this.props.fetchProfileRoomList(nextProps.profile.data);
    }
    if (!_.isEqual(this.props.inverseRequest, nextProps.inverseRequest)) {
      this.setState({ inverseRequest: nextProps.inverseRequest });
    }
    if (!_.isEqual(this.props.request, nextProps.request)) {
      this.setState({ request: nextProps.request });
    }
    if (!_.isEqual(this.props.roomList, nextProps.roomList)) {
      this.setState({ roomList: nextProps.roomList });
    }
    if (!_.isEqual(this.props.room, nextProps.room) && nextProps.room.data.id) {
      this.props.history.push(`/room/${nextProps.room.data.slug}`);
    }
  }

  sendRequest(receiver) {
    const request = {
      receiver,
      status: "Pending",
    };
    this.props.sendRequest(request);
  }

  setConnection() {
    this.props.setConnection(this.state.profile.data.id);
    this.props.history.push("/requests");
  }

  componentWillUnmount() {
    this.props.emptyRequest();
    this.props.emptyProfile();
    this.props.emptyRoomList();
    this.setState({ filePicturesList: [] });
  }

  linkToPreviousPage() {
    this.props.getPreviousPage(this.props.location.pathname);
  }

  renderPicturesList(profile) {
    let filePicturesList = [];
    profile.pictures.map(picture =>
      filePicturesList.push({
        original: `${IMAGES_S3_BUCKET_URL}/${picture.url}`,
      })
    );
    return filePicturesList;
  }

  renderItem(item, profile) {
    return (
      <div>
        {profile.room_count > 0 && (
          <div>
            <Label
              size="huge"
              floated="left"
              as="a"
              style={{
                left: 12,
                zIndex: 1,
                position: "absolute",
                backgroundColor: "rgb(255, 102, 77)",
                color: "#fff",
                opacity: 0.8,
                width: 45,
                borderRadius: 7,
              }}
            >
              <Icon
                name="home icon"
                style={{
                  marginLeft: -6,
                  opacity: 1.5,
                  backgroundColor: "rgb(255, 102, 77)",
                }}
              />
            </Label>
          </div>
        )}
        {(profile.team_with_others || profile.looking_for_a_room) && (
          <div>
            <Label
              size="huge"
              floated="right"
              as="a"
              style={{
                right: "27px",
                zIndex: 1,
                position: "absolute",
                backgroundColor: "#00D5CC",
                color: "#fff",
                opacity: 0.8,
                width: 45,
                borderRadius: 7,
              }}
            >
              <Icon
                name={profile.team_with_others ? "users" : "binoculars"}
                style={{ marginLeft: -6, opacity: 1.5 }}
              />
            </Label>
            {profile.team_with_others && profile.looking_for_a_room && (
              <Label
                size="huge"
                floated="right"
                as="a"
                style={{
                  right: "76px",
                  zIndex: 1,
                  position: "absolute",
                  backgroundColor: "#00D5CC",
                  color: "#fff",
                  opacity: 0.8,
                  width: 45,
                  borderRadius: 7,
                }}
              >
                <Icon
                  name="binoculars"
                  style={{ marginLeft: -3, opacity: 1.5 }}
                />
              </Label>
            )}
          </div>
        )}{" "}
        <Image
          centered
          style={{
            width: "90%",
            height: "90%",
            borderRadius: "8px",
            position: "relative",
          }}
          src={item.original}
        />
      </div>
    );
  }

  renderLeftNav(onClick, disabled) {
    return (
      <button
        className="image-gallery-custom-left-nav"
        disabled={disabled}
        onClick={onClick}
        style={{
          height: "98%",
          width: "10%",
          backgroundColor: "rgba(52, 52, 52, 0.0)",
          position: "absolute",
          left: "5%",
          zIndex: 1,
          border: 0,
          outline: "none",
        }}
      >
        <Icon
          name="chevron left"
          size={window.innerWidth > 767 ? "small" : "large"}
          circular
          style={{
            zIndex: 1,
            opacity: ".8",
            backgroundColor: "#00D5CC",
            color: "#fff",
          }}
        />
      </button>
    );
  }

  renderRightNav(onClick, disabled) {
    return (
      <button
        className="image-gallery-custom-right-nav"
        disabled={disabled}
        onClick={onClick}
        style={{
          height: "98%",
          width: "11%",
          backgroundColor: "rgba(52, 52, 52, 0.0)",
          position: "absolute",
          right: "10%",
          zIndex: 1,
          border: 0,
          outline: "none",
        }}
      >
        <Icon
          name="chevron right"
          size={window.innerWidth > 767 ? "small" : "large"}
          circular
          style={{
            zIndex: 1,
            opacity: ".8",
            backgroundColor: "#00D5CC",
            color: "#fff",
          }}
        />
      </button>
    );
  }

  render() {
    return (
      <div>
        <Container style={{ maxWidth: "1100px" }}>
          <Form onSubmit={event => event.preventDefault()}>
            <ProfileDetailView
              myProfile={this.state.myProfile.data}
              profile={this.state.profile.data}
              sendRequest={this.sendRequest}
              request={this.state.request}
              inverseRequest={this.state.inverseRequest}
              linkToPreviousPage={this.linkToPreviousPage}
              renderItem={this.renderItem}
              renderLeftNav={this.renderLeftNav}
              renderRightNav={this.renderRightNav}
              renderPicturesList={this.renderPicturesList}
              acceptRequest={this.props.acceptRequest}
              declineRequest={this.props.declineRequest}
              setConnection={this.setConnection}
              rooms={this.state.roomList}
            />
          </Form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({
  myProfile,
  profile,
  request,
  inverseRequest,
  roomList,
  room,
  socket,
}) => ({
  myProfile,
  profile,
  request,
  inverseRequest,
  roomList,
  room,
  socket,
});

const actions = {
  fetchProfileDetail,
  sendRequest,
  fetchLoggedInUserProfile,
  emptyRequest,
  getPreviousPage,
  fetchMyProfileAndRequests,
  acceptRequest,
  declineRequest,
  setConnection,
  fetchProfileRoomList,
  fetchRoomDetail,
  connectSocket,
  emptyRoomList,
  emptyProfile,
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(ProfileDetail)
);
