import React from "react";
import {
  Label,
  Icon,
  Container,
  Image,
  Grid,
  Divider,
} from "semantic-ui-react";
import {
  renderFavouritePicture,
} from "../../utils/helpers";
import { Link } from "react-router-dom";
import personPlaceholder from "../../assets/images/person-placeholder.jpg";

const filter_icon = require("../../assets/images/search.svg");

const ProfileListView = ({ profileList }) => {
  return (
    <Container fluid style={{ marginTop: 15 }}>
      <Grid stackable>
        <Grid.Row columns={2} textAlign="left">
          <Grid.Column>
            <Link to="/search_profile">
              <span
                style={{
                  marginRight: 10,
                  fontSize: 25,
                  fontWeight: "bold",
                  textDecoration: "none",
                  color: "#352727",
                  cursor: "pointer",
                  top: 5,
                  right: window.innerWidth > 767 ? 40 : 50,
                  zIndex: 1,
                }}
              >
                People
              </span>
              <Image
                verticalAlign="middle"
                src={filter_icon}
                style={{
                  marginBottom: 10,
                  width: "25px",
                  cursor: "pointer",
                  top: window.innerWidth > 767 ? 2 : 6,
                  zIndex: 1,
                }}
              />
            </Link>
            <Divider
              style={{
                borderWidth: "3px",
                borderRadius: "15px",
                borderColor: "#ff664d",
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable columns={4}>
        {profileList.slice(0, window.innerWidth > 674 ? 4 : 3).map(profile => (
          <Grid.Column key={profile.id} style={{ padding: 38 }} width={4}>
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  position: " absolute",
                  top: 10,
                  left: 10,
                  zIndex: 1,
                }}
              >
                {profile.room_count > 0 && (
                  <div>
                    <Label
                      size="huge"
                      floated="left"
                      as="a"
                      style={{
                        zIndex: 1,
                        position: "absolute",
                        backgroundColor: "rgb(255, 102, 77)",
                        color: "#fff",
                        opacity: 0.8,
                        width: 45,
                        borderRadius: 7,
                      }}
                    >
                      <Icon
                        name="home"
                        style={{
                          marginLeft: -6,
                          opacity: 1.5,
                          backgroundColor: "rgb(255, 102, 77)",
                        }}
                      />
                    </Label>
                  </div>
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  zIndex: 1,
                }}
              >
                {profile.team_with_others === true &&
                  profile.looking_for_a_room === false && (
                    <Label
                      size="huge"
                      floated="right"
                      as="a"
                      style={{
                        backgroundColor: "#00D5CC",
                        color: "#fff",
                        opacity: 0.8,
                        width: 45,
                        borderRadius: 7,
                      }}
                    >
                      <Icon
                        name="users"
                        style={{ marginLeft: -6, opacity: 1.5 }}
                      />
                    </Label>
                  )}
                {profile.team_with_others === false &&
                  profile.looking_for_a_room === true && (
                    <Label
                      size="huge"
                      floated="right"
                      as="a"
                      style={{
                        backgroundColor: "#00D5CC",
                        color: "#fff",
                        opacity: 0.8,
                        width: 45,
                        borderRadius: 7,
                      }}
                    >
                      <Icon
                        name="binoculars"
                        style={{ marginLeft: -3, opacity: 1.5 }}
                      />
                    </Label>
                  )}
                {profile.team_with_others === true &&
                  profile.looking_for_a_room === true && (
                    <div>
                      <Label
                        size="huge"
                        floated="right"
                        as="a"
                        style={{
                          backgroundColor: "#00D5CC",
                          color: "#fff",
                          opacity: 0.8,
                          width: 45,
                          borderRadius: 7,
                        }}
                      >
                        <Icon
                          name="users"
                          style={{ marginLeft: -6, opacity: 1.5 }}
                        />
                      </Label>
                      <Label
                        size="huge"
                        floated="right"
                        as="a"
                        style={{
                          right: "46px",
                          backgroundColor: "#00D5CC",
                          color: "#fff",
                          opacity: 0.8,
                          width: 45,
                          borderRadius: 7,
                        }}
                      >
                        <Icon
                          name="binoculars"
                          style={{ marginLeft: -3, opacity: 1.5 }}
                        />
                      </Label>
                    </div>
                  )}
              </div>
            </div>
            <Link to={`/profile/${profile.slug}`}>
              <Image
                className="profilefoto"
                style={{
                  borderRadius: "8px",
                  position: "relative",
                  cursor: "pointer",
                  marginBottom: 20,
                }}
                src={
                  profile.pictures[0]
                    ? renderFavouritePicture(profile.pictures)
                    : { personPlaceholder }
                }
              />
            </Link>
            <p style={{ fontWeight: "bold", color: "#707070", fontSize: 20 }}>
              {profile.name &&
                `${profile.name}, ${profile.age}`}
            </p>
            <Grid.Row>
              <p
                style={{
                  fontSize: 15,
                  fontStyle: "italic",
                  textAlign: "left",
                  marginTop: "3%",
                  color: "#FF664D",
                }}
              >
                {profile.interests[0] ? (
                  profile.interests.map(interest => `#${interest.name} `)
                ) : (
                  <label style={{ color: "grey" }}>No interest</label>
                )}
              </p>
            </Grid.Row>
          </Grid.Column>
        ))}
      </Grid>
    </Container>
  );
};

export default ProfileListView;
