import React from "react";
import PropTypes from "prop-types";

function FieldError({ message }) {
  return <span className="error-13 visible">{message}</span>;
}

FieldError.propTypes = {
  message: PropTypes.string.isRequired,
};

export default FieldError;
