import React from "react";
import { Icon } from "semantic-ui-react";

export const LeftNav = ({ onClick, disabled, left }) => (
  <button
    className="image-gallery-custom-left-nav"
    disabled={disabled}
    onClick={onClick}
    style={{
      top: '40%',
      height: "20%",
      width: "10%",
      backgroundColor: "rgba(52, 52, 52, 0.0)",
      position: "absolute",
      left,
      zIndex: 1,
      border: 0,
      outline: "none",
    }}
  >
    <Icon
      name="chevron left"
      size="large"
      circular
      style={{
        zIndex: 1,
        opacity: ".8",
        backgroundColor: "#00D5CC",
        color: "#fff"
      }}
    />
  </button>
);

export const RightNav = ({ onClick, disabled, right }) => (
  <button
    className="image-gallery-custom-right-nav"
    disabled={disabled}
    onClick={onClick}
    style={{
      top: '40%',
      height: "20%",
      width: "10%",
      backgroundColor: "rgba(52, 52, 52, 0.0)",
      position: "absolute",
      zIndex: 1,
      border: 0,
      right,
      outline: "none",
    }}
  >
    <Icon
      name="chevron right"
      size="large"
      circular
      style={{
        zIndex: 1,
        opacity: ".8",
        backgroundColor: "#00D5CC",
        color: "#fff"
      }}
    />
  </button>
);
