import { combineReducers } from 'redux';
import { sessionReducer, previousPageReducer } from './session_reducers';
import interestListReducer from './interest_reducers';
import {
  myProfileReducer,
  profileReducer,
  profileListReducer,
  profileSearchReducer,
  profileSearchPageReducer,
  homeProfileListReducer,
} from './profile_reducers';
import { fileUploadReducer, isSearchingReducer } from './file_reducers';
import {
  homeRoomListReducer,
  myRoomListReducer,
  roomListReducer,
  roomReducer,
  roomToBookReducer,
  roomSearchReducer,
  roomSearchPageReducer,
  citiesReducer,
} from './room_reducers';
import {
  sentRequestListReducer,
  receivedRequestListReducer,
  requestReducer,
  inverseRequestReducer,
} from './request_reducers';
import { SocketReducer, NotificationSocketReducer } from './socket_reducers';
import {
  connectionListReducer,
  messageListReducer,
  profileMessageListReducer,
  connectionReducer,
} from './connection_reducers';
import { areaListReducer, areaCodeListReducer } from './location_reducers';
import cookieReducer from './cookie_reducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  interestList: interestListReducer,
  myProfile: myProfileReducer,
  profile: profileReducer,
  profileList: profileListReducer,
  homeProfileList: homeProfileListReducer,
  profileSearch: profileSearchReducer,
  profileSearchPage: profileSearchPageReducer,
  file: fileUploadReducer,
  cities: citiesReducer,
  room: roomReducer,
  roomList: roomListReducer,
  homeRoomList: homeRoomListReducer,
  myRoomList: myRoomListReducer,
  roomToBook: roomToBookReducer,
  roomSearch: roomSearchReducer,
  roomSearchPage: roomSearchPageReducer,
  request: requestReducer,
  inverseRequest: inverseRequestReducer,
  sentRequestList: sentRequestListReducer,
  receivedRequestList: receivedRequestListReducer,
  socket: SocketReducer,
  connection: connectionReducer,
  connectionList: connectionListReducer,
  messageList: messageListReducer,
  profileMessageList: profileMessageListReducer,
  previousPage: previousPageReducer,
  areas: areaListReducer,
  areaCodes: areaCodeListReducer,
  notifications: NotificationSocketReducer,
  isSearching: isSearchingReducer,
  acceptedCookie: cookieReducer,
});

export default rootReducer;
