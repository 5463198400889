/* global window */
import React from "react";
import { Button } from "semantic-ui-react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import {
  relationshipOptionsProfileSearch,
  genderOptions,
  teamOptions,
  statusOptions,
} from "../../../assets/formOptions";
const SearchProfilePreferenceView = ({ data, handleFieldChange, cities }) => {
  return (
    <div>
      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light">City</span>
        <Button.Group widths={4}>
          {cities.map(cityInArr => (
            <Button
              data-tip
              type="button"
              key={cityInArr.id}
              className="roomigo-btn"
              active={
                !!data.preferred_city && data.preferred_city.id === cityInArr.id
              }
              value={cityInArr.id}
              onClick={() => handleFieldChange("preferred_city", cityInArr)}
            >
              {cityInArr.name}
            </Button>
          ))}
        </Button.Group>
      </div>

      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light">Show me</span>
        <Button.Group widths={4}>
          {teamOptions.map(show_me => (
            <>
              {window.innerWidth > 767 && show_me.value === "team_ups" ? (
                <ReactTooltip id="team_ups_btn">
                  These are people who want to team-up with others to find a
                  place
                </ReactTooltip>
              ) : null}
              <Button
                data-tip
                data-for={show_me.value === "team_ups" ? "team_ups_btn" : null}
                type="button"
                key={show_me.value}
                className="roomigo-btn"
                active={data.show_me === show_me.value}
                value={show_me.value}
                onClick={() => handleFieldChange("show_me", show_me.value)}
              >
                {show_me.text}
              </Button>
            </>
          ))}
        </Button.Group>
      </div>
      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light">Gender</span>
        <Button.Group widths={3}>
          {genderOptions.map(gender => (
            <Button
              type="button"
              key={gender.value}
              className="roomigo-btn"
              active={data.preferred_gender === gender.value}
              value={gender.value}
              onClick={() =>
                handleFieldChange("preferred_gender", gender.value)
              }
            >
              {gender.text}
            </Button>
          ))}
        </Button.Group>
      </div>
      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light">Aged</span>

        <div className="flex-row p-relative">
          <span className="title-16 light my-auto">From</span>
          <input
            className="room-listing-date-input my-auto"
            type="number"
            value={data.age_min}
            placeholder="Min"
            onChange={event =>
              handleFieldChange("age_min", parseInt(event.target.value, 10))
            }
          />

          <span className="title-16 light my-auto">To</span>
          <input
            className="room-listing-date-input my-auto"
            value={data.age_max}
            placeholder="Max"
            type="number"
            onChange={event =>
              handleFieldChange("age_max", parseInt(event.target.value, 10))
            }
          />
        </div>
      </div>
      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light">Living As</span>

        <Button.Group widths={3}>
          {relationshipOptionsProfileSearch.map(relationship => (
            <Button
              type="button"
              key={relationship.value}
              className="roomigo-btn"
              active={data.preferred_relationship === relationship.value}
              value={relationship.value}
              onClick={() =>
                handleFieldChange("preferred_relationship", relationship.value)
              }
            >
              {relationship.text}
            </Button>
          ))}
        </Button.Group>
      </div>

      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light">Status</span>
        <Button.Group widths={3}>
          {statusOptions.map(status => (
            <Button
              type="button"
              key={status.value}
              className="roomigo-btn"
              active={data.preferred_occupation === status.value}
              value={status.value}
              onClick={() =>
                handleFieldChange("preferred_occupation", status.value)
              }
            >
              {status.text}
            </Button>
          ))}
        </Button.Group>
      </div>
    </div>
  );
};
SearchProfilePreferenceView.propTypes = {
  data: PropTypes.object.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
};
export default SearchProfilePreferenceView;
