import {
  CONNECT_SOCKET,
  JOIN_CHAT_SUCCESS,
  JOIN_NOTIFICATION_CHANNEL,
} from '../actions/action_types';

export function SocketReducer(state = null, action) {
  switch (action.type) {
    case CONNECT_SOCKET:
    case JOIN_CHAT_SUCCESS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}

export function NotificationSocketReducer(state = null, action) {
  switch (action.type) {
    case JOIN_NOTIFICATION_CHANNEL: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
