import { UPLOAD_PICTURE, IS_SEARCHING } from "../actions/action_types";

export function fileUploadReducer(state = {}, action) {
  switch (action.type) {
    case UPLOAD_PICTURE:
      return action.payload.data.data;
    default: {
      return state;
    }
  }
}

export function isSearchingReducer(state = false, action) {
  switch (action.type) {
    case IS_SEARCHING: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
