import uuid from "uuid/v4";
import cookie from "react-cookies";

import { ACCEPT_COOKIE } from "./action_types";

export function acceptCookie() {
  const userUUID = uuid();
  const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 90);
  cookie.save("uid", userUUID, { expires });

  return {
    type: ACCEPT_COOKIE,
    payload: { uid: userUUID },
  };
}
