import React from "react";
import { Button, Container, Image, Grid, Modal } from "semantic-ui-react";
import { renderFavouritePicture } from "../../utils/helpers";

import { Link } from "react-router-dom";

const MyRoomsView = ({
  rooms,
  showRoomDetail,
  deleteRoom,
  updateRoom,
  isModalOpen,
  openModal,
  closeModal,
  message,
  updateAndActivateRoom,
}) => {
  return (
    <Container fluid style={{ marginBottom: 120 }}>
      <Grid>
        <Grid.Row columns={2} textAlign="left">
          <h1
            style={{
              color: "#FF664D",
              marginTop: 50,
              marginLeft: 35,
              fontFamily: "MYRIAD",
            }}
          >
            My rooms
          </h1>
        </Grid.Row>
      </Grid>
      <Grid stackable columns={4}>
        <Grid.Row verticalAlign="middle">
          {rooms.map(room => (
            <Grid.Column
              textAlign="center"
              key={room.id}
              verticalAlign="middle"
            >
              <Grid.Row verticalAlign="middle">
                <Grid.Column
                  width={11}
                  verticalAlign="middle"
                  style={{ marginTop: 20 }}
                >
                  <p
                    style={{
                      color: "#666",
                      fontWeight: "bold",
                      fontSize: 18,
                      marginBottom: "-2%",
                    }}
                  >
                    {room.area && `${room.area.name},`}{" "}
                    {room.area_code && `${room.area_code.code}`}
                    <br />
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row verticalAlign="middle">
                <Image
                  centered
                  style={{
                    marginBottom: "10%",
                    borderRadius: "8px",
                    cursor: "pointer",
                    opacity: room.deactivate && 0.5,
                  }}
                  onClick={() => showRoomDetail(room.slug)}
                  src={
                    room.pictures[0]
                      ? renderFavouritePicture(room.pictures)
                      : "https://vignette.wikia.nocookie.net/pineapplepedia/images/3/3c/No-images-placeholder.png/revision/latest?cb=20171113170721"
                  }
                />
              </Grid.Row>
              <Grid.Row centered>
                <Button.Group vertical>
                  {!room.deactivate && (
                    <Button
                      positive
                      as={Link}
                      content="EDIT ROOM"
                      labelPosition="right"
                      icon="edit"
                      to={`/room/${room.slug}/edit`}
                    />
                  )}
                  <Button
                    negative={!room.deactivate}
                    positive={room.deactivate}
                    icon={room.deactivate ? "plus" : "close"}
                    labelPosition="right"
                    content={room.deactivate ? "REACTIVATE" : "DEACTIVATE"}
                    onClick={() => updateRoom(room)}
                  />
                </Button.Group>
              </Grid.Row>
              <Grid.Row>
                <Modal
                  dimmer="inverted"
                  size="small"
                  onOpen={() => openModal()}
                  open={isModalOpen}
                  onClose={() => closeModal()}
                  closeOnDimmerClick={true}
                  style={{
                    marginTop: window.innerWidth < 767 ? "15%" : "20%",
                    marginLeft: window.innerWidth < 767 ? "2%" : "35%",
                  }}
                >
                  <Modal.Content style={{ textAlign: "center" }}>
                    <p
                      style={{
                        color: "#FF664D",
                        fontFamily: "MYRIAD",
                        fontSize: "25px",
                      }}
                    >
                      {message}
                    </p>
                    <Button
                      style={{
                        color: "#fff",
                        width: "180px",
                        borderRadius: "5px",
                        backgroundColor: "#00D5CC",
                      }}
                      size="large"
                      onClick={() => {
                        updateAndActivateRoom(room);
                        closeModal();
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        color: "#fff",
                        width: "180px",
                        borderRadius: "5px",
                        backgroundColor: "#00D5CC",
                      }}
                      size="large"
                      onClick={() => closeModal()}
                    >
                      No
                    </Button>
                  </Modal.Content>
                </Modal>
              </Grid.Row>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default MyRoomsView;
