import React from "react";
import { Button, Grid, Icon, Header } from "semantic-ui-react";
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import PropTypes from "prop-types";

const ShareButton = ({ title, text, buttonText }) => {
  let url = window.location.href;
  let iconSize = 48;

  return (
    <div className="share-button-div">
      {window.navigator.share ? (
        <Button
          onClick={() =>
            window.navigator.share({
              title: title,
              text: text,
              url: url,
            })
          }
          size="huge"
          fluid
          className="share-button"
        >
          <Icon name="share alternate" />
          Share this{buttonText !== "" ? " " + buttonText : ""}
        </Button>
      ) : (
        <>
          <Header className="share-label">
            Share this{buttonText !== "" ? " " + buttonText : ""}
          </Header>
          <Grid className="social-grid" padded centered>
            <FacebookShareButton url={url} quote={text} hashtag="#RooMigo">
              <FacebookIcon size={iconSize} round={true} />
            </FacebookShareButton>
            <WhatsappShareButton url={url} title={title}>
              <WhatsappIcon size={iconSize} round={true} />
            </WhatsappShareButton>
            <EmailShareButton url={url} subject={title} body={text}>
              <EmailIcon size={iconSize} round={true} />
            </EmailShareButton>
          </Grid>
        </>
      )}
    </div>
  );
};

ShareButton.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default ShareButton;
