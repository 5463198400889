/* global window */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchLoggedInUserProfile,
  fetchCities,
  getPreviousPage,
  fetchReceivedRequestList,
  fetchProfileMessageList,
  connectSocket,
  joinNotificationChannel,
} from "../actions";
import WebAppLayout from "./web_app_layout";
import MobileAppLayout from "./mobile_app_layout";
import _ from "lodash";
import cookie from "react-cookies";
import { withRouter } from "react-router-dom";
class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      myProfile: this.props.myProfile,
      receivedRequests: this.props.receivedRequestList,
    };
    this.handleResize = this.handleResize.bind(this);
    this.linkToPreviousPage = this.linkToPreviousPage.bind(this);
  }

  componentDidMount() {
    this.props.fetchCities();
    window.addEventListener("resize", this.handleResize);
    const jwt = cookie.load("jwt");
    if (jwt) {
      this.props.fetchLoggedInUserProfile();
      this.props.connectSocket(jwt);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(this.props.receivedRequestList, nextProps.receivedRequestList)
    ) {
      this.setState({ receivedRequestList: nextProps.receivedRequestList });
    }
    if (!_.isEqual(this.props.myProfile.data, nextProps.myProfile.data)) {
      this.setState({ myProfile: nextProps.myProfile });
      this.props.fetchReceivedRequestList();
      this.props.fetchProfileMessageList();
      const jwt = cookie.load("jwt");
      this.props.joinNotificationChannel(jwt, nextProps.myProfile.data.user_id);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  linkToPreviousPage() {
    this.props.getPreviousPage(this.props.location.pathname);
  }

  render() {
    if (this.props.roomIsOnSync === 0) {
      return <div />;
    }
    if (this.state.windowWidth < 768) {
      return (
        <MobileAppLayout
          children={this.props.children}
          myProfile={this.state.myProfile}
          linkToPreviousPage={this.linkToPreviousPage}
          receivedRequests={this.props.receivedRequestList}
          profileMessageList={this.props.profileMessageList}
        />
      );
    }
    return (
      <WebAppLayout
        children={this.props.children}
        myProfile={this.state.myProfile}
        location={this.props.location}
        linkToPreviousPage={this.linkToPreviousPage}
        receivedRequests={this.props.receivedRequestList}
        profileMessageList={this.props.profileMessageList}
      />
    );
  }
}

const mapStateToProps = ({
  myProfile,
  previousPage,
  receivedRequestList,
  profileMessageList,
  cities,
}) => ({
  myProfile,
  previousPage,
  receivedRequestList,
  profileMessageList,
  roomIsOnSync: cities.roomIsOnSync,
});

const actions = {
  connectSocket,
  fetchLoggedInUserProfile,
  fetchCities,
  getPreviousPage,
  fetchReceivedRequestList,
  fetchProfileMessageList,
  joinNotificationChannel,
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(AppLayout)
);
