import React from "react";
import { Link } from "react-router-dom";
import { Menu, Icon } from "semantic-ui-react";

const Footer = () => {
  return (
    <Menu
      widths={10}
      style={{
        borderTop: "1px solid #80808039",
        minHeight: "75px",
        justifyContent: "center",
      }}
      secondary
      stackable
    >
      <Menu.Item style={{ justifyContent: "center" }}>
        <Link to="/">
          <b>
            <font
              style={{
                color: "#FF664D",
                fontFamily: "MYRIAD",
                fontSize: 20,
              }}
            >
              RooMigo {(new Date().getFullYear())}
            </font>
          </b>
        </Link>
      </Menu.Item>
      <Menu.Item />
      <Menu.Item style={{ justifyContent: "center" }}>
        <Link to="/about_us">
          <b>
            <font color="gray">ABOUT US</font>
          </b>
        </Link>
      </Menu.Item>
      <Menu.Item style={{ justifyContent: "center" }}>
        <Link to="/contact_us">
          <b>
            <font color="gray">CONTACT US</font>
          </b>
        </Link>
      </Menu.Item>
      <Menu.Item style={{ justifyContent: "center" }}>
        <Link to="/terms">
          <b>
            <font color="gray">TERMS & CONDITIONS</font>
          </b>
        </Link>
      </Menu.Item>
      <Menu.Item style={{ justifyContent: "center" }}>
        <Link to="/privacy">
          <b>
            <font color="gray">PRIVACY POLICY</font>
          </b>
        </Link>
      </Menu.Item>
      <Menu.Item />

      <Menu.Item>
        <a
          href="https://www.facebook.com/RoomigoHQ/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#7F7F93" }}
        >
          <Icon link name="facebook square" size="big" />
        </a>
        <a
          href="https://twitter.com/roomigohq"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#7F7F93" }}
        >
          <Icon link name="twitter square" size="big" />
        </a>
        <a
          href="https://www.instagram.com/roomigohq/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#7F7F93" }}
        >
          <Icon link name="instagram" size="big" />
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default Footer;
