import React from "react";
import Loader from "./Loader";
import PropTypes from "prop-types";

import { PureComponent } from "react";
import { connect } from "react-redux";

/**
 * Wait for redux state
 *
 * @property component - Component constructor to render, when redux state is ready
 * @property initialActions - Array of dispatch()'able actions to fire on mount
 * @property mapStateToProps - Function that should return:
 *    a) Falsy value, if state is not yet ready (don't render Component)
 *    b) Object of mapped state values, if state is ready (render Component and pass the values)
 */
class WaitForState extends PureComponent {
  constructor(props) {
    super(props);
    const { initialActions, dispatch } = props;
    initialActions.map(action => dispatch(action));
  }

  render() {
    const {
      state,
      mapStateToProps,
      component: Renderable,
      ...rest
    } = this.props;
    const passableState = mapStateToProps(state);
    if (passableState) {
      const allProps = { ...rest, ...passableState };
      return <Renderable {...allProps} />;
    } else {
      return <Loader />;
    }
  }

  propTypes: {
    component: PropTypes.any,
    initialActions: PropTypes.array,
    mapStateToProps: PropTypes.string,
  };
}

export default connect(state => ({ state }))(WaitForState);
