import React from "react";
import { Form, Input, Grid, Button, Message } from "semantic-ui-react";

const SendResetEmailView = ({
  handleFieldChange,
  session,
  sendResetEmail,
  message,
  errorMessage,
}) => {
  return (
    <div className="reset-password-wrapper">
      <Form onSubmit={event => event.preventDefault()}>
        <Grid container centered>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <h1
                style={{
                  color: "#FF664D",
                  fontFamily: "MYRIAD",
                  marginLeft: "1%",
                }}
              >
                Reset Password
              </h1>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16}>
              <Input
                onChange={event =>
                  handleFieldChange("email", event.target.value)
                }
                type="email"
                placeholder="Email address"
                value={session.email}
                icon="mail outline"
                iconPosition="left"
                fluid
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column computer={5} mobile={16} verticalAlign="middle">
              <Button
                id="button"
                fluid
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#00D5CC",
                  color: "#fff",
                }}
                onClick={sendResetEmail}
              >
                SEND EMAIL
              </Button>
              {message && <Message positive>{message}</Message>}
              {errorMessage && <Message negative>{errorMessage}</Message>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
};

export default SendResetEmailView;
