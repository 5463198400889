import {
  CREATE_ROOM_SUCCESS,
  CREATE_ROOM_ERROR,
  EDIT_ROOM_SUCCESS,
  EDIT_ROOM_ERROR,
  FETCH_ROOM_LIST,
  FETCH_MY_ROOM_LIST,
  FETCH_HOME_ROOM_LIST,
  FETCH_ROOM_DETAIL,
  SET_ROOM_TO_BOOK,
  EMPTY_ROOM,
  EMPTY_ROOM_LIST,
  CREATE_ROOM_SEARCH,
  FIND_ROOMS,
  FETCH_USER_SEARCH_PARAMS,
  SET_USER_SEARCH_PARAMS_ON_SYNC_FLAG,
  DELETE_ROOM,
  ROOM_SEARCH_PAGE,
  SET_ROOM_FIELDS_ERRORS,
  SET_ROOM_ON_SYNC_FLAG,
  SET_CITIES_ON_SYNC_FLAG,
  SET_CITIES,
  CITY_SELECT,
} from '../actions/action_types';
const today = new Date();
const initialState = {
  roomIsOnSync: false,
  data: {
    city: 'Dublin',
    area: '',
    area_code: '',
    address: '',
    price: '',
    deposit: '',
    move_in_date: today,
    move_out_date: null,
    minimum_stay: '',
    description: '',
    bills_included: null,
    slogan: '',
    age_min: null,
    age_max: null,
    preferred_occupation: '',
    preferred_relationship: '',
    pictures: [],
    roommate_male_number: '',
    roommate_female_number: '',
    preferred_gender: '',
  },
  error: {},

  currentRoom: {
    fieldsWithError: {
      city: null,
      address: null,
      area: null,
      area_code: null,
      type: null,
      ensuite: null,
      roommate_male_number: null,
      price: null,
      bills_included: null,
      slogan: null,
      description: null,
      pictures: null,
      age_min: null,
    },
  },
};

export function roomReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ROOM_ON_SYNC_FLAG:
      return {
        ...state,
        roomIsOnSync: action.payload,
      };
    case FETCH_ROOM_DETAIL:
    case DELETE_ROOM:
    case CREATE_ROOM_SUCCESS:
    case EDIT_ROOM_SUCCESS: {
      return {
        data: action.payload.data,
        error: {},
        currentRoom: state.currentRoom,
      };
    }
    case SET_ROOM_FIELDS_ERRORS: {
      return {
        ...state,
        currentRoom: {
          ...state.currentRoom,
          fieldsWithError: {
            ...state.currentRoom.fieldsWithError,
            ...action.payload,
          },
        },
      };
    }
    case CREATE_ROOM_ERROR:
    case EDIT_ROOM_ERROR: {
      return {
        data: {},
        error: action.payload.response.data.errors,
        currentRoom: state.currentRoom,
      };
    }
    case EMPTY_ROOM: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

const citiesInitialState = {
  citiesIsOnSync: false,
  cities: [],
  selectedCity: null,
};

export function citiesReducer(state = citiesInitialState, action) {
  switch (action.type) {
    case SET_CITIES_ON_SYNC_FLAG:
      return {
        ...state,
        citiesIsOnSync: action.payload,
      };
    case CITY_SELECT:
      return {
        ...state,
        selectedCity: action.payload,
      };
    case SET_CITIES:
      return {
        ...state,
        cities: action.payload,
      };
    default: {
      return state;
    }
  }
}
export function homeRoomListReducer(state = [], action) {
  switch (action.type) {
    case FETCH_HOME_ROOM_LIST: {
      return action.payload.data.data;
    }
    default: {
      return state;
    }
  }
}

export function myRoomListReducer(state = [], action) {
  switch (action.type) {
    case FETCH_MY_ROOM_LIST: {
      return action.payload.data.data;
    }
    default: {
      return state;
    }
  }
}

export function roomToBookReducer(state = null, action) {
  switch (action.type) {
    case SET_ROOM_TO_BOOK: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}

export function roomListReducer(state = [], action) {
  switch (action.type) {
    case FETCH_ROOM_LIST:
    case FIND_ROOMS: {
      return action.payload.data.data;
    }
    case EMPTY_ROOM_LIST: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}

const initialSearchState = {
  userSearchParamsIsOnSync: false,
  searchParams: {
    id: null,
    area: [],
    city: {
      id: 1,
      name: 'Dublin',
    },
    area_code: [],
    price_min: 0,
    price_max: 9999,
    age_min: 18,
    age_max: 99,
    preferred_occupation: 'No preference',
    preferred_relationship: 'No preference',
    preferred_gender: 'No preference',
    ensuite: 'No preference',
    type: 'No preference',
  },
};

export function roomSearchReducer(state = initialSearchState, action) {
  switch (action.type) {
    case CREATE_ROOM_SEARCH:
    case FETCH_USER_SEARCH_PARAMS: {
      return {
        ...state,
        searchParams: action.payload.data.data || {
          ...initialSearchState.searchParams,
        },
      };
    }
    case SET_USER_SEARCH_PARAMS_ON_SYNC_FLAG:
      return {
        ...state,
        userSearchParamsIsOnSync: action.payload,
      };
    default: {
      return state;
    }
  }
}

export function roomSearchPageReducer(state = 1, action) {
  switch (action.type) {
    case ROOM_SEARCH_PAGE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
