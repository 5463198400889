import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dropdown, Button, Divider } from "semantic-ui-react";
import { areaToOptionList } from "../../../utils/helpers";
import FieldError from "./FieldError";
import { GOOGLE_API_KEY } from "../../../actions/root_url";
import Script from "react-load-script";
import GooglePlaceAutocomplete from "./GooglePlaceAutocomplete";
export class Location extends Component {
  state = {
    googleLoaded: false,
  };
  handleScriptLoad = () => {
    this.setState({ googleLoaded: true });
  };
  hideCitySelection = form => {
    switch (form) {
      case "create":
        return false;
      case "edit":
        return true;
      default:
        return false;
    }
  };
  render() {
    const {
      addressRef,
      areaRef,
      area_codeRef,
      setRoomFieldsWithError,
      room,
      setListingRoomValue,
      fieldsWithError,
      setRoomSeveralValues,
      availableAreaList,
      availableNeighbourhoodList,
      coordinates,
      cities,
      formType,
    } = this.props;

    return (
      <div className="roomigo-container">
        <div className="mx-20">
          <h2 style={{ marginTop: "15px", color: "#707070" }}>Location</h2>
          <Divider
            style={{
              borderWidth: "3px",
              borderRadius: "15px",
              borderColor: "#FF664D",
            }}
          />

          {/* <span className="title-16">Select City</span> */}
        </div>

        <div className="desktop-row location-listing-row">
          <div className="flex-1 room-list-flex-property">
            {!this.hideCitySelection(formType) && (
              <Button.Group id="selectCity" widths={cities.length}>
                {cities.map(city => (
                  <Button
                    key={city.id}
                    className="roomigo-btn"
                    active={
                      room.city
                        ? room.city.id === city.id
                          ? true
                          : false
                        : false
                    }
                    value={city.name}
                    onClick={() => {
                      const newCity = cities.find(
                        cityInArr => cityInArr.id === city.id
                      );
                      setRoomSeveralValues({
                        coordinates: null,
                        roomObj: {
                          city: { id: newCity.id, name: newCity.name },
                          area: null,
                          area_code: null,
                          address: "",
                        },
                      });
                    }}
                  >
                    {city.name}
                  </Button>
                ))}
              </Button.Group>
            )}
            {/* {room.city && ( */}
            <>
              <span className="title-16" ref={addressRef}>
                Search For Your Address {room.city && `in ${room.city.name}`}
              </span>
              <Script
                url={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`}
                onLoad={this.handleScriptLoad}
              />
              {this.state.googleLoaded && (
                <GooglePlaceAutocomplete
                  setRoomSeveralValues={setRoomSeveralValues}
                  city={room.city.name}
                  cityWithArr={cities.find(city => city.id === room.city.id)}
                  setRoomFieldsWithError={setRoomFieldsWithError}
                />
              )}
              {fieldsWithError.address && (
                <FieldError message={fieldsWithError.address} />
              )}
              {/* <span className={`error-13 ${searchError ? "visible" : ""}`}>
                  {searchError}
                </span> */}
              {/* <button
                id="searchAddress"
                onClick={handleAddressSearchSubmit}
                className="roomigo-submit-btn"
              >
                Search
              </button> */}
            </>
            {/* )} */}
          </div>
          <div className="flex-1 room-list-flex-property">
            {coordinates &&
              coordinates.lat != null &&
              coordinates.lng != null && (
                <img
                  className="room-listing-map"
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${
                    coordinates.lat
                  },${
                    coordinates.lng
                  }&zoom=15&size=487x225&scale=2&maptype=roadmap&markers=color:red%7C${
                    coordinates.lat
                  },${coordinates.lng}&key=${GOOGLE_API_KEY}`}
                  alt=""
                />
              )}
          </div>
        </div>
        <div className="desktop-row">
          {room.address && (
            <>
              <div className="flex-1 room-list-flex-property">
                <span className="title-16">Address</span>
                <input
                  type="text"
                  id="address"
                  disabled={true}
                  value={room.address}
                />
              </div>

              <div
                className="flex-1 room-list-flex-property min-w-45"
                style={{ display: "none" }}
              >
                <span className="title-16">City</span>
                <input disabled={true} id="city" value={room.city.name} />
              </div>
              {availableAreaList.length > 0 && (
                <div className="flex-1 room-list-flex-property min-w-45">
                  <>
                    <span className="title-16" ref={area_codeRef}>
                      Area
                      {` ${availableAreaList.length > 0 ? "*" : ""}`}
                    </span>
                    <Dropdown
                      id="area"
                      value={room.area_code ? room.area_code.id : ""}
                      placeholder="Select Area Code"
                      floating
                      search
                      fluid
                      selection
                      options={areaToOptionList(
                        availableAreaList,
                        "code",
                        "id"
                      )}
                      onChange={(event, data) => {
                        const newArea = availableAreaList.find(
                          areaInList => areaInList.id === data.value
                        );
                        setListingRoomValue("area_code", newArea);
                      }}
                    />
                    {fieldsWithError.area_code && (
                      <FieldError message={fieldsWithError.area_code} />
                    )}
                  </>
                </div>
              )}
              {availableNeighbourhoodList.length > 0 && (
                <div className="flex-1 room-list-flex-property">
                  <>
                    <span className="title-16" ref={areaRef}>
                      Neighbourhood
                      {availableAreaList.length === 0 ? (
                        " *"
                      ) : (
                        <span className="optional"> Optional</span>
                      )}
                    </span>
                    <Dropdown
                      id="area"
                      value={room.area ? room.area.id : ""}
                      placeholder="Select Area"
                      floating
                      search
                      fluid
                      selection
                      options={areaToOptionList(
                        availableNeighbourhoodList,
                        "code",
                        "id"
                      )}
                      onChange={(event, data) => {
                        const newNeighbourhood = availableNeighbourhoodList.find(
                          neigh => neigh.id === data.value
                        );
                        setListingRoomValue("area", newNeighbourhood);
                      }}
                    />

                    {fieldsWithError.area && (
                      <FieldError message={fieldsWithError.area} />
                    )}
                  </>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

Location.propTypes = {
  areaRef: PropTypes.object.isRequired,
  area_codeRef: PropTypes.object.isRequired,
  addressRef: PropTypes.object.isRequired,
  setRoomSeveralValues: PropTypes.func.isRequired,
  availableAreaList: PropTypes.arrayOf(PropTypes.object).isRequired,
  availableNeighbourhoodList: PropTypes.arrayOf(PropTypes.object).isRequired,
  room: PropTypes.object.isRequired,
  setListingRoomValue: PropTypes.func.isRequired,
  cities: PropTypes.array.isRequired,
  fieldsWithError: PropTypes.object.isRequired,
  setRoomFieldsWithError: PropTypes.func.isRequired,
  coordinates: PropTypes.object,
  formType: PropTypes.string.isRequired,
};

export default Location;
