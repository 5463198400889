import React, { Component } from "react";
import { connect } from "react-redux";
import { register } from "../../actions/session_actions";
import RegisterView from "./register_view";

class Register extends Component {
  constructor(props) {
    super(props);
    this.register = this.register.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);

    this.state = {
      session: { name: "", email: "", password: "" },
      errorMessage: "",
    };
  }

  register() {
    this.props.register(this.state.session);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.session && !nextProps.session.error) {
      this.props.history.push("/login");
    } else {
      this.setState({
        errorMessage: nextProps.session.error.message,
      });
    }
  }

  handleFieldChange(field, value) {
    this.setState({
      session: Object.assign({}, this.state.session, { [field]: value }),
      errorMessage: "",
    });
  }
  render() {
    return (
      <RegisterView
        handleFieldChange={this.handleFieldChange}
        register={this.register}
        session={this.state.session}
        errorMessage={this.state.errorMessage}
      />
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });

export default connect(mapStateToProps, { register })(Register);
