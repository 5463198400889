import React from "react";
import Home from "./home";
import PropTypes from "prop-types";
import Loader from "../../components/parts/Loader";

import { connect } from "react-redux";
import { PureComponent } from "react";
import { citySelect } from "../../actions";
import { hasCities, getCityFromParams } from "../../utils/city_select";

class MatchedHome extends PureComponent {
  state = {
    ready: false,
  };

  componentDidMount() {
    this.syncParamsCity();
  }

  componentDidUpdate() {
    this.syncParamsCity();
  }

  syncParamsCity = () => {
    const { cities, match, citySelect } = this.props;
    const { ready } = this.state;

    if (!ready && hasCities(cities)) {
      const currentParamCity = getCityFromParams(cities, match);
      citySelect(currentParamCity);
      this.setState({ ready: true });
    }
  };

  render() {
    const { ready } = this.state;
    if (ready) {
      return <Home {...this.props} />;
    } else {
      return <Loader />;
    }
  }
}

MatchedHome.propTypes = {
  selectedCity: PropTypes.object,
  match: PropTypes.object.isRequired,
  cities: PropTypes.array.isRequired,
  citySelect: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cities }) => ({
  cities: cities.cities,
  selectedCity: cities.selectedCity,
});

const actions = {
  citySelect,
};

export default connect(
  mapStateToProps,
  actions
)(MatchedHome);
