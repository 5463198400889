import React, { Component } from 'react';
import Slider from 'react-slick';

import ModalLayout from '../ModalLayout/ModalLayout';
import Button from '../Button/Button';
import RoomItem from './RoomItem';

class ChooseRoom extends Component {
  state = {
    currentRoomIndex: 0,
  };

  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: index => this.setState({ currentRoomIndex: index }),
  };

  handleRoomSelect = () => {
    const { rooms, setRoomToBook, history, onCancel } = this.props;
    const { currentRoomIndex } = this.state;
    const currentRoom = rooms[currentRoomIndex];

    setRoomToBook(currentRoom);
    onCancel();
    history.push(`/booking/request/${currentRoom.city.id}`);
  };

  render() {
    const { rooms, onCancel } = this.props;

    return (
      <ModalLayout onCancel={onCancel}>
        <h2 className="choose-room__header">Choose Room</h2>
        <div className="choose-room__slider">
          <Slider {...this.settings}>
            {rooms.map(room => (
              <div key={room.id}>
                <RoomItem room={room} />
              </div>
            ))}
          </Slider>
        </div>

        <div className="choose-room__actions">
          <Button onClick={this.handleRoomSelect}>Send Booking Request</Button>
        </div>
      </ModalLayout>
    );
  }
}

export default ChooseRoom;
