import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Image, Grid, Divider, Label } from "semantic-ui-react";

export default class Room extends Component {
  render() {
    const { room, renderFavouritePicture } = this.props;
    return (
      <Grid.Column key={room.id}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column
            width={11}
            verticalAlign="middle"
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: 18,
                marginBottom: 5,
              }}
            >
              {room.area && `${room.area.name}, `}
              {room.area_code && room.area_code.code}
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ position: "relative" }}>
          <Label
            size="huge"
            as="a"
            color="teal"
            style={{
              zIndex: 1,
              margin: 0,
              right: 0,
              position: "absolute",
              borderRadius: "0px 0px 0px 8px",
              backgroundColor: "#9ED6C9",
              color: "#FFFFFF",
              opacity: "0.8",
            }}
          >
            €{room.price}
          </Label>
          <Link to={`/room/${room.slug}`}>
            <Image
              centered
              style={{
                marginBottom: "10%",
                borderRadius: "8px 0px 8px 8px",
                cursor: "pointer",
              }}
              src={
                room.pictures[0]
                  ? renderFavouritePicture(room.pictures)
                  : "https://vignette.wikia.nocookie.net/pineapplepedia/images/3/3c/No-images-placeholder.png/revision/latest?cb=20171113170721"
              }
            />
          </Link>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <Image
              avatar
              bordered
              floated="left"
              style={{
                width: 55,
                height: 55,
                borderColor: "teal",
                borderWidth: 2,
              }}
              src={
                room.renter.data.profile.data.pictures[0]
                  ? renderFavouritePicture(
                      room.renter.data.profile.data.pictures
                    )
                  : "https://react.semantic-ui.com/assets/images/wireframe/image.png"
              }
            />
          </Grid.Column>
          <Grid.Column width={10} verticalAlign="middle">
            <p
              style={{
                fontWeight: "300",
                fontSize: 17,
              }}
            >
              <span
                style={{
                  marginTop: 20,
                  position: "absolute",
                }}
              >
                {room.renter.data.profile.data.name},{" "}
                {room.renter.data.profile.data.age}
              </span>
            </p>
          </Grid.Column>
        </Grid.Row>
        <Divider
          style={{
            borderWidth: "3px",
            borderRadius: "15px",
            borderColor: "#FFFFFF",
          }}
        />
        <Grid.Row>
          <Grid.Column width={3} verticalAlign="middle">
            <p
              style={{
                fontSize: 15,
                fontStyle: "italic",
                textAlign: "left",
                marginTop: "4%",
                marginBottom: "15%",
                marginLeft: 16,
                marginRight: 20,
                color: "#FF664D",
              }}
            >
              <br />
              <br />
              {room.renter.data.profile.data.interests
                .slice(0, 7)
                .map(interest => `#${interest.name} `)}
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid.Column>
    );
  }
}
