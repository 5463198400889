import React, { Component } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import _ from 'lodash';
import {
  fetchSentRequestList,
  fetchReceivedRequestList,
  acceptRequest,
  declineRequest,
  joinChatChannel,
  fetchProfileConnections,
  fetchChatMessageList,
  sendChatMessage,
  fetchProfileDetail,
  fetchProfileMessageList,
  readMessages,
  emptyConnection,
} from '../../../actions/index';
import { getChatId } from '../../../utils/helpers';

import RequestListView from './request_list_view';

class RequestList extends Component {
  constructor(props) {
    super(props);
    const jwt = cookie.load('jwt');
    this.state = {
      sentRequestList: this.props.sentRequestList,
      receivedRequestList: this.props.receivedRequestList,
      connectionList: this.props.connectionList,
      messageList: this.props.messageList,
      profile: this.props.myProfile.data,
      selectedChatId: '',
      connectionId: this.props.connection && this.props.connection.id,
      message: '',
      activeIndex: 0,
      isChatVisible: false,
      friendProfile: {},
      socket: this.props.socket,
      profileMessageList: this.props.profileMessageList,
      jwt,
    };
    this.acceptRequest = this.acceptRequest.bind(this);
    this.declineRequest = this.declineRequest.bind(this);
    this.fetchMessageList = this.fetchMessageList.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.showChatWindow = this.showChatWindow.bind(this);
    this.viewProfileDetail = this.viewProfileDetail.bind(this);
    this.props.fetchSentRequestList();
    this.props.fetchReceivedRequestList();
    this.props.fetchProfileConnections();
    this.props.fetchProfileMessageList();
  }

  componentDidMount() {
    if (this.props.connection.id) {
      const chatId = getChatId(
        this.props.connection.profile.data.id,
        this.props.connection.friend_profile.data.id
      );
      this.setState({
        selectedChatId: chatId,
        connectionId: this.props.connection.id,
        isChatVisible: !this.state.isChatVisible,
        friendProfile: this.props.connection.friend_profile.data,
      });
      this.props.joinChatChannel(chatId, this.state.jwt);
      this.props.readMessages(chatId, this.props.myProfile.data.id);
    }
    // Intercom
    window.Intercom('update');
  }

  componentWillUnmount() {
    this.props.emptyConnection();
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.sentRequestList, nextProps.sentRequestList)) {
      this.setState({ sentRequestList: nextProps.sentRequestList });
    }
    if (
      !_.isEqual(this.props.receivedRequestList, nextProps.receivedRequestList)
    ) {
      this.setState({ receivedRequestList: nextProps.receivedRequestList });
    }
    if (
      !_.isEqual(this.state.profileMessageList, nextProps.profileMessageList)
    ) {
      const { selectedChatId } = this.state;
      if (selectedChatId) {
        this.props.readMessages(selectedChatId, this.props.myProfile.data.id);
      }
      this.setState({ profileMessageList: nextProps.profileMessageList });
    }
    if (!_.isEqual(this.props.request, nextProps.request)) {
      this.props.fetchSentRequestList();
      this.props.fetchReceivedRequestList();
      this.props.fetchProfileConnections();
    }
    if (!_.isEqual(this.props.connection, nextProps.connection)) {
      const chatId = getChatId(
        nextProps.connection.profile.data.id,
        nextProps.connection.friend_profile.data.id
      );
      this.setState({
        selectedChatId: chatId,
        connectionId: nextProps.connection.id,
        isChatVisible: !this.state.isChatVisible,
        friendProfile: nextProps.connection.friend_profile.data,
      });
      this.props.joinChatChannel(chatId, this.state.jwt);
      this.props.readMessages(chatId, this.props.myProfile.data.id);
    }
    if (!_.isEqual(this.props.connectionList, nextProps.connectionList)) {
      this.setState({ connectionList: nextProps.connectionList });
    }
    if (!_.isEqual(this.props.messageList, nextProps.messageList)) {
      const { selectedChatId } = this.state;
      if (!nextProps.messageList.length) {
        this.setState({ messageList: nextProps.messageList });
      } else {
        if (
          selectedChatId &&
          nextProps.messageList[0] &&
          selectedChatId === nextProps.messageList[0].chat_id
        ) {
          this.setState({ messageList: nextProps.messageList });
        }
      }
    }
    if (!_.isEqual(this.props.socket, nextProps.socket)) {
      if (!nextProps.socket.topic) {
        this.setState({ socket: nextProps.socket });
      }
      if (this.state.connectionId) {
        this.props.fetchChatMessageList(this.state.connectionId);
      }
    }
    if (!_.isEqual(this.props.myProfile.data, nextProps.myProfile.data)) {
      this.setState({ profile: nextProps.myProfile.data });
    }
  }

  acceptRequest(request) {
    this.props.acceptRequest(request.id);
  }

  declineRequest(request) {
    this.props.declineRequest(request.id);
  }

  fetchMessageList(connection) {
    const chatId = getChatId(
      connection.profile.data.id,
      connection.friend_profile.data.id
    );
    this.setState({
      selectedChatId: chatId,
      connectionId: connection.id,
      isChatVisible: !this.state.isChatVisible,
      friendProfile: connection.friend_profile.data,
    });
    this.props.joinChatChannel(chatId, this.state.jwt);
    this.props.readMessages(chatId, this.props.myProfile.data.id);
  }

  showChatWindow() {
    this.setState({ isChatVisible: !this.state.isChatVisible });
  }

  handleFieldChange(message) {
    this.setState({ message });
  }

  viewProfileDetail(slug) {
    this.props.fetchProfileDetail(slug);
    this.props.history.push(`/profile/${slug}`);
  }

  sendMessage() {
    this.setState({ message: '' });
    this.props.sendChatMessage(
      this.state.selectedChatId,
      this.state.message,
      this.state.friendProfile
    );
  }

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex });
  };

  render() {
    const {
      sentRequestList,
      receivedRequestList,
      connectionList,
      messageList,
      connectionId,
      profile,
      activeIndex,
      isChatVisible,
      friendProfile,
      profileMessageList,
    } = this.state;
    return (
      <div className="site-container">
        <RequestListView
          sentRequests={sentRequestList}
          receivedRequests={receivedRequestList}
          acceptRequest={this.acceptRequest}
          declineRequest={this.declineRequest}
          connectionList={connectionList}
          fetchMessageList={this.fetchMessageList}
          messageList={messageList}
          profileMessageList={profileMessageList}
          profile={profile}
          connectionId={connectionId}
          handleFieldChange={this.handleFieldChange}
          sendMessage={this.sendMessage}
          messageText={this.state.message}
          handleTabChange={this.handleTabChange}
          activeIndex={activeIndex}
          isChatVisible={isChatVisible}
          showChatWindow={this.showChatWindow}
          viewProfileDetail={this.viewProfileDetail}
          friendProfile={friendProfile}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  request,
  sentRequestList,
  receivedRequestList,
  connection,
  connectionList,
  messageList,
  profileMessageList,
  socket,
  myProfile,
}) => ({
  request,
  sentRequestList,
  receivedRequestList,
  connection,
  connectionList,
  messageList,
  profileMessageList,
  socket,
  myProfile,
});

const actions = {
  fetchSentRequestList,
  fetchReceivedRequestList,
  acceptRequest,
  declineRequest,
  joinChatChannel,
  fetchProfileConnections,
  fetchChatMessageList,
  sendChatMessage,
  fetchProfileDetail,
  fetchProfileMessageList,
  readMessages,
  emptyConnection,
};

export default connect(
  mapStateToProps,
  actions
)(RequestList);
