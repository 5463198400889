import React, { Component } from "react";
import PropTypes from "prop-types";
import { IMAGES_S3_BUCKET_URL } from "../../../actions/root_url";
import Loader from "./Loader";
import FieldError from "./FieldError";

export default class UploadImages extends Component {
  constructor(props) {
    super(props);

    this.inputOpenFileRef = React.createRef();
  }
  showOpenFileDlg = () => {
    this.inputOpenFileRef.current.click();
  };

  onFileSelect = event => {
    // payload for error
    const payload = {};
    payload.pictures = null;
    const allowedTypes = ["image/jpeg", "application/pdf", "image/png"];
    Array.from(event.target.files).map(file => {
      if (allowedTypes.find(allowedType => allowedType === file.type)) {
        this.props.uploadPicture(file);
      } else {
        payload.pictures = "This file type is not allowed.";
      }
      return this.props.setRoomFieldsWithError(payload);
    });
  };

  render() {
    const {
      pictures,
      // errorMessageUpload,
      isSearching,
      deletePicture,
      // favouritePicture,
      fieldsWithError,
    } = this.props;

    return (
      <div>
        <span className="title-16 light" ref={this.props.picturesRef}>
          Upload Images *
        </span>
        {isSearching ? (
          <div className="upload-image-container">
            <Loader
              classNames="image-upload-loader"
              text="Please wait. Image is uploading."
            />
          </div>
        ) : (
          <>
            <input
              type="file"
              multiple
              accept="image/jpg, image/jpeg, image/png, application/pdf"
              style={{ display: "none" }}
              ref={this.inputOpenFileRef}
              onChange={this.onFileSelect}
            />
            {pictures.length === 0 ? (
              <>
                <div
                  className="upload-image-container upload-icon-background"
                  onClick={this.showOpenFileDlg}
                />
                {fieldsWithError.pictures && (
                  <FieldError message={fieldsWithError.pictures} />
                )}
              </>
            ) : (
              <div className="upload-image-container">
                {pictures.map(picture => (
                  <div
                    key={picture.url}
                    className="roomigo-room-list-image-container"
                  >
                    <div className="roomigo-room-list-image-inner-container">
                      <img
                        className="roomigo-room-list-image"
                        src={`${IMAGES_S3_BUCKET_URL}/${picture.url}`}
                        title={
                          picture.favorite
                            ? "ITS FAVORITE PICTURE"
                            : "NOT FAVORITE PICTURE"
                        }
                        alt=""
                      />
                      <div
                        className="roomigo-delete-room-list-image-btn"
                        onClick={() =>
                          deletePicture(
                            `${IMAGES_S3_BUCKET_URL}/${picture.url}`
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
                {pictures.length !== 10 && (
                  <div
                    className="add-new-room-image-btn"
                    onClick={this.showOpenFileDlg}
                  >
                    <div className="roomigo-plus-icon" />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
UploadImages.propTypes = {
  picturesRef: PropTypes.object.isRequired,
  setRoomFieldsWithError: PropTypes.func.isRequired,
  pictures: PropTypes.array.isRequired,
  uploadPicture: PropTypes.func.isRequired,
  errorMessageUpload: PropTypes.string.isRequired,
  isSearching: PropTypes.bool.isRequired,
  deletePicture: PropTypes.func.isRequired,
  favouritePicture: PropTypes.func.isRequired,
  fieldsWithError: PropTypes.object.isRequired,
};
