import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { resetPassword, sendResetEmail } from "../../actions/index";
import ResetPasswordView from "./reset_password_view";
import SendResetEmailView from "./send_reset_email_view";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.resetPassword = this.resetPassword.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.sendResetEmail = this.sendResetEmail.bind(this);
    const email =
      this.props.location.search &&
      this.props.location.search.split("&token=")[0].split("?email=")[1];
    const token =
      this.props.location.search &&
      this.props.location.search.split("&token=")[1];
    this.state = {
      session: {
        email,
        password: "",
        password_confirm: "",
        token,
      },
      message: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.session && nextProps.session.message) {
      this.setState({ errorMessage: nextProps.session.response.data.message });
    } else if (nextProps.session.data && nextProps.session.data.message) {
      this.setState({ message: nextProps.session.data.message });
    }
  }

  handleFieldChange(field, value) {
    this.setState({
      message: "",
      errorMessage: "",
      session: Object.assign({}, this.state.session, { [field]: value }),
    });
  }

  resetPassword() {
    if (
      !_.isEqual(
        this.state.session.password,
        this.state.session.password_confirm
      )
    ) {
      this.setState({
        message: "The passwords you have entered do not match.",
      });
    } else {
      this.props.resetPassword(this.state.session);
    }
  }

  sendResetEmail() {
    this.props.sendResetEmail(this.state.session.email);
  }

  render() {
    const { session, message, errorMessage } = this.state;
    return (
      <div>
        {session.token ? (
          <ResetPasswordView
            handleFieldChange={this.handleFieldChange}
            session={session}
            resetPassword={this.resetPassword}
            message={message}
            errorMessage={errorMessage}
          />
        ) : (
          <SendResetEmailView
            handleFieldChange={this.handleFieldChange}
            session={session}
            sendResetEmail={this.sendResetEmail}
            message={message}
            errorMessage={errorMessage}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => {
  return {
    session,
  };
};

const actions = {
  resetPassword,
  sendResetEmail,
};

export default connect(mapStateToProps, actions)(ResetPassword);
