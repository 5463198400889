import React from "react";
import { Divider, Dropdown, Grid, Image, Button } from "semantic-ui-react";
import "../../../utils/react-datetime.css";
import { areaToOptionList } from "../../../utils/helpers";
import filter_icon from "../../../assets/images/search.svg";
import {
  findRoomBedOptions,
  minPriceOptions,
  maxPriceOptions,
  roomSearchEnsuiteOptions,
  roomSearchGenderOptions,
  roomSearchRelationshipOptions,
  roomSearchStatusOptions,
} from "../../../assets/formOptions";

const SearchRoomView = ({
  window,
  search,
  handleFieldChange,
  handleSearchStatus,
  handleAreaCodeChange,
  searchStatus,
  areas,
  areaCodes,
  cities,
}) => {
  return (
    <div>
      <Grid stackable verticalAlign="middle">
        <Grid.Row
          columns={window > 767 ? 2 : 1}
          textAlign="left"
          style={{
            marginTop: "20px",
            marginLeft: window > 767 ? "0%" : "2%",
          }}
        >
          <Grid.Column>
            <div>
              <h2>Place</h2>
            </div>
            {searchStatus === true && (
              <Image
                centered
                src={filter_icon}
                style={{
                  width: "25px",
                  cursor: "pointer",
                  position: "absolute",
                  top: window > 767 ? 5 : 6,
                  right: window > 767 ? 15 : 25,
                }}
                onClick={() => handleSearchStatus()}
              />
            )}
            <Divider
              style={{
                borderWidth: "3px",
                borderRadius: "15px",
                borderColor: "#FF664D",
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light">City</span>
        <Button.Group widths={2}>
          {cities.map(city => (
            <Button
              type="button"
              key={city.id}
              className="roomigo-btn"
              active={search.city && search.city.id === city.id}
              value={city.id}
              onClick={() => handleFieldChange("city", city)}
            >
              {city.name}
            </Button>
          ))}
        </Button.Group>
      </div>
      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light">
          {areaCodes.length > 0 ? "Area" : "Neighbourhood"}
        </span>

        {areaCodes.length > 0 ? (
          <Dropdown
            id="area_code"
            fluid
            floating
            multiple
            search
            selection
            placeholder="Select Area"
            value={search.area_code.map(ac => ac.id || ac)}
            options={[
              { value: "all", text: "All areas" },
              ...areaToOptionList(areaCodes, "code", "id"),
            ]}
            onChange={(event, data) =>
              handleAreaCodeChange("area_code", data.value)
            }
          />
        ) : (
          <Dropdown
            id="area"
            fluid
            floating
            multiple
            search
            selection
            placeholder="Select Neighourhood"
            value={search.area.map(area => area.id || area)}
            options={[
              { value: "all", text: "All neighbourhoods" },
              ...areaToOptionList(areas, "code", "id"),
            ]}
            onChange={(event, data) => handleAreaCodeChange("area", data.value)}
          />
        )}
      </div>
      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light">Bed</span>
        <Button.Group widths={3}>
          {findRoomBedOptions.map(bed => (
            <Button
              type="button"
              key={bed.value}
              className="roomigo-btn"
              active={search.type === bed.value}
              value={bed.value}
              onClick={() => handleFieldChange("type", bed.value)}
            >
              {bed.text}
            </Button>
          ))}
        </Button.Group>
      </div>
      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light">EnSuite</span>
        <Button.Group widths={3}>
          {roomSearchEnsuiteOptions.map(ensuite => (
            <Button
              type="button"
              key={ensuite.value}
              className="roomigo-btn"
              active={search.ensuite === ensuite.value}
              value={ensuite.value}
              onClick={() => handleFieldChange("ensuite", ensuite.value)}
            >
              {ensuite.text}
            </Button>
          ))}
        </Button.Group>
      </div>
      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light">Rent</span>
        <div className="flex-row">
          <Dropdown
            id="roommate_male_number"
            className="flex-1 mr-6"
            fluid
            value={search.price_min}
            selection
            placeholder="Min"
            onChange={(event, data) =>
              handleFieldChange("price_min", data.value)
            }
            options={
              search.price_max
                ? minPriceOptions.filter(
                    option => option.value < search.price_max
                  )
                : minPriceOptions
            }
          />
          <Dropdown
            id="maxPriceDropdown"
            className="flex-1 ml-6"
            fluid
            value={search.price_max}
            selection
            placeholder="Max"
            onChange={(event, data) =>
              handleFieldChange("price_max", data.value)
            }
            options={
              search.price_min
                ? maxPriceOptions.filter(
                    option => option.value > search.price_min
                  )
                : maxPriceOptions
            }
          />
        </div>
      </div>

      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light">Available for</span>
        <Button.Group widths={3}>
          {roomSearchGenderOptions.map(gender => (
            <Button
              type="button"
              key={gender.value}
              className="roomigo-btn"
              active={search.preferred_gender === gender.value}
              value={gender.value}
              onClick={() =>
                handleFieldChange("preferred_gender", gender.value)
              }
            >
              {gender.text}
            </Button>
          ))}
        </Button.Group>
      </div>
      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light" />

        <Button.Group widths={3}>
          {roomSearchRelationshipOptions.map(relationship => (
            <Button
              type="button"
              key={relationship.value}
              className="roomigo-btn"
              active={search.preferred_relationship === relationship.value}
              value={relationship.value}
              onClick={() =>
                handleFieldChange("preferred_relationship", relationship.value)
              }
            >
              {relationship.text}
            </Button>
          ))}
        </Button.Group>
      </div>

      <div className="flex-1 room-list-flex-property roomigo-row">
        <span className="title-16 light" />
        <Button.Group widths={3}>
          {roomSearchStatusOptions.map(status => (
            <Button
              type="button"
              key={status.value}
              className="roomigo-btn"
              active={search.preferred_occupation === status.value}
              value={status.value}
              onClick={() =>
                handleFieldChange("preferred_occupation", status.value)
              }
            >
              {status.text}
            </Button>
          ))}
        </Button.Group>
      </div>
    </div>
  );
};

export default SearchRoomView;
