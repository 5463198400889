import React, { Component } from 'react';
import { connect } from 'react-redux';

class BookingRequest extends Component {
  render() {
    console.log(this.props.roomToBook);

    return (
      <div>
        <h1>BookingRequest</h1>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  roomToBook: state.roomToBook,
});

export default connect(mapStateToProps)(BookingRequest);
