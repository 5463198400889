import React from "react";
import FileUpload from "../../utils/file_upload";
import _ from "lodash";
import { IMAGES_S3_BUCKET_URL } from "../../actions/root_url";
import { Loader, Dimmer, Image, Grid } from "semantic-ui-react";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const speaker = require("../../assets/images/speaker.png");

const Pictures = ({
  profile,
  uploadPicture,
  renderItem,
  renderLeftNav,
  renderRightNav,
  filePictures,
  isSearching,
}) => {
  let orderedPictures = _.orderBy(filePictures, "favourite", "desc");
  if (filePictures.length !== profile.pictures.length) {
    let pictures = [];
    profile.pictures.map(picture =>
      pictures.push({
        original: `${IMAGES_S3_BUCKET_URL}/${picture.url}`,
        favourite: picture.favourite,
      })
    );
    orderedPictures = _.orderBy(pictures, "favourite", "desc");
  }

  return (
    <div>
      <Grid centered>
        <Grid.Row>
          {profile.pictures.length === 0 ? (
            <Image
              style={{
                borderRadius: 8,
                width: 400,
              }}
              src={speaker}
            />
          ) : (
            <ImageGallery
              items={orderedPictures}
              showPlayButton={false}
              showFullscreenButton={false}
              showThumbnails={false}
              lazyLoad={false}
              renderItem={renderItem}
              renderLeftNav={renderLeftNav}
              renderRightNav={renderRightNav}
            />
          )}
        </Grid.Row>
        <Grid.Row>
          {isSearching && (
            <Dimmer active inverted>
              <Loader size="mini">Loading</Loader>
            </Dimmer>
          )}
          <FileUpload upload={uploadPicture} />
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Pictures;
